export class StockTransfer {
  category: any;
  subcategory: any[] = [];
  locationFrom: any;
  warehouseFrom: any;
  binFrom: any;
  locationTo: any;
  warehouseTo: any;
  binTo: any;
  dateInput: any;
}
