import { Component, OnInit, Input } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { ItemService } from '@app/core/services/item.service';
import { DatePipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-item-tree-table',
  templateUrl: './item-tree-table.component.html',
  styleUrls: ['./item-tree-table.component.scss']
})
export class ItemTreeTableComponent implements OnInit {
  files = [];
  data: any[];
  loading: boolean;
  tableId: string;

  @Input()
  set treeData(values) {
    this.data = values;
  }

  @Input()
  set itemId(id) {
    this.tableId = id;
    if (this.tableId) {

    }
  }
  cols: any[];
  totalRecords: number;



  constructor(private itemService: ItemService, private decimalPipe: DecimalPipe) { }

  ngOnInit() {
    this.cols = [
      { field: 'location', header: 'Name', width: '50%' },
      // { field: 'bin', header: 'Bin', width: '10%' },
      // { field: 'batch', header: 'Batch#', width: '8%' },
      // { field: 'productionDate', header: 'Production Date', width: '10%' },
      // { field: 'expirydate', header: 'Expiry Date', width: '8%' },
      { field: 'instock', header: 'In Stock', width: '25%' },
      // { field: 'allocatedstock', header: 'Allocated Stock', width: '10%' },
      // { field: 'availablestock', header: 'Available Stock', width: '10%' },
      { field: 'value', header: 'Value', width: '25%' }
    ];
    this.totalRecords = 1000;

    this.loading = true;
    this.files = [];
    this.itemService.getLocationDetails(this.tableId).subscribe(
      (data: any) => {
        const pTree = [];
        for (let i = 0; i < data.body.length; i++) {
          const element = data.body[i];
          const node = {
            data: {
              location: element.location.name,
              bin: '',
              batch: '',
              locationId: element.location.id,
              productiondate: '',
              expirydate: '',
              instock: this.decimalPipe.transform(element.totalInStock, '1.3-3'),
              allocatedstock: element.totalAllocated,
              availablestock: element.totalAvailable,
              onOrder: element.totalOnOrder,
              isHighLight: false,
              value: this.decimalPipe.transform(element.totalValue, '1.3-3')
            },
            leaf: !element.warehousePresent
          };
          pTree.push(node);
        }
        this.files = pTree;
        this.files = [...this.files];
        this.loading = false;
      },
      err => {
        this.loading = false;
      }
    );
  }

  onNodeExpand(event) {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      const node = event.node;
      this.itemService.getFilteredStocks(this.tableId, node.data.locationId).subscribe(
        (data: any) => {
          const wHomeNamesList = Array.from(new Set(data.body.map(x => x.warehouse)));
          let pDatad = [];
          for (let i = 0; i < wHomeNamesList.length; i++) {
            let parentData = {
              data: {
                location: node.data.location + ' --> ' + wHomeNamesList[i],
                availablestock: undefined,
                allocatedstock: undefined,
                instock: undefined,
                onOrder: undefined,
                isHighLight: true,
                value: undefined
              }
            };

            let sumObject = {
               allocatedStockSum: 0,
               availableStockSum: 0,
               instockSum: 0,
               onOrderSum: 0,
               value: 0
            };
            data.body.filter(m => m.warehouse === wHomeNamesList[i]).forEach(x => {
              sumObject.allocatedStockSum = sumObject.allocatedStockSum + x.allocated;
              sumObject.availableStockSum = sumObject.availableStockSum + x.available;
              sumObject.instockSum = sumObject.instockSum + x.inStock;
              sumObject.onOrderSum = sumObject.onOrderSum + x.onOrder;
              sumObject.value = sumObject.value + x.value;
            });
            parentData.data.allocatedstock = sumObject.allocatedStockSum;
            parentData.data.availablestock = sumObject.availableStockSum;
            parentData.data.instock = this.decimalPipe.transform(sumObject.instockSum, '1.3-3');
            parentData.data.value = this.decimalPipe.transform(sumObject.value, '1.3-3');
            parentData.data.onOrder = sumObject.onOrderSum;
            pDatad.push(parentData);
          }

          node.children = pDatad;
          this.files = [...this.files];
        });
    }, 250);
  }
}
