import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
const httpOptions = {
  headers: new HttpHeaders({
    'api-end-type': 'ezyprocure'
  })
};

type FilterByDate = {fromDate: string, toDate:string};

@Injectable()
export class ManageStockService {

  constructor(public http: HttpClient) { }
  getWarehouse(id: any): Observable<any> {
    return this.http.get(`warehouse/all?locationId=${id.id}`);
  }
  getLocation(): Observable<any> {
    return this.http.get(`locations`);
  }
  getCategories(): Observable<any> {
    return this.http.get(`categories`);
  }
  getSubCategories(id: any): Observable<any> {
    return this.http.get(`subcategories?categoryId=${id}`);
  }
  getSubCategoriesByCategoryList(categoryIdList: any[]): Observable<any> {
    return this.http.put(`subcategories-for-catlist`, categoryIdList);
  }
  getBatchList(): Observable<any> {
    return this.http.get(`all-batches`);
  }
  getWarehouseList(): Observable<any> {
    return this.http.get(`all-warehouses`);
  }
  uploadCSV(formData: any): Observable<any> {
    return this.http.post(`stocktake/csvupload`, formData);
  }
  uploadStockinCSV(formData: any): Observable<any> {
    return this.http.post(`stockin/csvupload`, formData);
  }

  getStockDetails(
    wareId: any,
    subId: any,
    catId: any,
    locationId: any,
    itemId: any,
    pageNo: any,
    size: any,
    sortOrder: any
  ): Observable<HttpResponse<any>> {
    return this.http.get(
      `stock/filter?${wareId ? `&warehouseId=${wareId}` : ''}${
      subId ? `&subcategoryId=${subId}` : ''
      }${catId ? `&categoryId=${catId}` : ''}${
      locationId ? `&locationId=${locationId}` : ''
      }${itemId ? `&itemId=${itemId}` : ''}&transactionType=STOCKTAKE&page=${pageNo}&size=${size}&sort=${sortOrder}`,
      { observe: 'response' }
    );
  }

  getStockOutDetails(
    catId: any,
    subId: any,
    locationId: any,
    wareId: any,
    binId: any,
    itemId: any,
    pageNo: any,
    size: any,
    sort: any
  ): Observable<HttpResponse<any>> {
    return this.http.get(
      `stock/filter?${wareId ? `&warehouseId=${wareId}` : ''}${
      subId ? `&subcategoryId=${subId}` : ''
      }${catId ? `&categoryId=${catId}` : ''}${binId ? `&binId=${binId}` : ''}${itemId ? `&itemId=${itemId}` : ''}${
      locationId ? `&locationId=${locationId}` : ''
      }&transactionType=STOCKOUT&page=${pageNo}&size=${size}&sort=${sort}`,
      { observe: 'response' }
    );
  }

  getStockOutDetailsInitial(pageNo: any,
    size: any): Observable<HttpResponse<any>> {
      return this.http.get(
        `stock/filter?page=${pageNo}&size=${size}`,
        { observe: 'response' }
      );
    }
  getStockInDetails(pageNo: any,size: any, sort: any, transactionType:string, filterByDate: FilterByDate | null, poNumber: string): Observable<HttpResponse<any>>{
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(`stockin/report?&page=${pageNo}&size=${size}&sort=${sort}&transactionType=${transactionType}&ezyprocurePoNumber=${poNumber}`,filterByDate,
      { headers:headers,
        observe: 'response' }
    );
  }

  updateStockDetails(list: any): Observable<any> {
    return this.http.put(`stock/instock`, list);
  }

  getBin(id: any): Observable<any> {
    return this.http.get(`bins?warehouseId=${id}`);
  }

  getItemNames(): Observable<any> {
    return this.http.get(`item/all`);
  }

  getFormSubmission(form: any): Observable<any> {
    return this.http.post(`stockin/stockin`, form);
  }

  getTransDetails(id: any): Observable<any> {
    return this.http.get(`transaction-details?transactionNumber=${id}`);
  }

  getStockTransferDetails(id: any): Observable<any> {
    return this.http.get(`transfer-transaction-details?transactionNumber=${id}`);
  }

  geSupplierList() {

    return this.http.get<any>(`suppliers/all/visibility-type?visibilityType=ALL`, httpOptions);
  }

  getBuyerList() {
    return this.http.get<any>(`buyers?search=&`, httpOptions);
  }

  updateTransferDetails(obj: any) {
    return this.http.put(
      `stock/transfer`,
      obj
    );
  }

  getTransferDetails(
    subcategoryId: any,
    itemId: any,
    binFromId: any,
    wareId: any,
    pageNo: any,
    size: any,
    sort: any
  ): Observable<HttpResponse<any>> {
    let url =
      `stock/filter?subcategoryId=${subcategoryId}&itemId=${itemId}&warehouseId=${wareId}&transactionType=STOCKTRANSFER&page=${pageNo}&size=${size}&sort=${sort}`;
    if (!!binFromId) {
      url = `${url}&binId=${binFromId}`;
    }
    return this.http.get(
      url,
      { observe: 'response' }
    );
  }

  updateStockOutDetails(stockDTO: any): Observable<any> {
    return this.http.put(`stockout/stockout`, stockDTO);
  }

  getStockOutList(pageNo: any, size: any, sort: any, transactionType:string, filterByDate: FilterByDate | null): Observable<HttpResponse<any>> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    let response = this.http.post(
      `stockout/report?&page=${pageNo}&size=${size}&sort=${sort}&transactionType=${transactionType}`, filterByDate,
      {
        headers: headers,
        observe: 'response'
      }
    );
    return response;
  }

  getStockTakeList(pageNo: any, size: any, sort: any, filterByDate: FilterByDate | null): Observable<HttpResponse<any>> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    let response = this.http.post(
      `stocktake/report?&page=${pageNo}&size=${size}&sort=${sort}`, filterByDate,
      {
        headers: headers,
        observe: 'response'
      }
    );
    return response;
  }

  getStockTransferList(pageNo: any, size: any, sort: any, filterByDate: FilterByDate | null): Observable<HttpResponse<any>> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    let response = this.http.post(
      `stocktransfer/report?&page=${pageNo}&size=${size}&sort=${sort}`, filterByDate,
      {
        headers: headers,
        observe: 'response'
      }
    );
    return response;
  }

  getCSVDownload(
    itemApi: any,
    itemName: any,
    locName: any,
    wareName: any,
    filterByDate: any, unpaged: any, transactionType: string): Observable<HttpResponse<any>> {
    if (itemName)
      itemName = itemName.replace('%', '%25');
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

    if (itemApi === 'dashboard-minlist' || itemApi === 'dashboard-maxlist' || itemApi === 'dashboard-reorderlist') {

      return this.http.get(
        `${itemApi}?itemName=${itemName ? itemName : ''}&unpaged=${unpaged}`,
        { observe: 'response' });
    }
    else if (itemApi === 'stockin') {
      return this.http.post(
        `${itemApi}/report?itemName=${itemName ? itemName : ''}&locationName=${locName ? locName : ''}&warehouseName=${wareName ? wareName : ''}&transactionType=ALL&flag=false&unpaged=${unpaged}`, filterByDate,
        {
          headers: headers,
          observe: 'response'
        },
      );
    }
    else if (itemApi === 'stockout') {
      return this.http.post(
        `${itemApi}/report?itemName=${itemName ? itemName : ''}&locationName=${locName ? locName : ''}&warehouseName=${wareName ? wareName : ''}&transactionType=${transactionType}&flag=false&unpaged=${unpaged}`, filterByDate,
        {
          headers: headers,
          observe: 'response'
        },
      );
    }
    else {
      return this.http.post(
        `${itemApi}/report?itemName=${itemName ? itemName : ''}&locationName=${locName ? locName : ''}&warehouseName=${wareName ? wareName : ''}&flag=false&unpaged=${unpaged}`, filterByDate,
        {
          headers: headers,
          observe: 'response'
        },
      );
    }
  }

  getReportDetails(
    itemApi: any,
    itemName: any,
    locName: any,
    wareName: any,
    pageNo: any,
    size: number,
    filterByDate: any,
    sortOrder: any,
    transactionType: string
  ): Observable<HttpResponse<any>> {
    if (itemName)
      itemName = itemName.replace('%', '%25');
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

    if (itemApi === 'dashboard-minlist' || itemApi === 'dashboard-maxlist' || itemApi === 'dashboard-reorderlist') {
      return this.http.get(
        `${itemApi}?itemName=${itemName ? itemName : ''}&page=${pageNo}&size=${size}&sort=${sortOrder}`,
        { observe: 'response' },
      );
    }
    else if (itemApi === 'stocktake') {
      return this.http.post(
        `${itemApi}/report?itemName=${itemName ? itemName : ''}&locationName=${locName ? locName : ''}&warehouseName=${wareName ? wareName : ''}&flag=false&page=${pageNo}&size=${size}&sort=${sortOrder}`, filterByDate,
        {
          headers: headers,
          observe: 'response'
        },
      );
    }
    else if (itemApi === 'stocktransfer') {
      return this.http.post(
        `${itemApi}/report?itemName=${itemName ? itemName : ''}&locationName=${locName ? locName : ''}&warehouseName=${wareName ? wareName : ''}&flag=false&page=${pageNo}&size=${size}&sort=${sortOrder}`, filterByDate,
        {
          headers: headers,
          observe: 'response'
        },
      );
    }
    else if (itemApi === 'stockout') {
      return this.http.post(
        `${itemApi}/report?itemName=${itemName ? itemName : ''}&locationName=${locName ? locName : ''}&warehouseName=${wareName ? wareName : ''}&transactionType=${transactionType}&flag=false&page=${pageNo}&size=${10}&sort=${sortOrder}`, filterByDate,
        {
          headers: headers,
          observe: 'response'
        },
      );
    }
    else if (itemApi === 'stockin') {
      return this.http.post(
        `${itemApi}/report?itemName=${itemName ? itemName : ''}&locationName=${locName ? locName : ''}&warehouseName=${wareName ? wareName : ''}&transactionType=ALL&flag=false&page=${pageNo}&size=${size}&sort=${sortOrder}`, filterByDate,
        {
          headers: headers,
          observe: 'response'
        },
      );
    }
    else {
      return this.http.post(
        `${itemApi}/report?itemName=${itemName ? itemName : ''}&locationName=${locName ? locName : ''}&warehouseName=${wareName ? wareName : ''}&page=${pageNo}&size=${size}&sort=${sortOrder}`, filterByDate,
        {
          headers: headers,
          observe: 'response'
        },
      );
    }
  }

  checkBatch(batchNo: any): Observable<any> {
    return this.http.post(`batch-check`, batchNo);
  }
  getTreetableInventory(
    itemId: any,
    wareId: any,
    categoryId: any,
    batchId: any,
    locationId : any,
    pageNo: any, size: any, filterByDate: any, batchReport: boolean = false): Observable<HttpResponse<any>> {
    // return this.http.get(`inventory/summary?${itemId ? `&itemId=${itemId}` : ""}&page=${pageNo}&size=${size}`,
    // { observe: "response" }
    return this.http.get(
      `inventory/summary?${itemId ? `&itemId=${itemId}` : ''}${
      wareId ? `&warehouseId=${wareId}` : ''
      }${categoryId ? `&categoryId=${categoryId}` : ''}${
      batchId ? `&batchId=${batchId}` : ''}
      ${
      locationId ? `&locationId=${locationId}` : ''}
      &expiryDate=${filterByDate}&batchReport=${batchReport}`,
      { observe: 'response' }
    );
  }

  exportTreeInventoryReportCSV(
      itemId: any,
      wareId: any,
      categoryId: any,
      batchId: any,
      locationId : any,
      pageNo: any, size: any): Observable<HttpResponse<any>> {
      return this.http.get(
        `inventory/summary?${itemId ? `&itemId=${itemId}` : ''}${
        wareId ? `&warehouseId=${wareId}` : ''
        }${categoryId ? `&categoryId=${categoryId}` : ''}${
        batchId ? `&batchId=${batchId}` : ''}
        ${
        locationId ? `&locationId=${locationId}` : ''}
        &page=${pageNo}&size=10000`,
        { observe: 'response' }
      );
    }

    updateTransferFromViewDetails(obj: any) {
        return this.http.put(
          `stock/view/transfer`,
          obj
        );
      }

  getBatchItemNames(): Observable<any> {
    return this.http.get(`batch-item/all`);
  }

  getValidationLogs(
      date: any): Observable<HttpResponse<any>> {
      return this.http.get(`validations?&date=${date}`,
        { observe: 'response' }
      );
    }

    getStockOutExportDetails(pageNo: any, size: any, sortOrder: any): Observable<HttpResponse<any>> {
        return this.http.get(
          `stock/filter?transactionType=STOCKOUT&page=${pageNo}&size=${size}&sort=${sortOrder}`,
          { observe: 'response' }
        );
    }

    uploadStockOutCSV(formData: any): Observable<any> {
      return this.http.post(`stockout/csvupload`, formData);
    }

  //methods for variance report data

  getVarianceReportTable(
    itemName: any,
    wareNames: any,
    locNames: any,
    filterByDate: FilterByDate | null 
    ): Observable<HttpResponse<any>> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    let payload = {
      itemName : itemName ? itemName.name : '',
      locNames:[locNames],
      wareNames:wareNames,
      fromDate:filterByDate.fromDate,
      toDate: filterByDate.toDate
    };
    let response = this.http.post(
      `inventory/audit`, payload,
      {
        headers: headers,
        observe: 'response'
      }
    );
    return response;
  }

  exportVarianceReportCSV(
    itemId: any,
    wareId: any,
    categoryId: any,
    batchId: any,
    locationId: any,
    pageNo: any, size: any): Observable<HttpResponse<any>> {
    return this.http.get(
      `inventory/summary?${itemId ? `&itemId=${itemId}` : ''}${wareId ? `&warehouseId=${wareId}` : ''
      }${categoryId ? `&categoryId=${categoryId}` : ''}${batchId ? `&batchId=${batchId}` : ''}
          ${locationId ? `&locationId=${locationId}` : ''}
          &page=${pageNo}&size=10000`,
      { observe: 'response' }
    );
  }
}
