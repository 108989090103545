import { Component, OnInit, ViewChild } from '@angular/core';
import { Col } from '@app/shared/models/table-header.model';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddLocation } from '@app/shared/models/location.model';
import { WarehouseService } from '@app/core/services/warehouse.service';
import { Paginator } from '@app/shared/models/paginator.model';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { Country } from '@app/core/constants/country';
import { CountryListService } from '@app/core/services/country-list.service';
import { EditWarehouseComponent } from '../edit-warehouse/edit-warehouse.component';
import { HttpResponse } from '@angular/common/http';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { AddWarehouseComponent } from '../add-warehouse/add-warehouse.component';
import * as Constants from '@app/core/constants/common.constants';
import { ConfirmationService } from 'primeng/api';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
@Component({
  selector: 'app-list-warehouses',
  templateUrl: './list-warehouses.component.html',
  styleUrls: ['./list-warehouses.component.scss']
})
export class ListWarehousesComponent implements OnInit {
  name = new FormControl('');
  checked: boolean;
  bsModalRef: BsModalRef;
  dropdown: any;
  displayDialog: boolean;
  addLocation: AddLocation;
  breadcrumb: Breadcrumb[];
  locations: any;
  modalRef: BsModalRef;
  registerForm: FormGroup;
  submitted = false;
  countries: any;
  loc: any;
  filteredCountry: any;
  editLocation: any;
  router: any;
  paginator: Paginator;
  locationList: any;
  locationId: any;
  locationform: {
    code: any;
    name: any;
    address: any;
    location: { id: any };
    hasBin: boolean;
  };
  pageCount: number;
  totalCount: number;
  message: any;
  listingCount: number;
  cols: { field: string; header: string; width: string; }[];
  display: boolean;
  deletedId = null;
  csvList: any;
  csvOptions: any;
  warehouseReportList: any;
  location: any;
  count=1;
  constructor(
    private warehouseService: WarehouseService,
    private formBuilder: FormBuilder,
    private toastrMsgService: ToastrMsgService,
    private countryListService: CountryListService,
    private modalService: BsModalService,
    private confirmationService: ConfirmationService
  ) {
     this.csvOptions = {
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalseparator: '.',
              showLabels: true,
              showTitle: false,
              useBom: true,
              noDownload: false,
         headers: [
                'Sr.No',
                'Warehouse Code',
                'Warehouse Name',
                'Location',
                'Address'
              ]
              };
   }
  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;
  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.getLocationList();
    this.dropdown = Country;
    this.breadcrumb = [{ label: 'Manage Warehouses', routerLink: '' }];
    this.cols = [
      { field: 'code', header: 'Warehouse Code', width: '20%' },
      { field: 'name', header: 'Warehouse Name', width: '20%' },
      { field: 'location', header: 'Location', width: '15%' },
      { field: 'address', header: 'Address', width: '30%' }
    ];
    this.registerForm = this.formBuilder.group({
      warehouseId: ['', Validators.required],
      warehouseName: ['', Validators.required],
      bin: ['', Validators.required],
      address: ['', [Validators.required]],
      location: ['', [Validators.required]],
      check: ['']
    });
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",DESC";
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",ASC";
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getWarehouseList(this.paginator);
  }
  addLocations() {
    this.displayDialog = true;
  }
  getWarehouseList(lazyLoadData: Paginator) {
    this.warehouseService.getWarehouse(lazyLoadData).subscribe(
      (data: HttpResponse<any>) => {
        this.locations = data['body'];
        this.listingCount = data.body.length;
        this.totalCount = Number(data.headers.get('X-Total-Count'));
        if (this.totalCount <= this.pageCount) {
          this.paginator.paginator = false;
        } else {
          this.paginator.paginator = true;
        }
        this.paginator.loading = false;
        this.paginator.totalCount = this.totalCount;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);
        }
        else {
          this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
        }
      }
    );
  }
  getLocationList() {
    this.warehouseService.getLocationList().subscribe(
      data => {
        this.locationList = data['body'];
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);
        }
        else
          this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
  }
  changeEve(data) {
    if (data) {
      this.locationId = data.id;
    }
  }
  onSubmit() {
    this.submitted = true;
    this.locationform = {
      code: this.registerForm.value.warehouseId,
      name: this.registerForm.value.warehouseName,
      address: this.registerForm.value.address,
      location: { id: this.locationId },
      hasBin: this.checked
    };
    this.warehouseService.addWarehouse(this.locationform).subscribe(
      (data: any) => {
        this.getWarehouseList(this.paginator);
        this.toastrMsgService.success('', this.message.warehouseAddSuccessMsg, this.message.toastrTime);
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);
        }
        else
          this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
    this.registerForm.reset();
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
  }
  openModalWithComponent(locId: any) {
    this.warehouseService.setWarehouse(null);
    const initialState = {
      locationId: locId
    };
    this.modalService.onHide.subscribe((reason: string) => {
      if (this.warehouseService.getSavedWarehouse()) {
        this.getLocationList();
        this.getWarehouseList(this.paginator);
      }
    });
    this.bsModalRef = this.modalService.show(EditWarehouseComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  openModalAddLocation() {
    this.warehouseService.setWarehouse(null);
    this.modalService.onHide.subscribe((reason: string) => {
      if (this.warehouseService.getSavedWarehouse()) {
        this.getLocationList();
        this.getWarehouseList(this.paginator);
      }
    });
    this.bsModalRef = this.modalService.show(AddWarehouseComponent);
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  setCurrentPage(n: number) {
    const paging = {
      first: ((n - 1) * this.dataTable.rows),
      rows: this.dataTable.rows
    };
    this.loadUsersLazy(paging);
  }
  resetPagination($event?: any) {
    this.primePaginator.first = 0;
    this.paginator.pageNo = 0;
  }
  // deleteWarehouse(rowData: any) {
  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to delete this Warehouse?',
  //     icon: 'pi pi-info-circle',
  //     acceptLabel: 'yes',
  //     key: 'deleteWarehouse',
  //     rejectLabel: 'no',
  //     accept: () => {
  //       this.warehouseService.deleteWarehouse(rowData).subscribe(
  //         (data: HttpResponse<any>) => {
  //           this.toastrMsgService.success(
  //             '',
  //             this.message.warehouseDltMsg,
  //             this.message.toastrTime
  //           );
  //           if(this.listingCount==1)
  //       {
  //       this.setCurrentPage(this.paginator.pageNo);
  //     }
  //           this.getWarehouseList(this.paginator);


  //         },
  //         (err: any) => {
  //           if (navigator.onLine === false) {
  //             this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);

  //           }
  //           else{
  //           this.getWarehouseList(this.paginator);
  //           this.toastrMsgService.error('', this.message.activeBinExistMsg, this.message.toastrTime);
  //         }}
  //       );
  //     },
  //     reject: () => {
  //     }
  //   });
  // }
  cancel() {
    this.displayDialog = false;
  }
  deleteWarehouse(deletedId) {
    this.warehouseService.deleteWarehouse(deletedId).subscribe(
      (data: HttpResponse<any>) => {
        this.toastrMsgService.success(
          '',
          this.message.warehouseDltMsg,
          this.message.toastrTime
        );
        if (this.listingCount == 1) {
          this.setCurrentPage(this.paginator.pageNo);
        }
        this.getWarehouseList(this.paginator);

        this.display = false;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);

        }
        else {
          this.getWarehouseList(this.paginator);
          this.toastrMsgService.error('', this.message.activeBinExistMsg, this.message.toastrTime);
        }
      }
    );
  }
 getWarehouseItemList(lazyLoadData: Paginator) {
    this.warehouseService.getWarehouse(lazyLoadData).subscribe(
    data=>{
      //(data: HttpResponse<any>) => {
      this.warehouseReportList=data.body;
               this.csvList=[];
               this.warehouseReportList.forEach(item=> {
               this.csvList.push({
                                     SerialNo:this.count++,
                                     code: item.code,
                                     name: item.name,
                                     location: item.location.name,
                                     address: item.address
                                     });
                 });
      new AngularCsv(this.csvList, 'Warehouse', this.csvOptions);
        this.locations = data['body'];
        this.listingCount = data.body.length;
        this.totalCount = Number(data.headers.get('X-Total-Count'));
        if (this.totalCount <= this.pageCount) {
          this.paginator.paginator = false;
        } else {
          this.paginator.paginator = true;
        }
        this.paginator.loading = false;
        this.paginator.totalCount = this.totalCount;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);

        }
        else {
          this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
        }
      }
    );
  }
      downloadCSV(){
       this.getWarehouseItemList(this.paginator);
      }
}
