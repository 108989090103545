import { Component, OnInit } from '@angular/core';
import { HttpResponse } from "@angular/common/http";
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { Paginator } from "@app/shared/models/paginator.model";
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { DatePipe } from '@angular/common';

type TreeNode = {
  data?: any;
  children?: TreeNode[];
  leaf?: boolean;
  expanded?: boolean;
}

@Component({
  selector: 'audit-log-report',
  templateUrl: './audit-log-report.component.html',
  styleUrls: ['./audit-log-report.component.scss']
})
export class AuditLogReportComponent implements OnInit {

  message: any;
  pageCount: number;
  totalCount: number;
  loading: boolean;
  files: TreeNode[];
  paginator: Paginator;
  logDate: Date;
  csvOptions: any;
  csvHeaders: any[];

  constructor(private stockService: ManageStockService, private toastrMsgService: ToastrMsgService,private datePipe: DatePipe,) { }

  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.paginatorInitial();

    this.csvHeaders = ['Sl.No', 'Date', 'Transaction Type', 'Location Code', 'Item Code', 'Item Name', 'Quantity', 'UOM', 'Remark', 'Error Message']
    this.csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: this.csvHeaders,
    }
  }

  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: "",
      sortColumn: "",
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  loadUsersLazy(event: any) {
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
  }

  generateCSVList(responseData: any[]) {
    let csvList = [];
    if (responseData.length) {
      let rowCount = 0;
      responseData.forEach((data) => {
        csvList.push({
          slNo: ++rowCount,
          date: data.date.split('T')[0],
          transactionType: data.subTransactionType,
          locationCode: data.locationCode,
          itemCode: data.itemCode,
          itemName: data.itemName || '',
          quantity: data.quantity,
          uom: data.uom,
          remark: data.remark,
          errorMessage: data.errorMessage,
        });
      });
      this.exportCSV(csvList);
    }
  };

  exportCSV(csvList) {
    new AngularCsv(csvList, 'Auto Consumption Report', this.csvOptions);
  }

  getCSVData() {
    let responseData = [];
    let date = this.datePipe.transform(new Date(this.logDate),'yyyyMMdd');
    this.stockService.getValidationLogs(date)
      .subscribe(
        (data: HttpResponse<any>) => {
          responseData = data.body;
          if(data.body.length === 0){
            this.toastrMsgService.error(
              '',
              "No logs found for selected date",
              this.message.toastrTime
            );
          } else {
            this.generateCSVList(responseData);
          }
        },
        (err: any) => {
          this.handleResponseError();
        }
      );
  }
  handleResponseError() {
    !navigator.onLine ? this.toastrMsgService.offlineAlert() : this.toastrMsgService.error();
  }
}
