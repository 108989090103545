import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { StockTransfer } from '@app/shared/models/stock-transfer.model';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { DatePipe } from '@angular/common';
import { Paginator } from '@app/shared/models/paginator.model';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import * as Constants from '@app/core/constants/common.constants';
import { Col } from '@app/shared/models/table-header.model';
import { HttpResponse } from '@angular/common/http';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { ItemService } from '@app/core/services/item.service';
import { Dropdown, MultiSelect } from 'primeng/primeng';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-stock-transfer',
  templateUrl: './stock-transfer-add.component.html',
  styleUrls: ['./stock-transfer-add.component.scss']
})
export class StockTransferAddComponent implements OnInit {
  stockTransfer = new StockTransfer();
  color: string = null;
  breadcrumb: MenuItem[];
  cols: Col[];
  stockUpdateList = [];
  stockTransferObj = {};
  checked: boolean;
  apiNotCalling: boolean = false;
  display: boolean = false;
  paginator: Paginator;
  pageCount: number;
  totalCount: number;
  items: MenuItem[];
  tableShow: boolean = false;
  stockTransferList: any[] = [];
  _stockTransferList: any[] = [];
  categories: SelectItem[];
  subCategories: SelectItem[] = [];
  myTextarea: any;
  message: any;
  toTransferItemList: any[] = [];
  location: any;
  locationToArray = [];
  wareHouse: any;
  wareHouseTo: any;
  locationTo: any;
  binTo: any;
  bin: any;
  noBin1: boolean;
  noBin2: boolean;
  currentDate = new Date();
  selectedCategories: string[] = [];
  restrictTransfer: boolean = false;
  showPreview: boolean = false;
  itemList: any = [];
  selectedItem: any = null;

  constructor(
    public stockService: ManageStockService,
    private toastrMsgService: ToastrMsgService,
    public itemService: ItemService,
    private datePipe: DatePipe,
    public router: Router,private changeDetectorRef: ChangeDetectorRef
  ) {
    this.categoryDetails();
    this.locationDetails();
    this.itemDetails();
  }

  @ViewChild('ddStatusWareSrc') ddStatusWareSrc: Dropdown;
  @ViewChild('ddStatusLocSrc') ddStatusLocSrc: Dropdown;
  @ViewChild('ddStatusBinSrc') ddStatusBinSrc: Dropdown;
  @ViewChild('ddStatusWareDest') ddStatusWareDest: Dropdown;
  @ViewChild('ddStatusLocDest') ddStatusLocDest: Dropdown;
  @ViewChild('ddStatusBinDest') ddStatusBinDest: Dropdown;
  @ViewChild('ddStatusSub') ddStatusSub: Dropdown;
  @ViewChild('ddStatusCat') ddStatusCat: Dropdown;
  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt1') dataTable: Table;

  ngOnInit() {
    this.breadcrumb = [
      { label: 'Manage Stock Transfer', routerLink: '/stock/transfer' },
      { label: 'Add New Stock Transfer', url: '' }
    ];
    this.message = Constants.MESSAGES;
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.cols = [
      { field: 'itemCode', header: 'Item Code', width: "auto" },
      { field: 'itemName', header: 'Item Name', width: "20%" },
      { field: 'uom', header: 'Uom', width: "8%" },
      { field: 'inStock', header: 'In Stock', width: "9%" },
      { field: 'selectQuantity', header: 'Transfer Quantity', width: "9%" },
      { field: 'bin', header: 'From Bin (Shelf/Rack)', width: "auto" },
      { field: 'toBin', header: 'To Bin (Shelf/Rack)', width: "auto" },
      { field: 'batch', header: 'Batch No', width: "8%" }
    ];
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: 'createdDate,desc',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
    this.noBin1 = true;
    this.noBin2 = true;
    this.stockTransfer.dateInput = this.currentDate;
  }

  get disableTransfer() {
    return (!this.myTextarea ||
      !this.stockTransfer.warehouseTo ||
      this.toTransferItemList.length === 0) || this.restrictTransfer;
  }

  openPreview() {
    this.showPreview = true;
  }

  itemDetails() {
    this.itemService.getItem().subscribe(
      (data: any) => {
        this.itemList = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  itemChange() {
    this.getTransferDetails(this.paginator);
  }

  mapToOptions(element: any) {
    let options = {
      label: element.name,
      value: element.id
    }
    return options;
  }
  // to get the category details
  categoryDetails() {
    this.stockService.getCategories().subscribe(
      (data: any) => {
        if (data) {
          this.categories = data.map(this.mapToOptions);
        }
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // on category change
  catChange() {
    this.stockTransfer.subcategory = [];
    this.filterBySubCategory();
    this.subCatDetails();
  }

  // to get subcategory details
  subCatDetails() {
    this.stockService
      .getSubCategoriesByCategoryList(this.selectedCategories)
      .subscribe(
        (data: any) => {
          if (data) {
          this.subCategories = data.map(this.mapToOptions);
          }
        },
        (err: any) => {
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              '',
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
        }
      );
  }
  // to get location details
  locationDetails() {
    this.stockService.getLocation().subscribe(
      (data: any) => {
        this.location = data;

        this.locationToArray = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // on location change
  locChange() {
    this.stockTransfer.warehouseFrom = null;
    this.stockTransfer.binFrom = null;
    this.tableShow = false;
    this.warehouseDetails();

    this.toTransferItemList = [];
  }
  // on location to change
  locToChange() {
    this.stockTransfer.binTo = null;
    this.stockTransfer.warehouseTo = null;
    this.warehouseToDetails();
  }
  // on subcategory change
  subCatChange() {
    this.filterBySubCategory();
  }

  filterBySubCategory() {
    this.getTransferDetails(this.paginator);
    this.toTransferItemList = [];
  }

  // to get warehouse details
  warehouseDetails() {
    this.stockService.getWarehouse(this.stockTransfer.locationFrom).subscribe(
      (data: any) => {
        this.wareHouse = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // to get warehouse to details
  warehouseToDetails() {
    this.stockService.getWarehouse(this.stockTransfer.locationTo).subscribe(
      (data: any) => {
        this.wareHouseTo = data;
        if (this.stockTransfer.locationFrom === this.stockTransfer.locationTo) {
          if (
            this.bin.length === 0 ||
            (this.bin.length === 1 && this.stockTransfer.binFrom)
          ) {
            this.wareHouseTo.forEach((item, index) => {
              if (item.id === this.stockTransfer.warehouseFrom.id) {
                this.wareHouseTo.splice(index, 1);
              }
            });

            this.wareHouseTo = [...this.wareHouseTo];
          }
        }
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // on warehouse change
  warehouseChange() {
    this.stockTransfer.binFrom = null;
    this.tableShow = false;
    this.binDetails();
    this.getTransferDetails(this.paginator);
    this.toTransferItemList = [];
  }
  // on warehouse to change
  WarehouseToChange() {
    this.stockTransfer.binTo = null;
    this.binToDetails();
  }
  
  // on bin change
  binChange() {
    this.getTransferDetails(this.paginator);
    this.toTransferItemList = [];
  }
  // to get bin details
  binDetails() {
    this.stockService.getBin(this.stockTransfer.warehouseFrom.id).subscribe(
      (data: any) => {
        this.bin = data;
        if (this.bin.length === 0) {
          this.stockTransfer.binFrom = [];
          this.stockTransfer.binFrom.id = '';
          this.getTransferDetails(this.paginator);
          this.noBin1 = true;
        } else {
          this.noBin1 = false;
        }
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // to get bin to details
  binToDetails() {
    this.stockService.getBin(this.stockTransfer.warehouseTo.id).subscribe(
      (data: any) => {
        this.binTo = data;
        if (this.binTo.length !== 0) {
          this.binTo = [...this.binTo];
          this.noBin2 = false;
        } else {
          this.noBin2 = true;
        }
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // to gt list of to bins for selected row
  getSelectedRowBinToList(rowData: any) {
    if (!!this.binTo && this.binTo.length !== 0) {
      const rowSpecificBinList: any[] = JSON.parse(JSON.stringify(this.binTo));
      rowSpecificBinList.forEach((item, index) => {
        if (item.name === rowData.bin) {
          rowSpecificBinList.splice(index, 1);
        }
      });
      rowSpecificBinList.unshift({id: null, name: null});
      return rowSpecificBinList;
    }
    return [];
  }

  clearCategoryFilters() {
    this.stockTransfer.category = [];
    this.stockTransfer.subcategory = [];
  }

  // on transfer
  getTransferDetails(lazyLoadData: Paginator) {
    if (!!this.stockTransfer.subcategory && !!this.stockTransfer.warehouseFrom) {
      this.stockService
        .getTransferDetails(
          Array.isArray(this.stockTransfer.subcategory) ? this.stockTransfer.subcategory.join(',') : "",
          this.selectedItem ? this.selectedItem.id || "" : "",
          !!this.stockTransfer.binFrom ? this.stockTransfer.binFrom.id : null,
          this.stockTransfer.warehouseFrom.id,
          lazyLoadData.pageNo,
          lazyLoadData.limit,
          lazyLoadData.sortOrder
        )
        .subscribe(
          (data: HttpResponse<any>) => {
            this.stockTransferList = data.body;
            //backup list
            this._stockTransferList = data.body;
            this.tableShow = true;
            this.stockTransferList.forEach(item => {
              item.selectQuantity = null;
              item.checkboxValue = false;
              if (this.toTransferItemList.length > 0) {
                this.toTransferItemList.forEach(itemNew => {
                  if (itemNew.id === item.id) {
                    if (itemNew.selectQuantity !== null) {
                      item.selectQuantity = itemNew.selectQuantity;

                      item.checkboxValue = itemNew.checkboxValue;
                    } else {
                      item.checkboxValue = itemNew.checkboxValue;
                    }
                  }
                });
              }

              if (typeof item.inStock !== 'object') {
                item.inStock = item.inStock.toString();
              }
            });

            this.totalCount = Number(data.headers.get('X-Total-Count'));

            if (this.totalCount <= this.pageCount) {
              this.paginator.paginator = false;
            } else {
              this.paginator.paginator = true;
            }
            this.paginator.loading = false;
            this.paginator.totalCount = this.totalCount;
          },
          (err: any) => {
            this.paginator.loading = false;
            if (navigator.onLine === false) {
              this.toastrMsgService.warning(
                '',
                this.message.noInternet,
                this.message.toastrTime
              );
            } else {
              this.toastrMsgService.error(
                '',
                this.message.errorMsg,
                this.message.toastrTime
              );
            }
          }
        );
    } else {
      this.tableShow = false;
    }
  }
  // on pagination changes
  loadUsersLazy(event: any) {
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getTransferDetails(this.paginator);
  }

  // on bin to change
  onToBinChange(rowData: any) { 
    //get index
    let selectedRowIndex = this.toTransferItemList.findIndex((item) => item.id == rowData.id);
    // update list based on index
    if (selectedRowIndex === -1 && rowData.toBin) {
      this.toTransferItemList.push(JSON.parse(JSON.stringify(rowData)));
      this.toTransferItemList[this.toTransferItemList.length - 1].toBin = JSON.parse(JSON.stringify(rowData.toBin));
      this.toTransferItemList[this.toTransferItemList.length - 1].toBinId = rowData.toBin.id;
    }
    else if (selectedRowIndex > -1 && (!rowData.toBin || !rowData.toBin.id)) {
      this.toTransferItemList.splice(selectedRowIndex, 1);
    }
    else if(selectedRowIndex > -1 && (rowData.toBin && rowData.toBin.id)) {
      this.toTransferItemList[selectedRowIndex].toBin = JSON.parse(JSON.stringify(rowData.toBin));
      this.toTransferItemList[this.toTransferItemList.length - 1].toBinId = rowData.toBin.id;
    }
    this.updateBackupList(rowData, "BIN");
  }

  removeItem(index: number) {
    this.toTransferItemList.splice(index, 1);
  }
  checkavailableQty(rowData){
    rowData.selectQuantity= (rowData.selectQuantity>rowData.available)?rowData.available:rowData.selectQuantity;
    this.changeDetectorRef.detectChanges();
  }
  /** on quantity change */
  modelChange(rowData: any) {
    rowData.selectQuantity= (rowData.selectQuantity>rowData.available)?rowData.available:rowData.selectQuantity;
    this.changeDetectorRef.detectChanges();
    //set field highlight color
    if (!rowData.selectQuantity || (rowData.selectQuantity <= rowData.available)) {
      rowData.color = undefined;
    }
    else if (rowData.selectQuantity > rowData.available) {
      rowData.selectQuantity = rowData.available;
      rowData.color = 'red'
    }
    //get index
    let selectedRowIndex = this.toTransferItemList.findIndex((item) => item.id == rowData.id);
    // update list based on index
    if (selectedRowIndex === -1 && rowData.selectQuantity) {
      this.toTransferItemList.push(JSON.parse(JSON.stringify(rowData)));
    }
    else if(selectedRowIndex > -1 && rowData.selectQuantity) {
      this.toTransferItemList.splice(selectedRowIndex, 1, rowData);
    }
    this.updateBackupList(rowData, "QUANTITY");
  }

  updateBackupList(rowData: any, field: string) {
    let index = this._stockTransferList.findIndex((item) => item.id == rowData.id);
    if(field == "QUANTITY") {
      this._stockTransferList[index].selectQuantity = rowData.selectQuantity;
    }
    else if(field == "BIN") {
      this._stockTransferList[index].toBin = JSON.parse(JSON.stringify(rowData.toBin));
    }
  }

  rowSelect(event: any) {
    event.checkboxValue = !event.checkboxValue;
    if (event.checkboxValue === true) {
      this.toTransferItemList.push(event);
    } else {
      this.toTransferItemList.forEach(item => {
        if (item.id === event.id) {
          this.toTransferItemList.splice(this.toTransferItemList.indexOf(item), 1);
        }
      });
    }
  }

  reset() {
    this.stockTransfer = new StockTransfer();
    this.myTextarea = null;
    this.toTransferItemList = [];
    this.tableShow = false;
    this.router.navigateByUrl('/stock/transfer');
  }
  binClick(event: any) {
    if (event === 'ddStatusWareSrc') {
      this.ddStatusWareSrc.filled = true;
    } else if (event === 'ddStatusLocSrc') {
      this.ddStatusLocSrc.filled = true;
    } else if (event === 'ddStatusBinSrc') {
      this.ddStatusBinSrc.filled = true;
    } else if (event === 'ddStatusWareDest') {
      this.ddStatusWareDest.filled = true;
    } else if (event === 'ddStatusLocDest') {
      this.ddStatusLocDest.filled = true;
    } else if (event === 'ddStatusBinDest') {
      this.ddStatusBinDest.filled = true;
    } else if (event === 'ddStatusSub') {
      this.ddStatusSub.filled = true;
    } else if (event === 'ddStatusCat') {
      this.ddStatusCat.filled = true;
    }
  }
  onchange(event: any) { }
  readyTransfer() {
    this.display = true;
  }
  onHide(event: any) {
    this.display = false;
    this.myTextarea = null;

    this.stockTransfer.locationTo = null;
    this.stockTransfer.warehouseTo = null;
    this.stockTransfer.binTo = null;
    this.toTransferItemList = [];
  }
  // transfer the selected items
  finalProceed() {
    this.stockUpdateList = [];
    if (
      this.stockTransfer.warehouseTo != null &&
      this.myTextarea &&
      this.myTextarea.length > 0
    ) {

      let filteredList = this.toTransferItemList.filter(item => {
        return item.selectQuantity;
      });

      filteredList.forEach(item => {
        this.stockUpdateList.push({
          stockId: item.id,
          selectQuantity: item.selectQuantity,
          selectedBin: item.toBinId
        });
      });
      this.apiNotCalling = false;
      filteredList.forEach(item => {
        if (
          item.selectQuantity == null ||
          item.selectQuantity === 0 ||
          item.selectQuantity === ''
        ) {
          this.apiNotCalling = true;
        }
      });
      if (this.apiNotCalling === false) {
        this.restrictTransfer = true;
        let stockTransferObj = {
        "destinationWarehouseId" : this.stockTransfer.warehouseTo.id,
        "comment": this.myTextarea,
        "transferList":  this.stockUpdateList,
        "createdDate": this.stockTransfer.dateInput
        }
        this.stockService
          .updateTransferDetails(stockTransferObj)
          .subscribe(
            (data: any) => {
              this.tableShow = false;
              this.stockUpdateList = [];
              this.toTransferItemList = [];
              this.selectedCategories = [];
              this.myTextarea = null;
              this.stockTransfer = new StockTransfer();
              this.stockTransferList = [];
              this.restrictTransfer = false;
              this.toastrMsgService.success(
                '',
                this.message.transferSuccessMsg,
                this.message.toastrTime
              );
              this.showPreview = false;
              this.router.navigateByUrl('/stock/transfer');
            },
            (err: any) => {
              this.paginator.loading = false;
              this.restrictTransfer = false;
              if (navigator.onLine === false) {
                this.toastrMsgService.warning(
                  '',
                  this.message.noInternet,
                  this.message.toastrTime
                );
              } else {
                this.toastrMsgService.error(
                  '',
                  this.message.errorMsg,
                  this.message.toastrTime
                );
              }
            }
          );
      } else {
        this.toastrMsgService.error(
          '',
          this.message.errorMsgQuantity, // TODO
          this.message.toastrTime
        );
      }
    }
  }
}
