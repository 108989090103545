import { ItemService } from '@app/core/services/item.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Col } from "@app/shared/models/table-header.model";
import { Breadcrumb } from "@app/shared/models/breadcrumb.model";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WarehouseService } from "@app/core/services/warehouse.service";
import { AddLocation } from "@app/shared/models/location.model";
import { BinService } from "@app/core/services/bin.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { Country } from "@app/core/constants/country";
import { CountryListService } from "@app/core/services/country-list.service";
import { Router } from '@angular/router';
import { LocationService } from '@app/core/services/location.service';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';

@Component({
  selector: 'app-edit-warehouse',
  templateUrl: './edit-warehouse.component.html',
  styleUrls: ['./edit-warehouse.component.scss']
})
export class EditWarehouseComponent implements OnInit, AfterViewInit {
  name = new FormControl("");
  cols: Col[];
  locationId: any;
  lastNam: string;
  dropdown: any;
  displayDialog: boolean;
  addLocation: AddLocation;
  breadcrumb: Breadcrumb[];
  locations: any;
  registerForm: FormGroup;
  submitted = false;
  countries: any;
  filteredCountry: any;
  editLocation: any;
  messageEvent: any;
  locationList: any;
  warehouseList: any;
  warehouseId: any;
  message: any;
  locationIdSub: any;
  locationform: { id: any; code: any; name: any; location: { id: any; }; address: any; hasBin: any; defaultWarehouse: any;};
  duplicateCode: boolean;
  oldValue:any;
  constructor(private locationService: LocationService, public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder, private router: Router,
    private countryListService: CountryListService,
    private binService: BinService,
    private toastrMsgService: ToastrMsgService,
    private modalService: BsModalService,
    private  itemService:ItemService,
    private warehouseService: WarehouseService) { }
  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.getLocation();
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    this.dropdown = Country;
    this.registerForm = this.formBuilder.group({
      warehouseId: [""],
      warehouseName: [""],
      bin: [""],
      warehouse: [""],
      address: [""],
      location: [""],
      check: [""],
      checked: [""]
    });
    this.editLocations(this.locationId);
  }
  ngAfterViewInit() {
    this.displayDialog = true;

  }
  get f() {
    return this.registerForm.controls;
  }

  editLocations(locId: any) {

    locId = this.locationId
    this.warehouseService.editWarehouse(locId).subscribe(
      data => {

        this.editLocation = data["body"];
        this.registerForm.value.locationCode = this.editLocation.code;
        this.locationIdSub = this.editLocation.location.id;
        this.oldValue = this.editLocation.code;
        this.registerForm.setValue({

          "location": this.editLocation.location.name,
          "warehouseId": this.editLocation.code,
          "warehouseName": this.editLocation.name,
          "address": this.editLocation.address,
          "check": this.editLocation.hasBin,
          "bin": this.editLocation.hasBin,
          "checked": this.editLocation.defaultWarehouse,
          "warehouse": this.editLocation.defaultWarehouse
        });
      },

      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
  }
  getLocation() {

    this.warehouseService.getLocationList().subscribe(
      data => {

        this.locationList = data["body"];

      },

      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
  }
  getWarehouse(id: any) {

    this.warehouseService.getWarehouseList(id).subscribe(
      data => {

        this.warehouseList = data["body"];


      },
      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
  }

  onSubmit() {
    this.submitted = true;

    this.locationform = {
      id: this.locationId,
      code: this.registerForm.value.warehouseId,
      name: this.registerForm.value.warehouseName,
      location: { id: this.locationIdSub },
      address: this.registerForm.value.address,
      hasBin: this.registerForm.value.bin,
      defaultWarehouse: this.registerForm.value.warehouse
    };
    this.warehouseService
      .editWarehousePost(this.locationform)
      .subscribe((data: any) => {
        this.toastrMsgService.success('', 'Warehouse updated successfully', 3000);
        this.warehouseService.setWarehouse(data);
        this.bsModalRef.hide();
        this.displayDialog = false;
      }, (err: any) => {

        if (err.status == 400) {
          this.toastrMsgService.error('', 'Active bins exists for warehouse.Delete bins and try again', 4000);

        }

      });



  }
  cancel() {

    this.bsModalRef.hide();
    this.displayDialog = false;
  }
  checkDuplicate(type,item){
    this.itemService.checkDuplication(type,item).subscribe(
      (data:any)=>{
        if(data){
          if(this.oldValue ===item){
            this.duplicateCode = false;
          }else{
            this.duplicateCode = data.body;
          }
        }
      },err=>{

      }
    );
  }
}

