import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Col } from "@app/shared/models/table-header.model";
import { Breadcrumb } from "@app/shared/models/breadcrumb.model";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { WarehouseService } from "@app/core/services/warehouse.service";
import { AddLocation } from "@app/shared/models/location.model";
import { BinService } from "@app/core/services/bin.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { Country } from "@app/core/constants/country";
import { CountryListService } from "@app/core/services/country-list.service";
import { Router } from "@angular/router";
import { LocationService } from "@app/core/services/location.service";
import { ToastrMsgService } from "@app/shared/services/toastr-msg.service";
import * as Constants from "@app/core/constants/common.constants";

@Component({
  selector: "app-add-bin",
  templateUrl: "./add-bin.component.html",
  styleUrls: ["./add-bin.component.scss"]
})
export class AddBinComponent implements OnInit {
  name = new FormControl("");
  cols: Col[];
  lastNam: string;
  dropdown: any;
  displayDialog: boolean;
  addLocation: AddLocation;
  breadcrumb: Breadcrumb[];
  locations: any;
  locdata: any;
  data: any;
  binCodeAutoGEneration: any = '';
  modalRef: BsModalRef;
  registerForm: FormGroup;
  submitted = false;
  countries: any;
  filteredCountry: any;
  editLocation: any;
  router: any;
  locationList: any;
  warehouseList: any;
  warehouseId: any;
  locationform: { name: any; code: any; warehouse: { id: any }, defaultBin: boolean; };
  warehouse: { locationId: any };
  message: any;
  binList: any;
  locId: any;
  checked: boolean = false;
  constructor(
    private toastrMsgService: ToastrMsgService,
    private binService: BinService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private countryListService: CountryListService,
    private modalService: BsModalService,
    private warehouseService: WarehouseService
  ) { }
  ngOnInit() {
    this.message = Constants.MESSAGES;
    // FUNCTION CALL
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    this.displayDialog = true;
    this.getLocation();
    this.dropdown = Country;
    this.breadcrumb = [{ label: "Manage Bin", routerLink: "" }];
    this.cols = [
      { field: "location", header: "Location" },
      { field: "warehouseName", header: "WarehouseName" },
      { field: "binId", header: "Bin (Shelf/Rack) ID" },
      { field: "binName", header: "Bin (Shelf/Rack) Name" }
    ];
    this.registerForm = this.formBuilder.group({
      locationCode: ["", Validators.required],
      warehouseName: ["", Validators.required],
      binId: [""],
      binName: ["", [Validators.required]],
      bin: [""]
    });
    this.checkBinCount();
  }

  checkBinCount() {
    this.binService.checkBinAvailable().subscribe(response => {
      if(response.body == 0) {
        this.checked = true;
        this.registerForm.controls['bin'].disable();
      };
    },
    (error) => {

    })
  }

  addLocations() {
    this.displayDialog = true;
  }

  changeEve(data) {
    this.getBinId(this.locId.code, data.code);
    if (data) {
      this.warehouseId = data.id;
    }
  }
  changeEveLoc(locdata) {
    this.locId = locdata;

    if (locdata) {
      this.getWarehouse(locdata.id);
    }
  }

  getLocation() {
    this.warehouseService.getLocationList().subscribe(
      data => {
        this.locationList = data["body"];
      },

      (err: any) => { }
    );
  }
  getWarehouse(id: any) {
    this.warehouseService.getWarehouseListForBin(id, 'ADD_BIN').subscribe(
      data => {
        this.warehouseList = data["body"];
      },
      (err: any) => { }
    );
  }
  getBinId(id1: any, id2: any) {
    this.warehouseService.getBin(id1, id2).subscribe(
      resp => {
        this.binCodeAutoGEneration = resp["body"]["code"]


      });
  }
  onSubmit() {
    this.submitted = true;

    this.locationform = {
      name: this.registerForm.value.binName,
      code: this.binCodeAutoGEneration,
      warehouse: { id: this.warehouseId },
      defaultBin: this.checked,
    };

    this.binService
      .addbin(this.locationform)
      .subscribe((data: any) => {
        this.toastrMsgService.success(
          "",
          this.message.binAddSuccessMsg,
          this.message.toastrTime
        );
        this.binService.setBin(data);
        this.bsModalRef.hide();

        this.registerForm.reset();
      }, (err: any) => { });
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
  }

  cancel() {
    this.bsModalRef.hide();
    this.displayDialog = false;
  }
}
