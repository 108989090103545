import { Component, OnInit } from '@angular/core';
import { HttpResponse } from "@angular/common/http";
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { Paginator } from "@app/shared/models/paginator.model";
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';

type TreeNode = {
  data?: any;
  children?: TreeNode[];
  leaf?: boolean;
  expanded?: boolean;
}

@Component({
  selector: 'batch-expiry-report',
  templateUrl: './expiry-report.component.html',
  styleUrls: ['./expiry-report.component.scss']
})
export class ExpiryReportComponent implements OnInit {

  message: any;
  pageCount: number;
  totalCount: number;
  loading: boolean;
  files: TreeNode[];
  paginator: Paginator;
  expiryDate: Date;
  warehouse: any;
  location: any;
  item:any;
  warehousesList: any;
  locationsList: any;
  itemsList: any;
  filters: any;
  cols: any[];
  treeTableInventoryList = [];
  csvOptions: any;
  csvHeaders: any[];


  constructor(private stockService: ManageStockService, private toastrMsgService: ToastrMsgService,) { }

  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.paginatorInitial();
    this.filters = {
      itemId: undefined,
      locationId: undefined,
      warehouseId: undefined,
      expiryDate: undefined
    }
    this.cols = [
      { field: "name", header: "Code", width: "30%" },
      { field: "item", header: "Name", width: "30%" },
      { field: "uom", header: "UOM", width: "10%" },
      { field: "totalInStock", header: "In Stock", width: "10%" },
      { field: "totalValue", header: "Value", width: "10%" }

    ];
    this.csvHeaders = ['Sl.No', 'Code', 'Name', 'Inv. UoM', 'Batch Qty', 'Value', 'Location', 'Warehouse', 'Bin', 'Batch No', 'Production Date', 'Expiry Date']
    this.csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: this.csvHeaders,
    }
    this.itemNameDetails();
    this.locationDetails();
    this.getTreeInventoryTableDetails(this.paginator);
  }

  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: "",
      sortColumn: "",
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  loadUsersLazy(event: any) {
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getTreeInventoryTableDetails(this.paginator);
  }

  getTreeInventoryTableDetails(lazyData: Paginator) {
    let expiryISOString: string;
    expiryISOString = this.expiryDate ? this.setUTCDateTime(this.expiryDate).toISOString() : "";
    this.loading = true;
    this.stockService
      .getTreetableInventory(
        this.filters.itemId,
        this.filters.wareId,
        this.filters.categoryId,
        this.filters.batchId,
        this.filters.locId,
        lazyData.pageNo,
        lazyData.limit, expiryISOString, true)
      .subscribe(
        (data: HttpResponse<any>) => {
          const pTree = [];
          this.treeTableInventoryList = data.body;
          for (let i = 0; i < data.body.length; i++) {
            const element = data.body[i];
            const node = {
              data: {
                name: element.item.code,
                item: element.item.name,
                id: element.item.id,
                totalAllocated: element.summaryDTO.totalAllocated,
                totalAvailable: element.summaryDTO.totalAvailable,
                totalInStock: element.summaryDTO.totalInStock,
                totalOnOrder: element.summaryDTO.totalOnOrder,
                uom: element.summaryDTO.uom,
                totalValue: element.summaryDTO.totalValue
              },
              leaf: !(element.locationInventoryDTOList.length > 0)
            };
            pTree.push(node);
          }
          this.files = pTree;
          this.loading = false;

          this.totalCount = Number(data.headers.get("X-Total-Count"));

          if (this.totalCount <= this.pageCount) {
            this.paginator.paginator = false;
          } else {
            this.paginator.paginator = true;
          }
          this.paginator.loading = false;
          this.paginator.totalCount = this.totalCount;
        },
        (err: any) => {
          this.loading = false;
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              "",
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
              "",
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
          this.paginator.loading = false;
        }
      );
  }

  generateCSVList(responseData: any[]) {
    let csvList = [];
    if (responseData.length) {
      let rowCount = 0;
      responseData.forEach((itemDetail) => {
        let locationList = itemDetail.locationInventoryDTOList;
        if (itemDetail.item.managementMethod.name == "Yes") {
          locationList.forEach(locationDetail => {
            let warehouseList = locationDetail.warehouseWiseInventoryDTOList;
            warehouseList.forEach(warehouseDetail => {
              let stockList = warehouseDetail.stockList;
              if (stockList && stockList.length) {
                stockList.forEach(stock => {
                  let uom = itemDetail.summaryDTO.uom;
                  csvList.push({
                    slNo: ++rowCount,
                    code: itemDetail.item.code,
                    name: itemDetail.item.name,
                    uom: uom,
                    batchQty: stock.inStock.toFixed(3),
                    totalValue: stock.value != null ? stock.value.toFixed(3) : "0",
                    location: warehouseDetail.warehouse.location.name,
                    warehouse: warehouseDetail.warehouse.name,
                    bin: stock.bin != null ? stock.bin.name : "",
                    batch: stock.batch.batchNumber ? stock.batch.batchNumber : "",
                    prodDate: stock.batch.productionDate ? stock.batch.productionDate.split('T')[0] : "",
                    expiryDate: stock.batch.expiryDate ? stock.batch.expiryDate.split('T')[0] : "",
                  });
                });
              }
            });
          });
        }
      });
  if(csvList.length==0)
  {
    this.toastrMsgService.warning('CSV not exported',this.message.noData,this.message.toastrTime);
    return
  }
   this.exportCSV(csvList);
  }
  else
  {
      this.toastrMsgService.warning('CSV not exported',this.message.noData,this.message.toastrTime);
  }
    
  };  

  exportCSV(csvList) {
    new AngularCsv(csvList, 'BatchReport', this.csvOptions);
  }

  clear() {
    this.filters = {};
    this.location = undefined;
    this.warehouse = undefined;
    this.item = undefined;
    this.expiryDate = undefined;
    this.paginatorInitial();
    this.getTreeInventoryTableDetails(this.paginator);
  };

  expiryDateChange() {
    this.paginatorInitial();
  };
  search()
  {
    this.getTreeInventoryTableDetails(this.paginator);
  }
  handleWarehouseChange() {
    this.filters.warehouseId = this.warehouse.id;
    this.paginatorInitial();
  };

  handleLocationChange() {
    this.filters.locationId = this.location.id;
    this.filters.warehouseId = undefined;
    this.warehouseDetails();
    this.paginatorInitial();
   };

  handleItemChange() {
    this.filters.itemId = this.item.id;
    this.paginatorInitial();
   };

  locationDetails() {
    this.stockService.getLocation().subscribe(
      (data: any) => {
        this.locationsList = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  };

  warehouseDetails() {
    this.stockService.getWarehouse(this.location).subscribe(
      (data: any) => {
        this.warehousesList = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  };

  itemNameDetails() {
    this.stockService.getBatchItemNames().subscribe(
      (data: any) => {
        this.itemsList = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  onNodeExpand(event) {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      const node = event.node;
      node.children = [];

      if (event.node.parent === undefined || event.node.parent === null) {
        this.treeTableInventoryList.forEach(item => {
          if (item.item.id === event.node.data.id) {
            const locationDto = item.locationInventoryDTOList;
            locationDto.forEach(location => {
              const WareDto = location.warehouseWiseInventoryDTOList;
              const nodeNew = {
                data: {
                  name: location.location.code,
                  item: location.location.name,
                  id: location.location.id,
                  totalAllocated: location.summaryDTO.totalAllocated,
                  totalAvailable: location.summaryDTO.totalAvailable,
                  totalInStock: location.summaryDTO.totalInStock,
                  totalOnOrder: location.summaryDTO.totalOnOrder,
                  uom: location.summaryDTO.uom,
                  totalValue: location.summaryDTO.totalValue
                },
                leaf: !(WareDto.length > 0)
              };
              node.children.push(nodeNew);
            });
          }
        });
      } else {
        if (
          event.node.parent.parent === undefined ||
          event.node.parent.parent === null
        ) {
          this.treeTableInventoryList.forEach(item => {
            if (item.item.id === event.node.parent.data.id) {
              const locationDto = item.locationInventoryDTOList;
              locationDto.forEach(location => {
                if (location.location.id === event.node.data.id) {
                  const warehouseDto = location.warehouseWiseInventoryDTOList;
                  warehouseDto.forEach(ware => {
                    const stockDto = ware.stockList;
                    const nodeNew = {
                      data: {
                        name: ware.warehouse.code,
                        item: ware.warehouse.name,
                        id: ware.warehouse.id,
                        totalAllocated: ware.summaryDTO.totalAllocated,
                        totalAvailable: ware.summaryDTO.totalAvailable,
                        totalInStock: ware.summaryDTO.totalInStock,
                        totalOnOrder: ware.summaryDTO.totalOnOrder,
                        uom: ware.summaryDTO.uom,
                        totalValue: ware.summaryDTO.totalValue
                      },
                      leaf: !(stockDto.length > 0)
                    };
                    node.children.push(nodeNew);
                  });
                }
              });
            }
          });
        } else {

          this.treeTableInventoryList.forEach(item => {
            if (item.item.id === event.node.parent.parent.data.id) {
              const locationDto = item.locationInventoryDTOList;
              locationDto.forEach(location => {
                if (location.location.id === event.node.parent.data.id) {
                  const warehouseDto = location.warehouseWiseInventoryDTOList;
                  warehouseDto.forEach(ware => {
                    if (ware.warehouse.id === event.node.data.id) {
                      const stockDto = ware.binWiseInventoryDTOList;
                      stockDto.forEach(stock => {
                        const nodeNew = {
                          data: {
                            name: stock.bin.code,
                            item: stock.bin.name,
                            id: stock.id,
                            totalAllocated: stock.summaryDTO.totalAllocated,
                            totalAvailable: stock.summaryDTO.totalAvailable,
                            totalInStock: stock.summaryDTO.totalInStock,
                            totalOnOrder: stock.summaryDTO.totalOnOrder,
                            uom: stock.uom,
                            totalValue: stock.summaryDTO.totalValue
                          },
                          leaf: true
                        };
                        node.children.push(nodeNew);
                      });

                    }
                  });

                }
              });
            }
          });
        }
      }

      this.files = [...this.files];
    }, 10);
  }

  getCSVData() {
    let responseData = [];
    const isoDate: string = this.getISOStringDate(this.expiryDate);
    this.stockService.getTreetableInventory(this.filters.itemId,
      this.filters.wareId, this.filters.categoryId,
      this.filters.batchId, this.filters.locId,
      null, null, isoDate, true)
      .subscribe(
        (data: HttpResponse<any>) => {
          responseData = data.body;
          this.generateCSVList(responseData);
        },
        (err: any) => {
          this.handleResponseError();
        }
      );
  }

  setUTCDateTime(date: Date) {
    date = new Date(date.setUTCHours(23,59,59,999));
    return date;
  }

  handleResponseError() {
    !navigator.onLine ? this.toastrMsgService.offlineAlert() : this.toastrMsgService.error();
  }

  getISOStringDate(date: Date): string {
    let expiryISOString: string = date ? this.setUTCDateTime(date).toISOString() : "";
    return expiryISOString;
  }
}
