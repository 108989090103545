import { CategoryService } from '@app/core/services/category.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { EditSubCategory } from '@app/shared/models/edit-subcategory.model';
import { SubCategoryService } from '@app/core/services/sub-category.service';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
@Component({
  selector: 'app-edit-subcategory',
  templateUrl: './edit-subcategory.component.html',
  styleUrls: ['./edit-subcategory.component.scss']
})
export class EditSubcategoryComponent implements OnInit, AfterViewInit {
  public editSubCategory = new EditSubCategory();
  displayDialogNew: boolean;
  editData: any;
  categoryName: string;
  message: any;
  constructor(public bsModalRef: BsModalRef, private toastrMsgService: ToastrMsgService, private subCategoryService: SubCategoryService, private categoryService: CategoryService) { }

  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.editSubCategory = {
      id: null,
      name: null,
      description: null
    };
    this.editSubCategories(this.editData)
  }
  ngAfterViewInit(): void {
    this.displayDialogNew = true;
  }
  editSubCategories(rowData: any) {
    rowData = this.editData;
    this.categoryName = rowData.category.name;
    this.editSubCategory.id = rowData.id;
    this.editSubCategory.name = rowData.name;
    this.editSubCategory.description = rowData.description;
  }
  onSubmit() {
    this.subCategoryService.updateSubCategory(this.editSubCategory).subscribe((data: any) => {
      this.displayDialogNew = false;
      this.toastrMsgService.success('', this.message.subcategoryEditSuccessMsg, this.message.toastrTime);
      this.categoryService.setCategory(data);
      this.bsModalRef.hide();
    },
      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
    this.displayDialogNew = false;
  }
  editCancel() {
    this.bsModalRef.hide();
    this.displayDialogNew = false;
  }

}
