import { AuthGuard } from './../../core/guards/auth-guard';
import { ListSubCategoryComponent } from '@app/feature/manage-sub-category/list-sub-category/list-sub-category.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [  {path: 'manage-sub-category', component: ListSubCategoryComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageSubCategoryRoutingModule { }
