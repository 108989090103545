import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockTransferListComponent } from './stock-transfer-list/stock-transfer-list.component';
import { StockTakeAddComponent } from '@app/feature/manage-stock/stock-take-add/stock-take.component';
import { StockTransferAddComponent } from '@app/feature/manage-stock/stock-transfer-add/stock-transfer-add.component';
import { StockTransferViewComponent } from '@app/feature/manage-stock/stock-transfer-view/stock-transfer-view.component';
import { ManageStockRoutingModule } from '@app/feature/manage-stock/manage-stock.routing.module';
import { BreadcrumbModule } from 'primeng/components/breadcrumb/breadcrumb';
import { DropdownModule } from 'primeng/dropdown';
import { CoreModule } from '@app/core/core.module';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { DatePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import {OverlayPanelModule} from 'primeng/overlaypanel';

import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { StockTakeListComponent } from './stock-take-list/stock-take-list.component';
import { StockTakeViewComponent } from './stock-take-view/stock-take-view.component'
//import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    CheckboxModule,
    DialogModule,
    BreadcrumbModule,
    TooltipModule,
    DropdownModule,
    ConfirmDialogModule,
    BsDatepickerModule.forRoot(),
    InputTextModule,
    FormsModule,
    CoreModule,
    TableModule,
    MultiSelectModule,
    OverlayPanelModule,
    ManageStockRoutingModule
  ],
  providers: [DatePipe],
  declarations: [StockTransferListComponent, StockTransferViewComponent, StockTransferAddComponent, StockTakeAddComponent, StockTakeListComponent, StockTakeViewComponent]
})
export class ManageStockModule { }
