import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { StockOut } from '@app/shared/models/stock-out.model';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import * as Constants from '@app/core/constants/common.constants';
import { Col } from '@app/shared/models/table-header.model';
import { Paginator } from '@app/shared/models/paginator.model';
import { AuthService } from '@app/core/services/auth.service';
import { Dropdown } from 'primeng/primeng';
import { MenuItem, Breadcrumb } from 'primeng/primeng';
import { ItemService } from '@app/core/services/item.service';
@Component({
  selector: 'app-manage-stock-out',
  templateUrl: './manage-stock-out-add.component.html',
  styleUrls: ['./manage-stock-out-add.component.scss']
})
export class ManageStockOutAddComponent implements OnInit {
  enableSubmitButton: boolean = false;
  clear: boolean = true;
  pageCount: number;
  totalCount: number;
  stockOut = new StockOut();
  cols: Col[];
  stockOutDTO = {};
  location: any;
  stockUpdateList: any = [];
  stockUpdateListNew: any;

  categories: any;
  wareHouse: any;
  suppliers: any;
  paginator: Paginator;
  stockOutDetailsList: any;
  supplierEnable: boolean = true;
  message: any;
  selectedValue: any;
  subCategories: any;
  filterText: string;
  otherSupplierName: string;
  currentDate = new Date();
  bin: any;
  otherSupplier: boolean;
  locFilterApplied: boolean = false;
  warFilterApplied: boolean = false;
  restrictStockout: boolean = false;
  itemList : any;
  selectedItem: any = { id: ''};
  selectedItemName: string = undefined;
  breadcrumb: MenuItem[];
  showPreview: Boolean = false;

  constructor(
    public stockService: ManageStockService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private toastrMsgService: ToastrMsgService,
    private decimalPipe: DecimalPipe,
    public router: Router,
    public itemService: ItemService
  ) {
    this.itemDetails();
  }
  @ViewChild('ddStatusBin') ddStatusBin: Dropdown;
  @ViewChild('ddStatusWare') ddStatusWare: Dropdown;
  @ViewChild('ddStatusLoc') ddStatusLoc: Dropdown;
  @ViewChild('ddStatusSub') ddStatusSub: Dropdown;
  @ViewChild('ddStatusCat') ddStatusCat: Dropdown;
  @ViewChild('ddStatusSup') ddStatusSup: Dropdown;
  @ViewChild('ddStatusItem') ddStatusItem: Dropdown;
  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;
  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.breadcrumb = [
      { label: 'Manage Stock Out', routerLink: '/manage-stock-out-list' },
      { label: 'New Stock Out', url: '' }
    ];
    this.cols = [
      { field: 'itemCode', header: 'Item Code' },
      { field: 'itemName', header: 'Item Name', width: '11%' },
      // { field: 'category', header: 'Category' },
      { field: 'subcategory', header: 'Subcategory', width: '11%' },
      { field: 'inStock', header: 'In Stock' },
      // { field: 'available', header: 'Available' },
      // { field: 'allocated', header: 'Allocated' },
      { field: 'uom', header: 'UOM' },
      { field: 'location', header: 'Location' },
      { field: 'warehouse', header: 'Warehouse' },
      { field: 'bin', header: 'Bin(Shelf/Rack)' },
      { field: 'currentStock', header: 'Stock Out Qty' },
      { field: 'batch', header: 'Batch' }
    ];
    this.locationDetails();
    this.categoryDetails();
    this.itemDetails();
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };

    if (this.authService.getRole() === 'ROLE_SUPPLIER') {
      this.stockOut.supplier = { companyName: '' };
      this.supplierEnable = false;
    } else {
      this.supplierEnable = true;
      this.supplierDetails();
    }
    this.stockOut.documentDate = this.currentDate;
  }
  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  displayPreview() {
    this.showPreview = true;
  }

  hidePreview() {
    this.showPreview = false;
  }

  previewStockOutItems() {
    this.displayPreview();
  }

  stockOutSubmit() {
    this.stockOutAll();
    this.hidePreview();
  }

  removeItem(index) {
    this.stockUpdateList.splice(index, 1);
    this.enableSubmitButton = this.stockUpdateList.length != 0;
  }

  locationDetails() {
    this.stockService.getLocation().subscribe(
      (data: any) => {
        this.location = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  categoryDetails() {
    this.stockService.getCategories().subscribe(
      (data: any) => {
        this.categories = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // Calling the function each time the category dropdown changes
  catChange() {
    this.clear = false;
    this.stockOut.subcategory = [];
    this.stockOut.subcategory.id = '';
    this.stockOutDetails(this.paginator);
    this.subCatDetails();
  }
  // To get all the details of subcategories and showing the list in dropdown
  subCatDetails() {
    this.stockService.getSubCategories(this.stockOut.category.id).subscribe(
      (data: any) => {
        this.subCategories = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // To get the stock out list
  stockOutDetails(lazyLoadData: Paginator) {
    this.stockService
      .getStockOutDetails(
        this.stockOut.category.id,
        this.stockOut.subcategory.id,
        this.stockOut.location.id,
        this.stockOut.warehouse.id,
        this.stockOut.bin.id,
        this.selectedItem.id,
        lazyLoadData.pageNo,
        lazyLoadData.limit,
        lazyLoadData.sortOrder
      )
      .subscribe(
        (data: HttpResponse<any>) => {
          this.stockOutDetailsList = data.body;

          this.stockOutDetailsList.forEach(item => {
            this.stockUpdateList.forEach(itemUpdate => {
              if (Number(item.id) === Number(itemUpdate.id)) {
                item.currentStock = itemUpdate.currentStock;
              }
            });
            item.inStock = this.decimalPipe.transform(item.inStock.toString(), '1.3-3');
            item.available = item.available;
            item.allocated = item.allocated;
          });

          this.totalCount = Number(data.headers.get('X-Total-Count'));

          if (this.totalCount <= this.pageCount) {
            this.paginator.paginator = false;
          } else {
            this.paginator.paginator = true;
          }
          this.paginator.loading = false;
          this.paginator.totalCount = this.totalCount;
        },
        (err: any) => {
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              '',
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
          this.paginator.loading = false;
        }
      );
  }
  // To call each time page loads
  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      this.filterText = event.sortField;
      if (this.filterText === 'location') {
        this.filterText = 'warehouse.location.name';
      } else if (this.filterText === 'subcategory') {
        this.filterText = 'batch.item.subcategory.name';
      } else if (this.filterText === 'category') {
        this.filterText = 'batch.item.category.name';
      } else if (this.filterText === 'itemCode') {
        this.filterText = 'batch.item.code';
      } else if (this.filterText === 'itemName') {
        this.filterText = 'batch.item.name';
      }
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = this.filterText + ',DESC';
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = this.filterText + ',ASC';
      }
    } else {
      this.paginator.sortOrder = 'createdDate,DESC';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.stockOutDetails(this.paginator);
  }
  // To call each time location changes
  locChange() {
    this.clear = false;
    this.warehouseDetails();
    this.stockOut.warehouse = [];
    this.stockOut.warehouse.id = '';
    this.stockOut.bin = [];
    this.stockOut.bin.id = '';
    this.locFilterApplied = true;
    if (this.locFilterApplied) {
      this.cols = [
        { field: 'itemCode', header: 'Item Code' },
        { field: 'itemName', header: 'Item Name' },
        //{ field: 'category', header: 'Category' },
        { field: 'subcategory', header: 'Subcategory' },
        { field: 'inStock', header: 'In Stock' },
        //{ field: 'available', header: 'Available' },
        //{ field: 'allocated', header: 'Allocated' },
        { field: 'uom', header: 'UOM' },
        { field: 'location', header: 'Location' },
        { field: 'warehouse', header: 'Warehouse' },
        { field: 'bin', header: 'Bin(Shelf/Rack)' },
        { field: 'currentStock', header: 'Stock Out Qty' },
        { field: 'batch', header: 'Batch' }
      ];

    }
    if ( this.locFilterApplied && this.warFilterApplied) {
      this.cols = [
        { field: 'itemCode', header: 'Item Code' },
        { field: 'itemName', header: 'Item Name' },
        //{ field: 'category', header: 'Category' },
        { field: 'subcategory', header: 'Subcategory' },
        { field: 'inStock', header: 'In Stock' },
        //{ field: 'available', header: 'Available' },
        //{ field: 'allocated', header: 'Allocated' },
        { field: 'uom', header: 'UOM' },
        { field: 'location', header: 'Location' },
        { field: 'warehouse', header: 'Warehouse' },
        { field: 'bin', header: 'Bin(Shelf/Rack)' },
        { field: 'currentStock', header: 'Stock Out Qty' },
        { field: 'batch', header: 'Batch' }
      ];
    }
    this.stockOutDetails(this.paginator);
  }
  // To get the warehouse details and showing the list in dropdown
  warehouseDetails() {
    this.stockService.getWarehouse(this.stockOut.location).subscribe(
      (data: any) => {
        this.wareHouse = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // To call each time warehouse changes
  warehouseChange() {
    this.clear = false;
    this.binDetails();
    this.stockOut.bin = [];
    this.stockOut.bin.id = '';
    this.warFilterApplied = true;
    if (this.warFilterApplied) {
      this.cols = [
        { field: 'itemCode', header: 'Item Code' },
        { field: 'itemName', header: 'Item Name' },
        //{ field: 'category', header: 'Category' },
        { field: 'subcategory', header: 'Subcategory' },
        { field: 'inStock', header: 'In Stock' },
        //{ field: 'available', header: 'Available' },
        //{ field: 'allocated', header: 'Allocated' },
        { field: 'uom', header: 'UOM' },
        { field: 'location', header: 'Location' },
        { field: 'warehouse', header: 'Warehouse' },
        { field: 'bin', header: 'Bin(Shelf/Rack)' },
        { field: 'currentStock', header: 'Stock Out Qty' },
        { field: 'batch', header: 'Batch' }
      ];

    }
    if (this.locFilterApplied && this.warFilterApplied) {
      this.cols = [
        { field: 'itemCode', header: 'Item Code' },
        { field: 'itemName', header: 'Item Name' },
        //{ field: 'category', header: 'Category' },
        { field: 'subcategory', header: 'Subcategory' },
        { field: 'inStock', header: 'In Stock' },
        //{ field: 'available', header: 'Available' },
//        { field: 'allocated', header: 'Allocated' },
        { field: 'uom', header: 'UOM' },
        { field: 'location', header: 'Location' },
        { field: 'warehouse', header: 'Warehouse' },
        { field: 'bin', header: 'Bin(Shelf/Rack)' },
        { field: 'currentStock', header: 'Stock Out Qty' },
        { field: 'batch', header: 'Batch' }
      ];
    }
    this.stockOutDetails(this.paginator);
  }
  // To get the bin details and showing the list in the dropdown
  binDetails() {
    this.stockService.getBin(this.stockOut.warehouse.id).subscribe(
      (data: any) => {
        this.bin = data;
        if (this.bin.length === 0) {
          this.stockOut.bin = [];
          this.stockOut.bin.id = '';
        }
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // To call each time the bin changes
  binChange() {
    this.clear = false;
    this.stockOutDetails(this.paginator);
  }
  // To call each time the subcategory changes
  subcatChange() {
    this.clear = false;
    this.stockOutDetails(this.paginator);
  }
  // clear all the fields
  clearAll() {
    this.initialiseStockOut();
    this.locFilterApplied = false;
    this.warFilterApplied = false;
    this.cols = [
      { field: 'itemCode', header: 'Item Code' },
      { field: 'itemName', header: 'Item Name' },
      //{ field: 'category', header: 'Category' },
      { field: 'subcategory', header: 'Subcategory' },
      { field: 'inStock', header: 'In Stock' },
      //{ field: 'available', header: 'Available' },
      //{ field: 'allocated', header: 'Allocated' },
      { field: 'uom', header: 'UOM' },
      { field: 'location', header: 'Location' },
      { field: 'warehouse', header: 'Warehouse' },
      { field: 'bin', header: 'Bin(Shelf/Rack)' },
      { field: 'currentStock', header: 'Stock Out Qty' },
      { field: 'batch', header: 'Batch' }
    ];
    this.stockOutDetails(this.paginator);
    this.clear = true;
  }
  // To get the supplier details and showing the list in the dropdown
  supplierDetails() {
    this.stockService.geSupplierList().subscribe(
      (data: any) => {
        const otherSupplier = { companyName : 'Others' };
        data.push(otherSupplier);
        this.suppliers = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  /** 
   * To call each time when a value enter in to the text field 
   */ 
  modelChange(event: any, rowData: any) {
    // replace with max. available stock if value exceeds
    rowData.currentStock = rowData.currentStock > rowData.inStock ? rowData.inStock : rowData.currentStock;

    let selectedRowIndex = this.stockUpdateList.findIndex((item) => item.id == rowData.id);

    if (selectedRowIndex === -1 && rowData.currentStock) {
      this.stockUpdateList.push(JSON.parse(JSON.stringify(rowData)));
    }
    else if (selectedRowIndex > -1 && !rowData.currentStock) {
      this.stockUpdateList.splice(selectedRowIndex, 1);
    }
    else if(selectedRowIndex > -1 && rowData.currentStock) {
      this.stockUpdateList.splice(selectedRowIndex, 1, rowData);
    }
    this.enableSubmitButton = this.stockUpdateList.length != 0;
  }

  // update the stock out details
  stockOutAll() {
    this.stockUpdateListNew = [];
    this.stockUpdateList.forEach(item => {
      if (item.currentStock !== null) {
        this.stockUpdateListNew.push({
          stockId: item.id,
          currentStock: item.currentStock,
          itemCode: item.itemCode,
          warehouse: item.warehouse
        });
      }
    });
    if (this.otherSupplier && this.otherSupplierName) {
      this.stockOutDTO = {
        stockUpdateDTOList: this.stockUpdateListNew,
        supplier: this.otherSupplierName,
        remarks: this.stockOut.remarks,
        createdDate: this.stockOut.documentDate
      };
    } else {
      this.stockOutDTO = {
        stockUpdateDTOList: this.stockUpdateListNew,
        supplier: this.stockOut.supplier ? this.stockOut.supplier.companyName: null,
        remarks: this.stockOut.remarks,
        createdDate: this.stockOut.documentDate
      };
    }
    this.restrictStockout = true;
    this.stockService.updateStockOutDetails(this.stockOutDTO).subscribe(
      (data: any) => {
        this.selectedValue = [];
        this.stockUpdateList = [];
        this.stockUpdateListNew = [];
        this.stockOutDTO = {};
        this.stockOutDetails(this.paginator);
        this.enableSubmitButton = false;
        this.restrictStockout = false;
        this.router.navigateByUrl('/manage-stock-out-list');
        this.toastrMsgService.success(
          '',
          this.message.stockOutSuccessMsg,
          this.message.toastrTime
        );
        this.router.navigateByUrl('/manage-stock-out-list');
      },
      (err: any) => {
        this.restrictStockout = false;
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
        this.paginator.loading = false;
      }
    );
  }
  // calls each changes
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  initialiseStockOut() {
    this.stockOut.category = { id: '' };
    this.stockOut.subcategory = { id: '' };
    this.stockOut.location = { id: '' };
    this.stockOut.warehouse = { id: '' };
    this.stockOut.bin = { id: '' };
    this.selectedItem = { id: ''};
  }
  binClick(event: any) {
    // console.log(event,"event");
    if (event === 'ddStatusWare') {
      this.ddStatusWare.filled = true;
    } else if (event === 'ddStatusBin') {
      this.ddStatusBin.filled = true;
    } else if (event === 'ddStatusLoc') {
      this.ddStatusLoc.filled = true;
    } else if (event === 'ddStatusSub') {
      this.ddStatusSub.filled = true;
    } else if (event === 'ddStatusCat') {
      this.ddStatusCat.filled = true;
    } else if (event === 'ddStatusSup') {
      this.ddStatusSup.filled = true;
      if (this.stockOut.supplier.companyName === 'Others') {
        this.otherSupplier = true;
      } else {
        this.otherSupplier = false;
      }
    } else if(event === 'ddStatusItem'){
      this.ddStatusItem.filled =true;
    }
  }

  //  item change
    itemChange() {
      this.stockOutDetails(this.paginator);
      this.clear = false;
    }
     // To get the item details and showing the list in the dropdown
      itemDetails() {
        this.itemService.getItem().subscribe(
          (data: any) => {
            this.itemList = data;
            if (this.itemList.length === 0) {
              this.selectedItem = [];
              this.selectedItem.id = '';
            }
          },
          (err: any) => {
            if (navigator.onLine === false) {
              this.toastrMsgService.warning(
                '',
                this.message.noInternet,
                this.message.toastrTime
              );
            } else {
              this.toastrMsgService.error(
                '',
                this.message.errorMsg,
                this.message.toastrTime
              );
            }
          }
        );
      }
}
