import { AuthGuard } from './../../core/guards/auth-guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListReportsComponent } from '@app/feature/manage-reports/list-reports/list-reports.component';
import { InventryReportsComponent } from './inventry-reports/inventry-reports.component';
import { VarianceReportComponent } from './variance-report/variance-report.component';

const routes: Routes = [
  {path: 'manage-report', component: ListReportsComponent, canActivate: [AuthGuard]},
  {
    path: 'inventory-report', component: InventryReportsComponent, canActivate : [AuthGuard]
  },
  {
    path: 'variance-report', component: VarianceReportComponent, canActivate : [AuthGuard]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageReportsRoutingModule { }
