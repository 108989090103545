import { Component, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Col } from '@app/shared/models/table-header.model';
import { ItemService } from '@app/core/services/item.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { take, takeLast } from 'rxjs/operators';
import { Breadcrumb, MenuItem, Dropdown } from 'primeng/primeng';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { AuthService } from '@app/core/services/auth.service';
import { SubReceipeService } from '@app/core/services/sub-receipe.service';

@Component({
  selector: 'app-edit-subrecipe',
  templateUrl: './edit-subrecipe.component.html',
  styleUrls: ['./edit-subrecipe.component.scss']
})
export class EditSubrecipeComponent implements OnInit {
  @ViewChild('childStatusItem') childStatusItem: Dropdown;

  breadcrumb: MenuItem[];
  editParentSubrecipeForm: FormGroup;
  editSubRecipesForm : FormGroup;
  message: any;
  subRecipeitems : [{
    childItemName: any
    childItemCode: any
    childItemQty: any
    childParentItemUom: any
    cost: any
    parentItemCode: any
    parentItemName: any
    parentItemQty: any
    parentItemUom: any
  }];
  cols: Col[];
  allItems: any;
  // uomList: any[] = [];

  @ViewChild('ddStatusItem') ddStatusItem: Dropdown;

  parentItemCode: any;
  parentItemName: any;
  parentItemUom: any;
  parentItemQuantity: any;
  parentItemCost: any;
  selectedItemId: any;
  childSubRecipeData: any[] = [];
  displayInput: boolean;
  enableEdit : boolean = false;
  enableEditIndex = null;
  selectedCompanyId: any;
  selectedparentItemCode: any;

  childItemCode: any;
  childItemUom: any;
  display: boolean;
  removeIndex = null;

  constructor(
      private toastrMsgService: ToastrMsgService,
      private stockService: ManageStockService,
      private authService: AuthService,
      private router : Router,
      private route: ActivatedRoute,
      private itemService : ItemService,
      private subReceipeService : SubReceipeService,
      private fb: FormBuilder
  ) {
    // this.getUom();
    this.getAllItems();
    this.route.params.subscribe(params => {
      this.selectedCompanyId = params.companyID;
      this.selectedparentItemCode = params.parentItemCode;
      this.getSubRecipeItemsByID();
    });
  }

  ngOnInit() {
    if (sessionStorage.getItem('checkSubrecipePassword')) {
      if (sessionStorage.getItem('checkSubrecipePassword') != 'true') {
        this.router.navigateByUrl('/manage-subrecipe');
      }
    } else {
      this.router.navigateByUrl('/manage-subrecipe');
    }
    this.breadcrumb = [
          { label: 'Manage Sub Recipe', routerLink: '/manage-subrecipe' },
          { label: 'Edit Sub Recipe', url: '' }
        ];
        this.message = Constants.MESSAGES;
        this.formSetup();
        this.cols = [
          { field: 'slNo', header: 'Sr. No', width: '10%', textAlign: 'left' },
          { field: 'childItemName', header: 'Child Item Name', width: '25%', textAlign: 'left' },
          { field: 'childItemCode', header: 'Child Item Code', width: '25%', textAlign: 'left' },
          { field: 'childItemUom', header: 'Child Item Uom', width: '20%', textAlign: 'left' },
          { field: 'childItemQuantity', header: 'Child Item Quantity', width: '10%', textAlign: 'right !important' },
          { field: 'Actions', header: 'Actions', width: '10%', textAlign: 'left' },
        ];
        this.subRecipeitems = [{
          childItemName: '',
          childItemCode: '',
          childItemQty: '',
          childParentItemUom: '',
          cost: '',
          parentItemCode: '',
          parentItemName: '',
          parentItemQty: '',
          parentItemUom: '',
        }];
  }

  loadTableData(event: any) {
    this.getSubRecipeItemsByID();
  }

  onSubmitSubRecipe(editSubRecipesForm: FormGroup) {
    if (this.parentItemQuantity == undefined) {
      this.toastrMsgService.error('', 'Fill parent data and child data ', this.message.toastrTime);
    } else {
      if (this.childItemCode == this.parentItemCode) {
        this.toastrMsgService.error('', 'Parent item can\'t be selected', this.message.toastrTime);
      } else {
        this.childSubRecipeData.findIndex(x => x.childItemCode == this.childItemCode) == -1 ?
          this.childSubRecipeData.push({
            childItemCode: this.childItemCode,
            childItemName: editSubRecipesForm.value.childItemName.name,
            childItemUom: this.childItemUom,
            childItemQuantity: editSubRecipesForm.value.childItemQuantity
        })
        : this.toastrMsgService.error('Already edited', '', this.message.toastrTime);

        this.editSubRecipesForm.setValue({
          childItemCode: '',
          childItemName: '',
          childItemUom: '',
          childItemQuantity: ''
        });
        this.childItemCode = undefined;
        this.childItemUom = undefined;
      }
    }
  }

  getSubRecipeItemsByID(){
    this.subReceipeService.editSubRecipeById(this.selectedCompanyId,this.selectedparentItemCode).subscribe(
      (data: any) => {
        this.subRecipeitems = data.body;
        // Dummy Values
        this.subRecipeitems.map(items =>{
          this.childSubRecipeData.push({
            childItemName: items.childItemName,
            childItemCode: items.childItemCode,
            childItemUom: items.childParentItemUom,
            childItemQuantity: items.childItemQty
          })
        });
        this.parentItemCode = this.subRecipeitems[0].parentItemCode;
        this.parentItemQuantity = this.subRecipeitems[0].parentItemQty;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
      }
    );
  }
    
  getAllItems() {
    this.itemService.getItem().subscribe(
      (data: any) => {
        this.allItems = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  formSetup() {
      this.editSubRecipesForm = this.fb.group({
        childItemCode:  [{value: '', disabled: true}],
        childItemName: [{ value: '' }, Validators.required],
        childItemUom: [{ value: '', disabled: true}],
        childItemQuantity: [{value: '' } , Validators.required]
      });

      this.editParentSubrecipeForm = this.fb.group({
        parentItemName: [{ value: '',disabled: true}, Validators.required],
        parentItemCode:  [{value: '', disabled: true}],
        parentItemUom:  [{value: '', disabled: true}],
        parentItemQuantity: [{value: '' }, Validators.required],
        parentItemCost: [{value: '',disabled: true}]
      });
  }

  dropdownChange(event: any){
    if (event === 'ddStatusItem') {
      this.ddStatusItem.filled = true;
      this.onItemChangeUpdateItemDetails();
    } else if (event === 'childStatusItem') {
      this.childStatusItem.filled = true;
      this.onChildItemChangeUpdateItemDetails();
    }
  }

  onItemChangeUpdateItemDetails() {
    this.parentItemUom = this.editParentSubrecipeForm.get('parentItemName').value.uom.name;
    this.parentItemCost = this.editParentSubrecipeForm.get('parentItemName').value.price;
  }

  onChildItemChangeUpdateItemDetails() {
    this.childItemCode = this.editSubRecipesForm.get('childItemName').value.code;
    this.childItemUom = this.editSubRecipesForm.get('childItemName').value.uom.name;
  }

  // getUom() {
  //   this.itemService.getUom().subscribe(data => {
  //     this.uomList = data;
  //   });
  // }

  deleteChildItemData(removeIndex){
    this.childSubRecipeData.splice(removeIndex, 1);
    this.display = false;
  }

  onCancel() {
    this.router.navigateByUrl('/manage-subrecipe');
  }

  enableEditMethod(e, i) {
    this.enableEdit = true;
    this.enableEditIndex = i;
  }

  editSubRecipeData(rowdata, i){
    this.enableEdit = false;
    this.childSubRecipeData[i].childItemName    = rowdata.childItemName;
    this.childSubRecipeData[i].childItemQuantity = rowdata.childItemQuantity;
    this.childSubRecipeData[i].childItemUom = (rowdata.childItemUom.name) ? rowdata.childItemUom.name : rowdata.childItemUom;
  }

  updateSubRecipe(){
    this.enableEdit = false;
    let subrecipeData = [];
    this.childSubRecipeData.map( (items, index) => {
      subrecipeData.push({
        'parentItemCode': this.subRecipeitems[0].parentItemCode,
        'parentItemName': this.subRecipeitems[0].parentItemName,
        'parentItemUom' : this.subRecipeitems[0].parentItemUom,
        'parentItemQty' : this.parentItemQuantity,
        'childItemCode' : items.childItemCode,
        'childItemName' : items.childItemName,
        'childParentItemUom' : items.childItemUom.name ? items.childItemUom.name : items.childItemUom,
        'childItemQty' : items.childItemQuantity,
        'cost' : this.subRecipeitems[0].cost
      })
      this.childSubRecipeData[index].childItemUom = (items.childItemUom.name) ? items.childItemUom.name : items.childItemUom;
    });
    
    this.enableEdit = false;

    this.subReceipeService.updateSubReceipeData(subrecipeData).subscribe(
      (data: any) => {
        this.toastrMsgService.success('', 'Updated', this.message.toastrTime);
        this.router.navigateByUrl('/manage-subrecipe');
      },
      (err: any) => {
        // this.toastrMsgService.success('', 'Updated', this.message.toastrTime);
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      });;
  }

}
