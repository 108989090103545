import { AuthGuard } from './../../core/guards/auth-guard';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ManageStockInComponent } from "@app/feature/manage-stock-in/manage-stock-in/manage-stock-in.component";
import { AddStockInComponent } from "@app/feature/manage-stock-in/add-stock-in/add-stock-in.component";
import { ViewStockInComponent } from './view-stock-in/view-stock-in.component';

const routes: Routes = [
  { path: 'mange-stock-in', component: ManageStockInComponent, canActivate: [AuthGuard] },
  { path: 'mange-stock-in/add-stock-in', component: AddStockInComponent, canActivate: [AuthGuard] },
  { path: 'mange-stock-in/view-stock-in', component: ViewStockInComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageStockInRoutingModule { }
