import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  reportId = {'id':''};
  constructor(public http: HttpClient) { }
  getStockLevels(){
    return this.http.get(`dashboard`);
  }
  setReportIdAs(level:any){
  this.reportId.id = level;
  }
  getReportId(){
    return this.reportId.id;
  }
}
