import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { EditCategory } from '@app/shared/models/edit-category.model';
import { CategoryService } from '@app/core/services/category.service';
import { Router } from '@angular/router';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent implements OnInit, AfterViewInit {
  editCategory: EditCategory;
  displayDialogNew: boolean;
  editData: any;
  message: any;
  invalidCategory = false;
  constructor(public bsModalRef: BsModalRef,
    private toastrMsgService: ToastrMsgService,
    private categoryService: CategoryService, private router: Router) { }

  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.editCategory = {
      id: null,
      name: null,
      description: null
    };
    this.editCategories(this.editData);
  }

  ngAfterViewInit(): void {
    this.displayDialogNew = true;
  }
  editCategories(rowData: EditCategory) {
    rowData = this.editData;
    this.editCategory.id = rowData.id;
    this.editCategory.name = rowData.name;
    this.editCategory.description = rowData.description;
  }
  // edit category method
  onSubmit() {
    if (!this.invalidCategory) {
      this.categoryService.updateCategory(this.editCategory).subscribe((data: any) => {
        this.displayDialogNew = false;
        this.toastrMsgService.success('', this.message.categoryEditSuccessMsg, this.message.toastrTime);

        this.editCategory.name = null;
        this.editCategory.description = null;
        this.categoryService.setCategory(data);
        this.bsModalRef.hide();
      },
        (err: any) => {
          this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
        }
      );
      this.displayDialogNew = false;
    }
    // this.categoryService.updateCategory(this.editCategory).subscribe((data: any) => {
    //   this.displayDialogNew = false;
    //   this.toastrMsgService.success('', this.message.categoryEditSuccessMsg, this.message.toastrTime);

    //   this.editCategory.name = null;
    //   this.editCategory.description = null;
    //   this.categoryService.setCategory(data);
    //   this.bsModalRef.hide();
    // },
    //   (err: any) => {
    //     this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
    //   }
    // );
    // this.displayDialogNew = false;
  }
  // method for hiding modal
  editCancel() {
    this.bsModalRef.hide();
    this.displayDialogNew = false;
  }
}
