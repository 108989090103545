import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {DialogModule} from 'primeng/dialog';
import { ManageStockOutRoutingModule } from './manage-stock-out-routing.module';
import { ManageStockOutAddComponent } from './manage-stock-out-add/manage-stock-out-add.component';
import { DropdownModule, InputTextModule, BreadcrumbModule } from 'primeng/primeng';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import { ManageStockoutListComponent } from './manage-stockout-list/manage-stockout-list.component';
import { ManageStockoutViewComponent } from './manage-stockout-view/manage-stockout-view.component';


@NgModule({
  declarations: [ManageStockOutAddComponent, ManageStockoutListComponent, ManageStockoutViewComponent],
  imports: [
    CommonModule,
    ManageStockOutRoutingModule,
    DropdownModule,
    TableModule,
    FormsModule,
    TooltipModule,
    InputTextModule,
    BreadcrumbModule,
    BsDatepickerModule,
    DialogModule
  ]
})
export class ManageStockOutModule { }
