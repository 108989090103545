import { AuthService } from './../../../core/services/auth.service';
import { AddCategoryComponent } from '@app/feature/manage-category/add-category/add-category.component';
import { EditCategory } from '@app/shared/models/edit-category.model';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { Paginator } from '@app/shared/models/paginator.model';
import { CategoryService } from '@app/core/services/category.service';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { AddCategory } from '@app/shared/models/category.model';
import { Col } from '@app/shared/models/table-header.model';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import * as Constants from '@app/core/constants/common.constants';
import { HttpResponse } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EditCategoryComponent } from '@app/feature/manage-category/edit-category/edit-category.component';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
@Component({
  selector: 'app-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss']
})
export class ListCategoryComponent implements OnInit {
  displayDialog: boolean;
  displayDialogNew: boolean;
  addCategory: AddCategory;
  editCategory: EditCategory;
  cols: Col[];
  checked: boolean;
  paginator: Paginator;
  pageCount: number;
  totalCount: number;
  categoryList: any = [];
  values: string[];
  breadcrumb: Breadcrumb[];
  categories: { id: number; name: string; description: string; }[];
  bsModalRef: BsModalRef;
  message: any;
  listingCount: number;
  display: boolean;
  deletedId = null;
  csvList: any;
  csvOptions: any;
  categoryReportList: any;
  count=1;
  constructor(private categoryService: CategoryService, private modalService: BsModalService,
    private toastrMsgService: ToastrMsgService, private authService: AuthService,
    private confirmationService: ConfirmationService, private ngZone: NgZone
  ) {
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.csvOptions = {
                        fieldSeparator: ',',
                        quoteStrings: '"',
                        decimalseparator: '.',
                        showLabels: true,
                        showTitle: false,
                        useBom: true,
                        noDownload: false,
                headers: [
                          'Sr.No',
                          'Category Name',
                          'Description',
                         ]
                  };
  }
  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;
  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.breadcrumb = [
      { label: 'Manage Categories', routerLink: '' },
    ];
  this.cols = [
      { field: 'name', header: 'Category Name' },
      { field: 'description', header: 'Description'},
    ];
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  //add category modal
  public openModalWithComponent() {
    this.categoryService.setCategory(null);
    /* this is how we open a Modal Component from another component */
    this.bsModalRef = this.modalService.show(AddCategoryComponent);
    this.modalService.onHide.subscribe(() => {
      if (this.categoryService.getSavedCategory()) {
        this.getCategories(this.paginator);
      }
    });
  }
  //edit category modal
  public openModalEditCategories(rowData: EditCategory) {
    this.categoryService.setCategory(null);
    const initialState = {
      editData: rowData
    };
    this.bsModalRef = this.modalService.show(EditCategoryComponent, { initialState });
    this.modalService.onHide.subscribe(() => {
      if (this.categoryService.getSavedCategory()) {
        this.getCategories(this.paginator);
      }
    });
  }
  //method for getting previous page
  setCurrentPage(n: number) {
    const paging = {
      first: ((n - 1) * this.dataTable.rows),
      rows: this.dataTable.rows
    };
    this.loadUsersLazy(paging);
  }

  resetPagination($event?: any) {
    this.primePaginator.first = 0;
    this.paginator.pageNo = 0;
  }
  //category listing method
  getCategories(lazyLoadData: Paginator) {
    this.categoryService.getCategoryList(lazyLoadData).subscribe(
      (data: HttpResponse<any>) => {
        this.categoryList = data.body;
        this.listingCount = data.body.length;
        this.totalCount = Number(data.headers.get('X-Total-Count'));
        if ((this.totalCount <= this.pageCount)) {
          this.paginator.paginator = false;
        } else {
          this.paginator.paginator = true;
        }
        this.paginator.loading = false;
        this.paginator.totalCount = this.totalCount;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);

        }
        else {
          this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
          this.paginator.loading = false;
        }
      }
    );
  }
  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",DESC";
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",ASC";
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getCategories(this.paginator);
  }

  // delete category method
  // deleteCategory(rowDta: any, cd: any) {
  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to delete this Category?',
  //     icon: 'pi pi-info-circle',
  //     acceptLabel: 'yes',
  //     key: 'addCategory',
  //     rejectLabel: 'no',
  //     accept: () => {
  //       this.categoryService.deleteCategories(rowDta.id).subscribe(
  //         (data: HttpResponse<any>) => {
  //           this.toastrMsgService.success('', this.message.categoryDltMsg, this.message.toastrTime);
  //           if (this.listingCount == 1) {
  //             this.setCurrentPage(this.paginator.pageNo);
  //           }
  //           this.getCategories(this.paginator);
  //         },
  //         (err: any) => {
  //           this.toastrMsgService.error('', this.message.activeCategoryExistMsg, this.message.toastrTime);
  //         }
  //       );
  //     },
  //     reject: () => {
  //     }
  //   });
  // }

  deleteCategory(id: any) {
    this.categoryService.deleteCategories(id).subscribe(
      (data: HttpResponse<any>) => {
        this.toastrMsgService.success('', this.message.categoryDltMsg, this.message.toastrTime);
        if (this.listingCount == 1) {
          this.setCurrentPage(this.paginator.pageNo);
        }
        this.getCategories(this.paginator);
        this.display = false;
      },
      (err: any) => {
        this.toastrMsgService.error('', this.message.activeCategoryExistMsg, this.message.toastrTime);
      }
    );
  }
      getCategoryItemList(lazyLoadData: Paginator) {
          this.categoryService.getCategoryItemList(lazyLoadData).subscribe(
          data=>{
          this.categoryReportList=data.body;
                   this.csvList=[];
                   this.categoryReportList.forEach(item=> {
                   this.csvList.push({
                                        SerialNo: this.count++,
                                        category: item.name,
                                        description: item.description != null ? item.description : ''
                                     });
                                });
          new AngularCsv(this.csvList, 'Category', this.csvOptions);
                  this.listingCount = data.body.length;
                  this.totalCount = Number(data.headers.get('X-Total-Count'));
                      if ((this.totalCount <= this.pageCount)) {
                         this.paginator.paginator = false;
                      } else {
                         this.paginator.paginator = true;
                      }
                         this.paginator.loading = false;
                         this.paginator.totalCount = this.totalCount;
                         this.count = 1;
                      },
                      (err: any) => {
                         if (navigator.onLine === false) {
                         this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);
                         }
                      else {
                         this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
                         this.paginator.loading = false;
                         }
                       }
                     );
                   }
       downloadCSV(){
        this.getCategoryItemList(this.paginator);
  }
}
