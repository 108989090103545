import { ManageBinModule } from '@app/feature/manage-bin/manage-bin.module';
import { ManageReportsModule } from '@app/feature/manage-reports/manage-reports.module';
import { ManageDashboardModule } from '@app/feature/manage-dashboard/manage-dashboard.module';
import { AuthenticationModule } from '@app/feature/authentication/authentication.module';
import { ManageSubCategoryModule } from '@app/feature/manage-sub-category/manage-sub-category.module';
import { ManageCategoryModule } from '@app/feature/manage-category/manage-category.module';
import { DataService } from '@app/core/services/data.service';
import { LoaderComponent } from '@app/shared/loader/loader.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HeaderComponent } from './header/header.component';
import { ToastrModule } from 'ngx-toastr';
import { LoaderService } from './services/loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from '@app/core/http-interceptors/loader-interceptor';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { AccordionModule } from 'ngx-bootstrap';
import { DeviceDetectorModule, DeviceDetectorService } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import {TooltipModule} from 'primeng/tooltip';
import { ManageItemsModule } from '@app/feature/manage-items/manage-items.module';
import { ManageSubrecipeModule } from '@app/feature/manage-subrecipe/manage-subrecipe.module';
import { ManageLocationsModule } from '@app/feature/manage-locations/manage-locations.module';
import { ManageWarehouseModule } from '@app/feature/manage-warehouse/manage-warehouse.module';
import { ShowAuthedDirective } from './show-authed.directive';
//import { ChangeThemeDirective } from './directives/max-check.directive';
import { ManageStockModule } from '@app/feature/manage-stock/manage-stock.module';
import { ManageStockInModule } from '@app/feature/manage-stock-in/manage-stock-in.module';
import { ManageStockOutModule } from '@app/feature/manage-stock-out/manage-stock-out.module';
//import { HighlightDirective } from '@app/shared/directives/max-check.directive';
@NgModule({
  declarations: [HeaderComponent, LoaderComponent, SidebarComponent, ShowAuthedDirective],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({ preventDuplicates: true, resetTimeoutOnDuplicate: true }),
    AccordionModule,
    ManageWarehouseModule,
    DeviceDetectorModule,
    RouterModule,
    ManageLocationsModule,
    AccordionModule.forRoot(),
    ManageCategoryModule,
    TooltipModule,
    ManageSubCategoryModule,
    ManageItemsModule,
    ManageSubrecipeModule,
    AuthenticationModule,
    ManageDashboardModule,
    ManageReportsModule,
    ManageBinModule,
    ManageStockInModule,
    ManageStockModule,
    ManageStockOutModule
  ],
  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    DataService,
    DeviceDetectorService
  ],
  exports: [
    LoaderComponent,
    HeaderComponent,
    SidebarComponent,
    AccordionModule,
    DeviceDetectorModule,
    ShowAuthedDirective,
  ]
})
export class SharedModule { }
