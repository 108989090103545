import { Component, OnInit, ViewChild } from '@angular/core';
import { Col } from '@app/shared/models/table-header.model';
import { Paginator } from '@app/shared/models/paginator.model';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ItemService } from '@app/core/services/item.service';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { Router } from '@angular/router';
import * as Constants from '@app/core/constants/common.constants';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { FileUploadService } from '@app/core/services/fil-upload.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-list-subrecipe',
  templateUrl: './list-subrecipe.component.html',
  styleUrls: ['./list-subrecipe.component.scss']
})


export class ListSubrecipeComponent implements OnInit {

  breadcrumb: Breadcrumb[];
  pageCount: number;
  totalCount: number;
  subrecipeItems: any;
  subrecipes: any;
  cols: Col[];
  message: any;
  paginator: Paginator;
  searchKey: string = "";
  listingCount: number;
  csvList: any = [];
  sampleCSVList: any = [];
  csvOptions: any;
  sampleCSVOptions: any;
  subRecipeList : any;
  count=1;
  uploadTrue: boolean = false;
  csvFile: File;
  fileName: string;
  displayDialog: boolean;
  transactionErrorList: any[] = [];
  restrictSave: boolean = false;
  display: boolean = false;
  deletedId: any = null;

  constructor(
    private itemService: ItemService,
    private router: Router,
    private toastrMsgService: ToastrMsgService,
    private fileUploadService: FileUploadService,
  ){
    this.csvOptions = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: false,
          useBom: true,
          noDownload: false,
          headers: [
            'Sr.No',
            'parent_item_code',
            'parent_item_uom',
            'parent_item_qty',
            'child_item_code',
            'child_parent_item_uom',
            'child_item_qty'
          ]
    };
    this.sampleCSVOptions = {
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalseparator: '.',
              showLabels: true,
              showTitle: false,
              useBom: true,
              noDownload: false,
              headers: [
                'parent_item_code',
                'parent_item_uom',
                'parent_item_qty',
                'child_item_code',
                'child_parent_item_uom',
                'child_item_qty'
              ]
    };
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    headers:[
      'Item Code',
      'Item Name',
      'UOM',
      'Quantity',
      'Cost'
    ]
  }
  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;
    ngOnInit() {
      this.message = Constants.MESSAGES;
      this.breadcrumb = [{ label: 'Manage Sub Recipe', routerLink: '' }];
      this.cols = [
            { field: 'parentItemCode', header: 'Item Code', width: '20%' },
            { field: 'parentItemName', header: 'Item Name', width: '20%' },
            { field: 'parentItemUom', header: 'UOM', width:'20%' },
            { field: 'parentItemQty', header: 'Quantity', width:'20%' },
            { field: 'cost', header: 'Cost', width:'20%' }
          ];
      this.paginator = {
            pageNo: this.message.pageNo,
            limit: this.message.limit,
            paginator: true,
            rows: this.pageCount,
            loading: true,
            sortOrder: '',
            sortColumn: '',
            totalCount: this.message.totalCount,
            skip: this.message.skip
          };
      this.sampleCSVList =[{"parent_item_code": "PIT01", "parent_item_uom": "KG", "parent_item_qty": "110", "child_item_code": "CIM01", "child_parent_item_uom":"GRAM", "child_item_qty":"101"},
                    {"parent_item_code": "PIT02", "parent_item_uom": "KG", "parent_item_qty": "210", "child_item_code": "CIM02", "child_parent_item_uom":"GRAM", "child_item_qty":"501"}];
    }

    paginatorInitial() {
          this.paginator = {
          pageNo: this.message.pageNo,
          limit: this.message.limit,
          paginator: true,
          rows: this.pageCount,
          loading: true,
          sortOrder: '',
          sortColumn: '',
          totalCount: this.message.totalCount,
          skip: this.message.skip
        };
      }

  loadUsersLazy(event: any) {
      if (event.sortField !== undefined) {
        if (event.sortOrder === -1) {
          this.paginatorInitial();
          this.primePaginator.first = 0;
          this.paginator.sortOrder = event.sortField + ",DESC";
        } else if (event.sortOrder === 1) {
          this.paginatorInitial();
          this.primePaginator.first = 0;
          this.paginator.sortOrder = event.sortField + ",ASC";
        }
      } else {
        this.paginator.sortOrder = '';
      }
      this.paginator.loading = true;
      this.paginator.pageNo = event.first / event.rows;
      this.paginator.limit = event.rows;
      this.getSubrecipes(this.paginator);
    }
    resetPagination($event?: any) {
          this.primePaginator.first = 0;
          this.paginator.pageNo = 0;
    }

    handleSearchInput(event: KeyboardEvent) {
        if(event.keyCode == 13) {
          this.getSubrecipes();
        }
      }

    getSubrecipes(lazyLoadData: Paginator = this.paginator) {
        this.itemService.getSubrecipes(lazyLoadData, this.searchKey).subscribe(
          data =>{
            this.subrecipes = data['body'];
            this.listingCount = data.body.length;
            this.totalCount = Number(data.headers.get('X-Total-Count'));
            if (this.totalCount <= this.pageCount) {
              this.paginator.paginator = false;
            } else {
              this.paginator.paginator = true;
            }
            this.paginator.loading = false;
            this.paginator.totalCount = this.totalCount;
          },
          (err: any) => {
         }
        );
    }

    deleteSubrecipe(id: any){
    }
    openAddSubrecipe() {
      this.router.navigateByUrl('/manage-subrecipe/add-subrecipes');
    }

    download_Sample_CSV(){
      new AngularCsv(this.sampleCSVList, 'SAMPLE_SUBRECIPE_CSV', this.sampleCSVOptions);
    }
    downloadCSV(){
      this.getAllSubRecipe();
    }

    getAllSubRecipe(){
      this.count = 1;
      this.itemService.getAllSubrecipes().subscribe(
        (data: any) => {
          this.subRecipeList = data;
          this.csvList = [];
          this.subRecipeList.forEach(data => {
            this.csvList.push({
              SerialNo:this.count++,
              parent_item_code: data.parentItemCode,
              parent_item_uom: data.parentItemUom,
              parent_item_qty: data.parentItemQty,
              child_item_code: data.childItemCode,
              child_parent_item_uom: data.childParentItemUom,
              child_item_qty: data.childItemQty
            });
          });
          new AngularCsv(this.csvList, 'SubRecipe', this.csvOptions);
        },
        (err: any) => {
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              '',
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
              this.toastrMsgService.error(
                '',
                this.message.errorMsg,
                this.message.toastrTime
              );
            }
        }
      );
    }

    onSelectFile(event: any) {
      this.uploadTrue = false;
      if (event.target.files) {
        if (this.fileUploadService.checkFileType(event.target.files[0].type, event.target.files[0].name)) {
          this.csvFile = event.target.files[0];
            this.fileName = event.target.files[0].name;
            this.uploadTrue = true;
        } else {
            this.toastrMsgService.error(
              '',
              this.message.csvFailure,
              this.message.toastrTime
              );
            }
            event.target.value = '';
      }
    }

    submit() {
      this.uploadTrue = false;
      this.restrictSave = true;
      const formData = new FormData();
      formData.append('multipartFile', this.csvFile);
      this.itemService.uploadSubrecipeCSV(formData).subscribe(
        (data: any) => {
          if (data === null) {
            this.toastrMsgService.success(
              '',
              this.message.uploadCsvSuccess,
              this.message.toastrTime
            );
          } else {
              this.toastrMsgService.error(
                '',
                this.message.errorMsg,
                this.message.toastrTime
                );
              }
        },
        (err: any) => {
          this.restrictSave = false;
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              '',
              this.message.noInternet,
              this.message.toastrTime
            );
          }
          else if(Array.isArray(err.error) && err.error.length) {
            this.transactionErrorList = err.error;
          }
          else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
        }
      );
    }

    closeErrorList() {
      this.transactionErrorList = [];
    }

  cancel() {
    this.uploadTrue = false;
    this.displayDialog = false;
  }
}
