import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManageStockOutAddComponent } from '@app/feature/manage-stock-out/manage-stock-out-add/manage-stock-out-add.component';
import { ManageStockoutListComponent } from '@app/feature/manage-stock-out/manage-stockout-list/manage-stockout-list.component';
import { ManageStockoutViewComponent } from '@app/feature/manage-stock-out/manage-stockout-view/manage-stockout-view.component';

const routes: Routes = [
  { path: 'manage-stock-out-add', component: ManageStockOutAddComponent },
  { path: 'manage-stock-out-list', component: ManageStockoutListComponent },
  { path: 'manage-stock-out-view/:id', component: ManageStockoutViewComponent }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageStockOutRoutingModule { }
