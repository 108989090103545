import { AuthGuard } from './../../core/guards/auth-guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListLocationsComponent } from './list-locations/list-locations.component';
import { EditLocationComponent } from './edit-location/edit-location.component';


const routes: Routes = [
  {path: 'locations', component: ListLocationsComponent, canActivate: [AuthGuard]},
  {path: 'edit', component: EditLocationComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageLocationsRoutingModule { }
