import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DropdownModule} from 'primeng/dropdown';
import { ManageLocationsRoutingModule } from './manage-locations-routing.module';
import { ListLocationsComponent } from './list-locations/list-locations.component';
import { BreadcrumbModule } from 'primeng/components/breadcrumb/breadcrumb';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { TooltipModule } from 'primeng/tooltip';
import { EditLocationComponent } from '@app/feature/manage-locations/edit-location/edit-location.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AddLocationsComponent } from './add-locations/add-locations.component';
import { InputTextModule } from 'primeng/primeng';
import { LabelControlDirective } from '@app/core/directives/label-control.directive';

@NgModule({
  declarations: [
    ListLocationsComponent,
    EditLocationComponent,
    AddLocationsComponent,
    LabelControlDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    ManageLocationsRoutingModule,
    FormsModule,
    ModalModule.forRoot(),
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    CheckboxModule,
    ChipsModule,
    TooltipModule,
    BreadcrumbModule,
    InputTextModule
  ],
  entryComponents: [ AddLocationsComponent
  ],
  exports: [LabelControlDirective]
})
export class ManageLocationsModule { }
