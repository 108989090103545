import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MESSAGES } from '@app/core/constants/common.constants';

@Injectable({
  providedIn: 'root'
})
export class ToastrMsgService {
  constructor(private toastrService: ToastrService) {}
   /**Method for success toastr message */
  success(title: any, msg: any, time: any) {
    this.toastrService.success(msg, title, {
      timeOut: time
    });
  }
  /**Method for error toastr message */

  error(title: any = "", msg: any = MESSAGES.errorMsg, time: any = MESSAGES.toastrTime) {
    this.toastrService.error(msg, title, {
      timeOut: time
    });
  }
   /**Method for warning toastr message */

  warning(title: any, msg: any, time: any) {
    this.toastrService.warning(msg, title, {
      timeOut: time
    });
  }
/**Method for info toastr message */
  info(title: any, msg: any, time: any) {
    this.toastrService.info(msg, title, {
      timeOut: time
    });
  }

  /** offline alert */
  offlineAlert() {
    this.toastrService.error("", MESSAGES.noInternet, {
      timeOut: MESSAGES.toastrTime
    });
  }

}
