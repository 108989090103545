import { AuthGuard } from './core/guards/auth-guard';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './feature/authentication/login/login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full', canActivate: [NoAuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'redirect',
    component: LoginComponent
  },
  {
    path: 'manage-sub-category', loadChildren: './feature/manage-sub-category/manage-sub-category.module#ManageSubCategoryModule',
    canActivate: [AuthGuard]
  },
  // { path: 'manage-uom', loadChildren: './feature/manage-uom/manage-uom.module#ManageUomModule' },
  { path: 'manage-items', loadChildren: './feature/manage-items/manage-items.module#ManageItemsModule', canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
