import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventry-reports',
  templateUrl: './inventry-reports.component.html',
  styleUrls: ['./inventry-reports.component.scss']
})
export class InventryReportsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
