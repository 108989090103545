import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { NgForm } from '@angular/forms';
import { AddCategory } from '@app/shared/models/category.model';
import { CategoryService } from '@app/core/services/category.service';
import { Router } from '@angular/router';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit, AfterViewInit {

  displayDialog: boolean;
  addCategory: AddCategory;
  message: any;
  invalidCategory = false;
  restrictSubmit = false;

  constructor(public bsModalRef: BsModalRef,
    private toastrMsgService: ToastrMsgService,
    private categoryService: CategoryService,
    private router: Router) { }

  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.addCategory = {
      name: null,
      description: null

    };
  }
  ngAfterViewInit(): void {
    this.displayDialog = true;
  }
  // method for adding category
  onSubmit(addCategoryForm: NgForm) {
    // check for duplicate category name
    this.restrictSubmit = true;
    this.categoryService.checkDuplicateCategory(addCategoryForm.value.name).subscribe(
      resp => {
        if (resp.valid === false) {
          this.invalidCategory = true;
          this.restrictSubmit = false;
          this.toastrMsgService.error('', this.message.categoryAddDuplicateMsg, this.message.toastrTime);
        } else {
          this.invalidCategory = false;
          this.categoryService.addCategory(addCategoryForm.value).subscribe((data: any) => {
            this.restrictSubmit = false;
            if (data.status) {
              this.displayDialog = false;
            }
            this.toastrMsgService.success('', this.message.categoryAddSuccessMsg, this.message.toastrTime);
            this.addCategory.name = null;
            this.addCategory.description = null;
            this.categoryService.setCategory(data);
            this.bsModalRef.hide();
          },
            (err: any) => {
              this.restrictSubmit = false;
              this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
            }
          );
          this.displayDialog = false;
        }
      },
      err => {
        this.restrictSubmit = false;
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
    this.invalidCategory = false;
  }
  // hiding modal method
  cancel() {
    this.bsModalRef.hide();
    this.displayDialog = false;
  }
}
