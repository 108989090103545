import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject, ReplaySubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
const httpOptions = {
  headers: new HttpHeaders({
    'api-end-type':  'ezyprocure'
  })
};
@Injectable({
  providedIn: 'root'
})

export class SignInService {

  // private loggedIn = new BehaviorSubject<boolean>(false);
// private flag:boolean
  constructor(public http: HttpClient) { }
  // get isLoggedIn() {
  //   return this.loggedIn.asObservable(); // {2}
  // }
  login(signInvalue: any) {

    return this.http.post(`authenticate-ezyinventory
    `, signInvalue, httpOptions);
  }
  //for hiding sidebar in login page and making it visible after logged inn
  // setFlag(){
  //   const token = localStorage.getItem('token');
  //   if(token){
  //  return this.flag=true;
  // }
  }

