import { CategoryService } from '@app/core/services/category.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { AddSubCategory } from '@app/shared/models/sub-category.model';
import { SubCategoryService } from '@app/core/services/sub-category.service';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';

@Component({
  selector: 'app-add-subcategory',
  templateUrl: './add-subcategory.component.html',
  styleUrls: ['./add-subcategory.component.scss']
})
export class AddSubcategoryComponent implements OnInit, AfterViewInit {
  displayDialog: boolean;
  category: string;
  itemGroupName: string;
  message: any;
  categoryName: string;
  addSubCategory = new AddSubCategory();
  restrictSubmit = false;

  constructor(public bsModalRef: BsModalRef, private toastrMsgService: ToastrMsgService,
              private subCategoryService: SubCategoryService, private categoryService: CategoryService
  ) { }

  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.addSubCategory = {
      name: null,
      description: null,
      category: { id: null }
    };
    this.getList();

  }
  ngAfterViewInit(): void {
    this.displayDialog = true;
  }
  addSubCategories() {
    this.addSubCategory.name = null;
    this.addSubCategory.description = null;
    this.addSubCategory.category.id = null;
    this.itemGroupName = null;
    this.displayDialog = true;
  }
  getList() {
    this.subCategoryService.getCategoryList().subscribe(
      (data: any) => {
        this.category = data;
      },
      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
  }
  getCategoryId(event: any) {
    this.addSubCategory.category.id = event.value.id;
    this.categoryName = event.value.name;

  }
  onSubmit() {
    this.restrictSubmit = true;
    this.subCategoryService.checkDuplicateSubCategory(this.categoryName, this.addSubCategory.name).subscribe(
      (resp: any) => {
        if (resp.valid === false) {
          this.restrictSubmit = false;
          this.toastrMsgService.error('', this.message.subcategoryAddDuplicateMsg, this.message.toastrTime);

        } else {
           this.subCategoryService.addTheSubCategory(this.addSubCategory).subscribe(
          (data: any) => {
            this.restrictSubmit = false;
            this.displayDialog = false;
            this.toastrMsgService.success('', this.message.subcategoryAddSuccessMsg, this.message.toastrTime);
            this.categoryService.setCategory(data);
            this.bsModalRef.hide();
          },
         (err: any) => {
           
          this.restrictSubmit = false;
           this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);

          }
         );
           this.displayDialog = false;
        }

      },
      err => {
        this.restrictSubmit = false;
        console.log(err);

      }
    );

    // this.subCategoryService.addTheSubCategory(this.addSubCategory).subscribe(
    //   (data: any) => {
    //     this.displayDialog = false;
    //     this.toastrMsgService.success('', this.message.subcategoryAddSuccessMsg, this.message.toastrTime);
    //     this.categoryService.setCategory(data);
    //     this.bsModalRef.hide();
    //   },
    //   (err: any) => {
    //     this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);

    //   }
    // );
    // this.displayDialog = false;

  }
  cancel() {
    this.bsModalRef.hide();
    this.displayDialog = false;
  }
}
