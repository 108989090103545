import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BinService {

  private api: any;
  bin: any;
  constructor(public http: HttpClient) {
    this.api = environment.API_ENDPOINT;

  }
  getBin(lazyLoadData: any): Observable<HttpResponse<any>> {

    return this.http.get<any>(`bin?page=${lazyLoadData.pageNo}&size=${lazyLoadData.limit}&sort=${lazyLoadData.sortOrder}`, {
      observe: 'response'
    });
  }
  getItem(lazyLoadData: any, searchQuery: string=""):Observable<HttpResponse<any>> {
    if (searchQuery)
      searchQuery = searchQuery.replace('%', '%25');
   return this.http.get<any>(`item?page=${lazyLoadData.pageNo}&size=${lazyLoadData.limit}&sort=${lazyLoadData.sortOrder}&query=${searchQuery}`, {
      observe: 'response'
    });

  }
  getItemLst(lazyLoadData: any):

    Observable<HttpResponse<any>> {
      return this.http.get<any>(`item?page=${lazyLoadData.pageNo}&size=10000&sort=${lazyLoadData.sortOrder}`, {
        observe: 'response'
      });
    }
  getItemList(lazyLoadData: any):

    Observable<HttpResponse<any>> {
      return this.http.get<any>(`item`, {
        observe: 'response'

      });
    }
  getBinList(id: any): Observable<HttpResponse<any>> {

    return this.http.get<any>(`bins?warehouseId=${id}`, {
      observe: 'response'
    });
  }
  getBinItemList(id: any): Observable<HttpResponse<any>> {
      return this.http.get<any>(`bins`, {
        observe: 'response'
      });
    }
  addbin(formData: any): Observable<any> {
    const body = formData;
    return this.http.post(`bin`, body);
  }
  addItem(formData: any): Observable<any> {
    const body = formData;
    return this.http.post(`item`, body);
  }
  editItem(itemId, formData: any): Observable<any> {
    const body = formData;
    return this.http.put(`item/${itemId}`, body);
  }
  deleteBin(id: number) {

    return this.http.delete(`bin/${id}`);

  }

  // Setter and gettter for bin
  setBin(data: any) {
    this.bin = data;
  }

  getSavedBin() {
    return this.bin;
  }

  getAllBin(lazyLoadData: any): Observable<HttpResponse<any>> {

      return this.http.get<any>(`bin`, {
        observe: 'response'
      });
    }

  checkBinAvailable() : Observable<HttpResponse<any>> {
    return this.http.get<any>(`available-bin-count`, {
      observe: 'response'
    });
  }

  getAllItem() : Observable<HttpResponse<any>> {
    return this.http.get<any>('items', {
      observe: 'response'
    });
  }
}
