import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DropdownModule} from 'primeng/dropdown';
import { BreadcrumbModule } from 'primeng/components/breadcrumb/breadcrumb';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { TooltipModule } from 'primeng/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ManageBinRoutingModule } from './manage-bin-routing.module';
import { ListBinsComponent } from '@app/feature/manage-bin/list-bins/list-bins.component';
import { EditBinComponent } from '@app/feature/manage-bin/edit-bin/edit-bin.component';
import { AddBinComponent } from './add-bin/add-bin.component';
import { InputTextModule } from 'primeng/primeng';
import { ManageLocationsModule } from '../manage-locations/manage-locations.module';

@NgModule({
  declarations: [ListBinsComponent, EditBinComponent, AddBinComponent],
  imports: [
    CommonModule,
    ManageBinRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    FormsModule,
    ModalModule.forRoot(),
    TableModule,
    ConfirmDialogModule,
    ManageLocationsModule,
    DialogModule,
    CheckboxModule,
    ChipsModule,
    TooltipModule,
    BreadcrumbModule,
    InputTextModule
  ],
  entryComponents: [EditBinComponent,AddBinComponent
  ]
})
export class ManageBinModule { }
