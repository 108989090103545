import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Col } from '@app/shared/models/table-header.model';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { AddLocation } from '@app/shared/models/location.model';
import { LocationService } from '@app/core/services/location.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { Country } from '@app/core/constants/country';
import { CountryListService } from '@app/core/services/country-list.service';
import { BsModalRef } from 'ngx-bootstrap';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { ItemService } from '@app/core/services/item.service';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.scss']
})
export class EditLocationComponent implements OnInit, AfterViewInit {
  @Output() messageEvent = new EventEmitter<string>();
  name = new FormControl('');
  cols: Col[];
  locationId: any;
  lastNam: string;
  dropdown: any;
  displayDialog: boolean;
  addLocation: AddLocation;
  breadcrumb: Breadcrumb[];
  locations: any;
  cou: any;
  registerForm: FormGroup;
  submitted = false;
  countries: any;
  filteredCountry: any;
  locationform: { id: any; code: any; name: any; address: any; country: any };
  editLocation: any;
  message: any;
  countryId: any;
  duplicateCode: boolean;
  oldValue:any;
  constructor(private locationService: LocationService, public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder, private router: Router,
    private countryListService: CountryListService,
    private itemService: ItemService,
    private toastrMsgService: ToastrMsgService) { }
  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.getCountryList();

    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';


    this.dropdown = Country;
    this.breadcrumb = [{ label: 'Manage Locations', routerLink: '' }];
    this.cols = [
      { field: 'locationcode', header: 'Location Code' },
      { field: 'locationname', header: 'Location Name' },
      { field: 'address', header: 'Address' },
      { field: 'country', header: 'Country' }
    ];



    this.registerForm = this.formBuilder.group({
      locationCode: ['', Validators.required],
      locationName: ['', Validators.required],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]]
    });
    this.editLocations(this.locationId);
  }
  ngAfterViewInit() {
    this.displayDialog = true;
  }
  get f() {
    return this.registerForm.controls;
  }

  editLocations(locId: any) {

    locId = this.locationId;
    this.locationService.editLocation(locId).subscribe(
      data => {

        this.editLocation = data['body'];
        this.registerForm.value.locationCode = this.editLocation.code;
        this.oldValue = this.editLocation.code;
        this.registerForm.setValue({
          'locationCode': this.editLocation.code,
          'locationName': this.editLocation.name,
          'address': this.editLocation.address,
          'country': this.editLocation.country
        });
      },

      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );


  }
  getCountryList() {
    this.countryListService.getCountry().subscribe(
      data => {

        this.countries = data['body'];
      },
      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
  }
  changeEve(data) {
    if (data) {
      this.countryId = data.id;
    }

  }
  onSubmit() {

    this.submitted = true;
    this.locationform = {
      id: this.locationId,
      code: this.registerForm.value.locationCode,
      name: this.registerForm.value.locationName,
      address: this.registerForm.value.address,
      country: { id: this.countryId }
    };

    this.locationService
      .editLocationPost(this.locationform)
      .subscribe((data: any) => {
        this.bsModalRef.hide();
        this.displayDialog = false;
        this.registerForm.reset();
        this.locationService.setLocation(data);
        this.toastrMsgService.success('', this.message.locationEditSuccessMsg, this.message.toastrTime);
        this.messageEvent.emit();
      }, (err: any) => { });
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.router.navigateByUrl('/locations');
  }
  cancel() {

    this.bsModalRef.hide();
    this.displayDialog = false;
  }

  checkDuplicate(type,item){
    this.itemService.checkDuplication(type,item).subscribe(
      (data:any)=>{
        if(data){
          if(this.oldValue ===item){
            this.duplicateCode = false;
          }else{
            this.duplicateCode = data.body;
          }
        }
      },err=>{

      }
    );
  }
}
