import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuItem, Breadcrumb } from "primeng/primeng";
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';

import { Paginator } from '@app/shared/models/paginator.model';
import * as Constants from '@app/core/constants/common.constants';
import { Col } from '@app/shared/models/table-header.model';

import { ManageStockService } from '@app/core/services/manage-stock.service';
import { DatePipe } from '@angular/common';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';

@Component({
  selector: 'app-stock-transfer-view',
  templateUrl: './stock-transfer-view.component.html',
  styleUrls: ['./stock-transfer-view.component.scss']
})
export class StockTransferViewComponent implements OnInit {

  transactionId: string = undefined;
  remark: string;
  documentDate: string;
  fromLoc: string;
  fromWarehouse: string;
  toLoc: string;
  toWarehouse: string;
  stockTransferDetails: any = [];
  restrictStockUpdate: boolean = false;
  buttonEnable: boolean = false;
  stockUpdateList: any = [];
  stockUpdateListNew: any = [];
  stockTransferObj = {};

  breadcrumb: MenuItem[];

  paginator: Paginator;
  message: any;
  pageCount: number;
  filterText: string;
  datePipe = new DatePipe('en-US');
  cols: Col[];
  csvList: any;
  csvOptions: any;
  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private stockService: ManageStockService,
              private toastrMsgService: ToastrMsgService
    ) { }

  ngOnInit() {
    this.transactionId = this.route.snapshot.paramMap.get('id');

    this.breadcrumb = [
      { label: 'Manage Stock Transfer', routerLink: '/stock/transfer' },
      { label: 'View Stock Transfer', url: '' }
    ];

    this.message = Constants.MESSAGES;
    this.pageCount = Constants.PTABLE_PAGE_COUNT;

    this.cols = [
      { field: 'itemCode', header: 'Item Code' },
      { field: 'item', header: 'Item Name' },
      { field: 'uom', header: 'UOM' },
      { field: 'transferQuantity', header: 'Transfer Quantity' },
      { field: 'updateQuantity', header: 'Update Transfer Quantity' },
      { field: 'value', header: 'Value'},
      { field: 'sourceBin', header: 'From Bin(Shelf/Rack)' },
      { field: 'destinationBin', header: 'To Bin(Shelf/Rack)' },
      { field: 'batchNumber', header: 'Batch No.' }
    ];

    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
    this.getstockTransferDetails();
    this.csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: [
      'Code',
      'Name',
      'UOM',
      'Transfer Quantity',
      'Updated Transfer Quantity',
      'Value',
      'From Bin(Shelf/Rack)',
      'To Bin(Shelf/Rack)',
      'Batch',
      'Source Location',
      'Source Warehouse',
      'Destination Location',
      'Destination Warehouse'
       ]
    };
  }

  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ',DESC';
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ',ASC';
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getstockTransferDetails();
  }

  getstockTransferDetails(lazyLoadData: Paginator = this.paginator) {
    // this.transactionId
    this.stockService.getStockTransferDetails(this.transactionId).subscribe(
      (data) => {
        this.stockTransferDetails = data;
        if (!!this.stockTransferDetails && !!this.stockTransferDetails[0]) {
          this.transactionId = this.stockTransferDetails[0].transactionNumber;
          this.remark = this.stockTransferDetails[0].comment;
          this.documentDate = this.datePipe.transform(this.stockTransferDetails[0].createdDate, 'dd-MM-yyyy');
          this.fromLoc = this.stockTransferDetails[0].sourceLocation;
          this.fromWarehouse = this.stockTransferDetails[0].sourceWarehouse;
          this.toLoc = this.stockTransferDetails[0].destinationLocation;
          this.toWarehouse = this.stockTransferDetails[0].destinationWarehouse;
        }
        this.stockTransferDetails.forEach(element => {
          element.createdDate = this.datePipe.transform(element.createdDate, 'dd-MM-yyyy');
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  onCancel() {
    this.router.navigateByUrl('/stock/transfer');
  }

  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  modelChange(event: any, rowData: any) {
      this.buttonEnable = false;

      if (rowData.updateQuantity > rowData.transferQuantity) {
        rowData.updateQuantity = rowData.transferQuantity;
      }

      if (rowData.updateQuantity === null || rowData.updateQuantity === '') {
        rowData.updateQuantity = null;
        if (this.stockUpdateList.length > 0) {
          this.stockUpdateList.forEach(item => {
            if (item.id === rowData.id) {
              this.stockUpdateList.splice(this.stockUpdateList.indexOf(item), 1);
            }
          });
        }
      } else {
        if (this.stockUpdateList.length > 0) {
          let find = false;
          this.stockUpdateList.forEach(item => {
            if (item.id === rowData.id) {
              find = true;
              item.updateQuantity = rowData.updateQuantity;
            }
          });
          if (find === false) {
            this.stockUpdateList.push(rowData);
          }
        } else {
          this.stockUpdateList.push(rowData);
        }
      }
      if (this.stockUpdateList.length > 0) {
        this.stockUpdateList.find(item => {
          if (item.updateQuantity !== null) {
            this.buttonEnable = true;
          }
        });
      }
    }

      // update stock on transfer
      updateStockTransfer() {
        this.stockUpdateListNew = [];
        this.stockUpdateList.forEach(item => {
          if (item.updateQuantity !== null) {
            this.stockUpdateListNew.push({
              stockId: item.stockId,
              stockTransferReportId: item.id,
              selectedBin: item.binId,
              updateQuantity: item.updateQuantity,
              itemCode: item.itemCode,
            });
          }
        });

      this.stockTransferObj = {
        "destinationWarehouseId" : this.stockUpdateList[0].destinationWarehouseId,
        "transferList":  this.stockUpdateListNew,
        "sourceWarehouse" : this.stockUpdateList[0].sourceWarehouse
      }
          this.stockService
          .updateTransferFromViewDetails(this.stockTransferObj)
          .subscribe(
            (data: any) => {
              this.stockUpdateList = [];
              this.stockUpdateListNew = [];
              this.stockTransferObj = {}
              this.toastrMsgService.success(
                '',
                this.message.transferSuccessMsg,
                this.message.toastrTime
              );
              this.router.navigateByUrl('/stock/transfer');
            },
            (err: any) => {
              this.paginator.loading = false;
              if (navigator.onLine === false) {
                this.toastrMsgService.warning(
                  '',
                  this.message.noInternet,
                  this.message.toastrTime
                );
              } else {
                this.toastrMsgService.error(
                  '',
                  this.message.errorMsg,
                  this.message.toastrTime
                );
              }
            }
          );
    }
    exportStockTransferDetailsCSV() {
        this.csvList = [];
        this.stockTransferDetails.forEach((stock:any) =>{
          this.csvList.push({
            itemCode : stock.itemCode,
            itemName : stock.item,
            uom : stock.uom,
            transferQuantity : stock.transferQuantity || '',
            quantity : stock.quantity || '',
            value: stock.value || '',
            sourceBin: stock.sourceBin || '',
            destinationBin : stock.destinationBin || '',
            batchNumber : stock.batchNumber || '',
            locationCode : stock.sourceLocation || '',
            warehouseCode : stock.sourceWarehouse || '',
            destinationLocationCode : stock.destinationLocation || '',
            destinationWarehouseCode : stock.destinationWarehouse || ''
          });
        });
        new AngularCsv(this.csvList, 'Stock_Transfer_'+this.transactionId, this.csvOptions);      
    }
  
    downloadCSV() {
      this.exportStockTransferDetailsCSV();
    }
}
