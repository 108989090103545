import { ROLE_BUYER } from './../../../core/constants/common.constants';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm, FormControl } from '@angular/forms';
import { ItemService } from '@app/core/services/item.service';

import { ActivatedRoute } from '@angular/router';
import { DecimalPipe } from '@angular/common';

import { CategoryService } from '@app/core/services/category.service';
import { SubCategoryService } from '@app/core/services/sub-category.service';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { FormGroup } from '@angular/forms';
import { Col } from '@app/shared/models/table-header.model';
import { WarehouseService } from '@app/core/services/warehouse.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddInventoryComponent } from '../add-inventory/add-inventory.component';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { BinService } from '@app/core/services/bin.service';
import { location } from 'ngx-bootstrap/utils/facade/browser';
import { take, takeLast } from 'rxjs/operators';
import { BsDatepickerConfig } from 'ngx-bootstrap';

type AlternateUoM = { uomName: any, conversionFactor: number, baseUom:any, conversionFactorBaseUom: number };
type TabModel = {code: string, header: string, active: boolean};
type LocationDetail = { locationId: number, locationName: string, minLevel: number, maxLevel: number, reorderLevel: number, reorderQty: number};

@Component({
  selector: 'app-view-item',
  templateUrl: './view-item.component.html',
  styleUrls: ['./view-item.component.scss']
})
export class ViewItemComponent implements OnInit {
  breadcrumb: Breadcrumb[];
  category: string[];
  display: boolean;
  bsModalRef: BsModalRef;
  price: any;

  altUomData: any;
  catName: any;
  cols: Col[];
  itemCode: any;
  subCategory: any;
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  itemName: any;
  uom: any;
  alternate: any;
  tableContents: any = [];
  tableContentsUom: any = [];
  message: any;
  quantity: any;
  alternateUom: any;
  location: any;
  displayTable: boolean;
  warehouse: any;
  toggleid: boolean;
  bin: any;
  locationList: any;
  warehouseList: any;
  binList: any;
  uomListName: any;
  selectedValueUom: any;
  lactionName: any;
  loctionName: any;
  warehouseName: any;
  binName: any;
  toggle: boolean;
  catList: any;
  SubcatList: any;
  col1: any;
  col2: any;
  col3: any;
  col4: any;
  col5: any;
  col6: any;
  addInventory: {
    alternativeUomId: any;
    toggle: boolean;
    locationInventory: any;
    valuation: any;
    alternateUomQty: any;
    batchcode: any;
    category: any;
    subCategory: any;
    defaultSupplier: any;
    itemCode: any;
    itemName: any;
    cost: any;
    barCode: any;
    uom: any;
    alternateUom: any;
    location: any;
    warehouse: any;
    batchManagement: any;
    bin: any;
    baseUomQty: any;
    col1: any;
    col2: any;
    col3: any;
    col4: any;
    col5: any;
    col6: any;
  };
  uomList: any = [];
  batchList: any;
  supplierList: any;
  defaultSupplierList: any = [];
  alternateUoMsList: any[] = [];
  valuation: any;
  batchData: any;
  displayDialog: boolean;
  batchCodeBoolean: boolean;
  uomListData: any;
  uomAlterListData: any;
  locationId: any;
  warehouseId: any;
  binId: any;
  qtyValue: any;
  selectedItemId: any;
  itemList: any;
  tableContentsDefault: any;
  itemId: any;
  cat: any;
  defaultSupplierListName: any;
  openInventoryDetails: boolean;
  roleBuyer: boolean;
  bsValue = new Date();
  form = new FormGroup({
    dateRange: new FormControl([new Date()])
  });
  /* Tabs declaration */
  inventoryTab: TabModel = { code:"INV", header: "Inventory Details", active: true };
  locationTab: TabModel = { code:"LOC", header: "Inventory Levels", active: false };
  currentTab: TabModel;
  tabsList: TabModel[] = [];
  /* Location details */
  locationDetails: LocationDetail[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subCategoryService: SubCategoryService,
    private categoryService: CategoryService,
    private modalService: BsModalService,
    private itemService: ItemService,
    private binService: BinService,
    private toastrMsgService: ToastrMsgService,
    private warehouseService: WarehouseService,
    private bsDatepickerConfig: BsDatepickerConfig,
    private decimalPipe: DecimalPipe
  ) {

    this.route.params.subscribe(params => {
      this.selectedItemId = params.id;
    });
    this.bsDatepickerConfig.dateInputFormat = 'DD-MM-YYYY';
  }

  ngOnInit() {
    //populate tabs
    this.tabsList = [ this.inventoryTab, this.locationTab ];
    this.currentTab = this.inventoryTab;

    // this.getDefaultSupplier();
    this.roleBuyer = (localStorage.getItem('role') === ROLE_BUYER) ? true : false;
    this.displayDialog = true;
    this.getEditTableList();
    this.fetchInventoryLevels();
    // this.getbatch();
    // this.getValuation();
    this.getAlternateUomTableList();

    // this.getUom();
    // this.getBatchList();
    this.displayTable = true;
    this.cols = [
      { field: 'location', header: 'Location' },
      { field: 'warehouseName', header: 'Warehouse Name' },
      { field: 'binId', header: 'Bin (Shelf/Rack) ID' },
      { field: 'binName', header: 'Bin (Shelf/Rack) Name' }
    ];
    // this.getLocation();

    this.message = Constants.MESSAGES;

    this.breadcrumb = [
      { label: 'Manage Items', routerLink: '/manage-items' },
      { label: 'View Item', routerLink: '' }
    ];
    this.cols = [
      {
        field: 'location',
        header: 'Location'
      },
      { field: 'warehouse', header: 'Warehouse' },
      { field: 'bin', header: 'Bin (Shelf/Rack)' },
      { field: 'batch', header: 'Batch#' },
      { field: 'productiondate', header: 'ProductionDate' },
      { field: 'expirydate', header: 'Expirydate' },
      { field: 'instock', header: 'InStock' },
      //{ field: 'allocatedstock', header: 'AllocatedStock' },
     // { field: 'availablestock', header: 'Available Stock' }
    ];
    // this.paginator.paginator = true;


    this.addInventory = {
      toggle: true,
      locationInventory: null,
      alternativeUomId: '',
      category: '',
      valuation: '',
      subCategory: '',
      defaultSupplier: '',
      itemCode: '',
      itemName: '',
      cost: '',
      alternateUomQty: 6,
      barCode: '',
      batchcode: 'On Release',
      uom: '',
      alternateUom: ' ',
      location: '',
      // alternateUomQty:null,
      warehouse: '',
      batchManagement: '',
      bin: '',
      baseUomQty: 6,
      col1: '',
      col2: '',
      col3: '',
      col4: '',
      col5: '',
      col6: '',
    };


    this.getEditList();
  }

  onTabSwitch(activeTab: TabModel) {
    if(this.currentTab.code == activeTab.code) {
      return;
    }
    else {
      this.currentTab = activeTab;
      this.tabsList.forEach(tab => {
        if(tab.code == activeTab.code) {
          tab.active = true;
        }
        else {
          tab.active = false;
        }
      });
    }
  }

  changeEveLoc(locdata) {
    this.locationId = locdata.id;

    this.loctionName = locdata.name;
    if (locdata) {
      this.getWarehouse(locdata.id);
      this.addInventory.warehouse = null;
      this.getBinList('');
    }
  }
  changeWarLoc(warData) {
    this.warehouseId = warData.id;
    this.warehouseName = warData.name;

    if (warData) {
      this.getBinList(warData.id);
    }
  }
  changeWarLo(warData) {


    this.warehouseId = warData.id;
    this.warehouseName = warData.name;

    if (warData) {
      this.getBinList(warData.id);
    }
  }
  changeBinLoc(binData) {
    this.binId = binData.id;
    this.binName = binData.name;
  }
  changeEveCat(catData) {




    this.catName = catData.name;
    if (catData) {
      this.getSubCategoryList(catData.id);

    }
    this.addInventory.subCategory = this.itemList.subcategory;
  }
  changeQty(id) {
    this.qtyValue = id;
  }

  changeUomCat(uomData) {


    this.uomListData = uomData;
    this.uomListName = uomData.name;
  }
  changeUomAlt(altData) {
    this.altUomData = altData.name;
  }
  changeAlterUomCat(uomAlterData) {
    this.uomAlterListData = uomAlterData;
  }
  getBinList(id: any) {
    this.binService.getBinList(id).subscribe(
      data => {
        this.binList = data.body;
        this.addInventory.bin = this.itemList.defaultBin;
      },
      (err: any) => { }
    );
  }
  getCategoryList() {
    this.categoryService.getCategory().subscribe(data => {
      this.catList = data.body;
      this.addInventory.category = this.itemList.category;
    });
  }
  getBatchList() {
    this.itemService.getBatch().subscribe(data => {
      this.batchList = data.body;

      this.addInventory.batchManagement = this.itemList.managementMethod;
    });
  }
  getUom() {
    this.itemService.getUom().subscribe(data => {
      this.uomList = data;
      this.selectedValueUom = this.uomList.filter(i => i.id === this.itemList.uom.id);
      this.addInventory.uom = this.uomList.find(uom => uom.id === this.itemList.uom.id);
      // this.selectedValueAlternateUom = this.uomList.filter(i => i.id === this.itemList.alternativeUom.id);
      // this.addInventory.alternateUom = this.selectedValueAlternateUom[0];
    });
  }
  getDefaultSupplier() {
    this.itemService.getDefaultSupplier().subscribe(data => {
     this.defaultSupplierList = data;
     this.addInventory.defaultSupplier = this.defaultSupplierList.find(supplier => supplier.id === this.itemList.defaultSupplierId);

   });
  }
  getValuation() {
    this.itemService.getValuation().subscribe(data => {
      this.valuation = data;
      this.addInventory.valuation = this.itemList.valuationMethod;
    });
  }
  getbatch() {
    this.itemService.getBatch().subscribe(data => {
      this.batchData = data.body;
    });
  }
  getSubCategoryList(id: any) {

    this.subCategoryService.getSubCategory(id).subscribe(data => {
      this.SubcatList = data.body;
      this.addInventory.subCategory = this.itemList.subcategory;

    });
  }
  getWarehouse(id: any) {

    this.warehouseService.getWarehouseList(id).subscribe(
      data => {
        this.warehouseList = data.body;
        this.addInventory.warehouse = this.itemList.defaultWarehouse;
      },
      (err: any) => { }
    );
  }

  getEditList() {
    this.itemService.getEdit(this.selectedItemId).subscribe(data => {
      this.itemList = data.body;
      this.bsValue = new Date(this.itemList.activationDate);


      this.cat = this.itemList.category;

      //this.addInventory.alternativeUomId = this.itemList.alternativeUom.name;
      this.getSubCategoryList(this.itemList.category.id);
      if (this.roleBuyer) {
        this.getDefaultSupplier();
      }
      // this.getCategoryList();
      // this.getWarehouse(this.itemList.category.id)
      this.getLocation();
      if (this.itemList.defaultLocation) {
        this.getWarehouse(this.itemList.defaultLocation.id);
      }
      if (this.itemList.defaultWarehouse) {
        this.getBinList(this.itemList.defaultWarehouse.id);
      }
      this.getBatchList();
      this.getValuation();
      this.getUom();
      this.getCategoryList();
      // this.selectedDefault = this.defaultSupplierList.filter(i => i.id === this.itemList.defaultSupplierId);
      // this.addInventory.defaultSupplier = this.selectedDefault[0];

      this.addInventory.itemCode = this.itemList.code;
      this.addInventory.cost = this.decimalPipe.transform(this.itemList.price, '1.10-10');

      this.addInventory.barCode = this.itemList.barcode;

      this.addInventory.alternateUomQty = this.itemList.alternateUomQty;
      this.addInventory.itemName = this.itemList.name;
      this.addInventory.col1 = this.itemList.col1;
      this.addInventory.col2 = this.itemList.col2;
      this.addInventory.col3 = this.itemList.col3;
      this.addInventory.col4 = this.itemList.col4;
      this.addInventory.col5 = this.itemList.col5;
      this.addInventory.col6 = this.itemList.col6;
    });


  }


  getEditTableList() {

    this.itemService
      .getEditTable(this.selectedItemId)
      .subscribe(data => {
        this.tableContents = data.body;
        });
  }

  fetchInventoryLevels() {
    this.itemService.getInventoryLevels(Number(this.selectedItemId)).subscribe(response => {
      let responseData: LocationDetail[] = response.body;
      if (responseData && responseData.length > 0) {
        this.locationDetails = responseData;
      }
      else {
        this.locationDetails = [];
      }
    });
  }

  showDialog() {
    this.display = true;
  }
  showDialogBox() {
    this.display = false;
  }
  cancel() {
    this.display = false;
  }
  addFieldValue() {
    this.fieldArray.push(this.newAttribute);
    this.newAttribute = {};
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }

  onSubmit(addCategoryForm: NgForm) {
    this.itemId = this.addInventory.itemCode;
    const ItemDto = {
      name: addCategoryForm.value.itemName,
      code: this.addInventory.itemCode,
      companyId: this.itemList.companyId,
      createdBy: this.itemList.createdBy,
      createdDate: this.itemList.createdDate,
      id: this.itemList.id,
      stockInList: null,
      type: this.itemList.type,
      userId: this.itemList.userId,
      userType: this.itemList.userType,

      uom: { id: this.addInventory.uom.id },

      alternativeUom: { id: this.addInventory.alternateUom.id },
      defaultSupplierId: this.addInventory.defaultSupplier.id,
      alternateUomQty: this.addInventory.alternateUomQty,
      defaultWarehouse: { id: this.addInventory.warehouse.id },
      defaultLocation: { id: this.addInventory.location.id },
      defaultBin: { id: this.addInventory.bin.id },

      management: { name: 'BATCH' },
      managementMethod: { name: addCategoryForm.value.batchCode.name },
      category: { id: addCategoryForm.value.category.id },
      subcategory: { id: this.addInventory.subCategory.id },
      valuationMethod: this.addInventory.valuation,
      inventoryStatus: true,
      price: this.decimalPipe.transform(this.addInventory.cost, '1.10-10'),


      barcode: this.addInventory.barCode,
      baseUomQty: this.addInventory.baseUomQty,
      col1: this.addInventory.col1,
      col2: this.addInventory.col2,
      col3: this.addInventory.col3,
      col4: this.addInventory.col4,
      col5: this.addInventory.col5,
      col6: this.addInventory.col6
    };





    this.itemService
      .editItem(this.itemList.id, ItemDto)
      .subscribe((data: any) => { }, (err: any) => { });
    this.router.navigateByUrl('/list-item');
  }
  onChange(id: any) {
    this.toggle = id;
  }
  showCancelBox() {
    this.router.navigateByUrl('/list-item');
  }
  onRadioChange() { }
  getLocation() {
    this.itemService.getLocationList().subscribe(
      data => {
        this.locationList = data.body;
        this.addInventory.location = this.itemList.defaultLocation;
      },

      (err: any) => {
        this.toastrMsgService.error(
          '',
          this.message.errorMsg,
          this.message.toastrTime
        );
      }
    );
  }



  // Function to get hte supplier based on supplier id
  getDefaultSupplierById(id: any) {
      this.itemService.getDefaultSupplierId(id).subscribe(data => {
      this.addInventory.defaultSupplier = data.body;
    });
  }

  // Function to open and close teh inventory details
  openInventory() {
    this.openInventoryDetails = !this.openInventoryDetails;
  }

  // Function to redirect the user to listing page
  goToListingPage() {
    this.router.navigateByUrl('/manage-items');
  }

// Function to show list of AlternateUoM
    getAlternateUomTableList() {
           this.itemService.getAlternateUomList(this.selectedItemId)
           .subscribe(data => {
            this.tableContentsUom = data.body;
            this.tableContentsUom.forEach(uom=> {
               let _uom: AlternateUoM = { uomName: uom.alternateUomName, conversionFactor: uom.alternateConversionFactor, baseUom:uom.baseUomName,conversionFactorBaseUom: uom.baseUomFactor };
               this.alternateUoMsList.push(_uom);
                });
             this.getUoMNames();
             });
         }

  getUoMNames(altUoMList: any = this.alternateUoMsList): void {
    let maxIndex: number = altUoMList.length - 1;
    let selectedUoMs: string = "";
    altUoMList.forEach((uom: AlternateUoM, index: number) => {
      selectedUoMs = index != maxIndex ? (selectedUoMs + uom.uomName + ", ") : (selectedUoMs + uom.uomName);
    });
    this.addInventory.alternativeUomId = selectedUoMs;
  }
}
