import { PTABLE_PAGE_COUNT } from './../../../core/constants/common.constants';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Col } from '@app/shared/models/table-header.model';
import { Paginator } from '@app/shared/models/paginator.model';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WarehouseService } from '@app/core/services/warehouse.service';
import { AddLocation } from '@app/shared/models/location.model';
import { BinService } from '@app/core/services/bin.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Country } from '@app/core/constants/country';
import { AddBinComponent } from '@app/feature/manage-bin/add-bin/add-bin.component';
import { CountryListService } from '@app/core/services/country-list.service';
import { EditBinComponent } from '@app/feature/manage-bin/edit-bin/edit-bin.component';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { ConfirmationService } from 'primeng/api';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
@Component({
  selector: 'app-list-bins',
  templateUrl: './list-bins.component.html',
  styleUrls: ['./list-bins.component.scss']
})
export class ListBinsComponent implements OnInit {
  name = new FormControl('');
  bsModalRef: BsModalRef;
  lastNam: string;
  dropdown: any;
  displayDialog: boolean;
  addLocation: AddLocation;
  breadcrumb: Breadcrumb[];
  locations: any;
  modalRef: BsModalRef;
  registerForm: FormGroup;
  paginator: Paginator;
  submitted = false;
  countries: any;
  filteredCountry: any;
  editLocation: any;
  router: any;
  locationList: any;
  warehouseList: any;
  warehouseId: any;
  locationform: { name: any; code: any; warehouse: { id: any; }; };
  warehouse: { locationId: any; };
  pageCount: number;
  totalCount: number;
  message: any;
  listingCount: number;
  cols: { field: string; header: string; }[];
  display: boolean;
  deletedId = null;
  binReportList: any;
  binList: any;
  csvList: any;
  csvOptions: any;
  count=1;
  constructor(
    private toastrMsgService: ToastrMsgService,
    private binService: BinService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private countryListService: CountryListService,
    private modalService: BsModalService,
    private warehouseService: WarehouseService
  ) {
    this.pageCount = PTABLE_PAGE_COUNT;
    this.csvOptions = {
             fieldSeparator: ',',
             quoteStrings: '"',
             decimalseparator: '.',
             showLabels: true,
             showTitle: false,
             useBom: true,
             noDownload: false,
      headers: [
              'Sr.No',
              'Location',
              'Warehouse Name',
              'Bin (Shelf/Rack) Code',
              'Bin (Shelf/Rack) Name'
            ]
           };
  }
  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;
  ngOnInit() {
    this.message = Constants.MESSAGES;
    // FUNCTION CALL
    this.getLocation();
    this.dropdown = Country;
    this.breadcrumb = [{ label: 'Manage Bins', routerLink: '' }];
    this.cols = [
      { field: 'warehouse.location.name', header: 'Location' },
      { field: 'warehouseName', header: 'Warehouse Name' },
      { field: 'code', header: 'Bin (Shelf/Rack) Code' },
      { field: 'name', header: 'Bin (Shelf/Rack) Name' }
    ];
    this.registerForm = this.formBuilder.group({
      locationCode: ['', Validators.required],
      warehouseName: ['', Validators.required],
      binId: ['', [Validators.required]],
      binName: ['', [Validators.required]]
    });
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",DESC";
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",ASC";
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getBinList(this.paginator);
  }
  addLocations() {
    this.displayDialog = true;
  }
  getBinList(lazyLoadData: Paginator) {
    this.binService.getBin(lazyLoadData).subscribe(
    data=>{
        this.locations = data['body'];
        this.listingCount = data.body.length;
        this.totalCount = Number(data.headers.get('X-Total-Count'));
        if (this.totalCount <= this.pageCount) {
          this.paginator.paginator = false;
        } else {
          this.paginator.paginator = true;
        }
        this.paginator.loading = false;
        this.paginator.totalCount = this.totalCount;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);
        }
      }
    );
  }
  changeEve(data) {
    this.warehouseId = data.id;
  }
  changeEveLoc(locdata) {
    this.getWarehouse(locdata.id);
  }
  getLocation() {
    this.warehouseService.getLocationList().subscribe(
      data => {
        this.locationList = data['body'];
      },
      (err: any) => { }
    );
  }
  getWarehouse(id: any) {
    this.warehouseService.getWarehouseList(id).subscribe(
      data => {

        this.warehouseList = data['body'];
      },
      (err: any) => { }
    );
  }
  onSubmit() {
    this.submitted = true;
    this.locationform = {
      name: this.registerForm.value.binName,
      code: this.registerForm.value.binId,
      warehouse: { id: this.warehouseId },
    };

    this.binService
      .addbin(this.locationform)
      .subscribe((data: any) => {
        this.getBinList(this.paginator);
      }, (err: any) => { });

    this.toastrMsgService.success('', this.message.binAddSuccessMsg, this.message.toastrTime);

    this.registerForm.reset();

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
  }
  openModalWithComponent(locId: any) {
    this.binService.setBin(null);
    const initialState = {
      locationId: locId
    };
    this.modalService.onHide.subscribe((reason: string) => {
      if (this.binService.getSavedBin()) {
        this.getBinList(this.paginator);
      }
    });
    this.bsModalRef = this.modalService.show(EditBinComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  openModalAddbin() {
    this.binService.setBin(null);
    this.modalService.onHide.subscribe((reason: string) => {
      if (this.binService.getSavedBin()) {
        this.getBinList(this.paginator);
      }
    });
    this.bsModalRef = this.modalService.show(AddBinComponent);
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  setCurrentPage(n: number) {
    const paging = {
      first: ((n - 1) * this.dataTable.rows),
      rows: this.dataTable.rows
    };
    this.loadUsersLazy(paging);
  }
  resetPagination($event?: any) {
    this.primePaginator.first = 0;
    this.paginator.pageNo = 0;
  }
  cancel() {
    this.displayDialog = false;
  }
  // deleteBin(rowData: any) {
  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to delete this Bin?',
  //     icon: 'pi pi-info-circle',
  //     acceptLabel: 'yes',
  //     key: 'deleteBins',
  //     rejectLabel: 'no',
  //     accept: () => {
  //       this.binService.deleteBin(rowData).subscribe(
  //         (data: any) => {
  //           this.toastrMsgService.success('', this.message.binDltMsg, this.message.toastrTime);
  //           if(this.listingCount==1)
  //           {
  //           this.setCurrentPage(this.paginator.pageNo);
  //         }
  //           this.getBinList(this.paginator);
  //         },
  //         (err: any) => {
  //           this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);

  //         }
  //       );
  //     },
  //     reject: () => {
  //     }
  //   });
  // }

  deleteBin(id) {
    this.binService.deleteBin(id).subscribe(
      (data: any) => {
        this.toastrMsgService.success('', this.message.binDltMsg, this.message.toastrTime);
        if (this.listingCount == 1) {
          this.setCurrentPage(this.paginator.pageNo);
        }
        this.getBinList(this.paginator);
        this.display = false;
      },
      (err: any) => {
        if(err.status === 406){
          this.toastrMsgService.error('', err.error, this.message.toastrTime);
        }else{
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
        }

      }
    );
  }
  getBinItemList(lazyLoadData: Paginator) {
         this.binService.getAllBin(lazyLoadData).subscribe(
           data =>{
           this.binReportList = data.body;
           this.csvList = [];
           this.binReportList.forEach(item => {
           this.csvList.push({
                              SerialNo:this.count++,
                              location: item.warehouse.location.name,
                              warehouseName: item.warehouse.name,
                              binCode: item.code,
                              binName: item.name
                          });
                       });
           new AngularCsv(this.csvList, 'Bin', this.csvOptions);
             //this.locations = data['body'];
             this.listingCount = data.body.length;
             this.totalCount = Number(data.headers.get('X-Total-Count'));
             if (this.totalCount <= this.pageCount) {
               this.paginator.paginator = false;
             } else {
               this.paginator.paginator = true;
             }
             this.paginator.loading = false;
             this.paginator.totalCount = this.totalCount;
             this.count = 1;
           },
           (err: any) => {
                   },
         );
         }
       downloadCSV(){
         this.getBinItemList(this.paginator)
    }
}
