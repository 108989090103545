import { ROLE_BUYER, DEFAULT_MGMT_METHOD } from './../../../core/constants/common.constants';
import { Component, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Col } from '@app/shared/models/table-header.model';
import { ItemService } from '@app/core/services/item.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { take, takeLast } from 'rxjs/operators';
import { Breadcrumb, MenuItem, Dropdown } from 'primeng/primeng';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { AuthService } from '@app/core/services/auth.service';
import { SubReceipeService } from '@app/core/services/sub-receipe.service';

type TabModel = {code: string, header: string, active: boolean};

@Component({
  selector: 'app-add-subrecipes',
  templateUrl: './add-subrecipes.component.html',
  styleUrls: ['./add-subrecipes.component.scss']
})

export class AddSubrecipesComponent implements OnInit {

  breadcrumb: MenuItem[];
  addParentSubrecipeForm: FormGroup;
  addSubRecipesForm : FormGroup;
  message: any;
  subRecipeitems: any;
  allItems: any;
  cols: Col[];
  // uomList: any[] = [];

  @ViewChild('ddStatusItem') ddStatusItem: Dropdown;
  @ViewChild('childStatusItem') childStatusItem: Dropdown;

  parentItemCode: any;
  parentItemName: any;
  parentItemUom: any;
  parentItemQuantity: any;
  parentItemCost: any;

  childItemCode: any;
  childItemUom: any;

  childSubRecipeData: any[] = [];
  display: boolean;
  removeIndex = null;

  constructor(
    private toastrMsgService: ToastrMsgService,
    private stockService: ManageStockService,
    private authService: AuthService,
    private router : Router,
    private itemService : ItemService,
    private subReceipeService : SubReceipeService,
    private fb: FormBuilder
  ) {
    this.getSubRecipeItems();
    this.getAllItems();
    // this.getUom();
  }

  ngOnInit() {
    if (sessionStorage.getItem('checkSubrecipePassword')) {
      if (sessionStorage.getItem('checkSubrecipePassword') != 'true') {
        this.router.navigateByUrl('/manage-subrecipe');
      }
    } else {
      this.router.navigateByUrl('/manage-subrecipe');
    }
    this.breadcrumb = [
          { label: 'Manage Sub Recipe', routerLink: '/manage-subrecipe' },
          { label: 'Add Sub Recipe', url: '' }
        ];
        this.message = Constants.MESSAGES;
        this.formSetup();
        this.cols = [
          { field: 'slNo', header: 'Sr. No', width: '10%', textAlign: 'left' },
          { field: 'childItemName', header: 'Child Item Name', width: '25%', textAlign: 'left' },
          { field: 'childItemCode', header: 'Child Item Code', width: '25%', textAlign: 'left' },
          { field: 'childItemUom', header: 'Child Item Uom', width: '20%', textAlign: 'left' },
          { field: 'childItemQuantity', header: 'Child Item Quantity', width: '10%', textAlign: 'right !important' },
          { field: 'Actions', header: 'Actions', width: '10%', textAlign: 'left' },
        ];
  }

  loadTableData(event: any) {
    this.getSubRecipeItems();
  }

  onSubmitSubRecipe(addSubRecipesForm: FormGroup) {
    if (addSubRecipesForm.value.childItemName == undefined || addSubRecipesForm.value.childItemQuantity==undefined || this.childItemUom==undefined || this.parentItemName==undefined || this.parentItemQuantity == undefined || this.parentItemUom==undefined || this.parentItemCost==undefined) {
      this.toastrMsgService.error('', 'Fill parent data and child data ', this.message.toastrTime);
    } else {
      if (this.childItemCode == this.parentItemCode) {
        this.toastrMsgService.error('', 'Parent item can\'t be selected', this.message.toastrTime);
      } else {
        this.childSubRecipeData.findIndex(x => x.childItemCode == this.childItemCode) == -1 ? this.childSubRecipeData.push({
          "childItemCode": this.childItemCode,
          "childItemName": addSubRecipesForm.value.childItemName.name,
          "childItemUom": this.childItemUom,
          "childItemQuantity": addSubRecipesForm.value.childItemQuantity
        }) : this.toastrMsgService.error('Already added','',this.message.toastrTime);

        this.addSubRecipesForm.setValue({
          childItemCode: '',
          childItemName: '',
          childItemUom: '',
          childItemQuantity: ''
        });
        this.childItemCode = undefined;
        this.childItemUom = undefined;
      }
    }
  }

  getSubRecipeItems(){
    this.itemService.getSubrecipeItem().subscribe(
      (data: any) => {
        this.subRecipeitems = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
      }
    );
  }
    
  getAllItems() {
    this.itemService.getItem().subscribe(
      (data: any) => {
        this.allItems = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  formSetup() {
      this.addSubRecipesForm = this.fb.group({
        childItemCode:  [{value: '', disabled: true}],
        childItemName: [{ value: '' }, Validators.required],
        childItemUom: [{ value: '', disabled: true}],
        childItemQuantity: [{value: '' } , Validators.required]
      });

      this.addParentSubrecipeForm = this.fb.group({
        parentItemName: [{ value: '' }, Validators.required],
        parentItemCode:  [{value: '', disabled: true}],
        parentItemUom:  [{value: '', disabled: true}],
        parentItemQuantity: [{value: '' }, Validators.required],
        parentItemCost: [{value: '',disabled: true}]
      });
  }

  dropdownChange(event: any){
    if (event === 'ddStatusItem') {
      this.ddStatusItem.filled = true;
      this.onItemChangeUpdateItemDetails();
    } else if (event === 'childStatusItem') {
      this.childStatusItem.filled = true;
      this.onChildItemChangeUpdateItemDetails();
    }
  }

  onItemChangeUpdateItemDetails() {
    this.parentItemCode = this.addParentSubrecipeForm.get('parentItemName').value.code;
    this.parentItemUom = this.addParentSubrecipeForm.get('parentItemName').value.uom.name;
    this.parentItemCost = this.addParentSubrecipeForm.get('parentItemName').value.price;
  }

  onChildItemChangeUpdateItemDetails() {
    this.childItemCode = this.addSubRecipesForm.get('childItemName').value.code;
    this.childItemUom = this.addSubRecipesForm.get('childItemName').value.uom.name;
  }

  // getUom() {
  //   this.itemService.getUom().subscribe(data => {
  //     this.uomList = data;
  //   });
  // }

  deleteChildItemData(removeIndex){
    this.childSubRecipeData.splice(removeIndex, 1);
    this.display = false;
  }

  onCancel() {
    this.router.navigateByUrl('/manage-subrecipe');
  }

  addSubRecipeSubmit() {
    if (this.childSubRecipeData.findIndex(x => x.childItemCode == this.parentItemCode) != -1) {
      this.toastrMsgService.error('', 'Parent item can\'t be selected', this.message.toastrTime);
      return;
    }
    let subrecipeData = [];
    this.childSubRecipeData.map( items => {
      subrecipeData.push({
        'parentItemCode': this.parentItemCode,
        'parentItemName': this.parentItemName.name,
        'parentItemUom' : this.parentItemUom,
        'parentItemQty' : this.parentItemQuantity,
        'childItemCode' : items.childItemCode,
        'childItemName' : items.childItemName,
        'childParentItemUom' : items.childItemUom,
        'childItemQty' : items.childItemQuantity,
        'cost' : this.parentItemCost,
      })
    });
    
    this.subReceipeService.addSubReceipeData(subrecipeData).subscribe(
      (data: any) => {
        this.toastrMsgService.success('', 'Added', this.message.toastrTime);
        this.router.navigateByUrl('/manage-subrecipe');
      },
      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      });
  }

}
