import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { ManageItemsRoutingModule } from './manage-items-routing.module';
import { ListItemsComponent } from './list-items/list-items.component';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {RadioButtonModule} from 'primeng/radiobutton';

import { CheckboxModule } from 'primeng/checkbox';
import {TableModule} from 'primeng/table';
import { AddItemsComponent } from '@app/feature/manage-items/add-items/add-items.component';
import {InputTextModule} from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {TabViewModule} from 'primeng/tabview';
import {CalendarModule} from 'primeng/calendar';
import {AutoCompleteModule} from 'primeng/primeng';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { DatepickerModule, BsDatepickerModule, BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AddInventoryComponent } from './add-inventory/add-inventory.component';
import { BrowserModule } from '@angular/platform-browser';
import { EditItemsComponent } from './edit-items/edit-items.component';
import { ViewItemComponent } from './view-item/view-item.component';
import { ItemTreeTableComponent } from './item-tree-table/item-tree-table.component';
import {TreeTableModule} from 'primeng/treetable';
import { ManageLocationsModule } from '../manage-locations/manage-locations.module';





@NgModule({
  declarations: [ListItemsComponent,
    AddItemsComponent,
    AddInventoryComponent,
    EditItemsComponent,
    ViewItemComponent,
    ItemTreeTableComponent],
  imports: [
    RadioButtonModule,
    BrowserModule, UiSwitchModule,
    CommonModule,
    ManageItemsRoutingModule,
    BreadcrumbModule,
    TableModule,
    CheckboxModule,
    InputTextModule,
    FormsModule,
    DropdownModule,
    DialogModule,
    TabViewModule,
    CalendarModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    TooltipModule,
    ManageLocationsModule,
    BsDatepickerModule.forRoot(),
    TreeTableModule,
    ReactiveFormsModule
   ],
   entryComponents: [AddInventoryComponent
   ]
})
export class ManageItemsModule { }

