import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MenuItem } from "primeng/api";
import * as Constants from '@app/core/constants/common.constants';
import { Col } from '@app/shared/models/table-header.model';
import { Paginator } from '@app/shared/models/paginator.model';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';

import { ManageStockService } from '@app/core/services/manage-stock.service';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@app/core/services/fil-upload.service';

type FilterByDate = { fromDate: any, toDate: any };

@Component({
  selector: 'app-manage-stockout-list',
  templateUrl: './manage-stockout-list.component.html',
  styleUrls: ['./manage-stockout-list.component.scss']
})
export class ManageStockoutListComponent implements OnInit {

  breadcrumb: MenuItem[];
  cols: Col[];
  stockOutList: any;
  stockTransferList: any;
  paginator: Paginator;
  pageCount: number;
  message: any;
  totalCount: number;
  /* filters */
  transactionType: any = {name: 'Stock Out', code: Constants.STOCKOUT};
  transactionTypes: any = [];
  fromDate: any = null;
  toDate: any = null;
  utcFDate : any = null;
  utcTDate : any = null;
  csvOptions : any;
  stockDetailsList :  any;
  csvList : any;
  uploadTrue: boolean = false;
  csvFile: File;
  fileName: string;
  restrictSave: boolean = false;
  transactionErrorList: any[] = [];

  constructor(
    private stockService: ManageStockService,
    private toastrMsgService: ToastrMsgService,
    public router: Router,
    public fileUploadService: FileUploadService
  ) {
    this.csvOptions = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: false,
          useBom: true,
          noDownload: false,
          headers: [
            'Item Code',
            'Uom',
            'Bin',
            'Batch',
            'Location Code',
            'Warehouse Code',
            'Stock Out Quantity'
          ]
        };
  }

  @ViewChild('primePaginator') primePaginator: ezPaginator;

  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.cols = [
      { field: 'transactionType', header: 'Transaction Type' },
      { field: 'transactionNumber', header: 'Document No' },
      { field: 'createdDate', header: 'Document Date' },
      { field: 'remarks', header: 'Remarks' }
    ];

    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
    this.stockOutDetails(this.paginator);
    this.transactionTypes = [
          { name: 'All', code: Constants.ALL_STOCK_OUT},
          { name: 'Production Issue', code: Constants.PROD_ISSUE },
          { name: 'Stock Out', code: Constants.STOCKOUT },
          { name: 'Process Order', code: Constants.PROCESS_ORDER }
        ];
  }

  createStockout() {
    //manage-stock-out
    this.router.navigateByUrl('manage-stock-out-add');
  }

  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ',DESC';
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ',ASC';
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.stockOutDetails(this.paginator);
  }

  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  onTransactionChange() {
    this.paginatorInitial();
    this.stockOutDetails();
  }

  stockOutDetails(lazyLoadData: Paginator = this.paginator) {
    let filterByDate: FilterByDate | null = { fromDate: "", toDate: "" };
    if (this.fromDate && this.toDate) {
      this.utcFDate = this.utcFromDate(this.fromDate);
      this.utcTDate = this.utcToDate(this.toDate);
      filterByDate.fromDate = this.utcFDate;
      filterByDate.toDate = this.utcTDate;
    }
    else {
      filterByDate = null;
    }

    this.stockService
      .getStockOutList(lazyLoadData.pageNo, lazyLoadData.limit, lazyLoadData.sortOrder, this.transactionType.code, filterByDate)
      .subscribe((data) => {
        this.stockOutList = data.body;
        this.totalCount = Number(data.headers.get('X-Total-Count'));
        if (this.totalCount <= this.pageCount) {
          this.paginator.paginator = false;
        } else {
          this.paginator.paginator = true;
        }
        this.paginator.loading = false;
        this.paginator.totalCount = this.totalCount;
      },
        (error: any) => {
          this.paginator.loading = false;
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              '',
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
        });
  }

  clearFilters() {
    this.fromDate = "";
    this.toDate = "";
    this.stockOutDetails();
  }

  utcFromDate(fromDate) {
    fromDate = new Date(fromDate.setHours(0,0,0,0));
    return fromDate;
  }

  utcToDate(toDate) {
    toDate = new Date(toDate.setHours(23,59,58,999));
    return toDate;
  }

  stockDetails(lazyLoadData : string) {
    this.stockService.getStockOutExportDetails(0, 10000, lazyLoadData)
    .subscribe((data) => {
      this.stockDetailsList = data.body;
      this.csvList = [];
      this.stockDetailsList.forEach(stock =>{
        this.csvList.push({
          itemCode : stock.itemCode,
          uom : stock.uom,
          bin : stock.bin || '',
          batch : stock.batch || '',
          locationCode : stock.locationCode,
          warehouseCode : stock.warehouseCode
        });
      });
      new AngularCsv(this.csvList, 'Stock-Out', this.csvOptions);
    });
  }

  ExportStockOutDetails() {
    this.stockDetails(this.paginator.sortOrder);
  }

  cancel() {
    this.uploadTrue = false;
  }

  // on select the csv file to upload
    onSelectFile(event: any) {
      this.uploadTrue = false;
      if (event.target.files) {
        if (
          this.fileUploadService.checkFileType(
            event.target.files[0].type,
            event.target.files[0].name
          )
        ) {
          this.csvFile = event.target.files[0];
          this.fileName = event.target.files[0].name;
          this.uploadTrue = true;
        } else {
          this.toastrMsgService.error(
            '',
            this.message.csvFailure,
            this.message.toastrTime
          );
        }
        event.target.value = '';
      }
    }

    // on csv upload
      submit() {
        this.uploadTrue = false;
        this.restrictSave = true;
        const formData = new FormData();
        formData.append('multipartFile', this.csvFile);
        this.stockService.uploadStockOutCSV(formData).subscribe(
          (data: any) => {
            if (data === null) {
              this.toastrMsgService.success(
                '',
                this.message.uploadCsvSuccess,
                this.message.toastrTime
              );
            } else {
              this.toastrMsgService.error(
                '',
                this.message.errorMsg,
                this.message.toastrTime
                );
              }
          },
          (err: any) => {
            this.restrictSave = false;
            if (navigator.onLine === false) {
              this.toastrMsgService.warning(
                '',
                this.message.noInternet,
                this.message.toastrTime
              );
            }
            else if(Array.isArray(err.error) && err.error.length) {
              this.transactionErrorList = err.error;
            }
            else {
              this.toastrMsgService.error(
                '',
                this.message.errorMsg,
                this.message.toastrTime
              );
            }
          }
        );
      }
  closeErrorList() {
    this.transactionErrorList = [];
  }
}
