import { Component, OnInit, ViewChild } from '@angular/core';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { Col } from '@app/shared/models/table-header.model';
import { Paginator } from '@app/shared/models/paginator.model';
import * as Constants from '@app/core/constants/common.constants';
import { ItemService } from '@app/core/services/item.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { Router } from '@angular/router';
import { SubReceipeService } from '@app/core/services/sub-receipe.service';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@app/core/services/fil-upload.service';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-sub-recipe-listing',
  templateUrl: './sub-recipe-listing.component.html',
  styleUrls: ['./sub-recipe-listing.component.scss']
})
export class SubRecipeListingComponent implements OnInit {

  message: any;
  searchKey: string = "";
  pageCount: number;
  breadcrumb: Breadcrumb[];
  cols: Col[];
  paginator: Paginator;
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    useBom: true,
    noDownload: false,
    headers: [
      'Sr. No',
      'Parent Item Code',
      'Parent Item Name',
      'UOM',
      'Quantity',
      'Cost',
      'Child Item Code',
      'Child Item Name',
      'Child Item UOM',
      'Child Item Quantity',
    ]
  };
  csvSampleOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    useBom: true,
    noDownload: false,
    headers: [
      'parent_item_code',
      'parent_item_uom',
      'parent_item_qty',
      'child_item_code',
      'child_parent_item_uom',
      'child_item_qty',
    ]
  };

  subRecipeList = [];
  deletedId = null;
  display: boolean;
  displayPassword: boolean = false;
  selectedCompanyId: any;

  csvList : any = [];
  uploadTrue: boolean = false;
  csvFile: File;
  fileName: string;
  totalCount: number;
  
  passwordSubrecipeForm: FormGroup;

  constructor(
    private itemService: ItemService,
    private router: Router,
    private subReceipeService : SubReceipeService,
    private toastrMsgService: ToastrMsgService,
    public fileUploadService: FileUploadService,
    private fb: FormBuilder
  ) { }

  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;
  ngOnInit() {
    if (sessionStorage.getItem('checkSubrecipePassword')) {
      sessionStorage.getItem('checkSubrecipePassword') == 'true' ? this.displayPassword = false : this.displayPassword = true;
    } else {
      this.displayPassword = true;
    }
    this.passwordSubrecipeForm = this.fb.group({
      password: ['', Validators.required],
    });
    this.breadcrumb = [{ label: 'Manage Sub Recipe', routerLink: '' }];
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.cols = [
      { field: 'slNo', header: 'Sr. No', width: '10%', textAlign: 'left' },
      { field: 'itemCode', header: 'Parent Item Code', width: '20%', textAlign: 'left' },
      { field: 'itemName', header: 'Parent Item Name', width: '30%', textAlign: 'left' },
      { field: 'uom', header: 'UOM', width: '10%', textAlign: 'left' },
      { field: 'quantity', header: 'Quantity', width: '10%', textAlign: 'right !important' },
      { field: 'cost', header: 'Cost', width: '10%', textAlign: 'right !important' },
      { field: 'actions', header: 'Actions', width: '10%', textAlign: 'left' }
    ];
    this.message = Constants.MESSAGES;
    this.csvList =[
      {
      "parent_item_code": 'Box-TI',
      "parent_item_uom": 'MILLI METER',
      "parent_item_qty": 10,
      "child_item_code": 'BOX',
      "child_parent_item_uom": 'BOX',
      "child_item_qty": 5,
      },
    ];
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
    // this.paginatorInitial();
    // this.getSubRecipeList();
  }

  checkPasswordSubrecipe(passwordSubrecipeForm: FormGroup) {
    if (passwordSubrecipeForm.value.password == undefined || passwordSubrecipeForm.value.password == null) {
      this.toastrMsgService.error('', 'Enter password!', this.message.toastrTime);
    } else {
      this.itemService.checkPasswordSubrecipes(passwordSubrecipeForm.value.password).subscribe(
        data => {
          this.displayPassword = false;
          sessionStorage.setItem('checkSubrecipePassword', 'true');
        },
        (err: any) => {
          this.toastrMsgService.error('', 'Invalid password!', this.message.toastrTime);
        }
      );
    }
  }

  loadTableData(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",DESC";
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",ASC";
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getSubRecipeList();
  }

  getSubRecipeList() {
    this.itemService.getSubrecipes(this.paginator, this.searchKey).subscribe(
      data => {
        this.subRecipeList = data.body;
        this.selectedCompanyId = this.subRecipeList[0].companyId;
        
        this.totalCount = Number(data.headers.get('X-Total-Count'));
        if (this.totalCount <= this.pageCount) {
          this.paginator.paginator = false;
        } else {
          this.paginator.paginator = true;
        }
        this.paginator.loading = false;
        this.paginator.totalCount = this.totalCount;
      },
      (err: any) => {
      }
    );
  }

  exportAllSubRecipeItem() {
    this.itemService.getSubRecipeList(this.selectedCompanyId, this.searchKey).subscribe((data) => {
      
      let csvList = data.body.map((item, index) => {
        let csvItem = {
          slNo: index + 1,
          itemCode: item.parentItemCode,
          itemName: item.parentItemName || 'N-A',
          uom: item.parentItemUom,
          quantity: (item.parentItemQty).toFixed(3),
          cost: (item.cost).toFixed(3),
          childItemCode: item.childItemCode,
          childItemName: item.childItemName || 'N-A',
          childItemuom: item.childParentItemUom,
          childItemquantity: (item.childItemQty).toFixed(3),
        };
        return csvItem;
      });
      new AngularCsv(csvList, 'SubRecipe', this.csvOptions);
    },
      (err: any) => {
      }
    );
  }

  handleSearchInput(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      // this.paginatorInitial();
      this.getSubRecipeList();
    }
  }

  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  addNewItem() {
    this.router.navigateByUrl('manage-subrecipe/add-subrecipes');
  }

  editSubRecipe(companyId, parentItemCode) {
    this.router.navigate(['manage-subrecipe/edit-subrecipes', companyId,parentItemCode]);
   // this.router.navigateByUrl(`/manage-subrecipe/edit-subrecipes?companyId=${companyId}&username=${parentItemCode}`);
  }

  viewItem(parentItemCode: number) {
    this.router.navigate(['manage-subrecipe/view-subrecipe', parentItemCode]);
  }

  deleteSubReceipe(parentItemCode: any) {
    this.subReceipeService.deleteSubRecipeByParentItemCode(parentItemCode).subscribe(
      (data: HttpResponse<any>) => {
        this.toastrMsgService.success('', this.message.subrecipeDltMsg, this.message.toastrTime);
        this.display = false;
        this.getSubRecipeList();
      },
      (err: any) => {
        this.toastrMsgService.error('', this.message.activeSubrecipeExistMsg, this.message.toastrTime);
      }
    );
  }

  download_Sample_CSV() {
    new AngularCsv(this.csvList, 'Sample_Sub_Recipe_CSV', this.csvSampleOptions);
  }

  // on select the csv file to upload
  onSelectCSVFile(event: any) {
    this.uploadTrue = false;
    if (event.target.files) {
      if (
        this.fileUploadService.checkFileType(
          event.target.files[0].type,
          event.target.files[0].name
        )
      ) {
        this.csvFile = event.target.files[0];
        this.fileName = event.target.files[0].name;
        this.uploadTrue = true;
      } else {
        this.toastrMsgService.error(
          '',
          this.message.csvFailure,
          this.message.toastrTime
        );
      }
      event.target.value = '';
    }
  }

  // on upload csv cancel
  cancel() {
    this.uploadTrue = false;
  }

  // on csv upload
  submit() {
    this.uploadTrue = false;
    const formData = new FormData();
    formData.append('multipartFile', this.csvFile);
    this.itemService.uploadSubrecipeCSV(formData).subscribe(
      (data: any) => {
        this.getSubRecipeList();
        if(data && data[0].message && data[0].message === 'Success!'){
          this.toastrMsgService.success(
            '',
            this.message.uploadCsvSuccess,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          if (err.error.message) {
            this.toastrMsgService.error(
              '',
              err.error.message,
              this.message.toastrTime
            );
          } else if(err.error.length > 0) {
            err.error.forEach(element => {
              this.toastrMsgService.error(
                '',
                element.message,
                this.message.toastrTime
              );
            });
          }
        }
      }
    );
  }

}
