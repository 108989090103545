import {
  Component,
  AfterViewInit,
  OnInit,
  ChangeDetectorRef,
  ViewChild
} from "@angular/core";
import { ManageStockService } from "@app/core/services/manage-stock.service";
import { DashboardService } from "@app/core/services/dashboard.service";
import { AuthService } from "@app/core/services/auth.service";
import { DatePipe } from "@angular/common";
import { ToastrMsgService } from "@app/shared/services/toastr-msg.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Paginator } from "@app/shared/models/paginator.model";
import { Paginator as ezPaginator } from "primeng/paginator";
import * as Constants from "@app/core/constants/common.constants";
import { HttpResponse } from "@angular/common/http";
import { TreeNode, Dropdown } from "primeng/primeng";
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
type FilterByDate = { fromDate: any, toDate: any };
@Component({
  selector: 'app-tree-variance-report',
  templateUrl: './tree-variance-report.component.html',
  styleUrls: ['./tree-variance-report.component.scss']
})
export class TreeVarianceReportComponent implements AfterViewInit {

  pageCount: number;
  totalCount: number;
  files: TreeNode[];
  data: any[];
  loading: boolean;
  message: any;
  paginator: Paginator;
  cols: any[];
  reportForm: FormGroup;
  varianceInventoryList = [];
  treeTableList = [];
  itemNames: any = [];
  itemName: any;
  warehouseName: any;
  itemId: number;
  wareId: number;
  locId: number;
  categoryId: number;
  batchId: number;
  itemCode: any;
  categoryName: any;
  categories: any = [];
  warehouseList: any = [];
  warehouseListNew: any = [];
  warehouses : any = [];
  batchNo: any;
  batchList: any = [];
  locationName: any;
  locationList: any = [];
  fromDate: any = null;
  toDate: any = null;
  csvList: any;
  csvOptions: any;
  pTree = [];
  defaultBin: any;
  batch: any;
  uom: any;
  count = 1;
  openingStock : any;
  closingStock : any;

  constructor(
    public stockService: ManageStockService,
    public dashboardService: DashboardService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
    private toastrMsgService: ToastrMsgService,
    public route: Router,
    private fb: FormBuilder,
    private router: ActivatedRoute
  ) {
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: [
        'Date',
        'Transaction',
        'Type',
        'Quantity',
        'UOM',
        'Balance',
        'Varaince',
        'Varaince%'
      ]
    };
    this.message = Constants.MESSAGES;
  }
  @ViewChild("primePaginator") primePaginator: ezPaginator;
  @ViewChild('ddStatusName') ddStatusName: Dropdown;
  @ViewChild('ddStatusCode') ddStatusCode: Dropdown;
  @ViewChild('ddStatusWare') ddStatusWare: Dropdown;
  @ViewChild('ddStatusBatch') ddStatusBatch: Dropdown;
  @ViewChild('ddStatusCat') ddStatusCat: Dropdown;
  @ViewChild('ddStatusLoc') ddStatusLoc: Dropdown;
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
  ngOnInit() {
    this.formSetup();
    this.itemNameDetails();
    this.locationDetails();
    this.cols = [
      { field: "date", header: "Date", width: "25%" },
      { field: "transaction", header: "Transaction", width: "25%" },
      { field: "type", header: "Type", width: "20%" },
      { field: "quantity", header: "Quantity", width: "10%" },
      { field: "inventoryUom", header: "UOM", width: "10%" },
      { field: "balance", header: "Balance", width: "10%" },
      { field: "variance", header: "Variance", width: "10%" },
      { field: "variancePer", header: "Variance%", width: "10%" }
    ];
  }
  formSetup() {
    this.reportForm = this.fb.group({
      itemName: null,
      location: null,
      warehouses: [],
      fromDate: null,
      toDate: null
    });
  }

  itemNameDetails() {
    this.stockService.getItemNames().subscribe(
      (data: any) => {
        this.itemNames = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  batchDetails() {
    this.stockService.getBatchList().subscribe(
      data => {
        this.batchList = data;
      },
      err => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  warehouseDetails() {
    this.stockService.getWarehouse(this.locationName).subscribe(
      data => {
        this.warehouses = [];
        this.warehouseList = data;
      },
      err => {
        if (navigator.onLine === false) {
          this.warehouses = [];
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.warehouses = [];
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  // to get the category details and showing in the dropdown
  categoryDetails() {
    this.stockService.getCategories().subscribe(
      (data: any) => {
        this.categories = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  itemNameFilter() {
    this.itemId = this.itemName.id;
  }
  wareFilter() {
    this.wareId = this.warehouseName.id;
  }
  catFilter() {
    this.categoryId = this.categoryName.id;
  }

  locFilter() {
    this.locId = this.locationName.id;
    this.warehouseDetails();
  }

  clearFilter() {
    this.reportForm.reset();
    this.itemName = null;
    this.locationName = null;
    this.warehouses = [];
    this.toDate = null;
    this.fromDate = null;
    this.files = [];
    this.varianceInventoryList = [];
  }

  binClick(event: any) {
    if (event === 'ddStatusName') {
      this.ddStatusName.filled = true;
    } else if (event === 'ddStatusCode') {
      this.ddStatusCode.filled = true;
    } else if (event === 'ddStatusWare') {
      this.ddStatusWare.filled = true;
    } else if (event === 'ddStatusCat') {
      this.ddStatusCat.filled = true;
    } else if (event === 'ddStatusLoc') {
      this.ddStatusLoc.filled = true;
    }
  }

  getVarianceTableDetails(lazyData?: Paginator) {
    this.loading = true;
    this.files = [];
    let filterByDate: FilterByDate | null = { fromDate: "", toDate: "" };
    filterByDate.fromDate = this.utcFromDate(this.fromDate);
    filterByDate.toDate = this.utcToDate(this.toDate);
    this.stockService
      .getVarianceReportTable(
        this.itemName,
        this.warehouses? this.warehouses.map(x=>x.name):'',
        this.locationName ? this.locationName.name : '',
        filterByDate )
      .subscribe(
        (data: HttpResponse<any>) => {
          const pTree = [];
          data.body.inventoryAuditDTOList = data.body.inventoryAuditDTOList.filter((item:any) => {
            return !(item.docType === "STOCKTAKE" && item.balance === null);
          });          
          this.varianceInventoryList = data.body.inventoryAuditDTOList;
          this.openingStock = data.body.openingStock;
          this.closingStock = data.body.closingStock;
          const node = {
            data: {
              transaction : 'Opening Stock',
              balance: data.body.openingStock
            },
          };
          pTree.push(node);
          for (let i = 0; i < data.body.inventoryAuditDTOList.length; i++) {
            const element = data.body.inventoryAuditDTOList[i];
            const node = {
              data: {
                date : this.datePipe.transform(element.date, 'dd-MM-yyyy'),
                transaction : this.getTransaction(element),
                type : element.docType,
                quantity: this.getQuantity(element),
                inventoryUom: element.inventoryUom,
                balance: element.balance,
                variance : this.getVariance(element),
                variancePer : this.getVariancePercent(element) ? this.getVariancePercent(element)+'%' : ''
              },
            };
            pTree.push(node);
          }

          const node2 = {
            data: {
              transaction : 'Closing Stock',
              balance: data.body.closingStock
            },
          };
          pTree.push(node2);

          this.files = pTree;
          this.files = [...this.files];
          this.loading = false;
          this.totalCount = Number(data.headers.get("X-Total-Count"));
        },
        (err: any) => {
          this.loading = false;
          this.openingStock = '';
          this.closingStock = '';
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              "",
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
              "",
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
        }
      );
  }

  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: "",
      sortColumn: "",
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  loadUsersLazy(event: any) {
    // this.getVarianceTableDetails();
  }

  downloadCSV() {
    this.loading = true;
    try {
      this.csvList = [];
      this.csvList.push({
          Date: this.convert(this.fromDate),
          Transaction: 'Opening balance',
          Type: '',
          Quantity: '',
          UOM: '',
          Balance: this.openingStock,
          Variance : '',
          VariancePer : ''
      });
      this.varianceInventoryList.forEach(report => {

        this.csvList.push({
          Date: report.date,
          Transaction: this.getTransaction(report),
          Type: report.docType,
          Quantity: this.getQuantity(report),
          UOM: report.inventoryUom,
          Balance: report.balance,
          Variance : this.getVariance(report),
          VariancePer : this.getVariancePercent(report) ? this.getVariancePercent(report)+'%' : ''
        });
      });
      this.csvList.push({
        Date: this.convert(this.toDate),
        Transaction: 'Closing balance',
        Type: '',
        Quantity: '',
        UOM: '',
        Balance: this.closingStock,
        Variance : '',
        VariancePer : ''
    });
      new AngularCsv(this.csvList, 'VarianceReport', this.csvOptions);
      this.loading = false;
    } catch (err) {
      this.loading = false;
      if (navigator.onLine === false) {
        this.toastrMsgService.warning(
          "",
          this.message.noInternet,
          this.message.toastrTime
        );
      } else {
        this.toastrMsgService.error(
          "",
          this.message.errorMsg,
          this.message.toastrTime
        );
      }
    }
  }

  locationDetails() {
    this.stockService.getLocation().subscribe(
      (data: any) => {
        this.locationList = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  getReportData(){
    if(this.itemName && this.itemName.name && this.locationName && this.toDate && this.fromDate){
      this.getVarianceTableDetails();
    }
  }

  utcFromDate(fromDate) {
    return fromDate ? new Date(fromDate.setHours(0,0,0,0)) : "";
  }

  utcToDate(toDate) {
    return toDate ? new Date(toDate.setHours(23,59,58,999)) : "";
  }

  getTransaction(element: any) {
    switch (element.docType) {
      case "GRN":
        return "STOCKIN";
      case "PROD_ISSUE":
        return "STOCKOUT";
      case "TRANSFER_OUT":
      return "STOCKTRANSFER"
      case "TRANSFER_IN":
      return "STOCKTRANSFER";
      default:
        return element.docType;
    }
  }

  getQuantity(element: any) {
    switch (element.docType) {
      case "GRN":
      case "STOCKIN":
        return element.receivedQty;
      case "PROD_ISSUE":
      case "STOCKOUT":
        return element.issuedQty;
      case "STOCKTAKE":
        return element.balance;
      case "TRANSFER_OUT":
        return element.issuedQty;
      case "TRANSFER_IN":
        return element.receivedQty;
      default:
        return element.receivedQty;
    }
  }

  getVariance(report: any){
    if(report.inStock && report.oldInStock)return report.inStock - report.oldInStock;
    else return '';
  }

  getVariancePercent(report: any){
    if(report.oldInStock > 0) {
      return (((report.inStock - report.oldInStock) * 100)/report.oldInStock).toFixed(2);
    }
    else {
      return report.inStock;
    }
  }

  convert(str) {
    let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
  }
}
