import { NoAuthGuard } from './guards/no-auth.guard';
import { AuthGuard } from './guards/auth-guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { HighlightDirective } from '@app/core/directives/max-check.directives';

// import { HighlightDirective } from '@app/core/directives/max-check.directives';
@NgModule({
  declarations: [HighlightDirective,],
  imports: [
    CommonModule,
    LoggerModule.forRoot({ serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
    DeviceDetectorModule
  ],
  exports: [HighlightDirective],
  providers: [ManageStockService, AuthGuard,
    NoAuthGuard]
})
export class CoreModule { }
