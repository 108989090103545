import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { ManageSubrecipeRoutingModule } from './manage-subrecipe-routing.module';
import { ListSubrecipeComponent } from './list-subrecipe/list-subrecipe.component';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {RadioButtonModule} from 'primeng/radiobutton';

import { CheckboxModule } from 'primeng/checkbox';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {TabViewModule} from 'primeng/tabview';
import {CalendarModule} from 'primeng/calendar';
import {AutoCompleteModule} from 'primeng/primeng';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { DatepickerModule, BsDatepickerModule, BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { AddSubrecipesComponent } from '@app/feature/manage-subrecipe/add-subrecipes/add-subrecipes.component';
import { SubRecipeListingComponent } from './sub-recipe-listing/sub-recipe-listing.component';
import { EditSubrecipeComponent } from './edit-subrecipe/edit-subrecipe.component';
import { ViewSubrecipeComponent } from './view-subrecipe/view-subrecipe.component';

@NgModule({
  declarations: [
    ListSubrecipeComponent,
    AddSubrecipesComponent,
    SubRecipeListingComponent,
    EditSubrecipeComponent,
    ViewSubrecipeComponent
  ],
  imports: [
    RadioButtonModule,
    BrowserModule, UiSwitchModule,
    CommonModule,
    ManageSubrecipeRoutingModule,
    BreadcrumbModule,
    TableModule,
    CheckboxModule,
    InputTextModule,
    FormsModule,
    DropdownModule,
    DialogModule,
    TabViewModule,
    CalendarModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    TooltipModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule
  ]
})
export class ManageSubrecipeModule { }

