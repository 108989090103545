import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Paginator } from '@app/shared/models/paginator.model';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import * as Constants from '@app/core/constants/common.constants';
import { Col } from '@app/shared/models/table-header.model';
import { HttpResponse } from '@angular/common/http';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { AuthService } from '@app/core/services/auth.service';
import { FileUploadService } from '@app/core/services/fil-upload.service';
import { ConfirmationService } from 'primeng/api';
import { take } from 'rxjs/operators';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { Dropdown } from 'primeng/primeng'

type FilterByDate = { fromDate: any, toDate: any };

@Component({
  selector: 'app-manage-stock-in',
  templateUrl: './manage-stock-in.component.html',
  styleUrls: ['./manage-stock-in.component.scss']
})
export class ManageStockInComponent implements OnInit {
  cols: Col[];
  stockInList: any;
  uniqueStockInList: any;
  paginator: Paginator;
  message: any;
  pageCount: number;
  totalCount: number;
  uploadTrue: boolean = false;
  csvFile: File;
  fileName: string;
  buttonEnable: boolean = false;
  flag : boolean = false;
  displayDialog: boolean;
  /* filters */
  transactionType: any = { name: 'Stock In', code: Constants.STOCKIN };
  transactionTypes: any = [];
  fromDate: any = null;
  toDate: any = null;
  utcFDate : any = null;
  utcTDate : any = null;
  poNumber: string = "";
  csvOptions : any;
  csvList : any = [];

  constructor(
    public stockService: ManageStockService,
    private toastrMsgService: ToastrMsgService,
    private datePipe: DatePipe,
    private authService: AuthService,
    public router: Router,
    public fileUploadService: FileUploadService
  ) {
    this.csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: [
        'Supplier Name',
        'Document Date',
        'Remarks',
        'Item Code',
        'Inventory UOM',
        'In Stock',
        'Price',
        'Batch Number',
        'Production Date',
        'Expiry Date',
        'Bin Code',
        'Warehouse Code',
        'Location Code'
      ]
    };
  }
  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt1') dataTable: Table;
  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.cols = [
      { field: 'transactionType', header: 'Transaction Type' },
      { field: 'transactionNumber', header: 'Document No' },
      { field: 'createdDate', header: 'Document Date' },
      { field: 'supplier', header: 'Supplier' },
      { field: 'remarks', header: 'Remarks' }
    ];
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
    this.stockInDetails();
    this.transactionTypes = [
      { name: 'All', code: Constants.ALL },
      { name: 'GRN', code: Constants.GRN },
      { name: 'Stock In', code: Constants.STOCKIN }
    ];
    this.csvList =[{"Supplier Name": "esupplier", "Document Date": "20200505", "Remarks": "test upload", "Item Code": "T1", "Inventory UOM":"GRAM", "In Stock":"101", "Price":"450", "Batch Number":"X0909OPBVIQ", "Production Date":"20200505", "Expiry Date":"20200520", "Bin Code":"OLTONE_WHS02_BIN_77", "Warehouse Code":"WHS02", "Location Code":"OLTONE"},
                   {"Supplier Name": "esupplier", "Document Date": "20200505", "Remarks": "test upload", "Item Code": "T1", "Inventory UOM":"KG", "In Stock":"201", "Price":"450", "Batch Number":"C56709CNMN0", "Production Date":"20200505", "Expiry Date":"20200520", "Bin Code":"OLTONE_N1 WHS_BIN_78", "Warehouse Code":"N1 WHS", "Location Code":"OLTONE"}];
  }
  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  onTransactionChange() {
    this.paginatorInitial();
    this.stockInDetails();
    this.poNumber = "";
  }

  clearFilters() {
    this.fromDate = "";
    this.toDate = "";
    this.poNumber = "";
    this.stockInDetails();
  }

  stockInDetails(lazyLoadData: Paginator = this.paginator) {
    let filterByDate: FilterByDate | null = { fromDate: "", toDate: "" };
    if (this.fromDate && this.toDate) {
    this.utcFDate = this.utcFromDate(this.fromDate);
    this.utcTDate = this.utcToDate(this.toDate);
      filterByDate.fromDate = this.utcFDate;
      filterByDate.toDate = this.utcTDate;
    }
    else {
      filterByDate = null;
    }
    this.stockService
      .getStockInDetails(lazyLoadData.pageNo, lazyLoadData.limit, lazyLoadData.sortOrder, this.transactionType.code, filterByDate, this.poNumber)
      .subscribe(
        (data: HttpResponse<any>) => {
          this.stockInList = data.body;

          this.uniqueStockInList = Array.from(this.stockInList.map(s => s.transactionNumber)).map(transactionNumber => {
            let stockIn = this.stockInList.find(s => s.transactionNumber === transactionNumber);
            return {
              id: stockIn.id,
              transactionNumber: transactionNumber,
              supplier: stockIn.supplier,
              itemCode: stockIn.itemCode,
              itemName: stockIn.itemName,
              batchNumber: stockIn.batchNumber,
              productionDate: stockIn.productionDate,
              expiryDate: stockIn.expiryDate,
              location: stockIn.location,
              warehouseName: stockIn.warehouseName,
              binName: stockIn.binName,
              inStock: stockIn.inStock,
              onOrder: stockIn.onOrder,
              allocated: stockIn.allocated,
              remarks: stockIn.remarks,
              createdBy: stockIn.createdBy,
              createdDate: stockIn.createdDate,
              updatedBy: stockIn.updatedBy,
              updatedDate: stockIn.updatedDate,
              transactionType: stockIn.transactionType,
              userId: stockIn.userId,
              available: stockIn.available,
              category: stockIn.category,
              subcategory: stockIn.subcategory,
              valuationMethod: stockIn.valuationMethod,
              minLevel: stockIn.minLevel,
              maxLevel: stockIn.maxLevel,
              reOrderLevel: stockIn.reOrderLevel,
              oldInStock: stockIn.oldInStock,
              difference: stockIn.difference,
              uom: stockIn.uom,
              price: stockIn.price,
              active: stockIn.active,
              newDate: stockIn.newDate,
            };
          });

          if (this.authService.getRole() === 'ROLE_SUPPLIER') {
            this.cols.splice(3, 1);
          }
          this.uniqueStockInList.forEach(item => {
            item.newDate = this.datePipe.transform(
              new Date(item.expiryDate),
              'dd-MM-yyyy'
            );
            item.inStock = item.inStock.toString();
          });

          this.totalCount = Number(data.headers.get('X-Total-Count'));

          if (this.totalCount <= this.pageCount) {
            this.paginator.paginator = false;
          } else {
            this.paginator.paginator = true;
          }
          this.paginator.loading = false;
          this.paginator.totalCount = this.totalCount;
        },
        (err: any) => {
          this.paginator.loading = false;
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              '',
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
        }
      );
  }
  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ',DESC';
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ',ASC';
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.stockInDetails();
  }
  addStockIn() {
    this.router.navigateByUrl('/mange-stock-in/add-stock-in');
  }
  goToView(transId: string) {
    sessionStorage.setItem('stockTransId', transId);
  }


  // on csv upload
  submit() {
    this.uploadTrue = false;
    const formData = new FormData();
    formData.append('multipartFile', this.csvFile);
    this.stockService.uploadStockinCSV(formData).subscribe(
      (data: any) => {
        this.stockInDetails();
        if(data === true){
          this.toastrMsgService.success(
            '',
            this.message.uploadCsvSuccess,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // on upload csv cancel
  cancel() {
    this.uploadTrue = false;
    this.displayDialog = false;
  }
  // on select the csv file to upload
  onSelectCSVFile(event: any) {
    this.uploadTrue = false;
    if (event.target.files) {
      if (
        this.fileUploadService.checkFileType(
          event.target.files[0].type,
          event.target.files[0].name
        )
      ) {
        this.csvFile = event.target.files[0];
        this.fileName = event.target.files[0].name;
        this.uploadTrue = true;
      } else {
        this.toastrMsgService.error(
          '',
          this.message.csvFailure,
          this.message.toastrTime
        );
      }
      event.target.value = '';
    }
  }
  utcFromDate(fromDate) {
      fromDate = new Date(fromDate.setHours(0,0,0,0));
      return fromDate;
  }

  utcToDate(toDate) {
     toDate = new Date(toDate.setHours(23,59,58,999));
     return toDate;
  }
   download_Sample_CSV() {
    new AngularCsv(this.csvList, 'Sample_Stock_In_CSV', this.csvOptions);
   }
}
