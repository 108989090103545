import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Col } from "@app/shared/models/table-header.model";
import { Breadcrumb } from "@app/shared/models/breadcrumb.model";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WarehouseService } from "@app/core/services/warehouse.service";
import { AddLocation } from "@app/shared/models/location.model";
import { BinService } from "@app/core/services/bin.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { Country } from "@app/core/constants/country";
import { CountryListService } from "@app/core/services/country-list.service";
import { Router } from '@angular/router';
import { LocationService } from '@app/core/services/location.service';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { ItemService } from '@app/core/services/item.service';

@Component({
  selector: 'app-add-warehouse',
  templateUrl: './add-warehouse.component.html',
  styleUrls: ['./add-warehouse.component.scss']
})
export class AddWarehouseComponent implements OnInit {
  name = new FormControl("");
  cols: Col[];
  checked: boolean;
  checkedWarehouse: boolean;
  locationId: any;
  lastNam: string;
  dropdown: any;
  displayDialog: boolean;
  addLocation: AddLocation;
  breadcrumb: Breadcrumb[];
  locations: any;
  registerForm: FormGroup;
  submitted = false;
  countries: any;
  filteredCountry: any;
  editLocation: any;
  messageEvent: any;
  locationList: any;
  warehouseList: any;
  warehouseId: any;
  duplicateCode:boolean;
  locationIdSub: any;
  message: any;
  locationform: {
    code: any;
    name: any;
    address: any;
    location: { id: any };
    hasBin: boolean;
    defaultWarehouse: boolean;
  };
  constructor(private locationService: LocationService, public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder, private router: Router,
    private countryListService: CountryListService,
    private binService: BinService,
    private toastrMsgService: ToastrMsgService,
    private itemService: ItemService,
    private modalService: BsModalService,
    private warehouseService: WarehouseService) { }
  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.checked = false;
    this.checkedWarehouse = false;
    this.getLocation();
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    this.dropdown = Country;
    this.registerForm = this.formBuilder.group({
      warehouseId: ["", Validators.required],
      warehouseName: ["", Validators.required],
      bin: ["", Validators.required],
      warehouse: ["", Validators.required],
      address: [""],
      location: ["", [Validators.required]],
      check: [""]
    });
    this.checkWarehouseCount();
  }
  ngAfterViewInit() {
    this.displayDialog = true;

  }
  get f() {
    return this.registerForm.controls;
  }



  getLocation() {

    this.warehouseService.getLocationList().subscribe(
      data => {

        this.locationList = data["body"];

      },

      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
  }
  getWarehouse(id: any) {

    this.warehouseService.getWarehouseList(id).subscribe(
      data => {

        this.warehouseList = data["body"];


      },
      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
  }
  getWarehouseList() {
    this.warehouseService.getWarehouseLoc().subscribe(
      data => {
        this.locations = data["body"];
      },
      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
  }
  changeEve(data) {
    if (data) {
      this.locationId = data.id;
    }

  }

  onSubmit() {
    this.submitted = true;
    this.locationform = {
      code: this.registerForm.value.warehouseId,
      name: this.registerForm.value.warehouseName,
      address: this.registerForm.value.address,
      location: { id: this.locationId },
      hasBin: this.checked,
      defaultWarehouse: this.checkedWarehouse
    };

    this.warehouseService.addWarehouse(this.locationform).subscribe(
      (data: any) => {
        this.getWarehouseList();

        this.toastrMsgService.success("", this.message.warehouseAddSuccessMsg, this.message.toastrTime);
        this.warehouseService.setWarehouse(data);

        this.registerForm.reset();

        this.bsModalRef.hide();
        this.displayDialog = false;
      },
      (err: any) => {
        this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
  }
  cancel() {

    this.bsModalRef.hide();
    this.displayDialog = false;
  }


  checkDuplicate(type,item){
    this.itemService.checkDuplication(type,item).subscribe(
      (data:any)=>{
        if(data){
          this.duplicateCode = data.body;
        }
      },err=>{

      }
    );
  }

  checkWarehouseCount() {
    this.warehouseService.checkWarehouseAvailable().subscribe(response => {
      if(response.body == 0) {
        this.checkedWarehouse = true;
        this.registerForm.controls['warehouse'].disable();
      };
    },
    (error) => {
      
    })
  }

}

