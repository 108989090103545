import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
  private api: any;
  warehouse: any;
  constructor(public http: HttpClient) {
    this.api = environment.API_ENDPOINT;

  }
  getWarehouse(lazyLoadData: any): Observable<HttpResponse<any>> {

    return this.http.get<any>(`warehouse?page=${lazyLoadData.pageNo}&size=${lazyLoadData.limit}&sort=${lazyLoadData.sortOrder}`, {
      observe: 'response'
    });
  }
  getWarehouseLoc(): Observable<HttpResponse<any>> {

    return this.http.get<any>(`warehouse`, {
      observe: 'response'
    });
  }
  getLocationList(): Observable<HttpResponse<any>> {

    return this.http.get<any>(`locations`, {
      observe: 'response'
    });
  }
  getWarehouseList(id: any): Observable<HttpResponse<any>> {

    return this.http.get<any>(`warehouse/all?locationId=${id}`, {
      observe: 'response'
    });
  }
 getWarehouseItemList(id: any): Observable<HttpResponse<any>> {
      return this.http.get<any>(`warehouse`, {
        observe: 'response'
      });
    }
  getBin(id: any, id2: any) {
    return this.http.get<any>(`bin/bincode?locationCode=${id}&warehouseCode=${id2}`, {
      observe: 'response'
    });
  }

  addWarehouse(formData: any): Observable<any> {

    const body = formData;
    return this.http.post(`warehouse`, body);
  }
  editWarehousePost(formData: any): Observable<any> {

    const body = formData;
    return this.http.put(`warehouse`, body);
  }

  editBinPost(formData: any): Observable<any> {

    const body = formData;
    return this.http.put(`bin`, body);
  }
  editWarehouse(locationId: any): Observable<HttpResponse<any>> {

    return this.http.get<any>(`warehouse/${locationId}`, {
      observe: 'response'
    });
  }
  editBin(locationId: any): Observable<HttpResponse<any>> {

    return this.http.get<any>(`bin/${locationId}`, {
      observe: 'response'
    });
  }

  deleteLoc(id: number) {

    return this.http.delete(`location/${id}`);

  }

  deleteBinLoc(id: number) {

    return this.http.delete(`bin/${id}`);

  }
  deleteWarehouse(id: number) {

    return this.http.delete(`warehouse/${id}`);

  }

  getWarehouseListForBin(id: any, context: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(`warehouse/all?context=${context}&locationId=${id}`, {
      observe: 'response'
    });
  }


  // Setter and gettter for warehouse
  setWarehouse(data: any) {
    this.warehouse = data;
  }

  getSavedWarehouse() {
    return this.warehouse;
  }

  checkWarehouseAvailable() {
    return this.http.get<any>(`available-warehouse-count`, {
      observe: 'response'
    });
  }

}
