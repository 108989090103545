export class StockOut {
  category: any = {id:''};
  subcategory: any = {id:''};
  location: any = {id:''};
  warehouse: any = {id:''};
  bin: any = {id:''};
  supplier: any;
  documentDate: any;
  remarks: any;
}
