import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgForm } from '@angular/forms';

import { Item } from '@app/shared/models/item.model';
import { CategoryService } from '@app/core/services/category.service';
import { Router } from '@angular/router';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { location } from 'ngx-bootstrap/utils/facade/browser';
import { BinService } from '@app/core/services/bin.service';
import { WarehouseService } from '@app/core/services/warehouse.service';

@Component({
  selector: 'app-add-inventory',
  templateUrl: './add-inventory.component.html',
  styleUrls: ['./add-inventory.component.scss']
})
export class AddInventoryComponent implements OnInit, AfterViewInit {
  location: any;
  locationId: any;
  warehouseId: any;
  binId: any;
  todaysDate = new Date();
  displayDialog: boolean;
  batchCode: any;
  batchCodeBoolean: boolean;
  warehouse: any;
  binName: any;
  addCategory: { name: any; description: any };
  totalData: any = [];
  addCategoryForm: any;
  message: any;
  addInventory: any = {};
  totalDat: any;
  bin: any;
  incompleteInput: boolean;
  inventoryWarehouseList = [];
  inventoryBinList = [];
  locationList = [];
  tableContents = [];
  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private binService: BinService,
    private warehouseService: WarehouseService,
    private toastrMsgService: ToastrMsgService,
    private categoryService: CategoryService,
    private router: Router
  ) {}

  ngOnInit() {
    this.setInitialInventory();
    this.displayDialog = true;
    this.message = Constants.MESSAGES;
  }

  // Function to sset the initila values
  setInitialInventory() {
    this.addInventory = {
      location: this.location,
      warehouse: this.warehouse,
      bin: this.bin ? this.bin : {},
      batchId: null,
      stDate: null,
      endDate: null,
      availableStock: 0,
      allocatedStock: 0,
      instock: 1,
      onOrder: 0
    };
  }

  changeWarLoc(id: any) {}
  ngAfterViewInit(): void {
    this.displayDialog = true;
    this.addInventory.location = this.location;
    this.addInventory.warehouse = this.warehouse;
    this.addInventory.bin = this.bin ? this.bin : {};
  }



  // Function to submit the inventory Data
  onSubmit(addCategoryForm: NgForm) {
    if (
      addCategoryForm.valid ||
      (!this.addInventory.batchId &&
        !this.addInventory.stDate &&
        !this.addInventory.endDate)
    ) {
      this.addInventoryList(true, addCategoryForm);
      this.incompleteInput = false;
      this.modalService.setDismissReason(this.totalData);
      this.bsModalRef.hide();
    } else if (
      this.addInventory.batchId ||
      this.addInventory.stDate ||
      this.addInventory.endDate
    ) {
      this.incompleteInput = true;
      this.toastrMsgService.error(
        '',
        this.message.completeInputs,
        this.message.toastrTime
      );
    } else {
      this.toastrMsgService.error(
        '',
        this.message.invalidInput,
        this.message.toastrTime
      );
    }
  }
  cancel() {
    this.bsModalRef.hide();
  }

  // Function to check the Bactid duplication
  checkBatchIdDuplicate(submitted: boolean, addCategoryForm: NgForm) {
    const batchIds = [];
    const findBatchId = this.totalData.find(
      x => x.batchId === this.addInventory.batchId
    );
    const findBatchIdFromSavedList = this.tableContents.find(
      y => y.batchId === this.addInventory.batchId
    );
    batchIds.push(this.addInventory.batchId);
    if ((findBatchId || findBatchIdFromSavedList) && this.batchCodeBoolean) {
      this.toastrMsgService.error(
        '',
        `The batch number already exists`,
        this.message.toastrTime
      );
    } else {
      this.categoryService.checkBinDuplicate(batchIds).subscribe(
        (data: any) => {
          if (this.batchCodeBoolean === false) {
            this.addInventoryList(submitted, addCategoryForm);
          } else if (data && data.batchValid) {
            this.addInventoryList(submitted, addCategoryForm);
          } else if (
            data &&
            data.batchValid === false &&
            this.batchCodeBoolean
          ) {
            this.toastrMsgService.error(
              '',
              `The batch number already exists`,
              this.message.toastrTime
            );
          }
        },
        err => {}
      );
    }
  }

  // Function to manipulate the inventory details addition
  addInventoryList(submitted: boolean, addCategoryForm: NgForm) {
    addCategoryForm.value.batchId = this.addInventory.batchId;
    addCategoryForm.value.locId = this.addInventory.location.id;
    addCategoryForm.value.warId = this.addInventory.warehouse.id;
    addCategoryForm.value.binId = this.addInventory.bin
      ? this.addInventory.bin.id
      : null;
    addCategoryForm.value.nameloc = this.addInventory.location.name;
    addCategoryForm.value.namewar = this.addInventory.warehouse.name;
    addCategoryForm.value.namebin = this.addInventory.bin ? this.addInventory.bin.name  : '';
    if (addCategoryForm.valid) {
      this.totalData.unshift(addCategoryForm.value);
    }
    if (!submitted) {
      // addCategoryForm.reset();
      this.setInitialInventory();
      this.addInventory.bin = this.bin ? this.bin : {};
    }
  }
  //Todo: need to change below code for optimisation
  checkBatchIdValid(addCategoryForm: NgForm) {
    const batchIds = [];
    const findBatchId = this.totalData.find(
      x => x.batchId === this.addInventory.batchId
    );
    batchIds.push(this.addInventory.batchId);
    if( this.batchCodeBoolean ===false){
      this.onSubmit(addCategoryForm );
    }
    else if (findBatchId && this.batchCodeBoolean) {
      this.toastrMsgService.error(
        '',
        `The batch number already exists`,
        this.message.toastrTime
      );
    } else {
      this.categoryService.checkBinDuplicate(batchIds).subscribe(
        (data: any) => {
          if (this.batchCodeBoolean === false) {
            this.onSubmit(addCategoryForm );
          } else if (data && data.batchValid) {
            this.onSubmit(addCategoryForm );
          } else if (
            data &&
            data.batchValid === false &&
            this.batchCodeBoolean
          ) {
            this.toastrMsgService.error(
              '',
              `The batch number already exists`,
              this.message.toastrTime
            );
          }
        },
        err => {

        }
      );
    }
  }

  // Function to calculate the stock
  calculateStock() {
    this.addInventory.allocatedStock = this.addInventory.allocatedStock
      ? this.addInventory.allocatedStock
      : 0;
    this.addInventory.availableStock = this.addInventory.instock
      ? this.addInventory.instock - this.addInventory.allocatedStock
      : 0;
  }

  // Function to remove selected inventory from list
  deleteInventory(index) {
    this.totalData.splice(index, 1);
  }

  // Function to detect the change in inventory location
  changeInventoryLocation() {
    this.addInventory.warehouse = null;
    this.getInventoryWarehouses();
  }

  // Function to get warehouses based on the selected location
  getInventoryWarehouses() {
    this.warehouseService
      .getWarehouseList(this.addInventory.location.id)
      .subscribe(
        data => {
          this.inventoryWarehouseList = data['body'];
          this.addInventory.bin = '';
        },
        (err: any) => {}
      );
  }

  // Function to detect the change in inventory warehouse
  changeInventoryWarehouse() {
    this.getInventoryBins();
  }

  // Function to get the bins based on selected warehouse
  getInventoryBins() {
    this.binService.getBinList(this.addInventory.warehouse.id).subscribe(
      data => {
        this.inventoryBinList = data['body'];
      },
      (err: any) => {}
    );
  }
}
