import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  loadingStatus: Subject<any> = new Subject<any>();
  private loadings = false;
  constructor() {}
  get loading(): boolean {
    return this.loadings;
  }
/**
 * Function which holds the value of loading status
 */
  set loading(value: boolean) {
    this.loadings = value;
    this.loadingStatus.next(value);
  }
/**
 * method for start loading
 */
  startLoading() {
    this.loading = true;
  }
/**
 * method for stop loading
 */
  stopLoading() {
    this.loading = false;
  }
}
