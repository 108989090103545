import {
  Component,
  OnInit,
  DoCheck,
  ChangeDetectorRef,
  AfterViewInit,
  ViewChild
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  NgForm
} from '@angular/forms';
import { Breadcrumb, MenuItem, Dropdown } from 'primeng/primeng';
// import {  BatchValidator } from '@app/core/validators/batch.validator';
import { AddStockIn, AddStockInModel } from '@app/shared/models/add-stock-in.models';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import * as Constants from '@app/core/constants/common.constants';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-add-stock-in',
  templateUrl: './add-stock-in.component.html',
  styleUrls: ['./add-stock-in.component.scss']
})
export class AddStockInComponent implements OnInit {
  myForm: FormGroup;
  addStockForm: FormGroup;
  breadcrumb: MenuItem[];
  message: any;
  showMinMaxR: boolean = false;
  stop: boolean = false;
  totalData: any = [];
  addStockIn = new AddStockIn();
  addStockInModel = new AddStockInModel();
  otherSupplier: boolean;
  otherSupplierName: string;
  initialLoad: boolean = true;
  stockValue: number;
  noBatch: boolean;
  currentDate = new Date();
  findBatchId: any;
  initialPageLoad : boolean;
  noBin: boolean;
  restrictSubmit: boolean = false;
  batchCheckList : any = [];
  transactionErrorList: any[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cd: ChangeDetectorRef,
    private toastrMsgService: ToastrMsgService,
    public stockService: ManageStockService,
    private authService: AuthService,
    private decimalPipe: DecimalPipe
  ) {
    this.categoryDetails();
    this.locationDetails();
    this.itemNameDetails();
  }
  @ViewChild('ddStatus') ddStatus: Dropdown;
  @ViewChild('ddStatusWare') ddStatusWare: Dropdown;
  @ViewChild('ddStatusLoc') ddStatusLoc: Dropdown;
  @ViewChild('ddStatusSub') ddStatusSub: Dropdown;
  @ViewChild('ddStatusCat') ddStatusCat: Dropdown;
  @ViewChild('ddStatusItem') ddStatusItem: Dropdown;
  @ViewChild('ddStatusSup') ddStatusSup: Dropdown;
  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.formSetup();

    this.breadcrumb = [
      { label: 'Manage Stock In', routerLink: '/mange-stock-in' },
      { label: 'Add Stock In', url: '' }
    ];
    this.supplierDetails();
    this.initialPageLoad = false;


  }

  formSetup() {
    this.myForm = this.fb.group({
      supplier: [{ value: '' }],
      otherSupplier: [{ value: '' }],
      dateInput:  ['', Validators.required],
      location: ['', Validators.required],
      warehouse: [{ value: '', disabled: true }, Validators.required],
      bin: [{ value: '', disabled: true }],
      remarks: ['', Validators.required]
    });

    this.addStockForm = this.fb.group({
      itemName: [{ value: '' }, Validators.required],
      inStock: [{ value: '' }, Validators.required],
      allocated: [{ value: '', disabled: true }],
      onOrder: [''],
      bin: [{ value: ''}],
      productionDate: ['', Validators.required],
      batch: [{ value: '' }, Validators.required],
      expirDate: [{ value: '', disabled: true }, Validators.required],
      cost: [{ value: '' }, Validators.required],
      uom: [{value: ''}],
      value: [{value: ''}]
    });
    // this.addStockIn.dateInput = this.datePipe.transform(this.currentDate , 'dd-MM-yyyy');
    this.addStockIn.dateInput = this.currentDate;
    this.noBatch = false;
    this.noBin = true;
  }
  itemNameDetails() {
    this.stockService.getItemNames().subscribe(
      (data: any) => {
        this.addStockIn.itemNames = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  supplierDetails() {
  if(this.authService.getRole() === 'ROLE_BUYER'){
    this.stockService.geSupplierList().subscribe(
      (data: any) => {
        const otherSupplier = { companyName : 'Others' };
        data.push(otherSupplier);
        this.addStockIn.suppliers = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
    }
  }
  categoryDetails() {
    this.stockService.getCategories().subscribe(
      (data: any) => {
        this.addStockIn.categories = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  locationDetails() {
    this.stockService.getLocation().subscribe(
      (data: any) => {
        this.addStockIn.locations = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  catChange() {
    this.subCatDetails();
  }
  subCatDetails() {
    this.stockService.getSubCategories(this.addStockIn.category.id).subscribe(
      (data: any) => {
        this.addStockIn.subCategories = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  locChange() {
    this.warehouseDetails();
  }
  warehouseDetails() {
    this.stockService.getWarehouse(this.addStockIn.location).subscribe(
      (data: any) => {
        this.addStockIn.warehouses = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  warehouseChange() {
    this.binDetails();
  }

  binDetails() {
    this.stockService.getBin(this.addStockIn.warehouse.id).subscribe(
      (data: any) => {
        this.addStockIn.bins = data;
        if(this.addStockIn.bins.length === 0) {
          this.noBin = true;
        } else {
          this.noBin = false;
        }

      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  itemChange() {

    const batchControl =  this.addStockForm.get('batch');
    const productionDateControl =  this.addStockForm.get('productionDate');
    const expirDateControl =  this.addStockForm.get('expirDate');
    const itemNameControl = this.addStockForm.get('itemName');

    // code might be needed for better optimization later

    if (itemNameControl) {
      itemNameControl.valueChanges.subscribe(
        itemName => {
          if (itemName !== null) {
            if (itemName.managementMethod.name === 'No') {
              this.noBatch = true;
              batchControl.setValidators(null);
              productionDateControl.setValidators(null);
              expirDateControl.setValidators(null);
           } else {
              this.noBatch = false;
              batchControl.setValidators([Validators.required]);
              productionDateControl.setValidators([Validators.required]);
              expirDateControl.setValidators([Validators.required]);
            }
          }
    //       if (itemName.managementMethod.name === 'No Batch') {
    //     this.noBatch = true;
    //     batchControl.setValidators(null);
    //     productionDateControl.setValidators(null);
    //     expirDateControl.setValidators(null);

    //  } else {
    //     this.noBatch = false;
    //     batchControl.setValidators([Validators.required]);
    //     productionDateControl.setValidators([Validators.required]);
    //     expirDateControl.setValidators([Validators.required]);
    //   }
          batchControl.updateValueAndValidity();
          productionDateControl.updateValueAndValidity();
          expirDateControl.updateValueAndValidity();
    }
      );
    }
    // if (this.addStockIn.itemName.managementMethod.name === 'No Batch') {
    //   this.noBatch = true;
    //   this.addStockForm.value.batch = '';
    //   this.addStockForm.value.productionDate = '';
    //   this.addStockForm.value.expirDate = '';
    //   this.addStockIn.batchNo = ' ';
    //   this.addStockIn.expiryDate = ' ';
    //   this.addStockIn.productionDate = ' ';

    // } else {
    //   this.noBatch = false;
    //   this.addStockForm.value.batch = '';
    //   this.addStockForm.value.productionDate = '';
    //   this.addStockForm.value.expirDate = '';
    //   this.addStockIn.batchNo = null;
    //   this.addStockIn.expiryDate = null ;
    //   this.addStockIn.productionDate = null ;

    // }
  }

  itemNameChange(category?: any) {
    if (category === 'expirDate') {
      if (this.myForm.get(category).value === null) {
        if(this.initialLoad) {}
        this.myForm.get(category).disable();
        this.myForm.get(category).setValue(undefined);
      } else if (this.myForm.get(category).value === undefined) {
        this.myForm.get(category).enable();
      } else if (this.myForm.get(category).value === '') {
        this.myForm.get(category).disable();
      } else {
        this.myForm.get(category).enable();
      }
    } else {
      this.myForm.get(category).enable();
    }
  }

  itemNameChangeStock(category?: any) {
    if (category === 'expirDate') {
      if (this.addStockForm.get(category).value === null) {
        if (this.initialLoad) {
          this.addStockForm.get(category).disable();
          this.addStockForm.get(category).setValue(undefined);
        } else {
          this.addStockForm.get(category).enable();
        }
      } else if (this.addStockForm.get(category).value === undefined) {
        this.addStockForm.get(category).enable();
      } else if (this.addStockForm.get(category).value === '') {
        this.addStockForm.get(category).disable();
      } else {
        this.addStockForm.get(category).enable();
      }
    } else {
      this.addStockForm.get(category).enable();
    }
    this.initialLoad = false;
  }

  setInitialData() {
    this.addStockIn.itemName = null;
    this.addStockIn.batchNo = null;
    this.addStockIn.productionDate = null;
    this.addStockIn.expiryDate = null;
    this.addStockIn.allocated = null;
    this.addStockIn.onOrder = null;
    this.addStockIn.qtyReceived = null;
    this.addStockForm.reset();
    this.addStockForm.get('expirDate').disable();

  }

  checkBatchIdDuplicate(submitted: boolean, stockform) {
    const batchList = [];

    this.findBatchId = this.totalData.find(
      x => x.batchNumber === stockform.value.batch && x.item.id === stockform.value.itemName.id
    );
    if (this.findBatchId) {
      if (this.findBatchId.batchNumber === null) {
        this.findBatchId = undefined;
      }
    }
    let bachCList = [];
      bachCList.push({
      batchNumber : stockform.value.batch,
      id : stockform.value.itemName.id
    });
    this.stockService.checkBatch(bachCList).subscribe(
      data => {
        if (data.batchValid === false || this.findBatchId) {   // findBatchId
          this.toastrMsgService.error(
            '',
            this.message.batchMsg,
            this.message.toastrTime
          );
        } else {
          const stockData = {
            'warehouse' : this.myForm.get('warehouse').value,
            'item' : stockform.value.itemName,
            'batchNumber' : stockform.value.batch,
            'bin' : stockform.value.bin,
            'expiryDate' : stockform.value.expirDate,
            'productionDate' : stockform.value.productionDate,
            'inStock' : stockform.value.inStock,
            'uom' : stockform.value.uom,
            'price' : stockform.value.cost,
            // 'allocatedStock' : stockform.value.allocated,
            // 'onOrder' : stockform.value.onOrder,
            'availableStock' : stockform.value.inStock - stockform.value.allocated,
            'stockValue' : stockform.value.cost * stockform.value.inStock

          };
          this.totalData.unshift(stockData);
          const itemValue = stockform.value.itemName;
          if (
            itemValue.minLevel === 0 &&
            itemValue.maxLevel === 0 &&
            itemValue.reOrderLevel === 0
          )
          if (!submitted) {
            this.setInitialData();
            this.addStockForm.updateValueAndValidity();
          }
          this.noBatch = false;
        }

      },
      err => {

      }
    );
    // this.noBatch = false;

  }

  deleteInventory(index) {
    this.totalData.splice(index, 1);
  }

  onSubmitStock(myForm) {
    this.addStockInModel.batchList = this.totalData;
   if(myForm.value.supplier != null){
      if (this.otherSupplier) {
        this.addStockInModel.supplier = myForm.value.otherSupplier;
      } else {
        this.addStockInModel.supplier = myForm.value.supplier.companyName;
      }
    } else {
      this.addStockInModel.supplier = ' ';
    }
    this.addStockInModel.createdDate = myForm.value.dateInput;
    this.addStockInModel.remarks = myForm.value.remarks;
    this.restrictSubmit = true;
    this.stockService.getFormSubmission(this.addStockInModel).subscribe(
      resp => {
        this.toastrMsgService.success(
          '',
          resp.stockOutFlag ? "Batch production is success" : this.message.addStockInSuccessMsg,
          this.message.toastrTime
        );
        this.restrictSubmit = false;
        this.myForm.reset();
        this.router.navigateByUrl('/mange-stock-in');

      },
      err => {
        this.restrictSubmit = false;
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          if (Array.isArray(err.error.messages) && err.error.messages.length) {
            this.transactionErrorList = err.error.messages;
          }
          else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
        }
      }
    );

  }

  closeErrorList() {
    this.transactionErrorList = [];
  }

  onSubmit(myForm: FormGroup) {
    let batchList = [];
    let batchCList = [];
    batchCList.push({
      batchNumber : myForm.get('batch').value,
      id : myForm.get('batch').value.itemName.id
    });
    this.stockService.checkBatch(batchCList).subscribe(
      (data: any) => {
        batchList = [];

        if (data.batchValid === false || this.findBatchId) {
          this.myForm.get('batch').setValue(null);
          myForm.get('batch').setValue(null);
          this.toastrMsgService.error(
            '',
            this.message.batchMsg,
            this.message.toastrTime
          );
        } else {
          const itemValue = this.myForm.get('itemName').value;

          if (
            itemValue.minLevel === 0 &&
            itemValue.maxLevel === 0 &&
            itemValue.reOrderLevel === 0
          )
          if (this.authService.getRole() === 'ROLE_SUPPLIER') {
            myForm.controls['supplier'].setValue('');
          } else {
            myForm.controls['supplier'].setValue(
              myForm.get('supplier').value.companyName
            );
          }
          myForm.controls['batch'].setValue({
            batchNumber: myForm.get('batch').value,
            productionDate: myForm.get('productionDate').value,
            expiryDate: myForm.get('expirDate').value,
            item: myForm.get('itemName').value
          });
          myForm.removeControl('expirDate');
          myForm.removeControl('productionDate');
          myForm.removeControl('itemName');

          this.stockService.getFormSubmission(myForm.value).subscribe(
            (data: any) => {
              myForm.addControl(
                'expirDate',
                new FormControl(
                  { value: '', disabled: true },
                  Validators.required
                )
              );
              myForm.addControl(
                'productionDate',
                new FormControl('', Validators.required)
              );
              myForm.addControl(
                'itemName',
                new FormControl('', Validators.required)
              );

              this.myForm.reset();

              this.myForm.get('warehouse').disable();
              this.myForm.get('bin').disable();
              this.toastrMsgService.success(
                '',
                this.message.addStockInSuccessMsg,
                this.message.toastrTime
              );

              this.router.navigateByUrl('/mange-stock-in');
            },
            (err: any) => {
              if (navigator.onLine === false) {
                this.toastrMsgService.warning(
                  '',
                  this.message.noInternet,
                  this.message.toastrTime
                );
              } else {
                this.toastrMsgService.error(
                  '',
                  this.message.errorMsg,
                  this.message.toastrTime
                );
              }
            }
          );
        }
      },
      (err: any) => {}
    );
  }
  allocatedChanged(event: any) {
    this.addStockForm.get('inStock').valueChanges.subscribe(checked => {
      if (checked) {
        this.addStockForm.get('allocated').enable();
        if (this.addStockIn.allocated) {
          this.addStockForm
          .get('allocated')
          .setValidators([Validators.required, Validators.max(checked)]);
        }
        // this.addStockForm
        //   .get('allocated')
        //   .setValidators([Validators.required, Validators.max(checked)]);
      } else {
        this.addStockForm.get('allocated').setValidators(null);
      }
      this.addStockForm.get('allocated').updateValueAndValidity();
    });
  }

  binClick(event: any) {
    if (event === 'ddStatusWare') {
      this.ddStatusWare.filled = true;
    } else if (event === 'ddStatus') {
      this.ddStatus.filled = true;
    } else if (event === 'ddStatusLoc') {
      this.ddStatusLoc.filled = true;
      this.addStockIn.bins = null;
    } else if (event === 'ddStatusSub') {
      this.ddStatusSub.filled = true;
    } else if (event === 'ddStatusCat') {
      this.ddStatusCat.filled = true;
    } else if (event === 'ddStatusItem') {
      this.ddStatusItem.filled = true;
      this.onItemChangeUpdateItemDetails();
    } else if (event === 'ddStatusSup') {
      this.ddStatusSup.filled = true;
      if (this.addStockIn.supplier.companyName === 'Others') {
        this.otherSupplier = true;
      } else {
        this.otherSupplier = false;
      }
    }
  }

  onItemChangeUpdateItemDetails() {
    this.addStockIn.itemCost = this.addStockForm.get('itemName').value.price;
    this.addStockIn.uom = this.addStockForm.get('itemName').value.uom.name;
  }

  onCostChangeUpdateValue() {
    if(this.addStockIn.itemCost && this.addStockIn.qtyReceived) {
      this.addStockIn.itemValue = (this.addStockIn.qtyReceived * this.addStockIn.itemCost).toFixed(3);
    }
  }

  onCancel() {
    this.router.navigateByUrl('/mange-stock-in');
  }
}
