import { Router } from '@angular/router';
import { Dashboard } from '@app/shared/models/dashboard.model';
import { DashboardService } from '@app/core/services/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  message: any;
  breadcrumb: Breadcrumb[];
  public dashboardValues= new Dashboard();
  // dashboardValues:any;

  constructor(private dashboardService: DashboardService, private router:Router,private toastrMsgService: ToastrMsgService) { }

  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.breadcrumb = [
      { label: 'Dashboard', routerLink: '' },
    ];
    this.getDashboardDetails();
  }
  getDashboardDetails(){
    // this.dashboardService.getStockLevels().subscribe((data:Dashboard)=>{
    //   this.dashboardValues= data;

    // },
    //  (err: any) => {
    //   if (navigator.onLine === false) {
    //     this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);

    //   }
    //   }
    // );
    this.dashboardValues={"min":0,"max":0,"reorder":0,"inventory":0};
  }
  goToReport(level:any){
    this.router.navigateByUrl('/manage-report')
    this.dashboardService.setReportIdAs(level);
  }
}
