import { AuthGuard } from './../../core/guards/auth-guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListWarehousesComponent } from './list-warehouses/list-warehouses.component';

const routes: Routes = [
  {path: 'warehouse', component: ListWarehousesComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageWarehouseRoutingModule { }
