import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ManageDashboardRoutingModule } from './manage-dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import {CardModule} from 'primeng/card';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    ManageDashboardRoutingModule,
    BreadcrumbModule,
    CardModule
  ],
  exports: [DashboardComponent],

})
export class ManageDashboardModule { }
