import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { debug } from 'util';
const httpOptions = {
  headers: new HttpHeaders({
    'api-end-type': 'ezyprocure'
  })
};
@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private api: string;
  category: any;
  constructor(public http: HttpClient) { this.api = environment.API_ENDPOINT; }

  getCategoryList(lazyLoadData: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(`category?page=${lazyLoadData.pageNo}&size=${lazyLoadData.limit}&sort=${lazyLoadData.sortOrder}`, {
      observe: 'response'
    });
  }
   getCategoryItemList(lazyLoadData: any): Observable<HttpResponse<any>> {
      return this.http.get<any>(`category?page=${lazyLoadData.pageNo}&size=${10000}&sort=${lazyLoadData.sortOrder}`, {
        observe: 'response'
      });
    }
  getCategory(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`category?size=${100}`, {
      observe: 'response'
    });
  }
  getBatch(): Observable<HttpResponse<any>> {

    return this.http.get<any>(`management-method`, {
      observe: 'response'
    });
  }
  getUom() {
    ;
    return this.http.get<any>(`uoms?query&page=${0}&size=${1000}&sort=${'asc'}`, httpOptions);
  }
  getSupplier() {
    ;
    return this.http.get<any>(`uoms?query&page=${0}&size=${1000}&sort=${'asc'}`, httpOptions);
  }
  getDefaultSupplier() {

    return this.http.get<any>(`suppliers/all/visibility-type?visibilityType=ALL`, httpOptions);
  }
  getDefaultSupplierName(id) {

    return this.http.get<any>(`suppliers/all/visibility-type?visibilityType=ALL`, httpOptions);
  }
  getDefaultSupplierId(id: any) {

    return this.http.get<any>(`suppliers/${id}`, httpOptions);
  }
  getValuation() {

    return this.http.get<any>(`valuation`);
  }

  checkDuplicateCategory(name: string) {
    return this.http.get<any>(`categoryname-valid?categoryName=${name}`);
  }


  addCategory(categories: any) {
    if (categories.description == null) {
      return this.http.post(`category?categoryName=${categories.name}`, null);
    }
    else {
      return this.http.post(`category?categoryName=${categories.name}&description=${categories.description}`, null);

    }
  }
  updateCategory(rowData: any) {
    if (rowData.description == null) {
      return this.http.put(`category/${rowData.id}?categoryName=${rowData.name}`, null);
    }
    else {
      return this.http.put(`category/${rowData.id}?categoryName=${rowData.name}&description=${rowData.description}`, null);
    }
  }
  deleteCategories(rowData: number) {
    return this.http.delete(`category/${rowData}`);
  }


  // check bin duplication
  checkBinDuplicate(data:any){
    return this.http.post(`batch-check`,data);
  }

// Getter and setter for category and sub category
  setCategory(data: any) {
    this.category = data;
  }

  getSavedCategory() {
    return this.category;
  }
}
