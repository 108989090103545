import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(): boolean {
    const token = this.authService.getToken();
    if (!token || this.authService.checkTokenExpiry()) {
      this.redirectLogin();
      return false;
    }
    return true;
  }
  redirectLogin() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
