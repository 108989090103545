import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { debug } from 'util';
const httpOptions = {
  headers: new HttpHeaders({
    'api-end-type': 'ezyprocure'
  })
};
@Injectable({
  providedIn: 'root'
})
export class ItemService {
  private api: any;
  constructor(public http: HttpClient) {
    this.api = environment.API_ENDPOINT;
  }
  getBatch(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`management-method`, {
      observe: 'response'
    });
  }
  getUom() {
    return this.http.get<any>(
      `uoms?query&page=${0}&size=${1000}&sort=${'asc'}`,
      httpOptions
    );
  }
  getDefaultSupplier() {
    return this.http.get<any>(
      `suppliers/all/visibility-type?visibilityType=ALL`,
      httpOptions
    );
  }
  getDefaultSupplierName(id) {
    return this.http.get<any>(
      `suppliers/all/visibility-type?visibilityType=ALL`,
      httpOptions
    );
  }
  getValuation() {
    return this.http.get<any>(`valuation`);
  }
  addItem(formData: any): Observable<any> {
    const body = formData;
    return this.http.post(`item`, body);
  }
  getLocationList(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`locations`, {
      observe: 'response'
    });
  }
  getEdit(id): Observable<HttpResponse<any>> {
    return this.http.get<any>(`item/${id}`, {
      observe: 'response'
    });
  }
  getDefaultSupplierId(id: any) {
    return this.http.get<any>(`suppliers/${id}`, httpOptions);
  }
  getEditTable(id): Observable<HttpResponse<any>> {
    return this.http.get<any>(`inventory?itemId=${id}`, {
      observe: 'response'
    });
  }

  getInventoryLevels(itemId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>(`item/location-inventory-levels/${itemId}`, {
      observe: 'response'
    });
  }

  postInventoryLevels(itemId: number, inventoryDetails: any): Observable<Object> {
    return this.http.post(`item/location-inventory-levels/${itemId}`, inventoryDetails);
  }

  editItem(itemId, formData: any): Observable<any> {
    const body = formData;
    return this.http.put(`item/${itemId}`, body);
  }

  getDefaultItemDetails(): Observable<any> {

    return this.http.get<any>(`item/defaults`, {
      observe: 'response'
    });
  }
  getLocationDetails(id): Observable<any> {
    return this.http.get<any>(`item/inventory/location?itemId=${id}`, {
      observe: 'response'
    });
  }
  getWareHouseDetails(id, locationId): Observable<any> {
    return this.http.get<any>(
      `stock/filter?itemId=${id}&locationId=${locationId}&transactionType=STOCKTRANSFER`,
      {
        observe: 'response'
      }
    );
  }
  getBinDetails(itemId): Observable<any> {
    return this.http.get<any>(`stock/filter?itemId=${itemId}`, {
      observe: 'response'
    });
  }

  checkDuplication(type, item): Observable<any> {
    if (item)
      item = item.replace('%', '%25');
    return this.http.get<any>(`check/duplicate?type=${type}&param=${item}`, {
      observe: 'response'
    });
  }

  deleteItem(rowData: number) {
    return this.http.delete(`item/${rowData}`);
    }

  uploadCSV(formData:any): Observable<any>{
    return this.http.post(`item/csvupload`,formData);
  }

  addAltUom(formData_altuom: any): Observable<any> {
    const body = formData_altuom;
    return this.http.post(`alternateuom`, body);
   }

  getAlternateUomList(itemId): Observable<any> {
      return this.http.get<any>(`alternateuom/${itemId}`, {
      observe: 'response'
    });
  }

  editAlternateUom(itemId,formData_altUomEdit: any): Observable<any> {
          const body = formData_altUomEdit;
          return this.http.put(`alternateuomedit/${itemId}`, body);
  }

  deleteAlternateUom(rowData: number) {
    return this.http.delete(`alternateuomdelete/${rowData}`);
  }

  getItem(): Observable<any> {
      return this.http.get(`item/all`);
    }

  getFilteredStocks(id, locationId): Observable<any> {
    return this.http.get<any>(
      `stock/inv/filter?itemId=${id}&locationId=${locationId}`,
      {
        observe: 'response'
      }
    );
  }

  getSubrecipeItem(): Observable<any> {
    return this.http.get(`items/subrecipe`);
  }

  getSubrecipes(lazyLoadData: any, searchQuery: string=""):Observable<HttpResponse<any>> {
    if (searchQuery)
      searchQuery = searchQuery.replace('%', '%25');
    return this.http.get<any>(`sub-recipes?page=${lazyLoadData.pageNo}&size=${lazyLoadData.limit}&sort=${lazyLoadData.sortOrder}&parentItemCode=${searchQuery}`, {
      observe: 'response'
    });
  }

  checkPasswordSubrecipes(password: string):Observable<HttpResponse<any>> {
    return this.http.get<any>(`check/password?password=${password}`, {
      observe: 'response'
    });
  }

  getAllSubrecipes(): Observable<any> {
    return this.http.get(`sub-recipe/all`);
  }

  uploadSubrecipeCSV(formData:any): Observable<any>{
    return this.http.post(`sub-recipe/csvupload`,formData);
  }

  getSubRecipeList(companyID: any, parentItemCode:any ): Observable<HttpResponse<any>> {
   return this.http.get<any>(`sub-recipe/filter?companyId=${companyID}&parentItemCode=${parentItemCode}`, {
     observe: 'response'
   });
 }
}
