import { ROLE_BUYER } from './../../../core/constants/common.constants';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ItemService } from '@app/core/services/item.service';

import { ActivatedRoute } from '@angular/router';
import { DecimalPipe } from '@angular/common';

import { CategoryService } from '@app/core/services/category.service';
import { SubCategoryService } from '@app/core/services/sub-category.service';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { FormGroup } from '@angular/forms';
import { Col } from '@app/shared/models/table-header.model';
import { WarehouseService } from '@app/core/services/warehouse.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddInventoryComponent } from '../add-inventory/add-inventory.component';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { BinService } from '@app/core/services/bin.service';
import { location } from 'ngx-bootstrap/utils/facade/browser';
import { take, takeLast } from 'rxjs/operators';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { Dropdown } from 'primeng/primeng';

type AlternateUoMEdit = { alternateUomRowId:any,alternateUomId:any, uomName: any, conversionFactor: number, baseUom:any, conversionFactorBaseUom: number };
type AlternateUomWithItem = {id:any,alternateUomId:any,alternateUomName:any,alternateConversionFactor: number, baseUomId:any, baseUomName: any, baseUomFactor: number};
type TabModel = {code: string, header: string, active: boolean};
type LocationDetail = { locationId: number, locationName: string, minLevel: number, maxLevel: number, reorderLevel: number, reorderQty: number};

@Component({
  selector: 'app-edit-items',
  templateUrl: './edit-items.component.html',
  styleUrls: ['./edit-items.component.scss']
})
export class EditItemsComponent implements OnInit {
  breadcrumb: Breadcrumb[];
  category: string[];
  display: boolean;
  bsModalRef: BsModalRef;
  price: any;
  altUomData: any;
  catName: any;
  cols: Col[];
  itemCode: any;
  subCategory: any;
  col1: null;
  col2: null;
  col3: null;
  col4: null;
  col5: null;
  col6: null;
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  itemName: any;
  uom: any;
  alternate: any;
  tableContents: any = [];
  message: any;
  quantity: any;
  alternateUom: any;
  location: any;
  displayTable: boolean;
  warehouse: any;
  toggleid: boolean;
  bin: any;
  locationList: any;
  warehouseList: any;
  binList: any;
  uomListName: any;
  lactionName: any;
  loctionName: any;
  warehouseName: any;
  binName: any;
  tableContentsUom: any = [];

  toggle: boolean;
  catList: any;
  SubcatList: any;
  altUom:any;
  addInventory: {
    alternativeUomId: any;
    toggle: boolean;
    locationInventory: any;
    valuation: any;
    alternateUomQty: any;
    batchcode: any;
    category: any;
    subCategory: any;
    col1: null;
    col2: null;
    col3: null;
    col4: null;
    col5: null;
    col6: null;
    defaultSupplier: any;
    itemCode: any;
    itemName: any;
    cost: any;
    barCode: any;
    uom: any;
    alternateUom: any;
    location: any;
    warehouse: any;
    batchManagement: any;
    bin: any;
    baseUomQty: any;
    baseUoM:any;
    isActive:any;
  };
  uomList: any = [];
  altUomList: any = [];
  batchList: any;
  supplierList: any;
  defaultSupplierList: any = [];
  valuation: any;
  batchData: any;
  displayDialog: boolean;
  batchCodeBoolean: boolean;
  uomListData: any;

  uomAlterListData: any;
  locationId: any;
  warehouseId: any;
  binId: any;
  qtyValue: any;
  selectedItemId: any;
  itemList: any;
  tableContentsDefault: any;
  itemId: any;
  cat: any;
  defaultSupplierListName: any;
  itemStatusChanged: boolean;
  roleBuyer: boolean;
  minDate = new Date();
  bsValue = new Date();
  activationDate = new Date();
  isItemDuplicate:boolean;
  oldValue:any;
  alternateUoMsList: any[] = [];
  alternateUoMDropdown: any[] = [];
  alternateUoMsListItem:any[]=[];
  alternateUoMsListEdit: any[] = [];
  isActive: any;
  isCost: boolean;
  /* Tabs declaration */
  inventoryTab: TabModel = { code:"INV", header: "Inventory Details", active: true };
  locationTab: TabModel = { code:"LOC", header: "Inventory Levels", active: false };
  currentTab: TabModel;
  tabsList: TabModel[] = [];
  /* Location details */
  locationDetails: LocationDetail[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subCategoryService: SubCategoryService,
    private categoryService: CategoryService,
    private itemService: ItemService,
    private modalService: BsModalService,
    private binService: BinService,
    private toastrMsgService: ToastrMsgService,
    private warehouseService: WarehouseService,
    private bsDatepickerConfig: BsDatepickerConfig,
    private decimalPipe: DecimalPipe
  ) {

    this.route.params.subscribe(params => {
      this.selectedItemId = params.id;
    });
    this.bsDatepickerConfig.dateInputFormat = 'DD-MM-YYYY';
  }

  ngOnInit() {
    //populate tabs
    this.tabsList = [ this.inventoryTab, this.locationTab ];
    this.currentTab = this.inventoryTab;

    // this.getDefaultSupplier();
    this.roleBuyer = (localStorage.getItem('role') === ROLE_BUYER) ? true : false;

    this.getEditList();
    this.displayDialog = true;
    this.getEditTableList();
    this.fetchInventoryLevels();
    // this.getbatch();
    // this.getValuation();


    this.getUom();
    // this.getBatchList();
    this.displayTable = true;
    this.cols = [
      { field: 'location', header: 'Location' },
      { field: 'warehouseName', header: 'Warehouse Name' },
      { field: 'binId', header: 'Bin (Shelf/Rack) ID' },
      { field: 'binName', header: 'Bin (Shelf/Rack) Name' }
    ];
    // this.getLocation();

    this.message = Constants.MESSAGES;

    this.breadcrumb = [
      { label: 'Manage Items', routerLink: '/manage-items' },
      { label: 'Edit Item', routerLink: '' }
    ];
    this.cols = [
      {
        field: 'location',
        header: 'Location'
      },
      { field: 'warehouse', header: 'Warehouse' },
      { field: 'bin', header: 'Bin (Shelf/Rack)' },
      { field: 'batch', header: 'Batch#' },
      { field: 'productiondate', header: 'ProductionDate' },
      { field: 'expirydate', header: 'Expirydate' },
      { field: 'instock', header: 'InStock' },
      { field: 'allocatedstock', header: 'AllocatedStock' },
      { field: 'availablestock', header: 'Available Stock' }
    ];
    // this.paginator.paginator = true;


    this.addInventory = {
      toggle: true,
      locationInventory: null,
      alternativeUomId: undefined,
      category: '',
      valuation: '',
      subCategory: '',
      defaultSupplier: '',
      itemCode: '',
      itemName: '',
      cost: '',
      alternateUomQty: '',
      barCode: '',
      batchcode: 'On Release',
      uom: '',
      alternateUom: ' ',
      location: '',
      // alternateUomQty:null,
      warehouse: '',
      batchManagement: '',
      bin: '',
      baseUomQty: undefined,
      baseUoM:undefined,
      isActive: '',
      col1: null,
      col2: null,
      col3: null,
      col4: null,
      col5: null,
      col6: null,
    };

    this.alternateUoMsList = [];
    this.alternateUoMsListItem=[];
    this.alternateUoMsListEdit= [];
    this.getAlternateUomTableList();
  }

  onTabSwitch(activeTab: TabModel) {
    if(this.currentTab.code == activeTab.code) {
      return;
    }
    else {
      this.currentTab = activeTab;
      this.tabsList.forEach(tab => {
        if(tab.code == activeTab.code) {
          tab.active = true;
        }
        else {
          tab.active = false;
        }
      });
    }
  }

  changeEveLoc(locdata) {
    this.locationId = locdata.id;

    this.loctionName = locdata.name;
    if (locdata) {
      this.getWarehouse(locdata.id);
      this.addInventory.warehouse = null;
      // this.getBinList('');
    }
  }
  changeWarLoc(warData) {
    this.warehouseId = warData.id;
    this.warehouseName = warData.name;

    if (warData) {
      this.getBinList(warData.id);
    }
  }
  changeWarLo(warData) {


    this.warehouseId = warData.id;
    this.warehouseName = warData.name;

    if (warData) {
      this.getBinList(warData.id);
    }
  }
  changeBinLoc(binData) {
    this.binId = binData.id;
    this.binName = binData.name;
  }
  onValueChange(value: Date): void {
    this.itemList.activationDate = value.toISOString();
  }
  changeEveCat(catData) {




    this.catName = catData.name;
    if (catData) {
      this.getSubCategoryList(catData.id);

    }
    this.addInventory.subCategory = this.itemList.subcategory;
  }
  changeQty(id) {
    this.qtyValue = id;
  }

  changeUomCat(uomData) {
    this.uomListData = uomData;
    this.uomListName = uomData.name;
    this.filterAlternateUoms(uomData.name);
  }
  changeUomAlt(altData) {
    this.altUomData = altData.name;
  }

  changeAlterUomCat(uomAlterData) {
    this.uomAlterListData = uomAlterData;
  }

  getBinList(id: any) {
    this.binService.getBinList(id).subscribe(
      data => {
        this.binList = data.body;
        this.addInventory.bin = this.itemList.defaultBin;
      },
      (err: any) => { }
    );
  }
  getCategoryList() {

    this.categoryService.getCategory().subscribe(data => {


      this.catList = data.body;
      this.addInventory.category = this.itemList.category;


    });
  }
  getBatchList() {
    this.itemService.getBatch().subscribe(data => {
      this.batchList = data.body;

      this.addInventory.batchManagement = this.itemList.managementMethod;
    });
  }


  getUom() {
    this.itemService.getUom().subscribe(data => {
      this.uomList = data;
      if (this.itemList) {
        this.addInventory.uom = this.uomList.find(uom => uom.id === this.itemList.uom.id);
      }
    });
  }

  deleteUoM(alternateUoM, index) {
    //this.display = false;
    this.itemService.deleteAlternateUom(alternateUoM)
      .subscribe((data: any) => {
        this.toastrMsgService.success('success', this.message.AlternateUoMaddSuccess, this.message.toastrTime);
        this.alternateUoMsList.splice(index, 1);
      },
        (err: any) => {
        });
  }

 /**
   * @description make UoM list without base UoM
   * @param {string} baseUoM - base UoM name
   */
  filterAlternateUoms(baseUoM: string) {
    this.alternateUoMDropdown = this.uomList.filter(uom => {
      return uom.name != baseUoM;
    })
  }

  getDefaultSupplier() {
    this.itemService.getDefaultSupplier().subscribe(data => {
      this.defaultSupplierList = data;
      this.addInventory.defaultSupplier = this.defaultSupplierList.find(supplier => supplier.id === this.itemList.defaultSupplierId);
    });
  }


  getValuation() {
    this.itemService.getValuation().subscribe(data => {
      this.valuation = data;
      this.addInventory.valuation = this.itemList.valuationMethod;
    });
  }
  getbatch() {
    this.itemService.getBatch().subscribe(data => {
      this.batchData = data.body;
    });
  }
  getSubCategoryList(id: any) {

    this.subCategoryService.getSubCategory(id).subscribe(data => {
      this.SubcatList = data.body;
      this.addInventory.subCategory = this.itemList.subcategory;

    });
  }
  getWarehouse(id: any) {

    this.warehouseService.getWarehouseList(id).subscribe(
      data => {
        this.warehouseList = data.body;
        this.addInventory.warehouse = this.itemList.defaultWarehouse;

      },
      (err: any) => { }
    );
  }

  getEditList() {
    this.itemService.getEdit(this.selectedItemId).subscribe(data => {
      this.itemList = data.body;
      this.bsValue = new Date(this.itemList.activationDate);

      this.cat = this.itemList.category;
      this.addInventory.alternateUom = this.itemList.alternativeUom;
      //this.addInventory.alternativeUomId = this.itemList.alternativeUom.name ? this.itemList.alternativeUom : null;
      this.getSubCategoryList(this.itemList.category.id);
      if (this.roleBuyer) {
        this.getDefaultSupplier();
      }
      // this.getCategoryList();
      // this.getWarehouse(this.itemList.category.id)
      this.getLocation();


      this.getBatchList();
      this.getValuation();
      this.getUom();
      this.getCategoryList();
      if (this.itemList.defaultLocation) {
        this.getWarehouse(this.itemList.defaultLocation.id);
      }
      if (this.itemList.defaultWarehouse) {
        this.getBinList(this.itemList.defaultWarehouse.id);
      }

      this.addInventory.itemCode = this.itemList.code;
      this.oldValue = this.itemList.code;
      this.addInventory.cost = this.decimalPipe.transform(this.itemList.price, '1.10-10');
      this.addInventory.uom = this.itemList.uom;
      //this.addInventory.uom = {"name": this.itemList.uom.name, "code": this.itemList.uom.id};
      this.addInventory.barCode = this.itemList.barcode;

      this.addInventory.alternateUomQty = this.itemList.alternateUomQty;

      this.addInventory.itemName = this.itemList.name;

      this.addInventory.isActive = this.itemList.isActive;
      this.addInventory.col1 = this.itemList.col1;
      this.addInventory.col2 = this.itemList.col2;
      this.addInventory.col3 = this.itemList.col3;
      this.addInventory.col4 = this.itemList.col4;
      this.addInventory.col5 = this.itemList.col5;
      this.addInventory.col6 = this.itemList.col6;

    });

    // this.getDefaultSupplierId(this.itemList.defaultSupplierId)

  }
  getDefaultSupplierId(id: any) {

    this.itemService
      .getDefaultSupplierId(id)
      .subscribe(data => {
        this.tableContentsDefault = data.body;

      });
  }

  getEditTableList() {

    this.itemService
      .getEditTable(this.selectedItemId)
      .subscribe(data => {
        this.tableContents = data.body;
        if (this.tableContents.length === 0) {
          this.display = false;
        }

      });
  }

  fetchInventoryLevels() {
    this.itemService.getInventoryLevels(Number(this.selectedItemId)).subscribe(response => {
      let responseData: LocationDetail[] = response.body;
      if (responseData && responseData.length > 0) {
        this.locationDetails = responseData;
      }
      else {
        this.locationDetails = [];
      }
    });
  }

  showDialog() {
    this.display = true;
  }
  showDialogBox() {
    this.display = false;
  }
  cancel() {
    this.display = false;
  }
  addFieldValue() {
    this.fieldArray.push(this.newAttribute);
    this.newAttribute = {};
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }

  checkMinMaxLevel(locationLevel: LocationDetail) {
    return Number(locationLevel.maxLevel) >= Number(locationLevel.minLevel);
  }

  checkReorderLevel(locationLevel: LocationDetail) {
    return Number(locationLevel.maxLevel) >= Number(locationLevel.reorderLevel) && Number(locationLevel.reorderLevel) >= Number(locationLevel.minLevel);
  }

  validateInventoryLevel() {
    let isMaxLevelValid = true;
    let isReorderLevelValid = true;

    if(this.locationDetails.length) {
      isMaxLevelValid = this.locationDetails.every(this.checkMinMaxLevel);
      if(!isMaxLevelValid) {
        this.toastrMsgService.error("Invalid Data", "Minimum level exceeds maximum level for some locations", this.message.toastrTime);
      }
      else {
        isReorderLevelValid = this.locationDetails.every(this.checkReorderLevel);
        if(!isReorderLevelValid) {
          this.toastrMsgService.error("Invalid Data", "Reorder level should be between minimum level and maximum level", this.message.toastrTime);
        }
      }
      return isMaxLevelValid && isReorderLevelValid;
    }
  }

  onSubmit(addCategoryForm: NgForm) {
    if(!this.validateInventoryLevel()) {
      return;
    }

    this.itemId = this.addInventory.itemCode;

    const ItemDto = {
      name: addCategoryForm.value.itemName ? addCategoryForm.value.itemName : this.addInventory.itemName,
      code: this.addInventory.itemCode,
      companyId: this.itemList.companyId,
      createdBy: this.itemList.createdBy,
      createdDate: this.itemList.createdDate,
      id: this.itemList.id,
      stockInList: null,
      type: this.itemList.type,
      userId: this.itemList.userId,
      userType: this.itemList.userType,
      ezyprocureItemId: this.itemList.ezyprocureItemId,
      uom: { id: this.addInventory.uom.id },
      alternativeUom: { id: this.addInventory.alternateUom ? this.addInventory.alternateUom.id : null },
      defaultSupplierId: this.addInventory.defaultSupplier ? this.addInventory.defaultSupplier.id : null,
      alternateUomQty: this.addInventory.alternateUomQty,
      defaultWarehouse: this.addInventory.warehouse ? { id : this.addInventory.warehouse.id } : null,
      defaultLocation: this.addInventory.location ? { id: this.addInventory.location.id } : null,
      defaultBin: this.addInventory.bin ? { id: this.addInventory.bin.id } : null,
      management: { name: 'BATCH' },
      managementMethod: { name: this.addInventory.batchManagement.name },
      category: { id: addCategoryForm.value.category ? addCategoryForm.value.category.id : this.addInventory.category.id },
      subcategory: { id: this.addInventory.subCategory ? this.addInventory.subCategory.id : null },
      valuationMethod: this.addInventory.valuation,
      inventoryStatus: true,
      price: this.itemList.isSubRecipe ? this.addInventory.cost : 0,
      barcode: this.addInventory.barCode,
      isActive: this.itemList.isActive,
      isSubRecipe: this.itemList.isSubRecipe,
      inActiveReason: this.itemList.inActiveReason,
      activationDate: this.itemList.activationDate,
      col1: this.addInventory.col1,
      col2: this.addInventory.col2,
      col3: this.addInventory.col3,
      col4: this.addInventory.col4,
      col5: this.addInventory.col5,
      col6: this.addInventory.col6
    };

      this.itemService
        .editItem(this.itemList.id, ItemDto)
        .subscribe((data: any) => {

          this.itemService.postInventoryLevels(Number(this.selectedItemId), this.locationDetails)
            .subscribe(response => {

            },
              (error) => {
                this.toastrMsgService.error('Error', 'Inventory details could not be saved', this.message.toastrTime);
              });

          this.itemService.editAlternateUom(this.itemList.id, this.alternateUoMsListItem)
            .subscribe((data: any) => {

            },
              (err: any) => {
                this.toastrMsgService.error('Alternate UoM', 'Error occured on saving', this.message.toastrTime);
              });
          this.router.navigateByUrl('/manage-items');
          this.toastrMsgService.success('', this.message.itemeditSuccess, this.message.toastrTime);
        }, (err: any) => {
          this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
        });
  }
  onChange(itemStatus) {
    this.itemList.isActive = itemStatus;
    this.itemStatusChanged = !this.itemStatusChanged;
    this.itemList.inActiveReason = '';
  }

  onSubRecipeChange(subrecipe) {
    this.itemList.isSubRecipe = subrecipe;
  }

  showCancelBox() {
    this.router.navigateByUrl('/manage-items');
  }
  onRadioChange() { }
  getLocation() {
    this.itemService.getLocationList().subscribe(
      data => {
        this.locationList = data.body;
        this.addInventory.location = this.itemList.defaultLocation;

      },

      (err: any) => {
        this.toastrMsgService.error(
          '',
          this.message.errorMsg,
          this.message.toastrTime
        );
      }
    );
  }

     // Function to cancel the alternate uom selection
  cancelAltUom() {
    this.addInventory.alternateUomQty = null;
    this.addInventory.baseUomQty=null;
    this.addInventory.alternateUom = {};
    this.display = !this.display;
  }

   // validate cost
   isValidCost() {
    return /^\s*(?=.*[1-9])\d*(?:\.\d{1,20})?\s*$/.test(this.addInventory.cost);
  }
   submitAltUom() {
     this.display = !this.display;
     this.getUoMNames();
     //this.getAltUomList();
     this.alternateUoMsList.forEach(uom => {
                    let _auom: AlternateUomWithItem={id:uom.alternateUomRowId,alternateUomId:uom.alternateUomId, alternateUomName:uom.uomName, alternateConversionFactor: uom.conversionFactor, baseUomId:this.addInventory.uom.id, baseUomName:this.addInventory.uom.name, baseUomFactor:uom.conversionFactorBaseUom };
                    this.alternateUoMsListItem.push(_auom);
                    });
   }
  checkDuplicate(type,item){
    this.itemService.checkDuplication(type,item).subscribe(
      (data:any)=>{
        if(data){
          if(this.oldValue ===item){
            this.isItemDuplicate = false;
          }else{
            this.isItemDuplicate = data.body;
          }
        }
      },err=>{

      }
    );
  }

    addUoMToList() {
      const regexp = /^\s*(?=.*[1-9])\d*(?:\.\d{1,20})?\s*$/;

      if (!this.addInventory.alternateUomQty || !regexp.test(this.addInventory.alternateUomQty)) {
        this.toastrMsgService.error('', 'Please enter a valid conversion factor', this.message.toastrTime);
        return;
      }

      if (!this.addInventory.baseUomQty || !regexp.test(this.addInventory.baseUomQty)) {
        this.toastrMsgService.error('', 'Please enter a valid conversion factor', this.message.toastrTime);
        return;
      }

      if (!this.addInventory.alternateUom) {
        this.toastrMsgService.error('', 'Please choose an alternate UoM', this.message.toastrTime);
        return;
      }

      if (this.checkDuplicateUoM()) {
        this.toastrMsgService.error('', 'Duplicate entry', this.message.toastrTime);
        return;
      }

      let _uom: AlternateUoMEdit = {alternateUomRowId:null,alternateUomId:this.addInventory.alternateUom.id ,uomName: this.addInventory.alternateUom.name, conversionFactor: this.addInventory.alternateUomQty, baseUom:this.addInventory.uom.name, conversionFactorBaseUom: this.addInventory.baseUomQty };
      this.alternateUoMsList.push(_uom);
      this.addInventory.alternateUom = this.addInventory.alternateUomQty = undefined;
      this.addInventory.baseUoM = this.addInventory.baseUomQty = undefined;
    }

    checkDuplicateUoM(): boolean {
       let hasDuplicate = false;
       this.alternateUoMsList.forEach(uom => {
       if (uom.uomName.name == this.addInventory.alternateUom.name) {
       hasDuplicate = true;
       }
      });
      return hasDuplicate;
    }

     getUoMNames(altUoMList: any = this.alternateUoMsList): void {
        let maxIndex: number = altUoMList.length - 1;
        let selectedUoMs: string = "";
        altUoMList.forEach((uom: AlternateUoMEdit, index: number) => {
          selectedUoMs = index != maxIndex ? (selectedUoMs + uom.uomName + ", ") : (selectedUoMs + uom.uomName);
        });
        this.addInventory.alternativeUomId = selectedUoMs;
      }

  //    getAltUomList() {
  //      this.itemService.getUom().subscribe(data => {
  //      this.altUomList = data;
  //      this.altUom= this.altUomList.find(uom =>uom.name === this.addInventory.alternateUom)
  //    });

  // }
    // Function to show list of AlternateUoM
    getAlternateUomTableList() {
           this.itemService.getAlternateUomList(this.selectedItemId)
           .subscribe(data => {
           this.tableContentsUom = data.body;
           this.tableContentsUom.forEach(uom=> {
               let _uom: AlternateUoMEdit = {alternateUomRowId:uom.id, alternateUomId:uom.alternateUomId, uomName: uom.alternateUomName, conversionFactor: uom.alternateConversionFactor, baseUom:uom.baseUomName,conversionFactorBaseUom: uom.baseUomFactor };
               this.alternateUoMsList.push(_uom);
               });
           this.getUoMNames();
           });
    }

}
