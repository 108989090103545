import { LoaderInterceptor } from './core/http-interceptors/loader-interceptor';
import { LoaderService } from '@app/shared/services/loader.service';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { AuthInterceptor } from './core/http-interceptors/auth-interceptor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
 // import { ManageStockOutModule } from './feature/manage-stock-out/manage-stock-out.module';
//import { HighlightDirective } from '@app/core/directives/max-check.directives';


@NgModule({
  declarations: [
    AppComponent
    //HighlightDirective
  ],
  imports: [
    AngularFontAwesomeModule,
    HttpClientModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    SharedModule,
    BsDatepickerModule.forRoot()



  ],

  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
