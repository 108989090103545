import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuItem, Breadcrumb } from 'primeng/primeng';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';

import { Paginator } from '@app/shared/models/paginator.model';
import * as Constants from '@app/core/constants/common.constants';
import { Col } from '@app/shared/models/table-header.model';

import { AddStockIn, AddStockInModel } from '@app/shared/models/add-stock-in.models';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-manage-stockout-view',
  templateUrl: './manage-stockout-view.component.html',
  styleUrls: ['./manage-stockout-view.component.scss']
})
export class ManageStockoutViewComponent implements OnInit {

  breadcrumb: MenuItem[];
  cols: Col[];

  stockOutDetailsViewList: any = [];
  transactionId: string = undefined;
  addStockInModel = new AddStockInModel();
  datePipe = new DatePipe('en-US');

  paginator: Paginator;
  filterText: string;
  message: any;
  pageCount: number;

  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;

  constructor(
    private stockService: ManageStockService,
    private toastrMsgService: ToastrMsgService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    this.transactionId = this.route.snapshot.paramMap.get('id');

    this.message = Constants.MESSAGES;
    this.pageCount = Constants.PTABLE_PAGE_COUNT;

    this.breadcrumb = [
      { label: 'Manage Stock Out', routerLink: '/manage-stock-out-list' },
      { label: 'View Stock Out', url: '' }
    ];
    this.cols = [
      { field: 'itemCode', header: 'Item Code' },
      { field: 'itemName', header: 'Item Name' },
      { field: 'uom', header: 'UOM' },
      { field: 'location', header: 'Location' },
      { field: 'warehouse', header: 'Warehouse' },
      { field: 'bin', header: 'Bin(Shelf/Rack)' },
      { field: 'batch', header: 'Batch' },
      { field: 'currentStock', header: 'Stock Out Qty' },
      { field: 'amount', header: 'Amount' }
    ];
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
    this.getStockOutDetailByTransaction();
  }

  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      this.filterText = event.sortField;
      // if (this.filterText === 'location') {
      //   this.filterText = 'warehouse.location.name';
      // } else if (this.filterText === 'subcategory') {
      //   this.filterText = 'batch.item.subcategory.name';
      // } else if (this.filterText === 'category') {
      //   this.filterText = 'batch.item.category.name';
      // } else if (this.filterText === 'itemCode') {
      //   this.filterText = 'batch.item.code';
      // } else if (this.filterText === 'itemName') {
      //   this.filterText = 'batch.item.name';
      // }
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = this.filterText + ',DESC';
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = this.filterText + ',ASC';
      }
    } else {
      this.paginator.sortOrder = 'createdDate,DESC';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getStockOutDetailByTransaction();
  }

  onCancel() {
    this.router.navigateByUrl('/manage-stock-out-list');
  }

  getStockOutDetailByTransaction() {
    this.stockService.getTransDetails(this.transactionId).subscribe(
      data => {
        this.addStockInModel.createdDate = this.datePipe.transform(data.createdDate, 'dd-MM-yyyy');
        this.addStockInModel.supplier = data.supplier;
        this.addStockInModel.remarks = data.remarks
        this.stockOutDetailsViewList = data.batchList.map(batch => {
          let processedBatch = {
            'itemCode': batch.item.code,
            'itemName': batch.item.name,
            'uom': batch.uom,
            'location': batch.warehouse.location.name,
            'warehouse': batch.warehouse.name,
            'bin': batch.bin.name,
            'batch': batch.batchNumber,
            'currentStock': batch.stockOutQuantity,
            'amount': batch.amount
          }
          return processedBatch;
        });
      },
      err => {
        console.log(err);
      }
    );
  }
}
