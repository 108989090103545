import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import 'rxjs/add/operator/filter';
import * as moment from 'moment';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { Paginator } from '@app/shared/models/paginator.model';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import * as Constants from '@app/core/constants/common.constants';
import { Col } from '@app/shared/models/table-header.model';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { DatePipe } from '@angular/common';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { AuthService } from '@app/core/services/auth.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { DashboardService } from '@app/core/services/dashboard.service';
import { Dropdown } from 'primeng/primeng';

//import { ItemService } from '@app/core/services/item.service';

@Component({
  selector: 'app-list-reports',
  templateUrl: './list-reports.component.html',
  styleUrls: ['./list-reports.component.scss']
})
export class ListReportsComponent implements AfterViewInit {
  breadcrumb: Breadcrumb[];
  inventoryReportShow: boolean = false;
  csvList: any;
  pageCount: number;
  totalCount: number;
  message: any;
  stockReportList = [];
  itemReportList = []
  stockTake: boolean = false;
  cols: Col[];
  paginator: Paginator;
  csvOptions: any;
  fromDate: Date;
  toDate: Date;
  expiryDate: Date;
  filterByDate: any;
  fromDateFilter: Date;
  toDateFilter: Date;
  csvReport = [];
  tabs = [];
  api: any;
  unpaged: boolean;
  itemNames: any = [];
  itemName: any;
  location: any = [];
  selectedLocation: any;
  itemNme: any;
  locNme: any;
  wareNme: any;
  selectedWare: any;
  wareHouse: any;
  transactionTypes: any = [];
  transactionType: any = { name: 'All', code: Constants.ALL_STOCK_OUT };
  selectedToLocation:any;
  wareNmeTo:any;
  selectedWareTo:any;
  wareHouseTo:any;
  locNmeTo:any;
  constructor(
    public stockService: ManageStockService,
    public dashboardService: DashboardService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
    private toastrMsgService: ToastrMsgService,
    public route: Router,
    private router: ActivatedRoute
  ) {
    this.tabs = [
      {
        id: 1,
        name: 'Stock Take Report',
        active: true,
        date: true,
        api: 'stocktake'
      },
      {
        id: 2,
        name: 'Inventory Report',
        active: false,
        date: true,
        api: 'stockinventory'
      },
      {
        id: 3,
        name: 'Stock Transfer Report',
        active: false,
        date: true,
        api: 'stocktransfer'
      },
      {
        id: 4,
        name: 'Stock In Report',
        active: false,
        date: true,
        api: 'stockin'
      },
      {
        id: 5,
        name: 'Stock Out Report',
        active: false,
        date: true,
        api: 'stockout'
      },
      {
        id: 6,
        name: 'Min Level Report',
        active: false,
        date: false,
        api: 'dashboard-minlist'
      },
      {
        id: 7,
        name: 'Max Level Report',
        active: false,
        date: false,
        api: 'dashboard-maxlist'
      },
      {
        id: 8,
        name: 'Reorder Level Report',
        active: false,
        date: false,
        api: 'dashboard-reorderlist'
      },
      {
        id: 9,
        name: 'Batch Report',
        active: false,
        date: false,
        api: 'expiry-report'
      },
      {
        id: 10,
        name: 'Auto Consumption Report',
        active: false,
        date: false,
        api: 'log-report'
      }
    ];
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Stock Take List :',
      useBom: true,
      noDownload: false,
      headers: []
    };
  }

  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt1') dataTable: Table;
  @ViewChild('ddStatusName') ddStatusName: Dropdown;
  @ViewChild('ddStatusCode') ddStatusCode: Dropdown;
  @ViewChild('ddStatusWare') ddStatusWare: Dropdown;
  @ViewChild('ddStatusLoc') ddStatusLoc: Dropdown;

  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.breadcrumb = [{ label: 'Manage Reports', routerLink: '' }];
    this.transactionTypes = [
      { name: 'All', code: Constants.ALL_STOCK_OUT },
      { name: 'Production Issue', code: Constants.PROD_ISSUE },
      { name: 'Stock Out', code: Constants.STOCKOUT },
      { name: 'Process Order', code: Constants.PROCESS_ORDER }
    ];
    this.cols = [];
    this.unpaged = false;
    this.itemNameDetails();
    this.locationDetails();
    this.paginatorInitial();
    this.setupVaribleInitial();
  }
  setupVaribleInitial()
  {
    this.fromDate=new Date();
   this.fromDate.setMonth(this.fromDate.getMonth()-1);
    this.toDate=new Date();
  }
  search()
  {
    try {
      var sixMonthBefore = moment(this.toDate).subtract(6, 'months');
    var moment2=moment(this.fromDate);
   if(sixMonthBefore.isAfter(moment2)){
    this.toastrMsgService.warning(
      'More than 6 Months',
      this.message.moreThanSixMonths,
      this.message.toastrTime
    );
    return;
   } 
    } catch (error) {
      
    }    
   
    try {
      this.fromDateFilter = new Date(this.fromDate.setHours(0, 0, 0));
      this.toDateFilter = new Date(this.toDate.setHours(23, 59, 59));
   } catch (error) { }
    
    this.paginatorInitial();
    this.primePaginator.first = 0;
    this.tabs.forEach(item => {
      if (item.active === true) {
        this.colsBasedReports(item);
      }
    });
  }
  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  onTransactionChange() {
    this.paginatorInitial();
    this.primePaginator.first = 0;
    this.tabs.forEach(item => {
      if (item.active === true) {
        this.colsBasedReports(item);
      }
    });
  }

  itemNameDetails() {
    this.stockService.getItemNames().subscribe(
      (data: any) => {
        this.itemNames = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  locationDetails() {
    this.stockService.getLocation().subscribe(
      (data: any) => {
        this.location = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  warehouseDetails() {
    this.stockService.getWarehouse(this.selectedLocation).subscribe(
      (data: any) => {
        this.wareHouse = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  warehouseToDetails() {
    this.stockService.getWarehouse(this.selectedToLocation).subscribe(
      (data: any) => {
        this.wareHouseTo = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  locChange() {
    this.locNme = this.selectedLocation.name;
    this.warehouseDetails();
    this.paginatorInitial();
    this.primePaginator.first = 0;
    
  }
  
  locChangeTo() {
    this.locNmeTo = this.selectedToLocation.name;
    this.warehouseToDetails();
    this.paginatorInitial();
    this.primePaginator.first = 0;
    
  }
 locToChange() {
    this.locNmeTo = this.selectedToLocation.name;
    this.warehouseDetails();
    this.paginatorInitial();
    this.primePaginator.first = 0;
}
 wareChange() {
    this.wareNme = this.selectedWare.name;
  }
  wareToChange() {
    this.wareNmeTo = this.selectedWareTo.name;
  }
  clear() {
    this.fromDate = null;
    this.toDate = null;
    this.itemName = undefined;
    this.itemNme = undefined;
    this.selectedLocation = undefined;
    this.selectedToLocation=undefined;
    this.locNmeTo=undefined;
    this.locNme = undefined;
    this.selectedWare = undefined;
    this.wareNme = undefined;
    this.selectedWareTo=undefined;
    this.wareHouseTo=undefined;
    this.wareNmeTo=undefined;
    this.fromDateFilter = undefined;
    this.toDateFilter = undefined;
    this.paginatorInitial();
    this.primePaginator.first = 0;
    this.tabs.forEach(item => {
      if (item.active === true) {
        this.colsBasedReports(item);
      }
    });
  }
  fromDateChange(event: any) {
    this.fromDateFilter = new Date(this.fromDate.setHours(0, 0, 0));
  }
  toDateChange(event: any) {
    this.toDateFilter = new Date(this.toDate.setHours(23, 59, 59));
  }

  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  binClick(event: any) {
    if (event === 'ddStatusName') {
      this.ddStatusName.filled = true;
    } else if (event === 'ddStatusCode') {
      this.ddStatusCode.filled = true;
    }  else if (event === 'ddStatusWare') {
      this.ddStatusWare.filled = true;
    } else if (event === 'ddStatusLoc') {
      this.ddStatusLoc.filled = true;
    }
  }
  itemNameFilter() {
    this.itemNme = this.itemName.name;
    }
  dashboardActive(level: any) {
    this.tabs.forEach(item => {
      if (item.id === level) {
        item.active = true;
        this.api = item.api;
        this.colsBasedReports(item);
      } else {
        item.active = false;
      }
    });
  }
  dashboardReports(level: any) {
    switch (level) {
      case 6: {
        this.dashboardActive(level);
        break;
      }
      case 7: {
        this.dashboardActive(level);
        break;
      }
      case 8: {
        this.dashboardActive(level);
        break;
      }
      default: {
        break;
      }
    }
  }
  colsBasedReports(itemSelected: any) {
    try {
      this.fromDateFilter = new Date(this.fromDate.setHours(0, 0, 0));
    this.toDateFilter = new Date(this.toDate.setHours(23, 59, 59));
    } catch (error) {
      
    }
    this.cols = [];
    this.tabs.forEach(item => {
      if (item.id !== itemSelected.id) {
        item.active = false;
      } else {
        item.active = true;

        switch (item.api) {
          case 'stocktake': {
            this.inventoryReportShow = false;
            this.stockTakeCols();
            this.stockReportDetails(this.paginator, itemSelected);
            break;
          }
          case 'stockinventory': {
            this.inventoryReportShow = true;
            break;
          }
          case 'stocktransfer': {
            this.inventoryReportShow = false;
            this.stockTransferCols();
            this.stockReportDetails(this.paginator, itemSelected);
            break;
          }
          case 'stockin': {
            this.inventoryReportShow = false;
            this.stockInCols();
            this.stockReportDetails(this.paginator, itemSelected);
            break;
          }
          case 'stockout': {
            this.inventoryReportShow = false;
            this.stockOutCols();
            this.stockReportDetails(this.paginator, itemSelected);
            break;
          }
          case 'dashboard-minlist': {
            this.inventoryReportShow = false;
            this.minCols();
            this.stockReportDetails(this.paginator, itemSelected);
            break;
          }
          case 'dashboard-maxlist': {
            this.inventoryReportShow = false;
            this.maxCols();
            this.stockReportDetails(this.paginator, itemSelected);
            break;
          }
          case 'dashboard-reorderlist': {
            this.inventoryReportShow = false;
            this.reorderCols();
            this.stockReportDetails(this.paginator, itemSelected);
            break;
          }
          case 'expiry-report': {
            this.inventoryReportShow = false;
            break;
          }
          case 'log-report':{
            this.inventoryReportShow =false;
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  }

  onClick(itemSelected: any) {
   this.clearallfields();
    itemSelected.active = true;
    this.api = itemSelected.api;
    this.dashboardService.setReportIdAs('');
    this.paginatorInitial();
    if (itemSelected.api !== 'stockinventory' && this.primePaginator !== undefined) {
      this.primePaginator.first = 0;
    }
    this.colsBasedReports(itemSelected);
  }
  clearallfields()
  {
    this.fromDate = null;
    this.toDate = null;
    this.itemName = undefined;
    this.itemNme = undefined;
    this.selectedLocation = undefined;
    this.locNme = undefined;
    this.selectedWare = undefined;
    this.wareNme = undefined;
    this.selectedLocation =
    this.fromDateFilter = undefined;
    this.toDateFilter = undefined;
    this.setupVaribleInitial();
  }
  minCols() {
    this.cols = [
      { field: 'itemCode', header: 'Item Code', width: 'auto' },
      { field: 'itemName', header: 'Item Name', width: 'auto' },
      { field: 'uom', header: 'UOM', width: 'auto' },
      { field: 'location', header: 'Location', width: 'auto' },
      { field: 'availableSum', header: 'In Stock', width: "90px" },
      { field: 'min', header: 'Min Level', width: "90px" },
      { field: 'categoryName', header: 'Category', width: 'auto' },
      { field: 'subcategoryName', header: 'Subcategory', width: 'auto' }
    ];
  }
  maxCols() {
    this.cols = [
      { field: 'itemCode', header: 'Item Code', width: 'auto' },
      { field: 'itemName', header: 'Item Name', width: 'auto' },
      { field: 'uom', header: 'UOM', width: 'auto' },
      { field: 'location', header: 'Location', width: 'auto' },
      { field: 'availableSum', header: 'In Stock', width: "90px" },
      { field: 'max', header: 'Max Level', width: "90px" },
      { field: 'categoryName', header: 'Category', width: 'auto' },
      { field: 'subcategoryName', header: 'Subcategory', width: 'auto' }
    ];
  }
  reorderCols() {
    this.cols = [
      { field: 'itemCode', header: 'Item Code', width: 'auto' },
      { field: 'itemName', header: 'Item Name', width: 'auto' },
      { field: 'uom', header: 'UOM', width: 'auto' },
      { field: 'location', header: 'Location', width: 'auto' },
      { field: 'availableSum', header: 'In Stock', width: "90px" },
      { field: 'reorder', header: 'Reorder Level', width: "110px" },
      { field: 'reorderQty', header: 'Reorder Quantity', width: "120px" },
      { field: 'categoryName', header: 'Category', width: 'auto' },
      { field: 'subcategoryName', header: 'Subcategory', width: 'auto' }
    ];
  }
  stockTakeCols() {
    this.cols = [
      { field: 'transactionNumber', header: 'Transaction#',  width: "150px" },
      { field: 'createdDate', header: 'Created Date', width: "100px" },
      { field: 'itemName', header: 'Item Name', width: "90px" },
      { field: 'subcategory', header: 'Subcategory', width: "100px" },
      { field: 'oldInStock', header: 'Old In Stock', width: "90px" },
      { field: 'inStock', header: 'Stock Take', width: "90px" },
      { field: 'uom', header: 'UOM', width: "70px" },
      { field: 'amount', header: 'Value', width: "70px"},
      { field: 'difference', header: 'Variance Qty', width: "80px" },
      { field: 'variancePercent', header: 'Variance Qty %', width: "110px" },
      { field: 'varianceValue', header: 'Variance Value', width: "110px" },
      { field: 'location', header: 'Location', width: "100px" },
      { field: 'warehouseName', header: 'Warehouse', width: "100px" },
      { field: 'binName', header: 'Bin(Shelf/Rack)', width: "110px" },
      { field: 'batchNumber', header: 'Batch', width: "100px" }
    ];
  }
  stockOutCols() {
    this.cols = [
      { field: 'transactionType', header: 'Transaction Type',  width: "120px" },
      { field: 'transactionNumber', header: 'Transaction#',  width: "150px" },
      { field: 'createdDate', header: 'Created Date',  width: "100px" },
      { field: 'subcategory', header: 'Subcategory',  width: "100px" },
      { field: 'itemName', header: 'Item Name',  width: "90px" },
      { field: 'uom', header: 'UOM',  width: "70px" },
      { field: 'inStock', header: 'In Stock',  width: "90px" },
      { field: 'oldInStock', header: 'Old Stock',  width: "90px" },
      { field: 'difference', header: 'Stock Out Qty',  width: "110px" },
      { field: 'amount', header: 'Amount',  width: "90px"},
      { field: 'batchNumber', header: 'Batch#',  width: "90px" },
      { field: 'location', header: 'Location',  width: "90px" },
      { field: 'warehouseName', header: 'Warehouse',  width: "90px" },
      { field: 'binName', header: 'Bin(Shelf/Rack)',  width: "110px" }
    ];
    if (this.authService.getRole() === 'ROLE_SUPPLIER') {
      this.cols.splice(4, 1);
    }
  }
  stockInCols() {
    this.cols = [
      { field: 'transactionType', header: 'Transaction Type',  width: "140px" },
      { field: 'transactionNumber', header: 'Transaction#',  width: "150px" },
      { field: 'createdDate', header: 'Created Date',  width: "100px" },
      { field: 'supplier', header: 'Supplier',  width: "90px" },
      { field: 'location', header: 'Location',  width: "90px" },
      { field: 'warehouseName', header: 'Warehouse',  width: "90px" },
      { field: 'itemName', header: 'Item Name',  width: "90px" },
      { field: 'uom', header: 'UOM',  width: "70px" },
      { field: 'rate', header: 'Rate',  width: "110px" },
      { field: 'inStock', header: 'Quantity',  width: "100px" },
      { field: 'amount', header: 'Amount',  width: "90px" },
      { field: 'remarks', header: 'Remarks',  width: "90px" },
      { field: 'ezyprocurePoUom', header: 'Buy Uom',  width: "90px" },
      { field: 'ezyprocurePoQty', header: 'Buy Quantity',  width: "100px" },
      { field: 'binName', header: 'Bin(Shelf/Rack)',  width: "120px" },
      { field: 'batchNumber', header: 'Batch#',  width: "90px" },
      { field: 'productionDate', header: 'Production Date',  width: "140px" },
      { field: 'expiryDate', header: 'Expiry Date',  width: "90px" }
    ];
    if (this.authService.getRole() === 'ROLE_SUPPLIER') {
      this.cols.splice(4, 1);
    }
  }
  stockTransferCols() {
    this.cols = [
      { field: 'transactionNumber', header: 'Transaction#',  width: "150px" },
      { field: 'createdDate', header: 'Created Date', width: "100px" },
      { field: 'item', header: 'Item Name', width: "90px" },
      { field: 'uom', header: 'UOM', width: "70px" },
      { field: 'sourceLocation', header: 'Location(Src.)', width: "100px" },
      { field: 'sourceWarehouse', header: 'Warehouse(Src.)', width: "130px" },
      { field: 'sourceBin', header: 'Bin(Shelf/Rack)(Src.)', width: "140px" },
      { field: 'transferQuantity', header: 'Transfer Quantity', width: "130px" },
      { field: 'quantity', header: 'Quantity', width: "90px" },
      { field: 'value', header: 'Amount', width: "70px"},
      { field: 'batchNumber', header: 'Batch', width: "70px"},
      { field: 'destinationLocation', header: 'Location(Dest.)', width: "110px" },
      { field: 'destinationWarehouse', header: 'Warehouse(Dest.)', width: "120px" },
      { field: 'destinationBin', header: 'Bin(Shelf/Rack)(Dest.)', width: "10px" },
      { field: 'comment', header: 'Remarks', width: "90px" }
    ];
  }
  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ',DESC';
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ',ASC';
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    var id = this.dashboardService.getReportId();
    if (id !== '') {
      this.dashboardReports(id);
    } else {
      this.tabs.forEach(item => {
        if (item.active === true) {
          this.api = item.api;
          this.colsBasedReports(item);
        }
      });
    }
  }

  stockReportDetails(lazyLoadData: Paginator, item: any) {
    this.stockReportList = [];
    let filderDates={};
    
    if (this.fromDateFilter !== undefined && this.toDateFilter !== undefined) {
      filderDates["fromDate"]=this.fromDateFilter.toISOString();
      filderDates["toDate"]=this.toDateFilter.toISOString();
    }
    if (this.locNme !== undefined ){
      filderDates["sourceLocation"]=this.locNme;
    }
    if(this.wareNme !== undefined) {
     filderDates["sourceWarehouse"]=this.wareNme;
    }
    if (this.locNmeTo !== undefined){
      filderDates["destinationLocation"]=this.locNmeTo;
    }
    if (this.wareNmeTo !== undefined) {
     filderDates["destinationWarehouse"]=this.wareNmeTo;
    }
    this.filterByDate = JSON.stringify(filderDates);
    this.stockService
      .getReportDetails(
        item.api,
        this.itemNme,
        this.locNme,
        this.wareNme,
        lazyLoadData.pageNo,
        lazyLoadData.limit,
        this.filterByDate,
        lazyLoadData.sortOrder,
        this.transactionType.code
      )
      .subscribe(
        (data: HttpResponse<any>) => {
          this.stockReportList = data.body;
          this.csvList = [];
          this.stockReportList.forEach(itemReport => {
            if (!itemReport.createdDate) {
            } else {
              itemReport.createdDate = this.datePipe.transform(
                new Date(itemReport.createdDate),
                'dd-MM-yyyy'
              );
            }
            if (!itemReport.updatedDate) {
            } else {
              itemReport.updatedDate = this.datePipe.transform(
                new Date(itemReport.updatedDate),
                'dd-MM-yyyy'
              );
            }
            if (!itemReport.productionDate) {
            } else {
              itemReport.productionDate = this.datePipe.transform(
                new Date(itemReport.productionDate),
                'dd-MM-yyyy'
              );
            }
            if (!itemReport.expiryDate) {
            } else {
              itemReport.expiryDate = this.datePipe.transform(
                new Date(itemReport.expiryDate),
                'dd-MM-yyyy'
              );
            }
            itemReport.category = itemReport.categoryName;
            itemReport.subcategory = itemReport.subcategoryName;
            itemReport.location = itemReport.locationName;
            itemReport.uom = !itemReport.inventoryUom ? itemReport.uom : itemReport.inventoryUom;
            itemReport.rate = itemReport.price;
            // variance percent calculation
            if(itemReport.oldInStock > 0) {
              itemReport.variancePercent = (((itemReport.inStock - itemReport.oldInStock) * 100)/itemReport.oldInStock).toFixed(2);
            }
            else {
              itemReport.variancePercent = itemReport.inStock;
            }
            itemReport.variance = Math.sign(itemReport.inStock - itemReport.oldInStock);
            itemReport.difference = itemReport.variance * itemReport.difference;
            itemReport.varianceValue = ((itemReport.inStock * itemReport.price)-(itemReport.oldInStock * itemReport.price)).toFixed(3);
          });

          this.totalCount = Number(data.headers.get('X-Total-Count'));

          if (this.totalCount <= this.pageCount) {
            this.paginator.paginator = false;
          } else {
            this.paginator.paginator = true;
          }
          this.paginator.loading = false;
          this.paginator.totalCount = this.totalCount;
        },
        (err: any) => {
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              '',
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
          this.paginator.loading = false;
        }
      );
  }
  csvDownload(item: any) {
    this.unpaged = true;
    if (this.fromDateFilter !== undefined && this.toDateFilter !== undefined) {
      this.filterByDate = JSON.stringify({
        fromDate: this.fromDateFilter.toISOString(),
        toDate: this.toDateFilter.toISOString()
      });
    } else {
      this.filterByDate = null;
    }
    this.stockService.getCSVDownload(
      item.api,
      this.itemNme,
      this.locNme,
      this.wareNme,
      this.filterByDate, this.unpaged, this.transactionType.code).subscribe(
      (data: HttpResponse<any>) => {
        this.csvReport = data.body;
        if(this.csvReport.length==0)
        {
          this.toastrMsgService.warning('CSV not exported',this.message.noData,this.message.toastrTime);
          return
        }
        this.csvReport.forEach(itemReport => {
          if (!itemReport.createdDate) {
          } else {
            itemReport.createdDate = this.datePipe.transform(
              new Date(itemReport.createdDate),
              'dd-MM-yyyy'
            );
          }
          if (!itemReport.updatedDate) {
          } else {
            itemReport.updatedDate = this.datePipe.transform(
              new Date(itemReport.updatedDate),
              'dd-MM-yyyy'
            );
          }
          if (!itemReport.productionDate) {
          } else {
            itemReport.productionDate = this.datePipe.transform(
              new Date(itemReport.productionDate),
              'dd-MM-yyyy'
            );
          }
          if (!itemReport.expiryDate) {
          } else {
            itemReport.expiryDate = this.datePipe.transform(
              new Date(itemReport.expiryDate),
              'dd-MM-yyyy'
            );
          }
        });
        if (item.api === 'stocktake') {
          this.csvStockTakeDetails(item, this.csvReport);
        } else if (item.api === 'stocktransfer') {
          this.csvStockTransferDetails(item, this.csvReport);
        } else if (item.api === 'stockin') {
          this.csvStockInDetails(item, this.csvReport);
        } else if (item.api === 'stockout') {
          this.csvStockOutDetails(item, this.csvReport);
        } else if (item.api === 'dashboard-minlist') {
          this.csvStockMinDetails(item, this.csvReport);
        } else if (item.api === 'dashboard-maxlist') {
          this.csvStockMaxDetails(item, this.csvReport);
        } else if (item.api === 'dashboard-reorderlist') {
          this.csvStockReorderDetails(item, this.csvReport);
        }
      },
      (err: any) => {}
    );
  }
  csvStockReorderDetails(item: any, csvReport: any) {
    this.csvList = [];
    csvReport.forEach(itemReport => {
      this.csvOptions.headers = [
        'Item Code',
        'Item Name',
        'UOM',
        'Location',
        'Total Available',
        'Reorder Level',
        'Reorder Quantity',
        'Category',
        'Subcategory',
      ];
      this.csvList.push({
        'Item Code': itemReport.itemCode,
        'Item Name': itemReport.itemName,
        UOM: itemReport.uom,
        Location: itemReport.locationName,
        'Total Available': itemReport.availableSum,
        'Reorder level': itemReport.reorder,
        'Reorder Quantity': itemReport.reorderQty,
        Category: itemReport.categoryName,
        Subcategory: itemReport.subcategoryName,
      });
    });
    this.generateCSV();
  }
  csvStockMaxDetails(item: any, csvReport: any) {
    this.csvList = [];
    csvReport.forEach(itemReport => {
      this.csvOptions.headers = [
        'Item Code',
        'Item Name',
        'UOM',
        'Location',
        'Total Available',
        'Max Level',
        'Category',
        'Subcategory',
      ];
      this.csvList.push({
        'Item Code': itemReport.itemCode,
        'Item Name': itemReport.itemName,
        UOM: itemReport.uom,
        Location: itemReport.locationName,
        'Total Available': itemReport.availableSum,
        'Max level': itemReport.max,
        Category: itemReport.categoryName,
        Subcategory: itemReport.subcategoryName
      });
    });
    this.generateCSV();
  }
  csvStockMinDetails(item: any, csvReport: any) {
    this.csvList = [];
    csvReport.forEach(itemReport => {
      this.csvOptions.headers = [
        'Item Code',
        'Item Name',
        'UOM',
        'Location',
        'Total Available',
        'Min Level',
        'Category',
        'Subcategory'

      ];
      this.csvList.push({
        'Item Code': itemReport.itemCode,
        'Item Name': itemReport.itemName,
        UOM: itemReport.uom,
        Location: itemReport.locationName,
        'Total Available': itemReport.availableSum,
        'Min level': itemReport.min,
        Category: itemReport.categoryName,
        Subcategory: itemReport.subcategoryName
      });
    });
    this.generateCSV();
  }
  csvStockTakeDetails(item: any, csvReport: any) {
    this.csvList = [];
    csvReport.forEach(itemReport => {
      this.csvOptions.headers = [
        'Transaction#',
        'Created Date',
        'Item Name',
        'Subcategory',
        'Old In Stock',
        'Stock Take',
        'UOM',
        'Value',
        'Variance Qty',
        'Variance Qty %',
        'Variance Value',
        'Location',
        'Warehouse',
        'Bin(Shelf/Rack)',
        'Batch#'
      ];
      // variance percent calculation
      if(itemReport.oldInStock > 0) {
        itemReport.variancePercent = (((itemReport.inStock - itemReport.oldInStock) * 100)/itemReport.oldInStock).toFixed(2);
      }
      else {
        itemReport.variancePercent = itemReport.inStock;
      }
      itemReport.variance = Math.sign(itemReport.inStock - itemReport.oldInStock);
      itemReport.difference = itemReport.variance * itemReport.difference;
      itemReport.varianceValue = ((itemReport.inStock * itemReport.price)-(itemReport.oldInStock * itemReport.price)).toFixed(3);
      this.csvList.push({
        'Transaction#': itemReport.transactionNumber,
        'Created Date': itemReport.createdDate,
        'Item Name': itemReport.itemName,
        Subcategory: itemReport.subcategoryName,
        'Old In Stock': itemReport.oldInStock,
        'Stock Take': itemReport.inStock,
        'UOM': itemReport.inventoryUom,
        'Value': itemReport.amount,
        varianceQty: itemReport.difference,
         variancePercent: itemReport.variancePercent,
         varianceValue: itemReport.varianceValue,
         Location: itemReport.locationName,
         Warehouse: itemReport.warehouseName,
         Bin: itemReport.binName || '',
        'Batch#': itemReport.batchNumber || '',
      });
    });
    this.generateCSV();
  }
  csvStockTransferDetails(item: any, csvReport: any) {
    this.csvList = [];
    csvReport.forEach(itemReport => {
      this.csvOptions.headers = [
        'Transaction#',
        'Created Date',
        'Item Name',
        'UOM',
        'Location(Src.)',
        'Warehouse(Src.)',
        'Bin(Shelf/Rack)(Src.)',
        'Transfer Quantity',
        'Quantity',
        'Amount',
        'Batch',
        'Location(Dest.)',
        'Warehouse(Dest.)',
        'Bin(Shelf/Rack)(Dest.)',
        'Remarks'
      ];
      this.csvList.push({
        'Transaction#': itemReport.transactionNumber,
        'Created Date': itemReport.createdDate,
        Item: itemReport.item,
        UOM: itemReport.uom,
        'Location(Src.)': itemReport.sourceLocation,
        'Warehouse(Src.)': itemReport.sourceWarehouse,
        'Bin(Shelf/Rack)(Src.)': itemReport.sourceBin,
        'Transfer Quantity': itemReport.transferQuantity,
        Quantity: itemReport.quantity,
        'Amount': itemReport.value != null ? itemReport.value.toFixed(3) : "",
        'Batch': itemReport.batchNumber,
        'Location(Dest.)': itemReport.destinationLocation,
        'Warehouse(Dest.)': itemReport.destinationWarehouse,
        'Bin(Shelf/Rack)(Dest.)': itemReport.destinationBin,
        Remarks: itemReport.comment
      });
    });
    this.generateCSV();
  }
  csvStockInDetails(item: any, csvReport: any) {
    this.csvList = [];
    csvReport.forEach(itemReport => {
      this.csvOptions.headers = [
        'Transaction Type',
        'Transaction#',
        'Created Date',
        'Supplier',
        'Location',
        'Warehouse',
        'Item Name',
        'UOM',
        'Rate',
        'Quantity',
        'Amount',
        'Remarks',
        'Buy Uom',
        'Buy Quantity',
        'Bin(Shelf/Rack)',
        'Batch#',
        'Production Date',
        'Expiry Date'
      ];
      this.csvList.push({
        'Transaction Type': itemReport.transactionType,
        'Transaction#': itemReport.transactionNumber,
        'Created Date': itemReport.createdDate,
        Supplier: itemReport.supplier,
        Location: itemReport.locationName,
        Warehouse: itemReport.warehouseName,
        'Item Name': itemReport.itemName,
        UOM: itemReport.inventoryUom,
        'Rate': itemReport.price,
        'Quantity': itemReport.inStock,
        'Amount': itemReport.amount != null ? itemReport.amount.toFixed(3) : "",
        Remarks: itemReport.remarks || '',
        'Buy Uom': itemReport.ezyprocurePoUom || '',
        'Buy Quantity': itemReport.ezyprocurePoQty != null ? itemReport.ezyprocurePoQty.toFixed(3) : '',
        Bin: itemReport.binName || '',
        'Batch#': itemReport.batchNumber || '',
        'Production Date': itemReport.productionDate || '',
        'Expiry Date': itemReport.expiryDate || ''
      });
    });
    if (this.authService.getRole() === 'ROLE_SUPPLIER') {
      this.csvList = [];
      csvReport.forEach(itemReport => {
        this.csvOptions.headers = [
          'ID',
          'Transaction#',
          'Item Name',
          'Batch#',
          'Category',
          'Subcategory',
          'Location',
          'Warehouse',
          'Bin(Shelf/Rack)',
          'In Stock',
          'Available',
          'Allocated',
          'On Order',
          'Created Date',
          'Production Date',
          'Expiry Date',
          'Remarks'
        ];
        this.csvList.push({
          Id: itemReport.id,
          'Transaction#': itemReport.transactionNumber,
          'Item Name': itemReport.itemName,
          'Batch#': itemReport.batchNumber,
          Category: itemReport.category,
          Subcategory: itemReport.subcategory,
          Location: itemReport.location,
          Warehouse: itemReport.warehouseName,
          Bin: itemReport.binName,
          'In Stock': itemReport.inStock,
          Available: itemReport.available,
          Allocated: itemReport.allocated,
          'On Order': itemReport.onOrder,
          'Created Date': itemReport.createdDate,
          'Production Date': itemReport.productionDate,
          'Expiry Date': itemReport.expiryDate,
          Remarks: itemReport.remarks
        });
      });
    }
    this.generateCSV();
  }
  csvStockOutDetails(item: any, csvReport: any) {
    this.csvList = [];
    csvReport.forEach(itemReport => {
      this.csvOptions.headers = [
        'Transaction Type',
        'Transaction#',
        'Created Date',
        'Subcategory',
        'Item Code',
        'Item Name',
        'UOM',
        'In Stock',
        'Old Stock',
        'Stock Out Qty',
        'Value',
        'Batch#',
        'Location',
        'Warehouse',
        'Bin(Shelf/Rack)',
        'Remarks'
      ];
      this.csvList.push({
        'Transaction Type': itemReport.transactionType,
        'Transaction#': itemReport.transactionNumber,
        'Created Date': itemReport.createdDate,
        Subcategory: itemReport.subcategoryName,
        'Item Code': itemReport.itemCode,
        'Item Name': itemReport.itemName,
        'UOM': itemReport.inventoryUom,
        'Stock Out': itemReport.inStock,
        'Old Stock': itemReport.oldInStock,
        'Stock Out Qty': itemReport.difference,
        'Value': itemReport.amount != null ? itemReport.amount.toFixed(3) : "",
        'Batch#': itemReport.batchNumber || '',
        Location: itemReport.locationName,
        Warehouse: itemReport.warehouseName,
        Bin: itemReport.binName || "",
        Remarks: itemReport.remarks || ""
      });
    });
    if (this.authService.getRole() === 'ROLE_SUPPLIER') {
      this.csvList = [];
      csvReport.forEach(itemReport => {
        this.csvOptions.headers = [
          'ID',
          'Transaction#',
          'Item Name',
          'Batch#',
          'Category',
          'Subcategory',
          'Location',
          'Warehouse',
          'Bin(Shelf/Rack)',
          'In Stock',
          'Old Stock',
          'Stock Out Qty',
          'Updated Date'
        ];
        this.csvList.push({
          Id: itemReport.id,
          'Transaction#': itemReport.transactionNumber,
          'Item Name': itemReport.itemName,
          'Batch#': itemReport.batchNumber,
          Category: itemReport.category,
          Subcategory: itemReport.subcategory,
          Location: itemReport.location,
          Warehouse: itemReport.warehouseName,
          Bin: itemReport.binName,
          'In Stock': itemReport.inStock,
          'Old Stock': itemReport.oldInStock,
          'Stock Out Qty': itemReport.difference,
          'Updated Date': itemReport.updatedDate
        });
      });
    }
    this.generateCSV();
  }
  generateCSV() {
    this.tabs.forEach(item => {
      if (item.active === true) {
        if (item.api === 'stocktake') {
          this.csvOptions.title = 'Stock Take Report:';
          new AngularCsv(this.csvList, 'Stock-Take-Report', this.csvOptions);
        } else if (item.api === 'stockin') {
          this.csvOptions.title = 'Stock In Report:';
          new AngularCsv(this.csvList, 'Stock-In-Report', this.csvOptions);
        } else if (item.api === 'stockout') {
          this.csvOptions.title = 'Stock Out Report:';
          new AngularCsv(this.csvList, 'Stock-Out-Report', this.csvOptions);
        } else if (item.api === 'stocktransfer') {
          this.csvOptions.title = 'Stock Transfer Report:';
          new AngularCsv(
            this.csvList,
            'Stock-transfer-Report',
            this.csvOptions
          );
        } else if (item.api === 'dashboard-minlist') {
          this.csvOptions.title = 'Min Report:';
          new AngularCsv(this.csvList, 'Min-Report', this.csvOptions);
        } else if (item.api === 'dashboard-maxlist') {
          this.csvOptions.title = 'Max Report:';
          new AngularCsv(this.csvList, 'Max-Report', this.csvOptions);
        } else if (item.api === 'dashboard-reorderlist') {
          this.csvOptions.title = 'Reorder Report:';
          new AngularCsv(this.csvList, 'Reorder-Report', this.csvOptions);
        }
      }
    });
  }
  
  downloadCSV() {
    this.tabs.forEach(item => {
      if (item.active === true) {
        this.csvDownload(item);
      }
    });
  }
  moveleft() {
    document.getElementsByClassName(
      'ui-table-scrollable-body'
    )[0].scrollLeft -= 200;
  }
  moveright() {
    document.getElementsByClassName(
      'ui-table-scrollable-body'
    )[0].scrollLeft += 200;
  }
  customSort(event: any) {}



  /*itemReportDetails(lazyLoadData: Paginator, item: any) {
      /*if (this.fromDateFilter !== undefined && this.toDateFilter !== undefined) {
        this.filterByDate = JSON.stringify({
          fromDate: this.fromDateFilter.toISOString(),
          toDate: this.toDateFilter.toISOString()
        });*/
      //} else {
        //this.filterByDate = null;
      //}
     /* this.itemService.getReportDetails(
          this.itemCode,
          this.itemName,
         // this.cost,
          //this.minLevel,
          //this.maxLevel,
          //this.reOrderLevel,
          //lazyLoadData.pageNo,
          //lazyLoadData.limit,
          //this.filterByDate,
          //lazyLoadData.sortOrder
        )*/
        /*.subscribe(
          (data: HttpResponse<any>) => {
            this.itemReportList = data.body;

            this.csvList = [];*/

           /*this.itemReportList.forEach(itemReport => {
              if (!itemReport.createdDate) {
              } else {
                itemReport.createdDate = this.datePipe.transform(
                  new Date(itemReport.createdDate),
                  'dd-MM-yyyy'
                );
              }
              if (!itemReport.updatedDate) {
              } else {
                itemReport.updatedDate = this.datePipe.transform(
                  new Date(itemReport.updatedDate),
                  'dd-MM-yyyy'
                );
              }
              if (!itemReport.productionDate) {
              } else {
                itemReport.productionDate = this.datePipe.transform(
                  new Date(itemReport.productionDate),
                  'dd-MM-yyyy'
                );
              }
              if (!itemReport.expiryDate) {
              } else {
                itemReport.expiryDate = this.datePipe.transform(
                  new Date(itemReport.expiryDate),
                  'dd-MM-yyyy'
                );
              }
            });*/

}
