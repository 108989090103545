import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/services/auth.service';
import { environment } from '@env/environment';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';

@Injectable()
export class AuthInterceptor   implements HttpInterceptor {
  private token: string;
  private data: any;
  message: any;
  private viaEzp = 0;
  private api = environment.API_ENDPOINT;
  private apiEp = environment.EP_API_ENDPOINT;
  constructor(private authService: AuthService,private toastrMsgService: ToastrMsgService,
    ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   // Assume your authorization token is aleady saved in localstorage
    // check token is available or not in localstorage
    this.token = this.authService.getToken();
    if (this.token) {
      req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${this.token}`) });
    }
    // Set/Specify certain media types which are acceptable for the response
    req = req.clone({ headers: req.headers.set('Accept', 'application/json')});
    if (req.headers.get('api-end-type') === 'ezyprocure') {

      req.headers.delete('api-end-type');
      req = req.clone({
        url: `${this.apiEp}${req.url}`
      });

    }

    else {

      req = req.clone({
        url: `${this.api}${req.url}`
      });
    }
    this.message = Constants.MESSAGES;

    // Set API end points with request url
    // this.authService.apiEndIdentification.subscribe((data: any) => {
    //   this.data = data;
    // });
    // if (this.data === 1) {
    //   req = req.clone({
    //     url: `${this.apiEp}${req.url}`
    //   });
    //   this.authService.setApiEndIdentification(null);
    // }
    // else {
    //   req = req.clone({
    //     url: `${this.api}${req.url}`
    //   });
    // }
    return next.handle(req);

   }

}
