import { Directive, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

@Directive({
  selector: '[appLabelControl]'
})
export class LabelControlDirective implements OnInit, OnDestroy {

  valueOfTheForm: Subscription;

  constructor(
    private el: ElementRef,
    private formControlName: FormControlName // Inject FormControlName
  ) {

  }

  ngOnInit() {
    this.valueOfTheForm = this.formControlName.valueChanges.subscribe(value => {

      const inputFields = this.el.nativeElement;
      inputFields.classList.toggle('ui-state-filled', value);
      inputFields.classList.toggle('ui-inputwrapper-filled', value);

    });
  }
  ngOnDestroy() {
    this.valueOfTheForm.unsubscribe();
  }

}

