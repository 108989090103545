import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { of, BehaviorSubject, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '@app/shared/models/user.model';
import { distinctUntilChanged } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
const httpOptions = {
  headers: new HttpHeaders({
    'api-end-type': 'ezyprocure'
  })
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public apiEndIdentification = new BehaviorSubject(null);
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable();
  // .pipe(distinctUntilChanged())
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();


  constructor(public http: HttpClient, private router: Router) { }
  getToken() {
    return localStorage.getItem('token');
  }
  setToken(token: string) {
    localStorage.setItem('token', token);
  }
  setRole(role: string) {
    localStorage.setItem('role', role);
  }

  setApiEndIdentification(id: any) {
    this.apiEndIdentification.next(id);
  }
  getAuthenticatedUserDetails() {
    return this.http.get(`account`, httpOptions);
  }
  populate() {

    // If JWT detected, attempt to get & store user's info
    const token = this.getToken();
    if (token) {
      this.getAuthenticatedUserDetails().subscribe(
        (data: any) => {
          this.setAuth(data);
        },
        err => this.purgeAuth()
      );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }
  setAuth(user: User) {
    // need some modification in future
    // Save JWT sent from server in localstorage
    // localStorage.setItem('token', user.token);
    // Set current user data into observable
    this.currentUserSubject.next(user);

    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;

  }
  purgeAuth() {
    // need some modification in future
    // Remove JWT from localstorage
    // this.jwtService.destroyToken();
    localStorage.removeItem('token');
    // need some modification in future
    // Set current user to an empty object
    // this.userDetails.next();
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    //this.router.navigate(['/login']);
  }
  // logout() {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('role');
  // this.purgeAuth();
  // }

  // Function to get user role
  getRole() {
    return localStorage.getItem('role');
  }

  // Function to check whether the token is expired or not
  checkTokenExpiry() {
    const helper = new JwtHelperService();
    return helper.isTokenExpired(this.getToken());
  }

}
