import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { AddStockIn, AddStockInModel } from '@app/shared/models/add-stock-in.models';
import { Router } from '@angular/router';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { AuthService } from '@app/core/services/auth.service';
import { Dropdown } from 'primeng/primeng';
import * as Constants from '@app/core/constants/common.constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-view-stock-in',
  templateUrl: './view-stock-in.component.html',
  styleUrls: ['./view-stock-in.component.scss']
})
export class ViewStockInComponent implements OnInit {
  myForm: FormGroup;
  addStockForm: FormGroup;
  breadcrumb: MenuItem[];
  message: any;
  showMinMaxR: boolean = false;
  supplierEnable = false;
  stop: boolean = false;
  totalData: any = [];
  addStockIn: any = {};
  addStockInModel = new AddStockInModel();
  otherSupplier: boolean;
  otherSupplierName: string;
  initialLoad: boolean = true;
  stockValue: number;
  showAdd: boolean = false;
  transactionId: string;
  pipe = new DatePipe('en-US');

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cd: ChangeDetectorRef,
    private toastrMsgService: ToastrMsgService,
    public stockService: ManageStockService,
    private authService: AuthService
  ) {
     if (sessionStorage.getItem('stockTransId')) {
      this.transactionId = sessionStorage.getItem('stockTransId');
     }
    // this.categoryDetails();
   //  this.locationDetails();
    // this.itemNameDetails();
   }
   @ViewChild('ddStatus') ddStatus: Dropdown;
   @ViewChild('ddStatusWare') ddStatusWare: Dropdown;
   @ViewChild('ddStatusLoc') ddStatusLoc: Dropdown;
   @ViewChild('ddStatusSub') ddStatusSub: Dropdown;
   @ViewChild('ddStatusCat') ddStatusCat: Dropdown;
   @ViewChild('ddStatusItem') ddStatusItem: Dropdown;
   @ViewChild('ddStatusSup') ddStatusSup: Dropdown;

  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.formSetup();

    this.breadcrumb = [
      { label: 'Manage Stock In', routerLink: '/mange-stock-in' },
      { label: 'View Stock In', url: '' }
    ];
    if (this.authService.getRole() === 'ROLE_SUPPLIER') {
      this.supplierEnable = false;
    } else {
      this.supplierEnable = true;
      // this.supplierDetails();
    }
    this.getStockDetails();
  }

  formSetup() {
    this.myForm = this.fb.group({
      supplier: [{ value: '', disabled: true }, Validators.required],
      otherSupplier: [{ value: '' }],
      dateInput:  [{value: '', disabled: true}, Validators.required],
      location: [{value: '',disabled: true}, Validators.required],
      warehouse: [{ value: '', disabled: true}, Validators.required],
      bin: [{ value: '', disabled: true }],
      remarks: [{value: '', disabled: true} , Validators.required],
      documentNo: [{value: '', disabled: true}, Validators.required],
      transactionType: [{value: '', disabled: true}, Validators.required],
      poNumber: [{value: '', disabled: true}, Validators.required]
    });

    this.addStockForm = this.fb.group({
      itemName: [{ value: '' }, Validators.required],
      inStock: [{ value: '' }, Validators.required],
      allocated: [{ value: '', disabled: true }],
      onOrder: [''],
      bin: [{ value: ''}],
      warehouse: [{ value: ''}],
      productionDate: ['', Validators.required],
      batch: [{ value: '' }, Validators.required],
      expirDate: [{ value: '', disabled: true }, Validators.required],
      documentNo: [{ value: '', disabled: true}, Validators.required],
      transactionType: [{value: '', disabled: true}, Validators.required],
      poNumber: [{value: '', disabled: true}, Validators.required]
    });
  }

  getStockDetails() {
    this.stockService.getTransDetails(this.transactionId).subscribe(
      data => {
        this.addStockIn.supplier = data.supplier;
        this.addStockIn.remarks = data.remarks;
        this.addStockIn.ezyprocurePoNumber = data.ezyprocurePoNumber;
        this.addStockIn.transactionType = data.transactionType;
        this.addStockIn.dateInput = this.pipe.transform(data.createdDate, 'dd-MM-yyyy');
        this.totalData = data.batchList;
        this.totalData.forEach(element => {
          this.addStockIn.warehouse = element.warehouse.name;
          if(data.transactionType != "GRN") {
            element.stockValue = element.item.price * element.inStock;
          }
          else {
            element.stockValue = element.amount;
            element.item.price = element.price;
            element.inStock = element.inStock;
          }
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  itemNameDetails() {
    this.stockService.getItemNames().subscribe(
      (data: any) => {
        this.addStockIn.itemNames = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  supplierDetails() {
    this.stockService.geSupplierList().subscribe(
      (data: any) => {
        const otherSupplier = { companyName : 'Others' };
        data.push(otherSupplier);
        this.addStockIn.suppliers = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  categoryDetails() {
    this.stockService.getCategories().subscribe(
      (data: any) => {
        this.addStockIn.categories = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  locationDetails() {
    this.stockService.getLocation().subscribe(
      (data: any) => {
        this.addStockIn.locations = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  catChange() {
    this.subCatDetails();
  }

  subCatDetails() {
    this.stockService.getSubCategories(this.addStockIn.category.id).subscribe(
      (data: any) => {
        this.addStockIn.subCategories = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  locChange() {
    this.warehouseDetails();
  }

  warehouseDetails() {
    this.stockService.getWarehouse(this.addStockIn.location).subscribe(
      (data: any) => {
        this.addStockIn.warehouses = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  warehouseChange() {
    this.binDetails();
  }

  binDetails() {
    this.stockService.getBin(this.addStockIn.warehouse.id).subscribe(
      (data: any) => {
        this.addStockIn.bins = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  itemNameChange(category?: any) {
    if (category === 'expirDate') {
      if (this.myForm.get(category).value === null) {
        if(this.initialLoad) {}
        this.myForm.get(category).disable();
        this.myForm.get(category).setValue(undefined);
      } else if (this.myForm.get(category).value === undefined) {
        this.myForm.get(category).enable();
      } else if (this.myForm.get(category).value === '') {
        this.myForm.get(category).disable();
      } else {
        this.myForm.get(category).enable();
      }
    } else {
      this.myForm.get(category).enable();
    }
  }

  itemNameChangeStock(category?: any) {
    if (category === 'expirDate') {
      if (this.addStockForm.get(category).value === null) {
        if (this.initialLoad) {
          this.addStockForm.get(category).disable();
          this.addStockForm.get(category).setValue(undefined);
        } else {
          this.addStockForm.get(category).enable();
        }
      } else if (this.addStockForm.get(category).value === undefined) {
        this.addStockForm.get(category).enable();
      } else if (this.addStockForm.get(category).value === '') {
        this.addStockForm.get(category).disable();
      } else {
        this.addStockForm.get(category).enable();
      }
    } else {
      this.addStockForm.get(category).enable();
    }
    this.initialLoad = false;
  }

  setInitialData() {
    this.addStockIn.itemName = null;
    this.addStockIn.batchNo = null;
    this.addStockIn.productionDate = null;
    this.addStockIn.expiryDate = null;
    this.addStockIn.allocated = null;
    this.addStockIn.onOrder = null;
    this.addStockIn.qtyReceived = null;
    this.addStockForm.reset();
    this.addStockForm.get('expirDate').disable();

  }

  allocatedChanged(event: any) {
    this.addStockForm.get('inStock').valueChanges.subscribe(checked => {
      if (checked) {
        this.addStockForm.get('allocated').enable();
        if (this.addStockIn.allocated) {
          this.addStockForm
          .get('allocated')
          .setValidators([Validators.required, Validators.max(checked)]);
        }
        // this.addStockForm
        //   .get('allocated')
        //   .setValidators([Validators.required, Validators.max(checked)]);
      } else {
        this.addStockForm.get('allocated').setValidators(null);
      }
      this.addStockForm.get('allocated').updateValueAndValidity();
    });
  }

  binClick(event: any) {
    if (event === 'ddStatusWare') {
      this.ddStatusWare.filled = true;
    } else if (event === 'ddStatus') {
      this.ddStatus.filled = true;
    } else if (event === 'ddStatusLoc') {
      this.ddStatusLoc.filled = true;
      this.addStockIn.bins = null;
    } else if (event === 'ddStatusSub') {
      this.ddStatusSub.filled = true;
    } else if (event === 'ddStatusCat') {
      this.ddStatusCat.filled = true;
    } else if (event === 'ddStatusItem') {
      this.ddStatusItem.filled = true;
    } else if (event === 'ddStatusSup') {
      this.ddStatusSup.filled = true;
      if (this.addStockIn.supplier.companyName === 'Others') {
        this.otherSupplier = true;
      } else {
        this.otherSupplier = false;
      }
    }
  }
  onCancel() {
    if (sessionStorage.getItem('stockTransId')) {
      sessionStorage.removeItem('stockTransId');
    }
    this.router.navigateByUrl('/mange-stock-in');
  }

  checkBatchIdDuplicate(submitted: boolean, stockform) {
    const batchList = [];
    const findBatchId = this.totalData.find(
      x => x.batch === stockform.value.batch
    );
    batchList.push(stockform.value.batch);
    this.stockService.checkBatch(batchList).subscribe(
      data => {

        if (data.batchValid === false || findBatchId) {
          this.toastrMsgService.error(
            '',
            this.message.batchMsg,
            this.message.toastrTime
          );
        } else {
          const stockData = {
            'item' : stockform.value.itemName,
            'batchNumber' : stockform.value.batch,
            'bin' : stockform.value.bin,
            'expiryDate' : stockform.value.expirDate,
            'productionDate' : stockform.value.productionDate,
            'inStock' : stockform.value.inStock,
            // 'allocatedStock' : stockform.value.allocated,
            // 'onOrder' : stockform.value.onOrder,
            'availableStock' : stockform.value.inStock - stockform.value.allocated,
            'stockValue' : stockform.value.itemName.price * stockform.value.inStock

          };
          this.totalData.unshift(stockData);
          const itemValue = stockform.value.itemName;
          if (
            itemValue.minLevel === 0 &&
            itemValue.maxLevel === 0 &&
            itemValue.reOrderLevel === 0
          ) {
            this.toastrMsgService.warning(
              '',
              this.message.stockInAlert,
              this.message.toastrTime
            );
          }
          // console.log(this.totalData);
          if (!submitted) {
            this.setInitialData();
            this.addStockForm.updateValueAndValidity();
          }
        }

      },
      err => {

      }
    );
  }

  deleteInventory(index) {
    this.totalData.splice(index, 1);
    // console.log(this.totalData);
  }

  onSubmitStock(myForm) {
    this.addStockInModel.batchList = this.totalData;
    if (this.supplierEnable) {
      if (this.otherSupplier) {
        this.addStockInModel.supplier = myForm.value.otherSupplier;
      } else {
        this.addStockInModel.supplier = myForm.value.supplier.companyName;
      }
    } else {
      this.addStockInModel.supplier = ' ';
    }
    this.addStockInModel.createdDate = myForm.value.dateInput;
    this.addStockInModel.remarks = myForm.value.remarks;
    //this.addStockInModel.warehouse = myForm.value.warehouse;
//    this.addStockInModel.documentNo = myForm.value.documentNo;
    // console.log(this.addStockInModel);
    this.stockService.getFormSubmission(this.addStockInModel).subscribe(
      resp => {
        this.toastrMsgService.success(
          '',
          this.message.addStockInSuccessMsg,
          this.message.toastrTime
        );
        this.myForm.reset();
        this.router.navigateByUrl('/mange-stock-in');

      },
      err => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );

  }

}
