import { AuthService } from '@app/core/services/auth.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Ezy-Inventory';
  constructor (
    private authService: AuthService
  ) {}
  ngOnInit(){
    this.authService.populate();
  }
}
