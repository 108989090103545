import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CountryListService {

constructor(public http: HttpClient) {

}
  getCountry(): Observable<HttpResponse<any>> {

    return this.http.get<any>(`country`, {
      observe: 'response'
    });

}

}
