import { Component, OnInit, ViewChild } from '@angular/core';
import { Col } from '@app/shared/models/table-header.model';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddLocation } from '@app/shared/models/location.model';
import { Paginator } from '@app/shared/models/paginator.model';
import { LocationService } from '@app/core/services/location.service';
import { WarehouseService } from '@app/core/services/warehouse.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { Country } from '@app/core/constants/country';
import { CountryListService } from '@app/core/services/country-list.service';
import { EditLocationComponent } from '@app/feature/manage-locations/edit-location/edit-location.component';
import { HttpResponse } from '@angular/common/http';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { AddLocationsComponent } from '../add-locations/add-locations.component';
import * as Constants from '@app/core/constants/common.constants';
import { ConfirmationService } from 'primeng/api';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
@Component({
  selector: 'app-list-locations',
  templateUrl: './list-locations.component.html',
  styleUrls: ['./list-locations.component.scss']
})
export class ListLocationsComponent implements OnInit {
  name = new FormControl('');
  bsModalRef: BsModalRef;
  dropdown: any;
  displayDialog: boolean;
  addLocation: AddLocation;
  breadcrumb: Breadcrumb[];
  locations: any;
  modalRef: BsModalRef;
  registerForm: FormGroup;
  paginator: Paginator;
  submitted = false;
  countries: any;
  filteredCountry: any;
  locationform: { code: any; name: any; address: any; country: any };
  editLocation: any;
  router: any;
  messages: any;
  pageCount: number;
  totalCount: number;
  message: any;
  listingCount: number;
  cols: { field: string; header: string; }[];
  display: boolean;
  deletedId = null;
  csvList: any;
  csvOptions: any;
  locationReportList: any;
  location: any;
  count=1;
  constructor(
    private locationService: LocationService,
    private formBuilder: FormBuilder,
    private countryListService: CountryListService,
    private modalService: BsModalService,
    private warehouseService: WarehouseService,
    private toastrMsgService: ToastrMsgService,
    private confirmationService: ConfirmationService
  ) {
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
     this.csvOptions = {
                 fieldSeparator: ',',
                 quoteStrings: '"',
                 decimalseparator: '.',
                 showLabels: true,
                 showTitle: false,
                 useBom: true,
                 noDownload: false,
            headers: [
                  'Sr.No',
                  'Location Code',
                  'Location Name',
                  'Address',
                  'Country'
                ]
                  };
  }
  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;
  ngOnInit() {
    this.getCountryList();
    this.message = Constants.MESSAGES;
    this.dropdown = Country;
    this.breadcrumb = [{ label: 'Manage Locations', routerLink: "" }];
    this.cols = [
      { field: 'code', header: 'Location Code' },
      { field: 'name', header: 'Location Name' },
      { field: 'address', header: 'Address' },
      { field: 'country', header: 'Country' }
    ];
    this.registerForm = this.formBuilder.group({
      locationCode: ["", Validators.required],
      locationName: ["", Validators.required],
      address: ["", [Validators.required]],
      country: ["", [Validators.required]]
    });
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",DESC";
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",ASC";
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getLocationList(this.paginator);
  }
  addLocations() {
    this.displayDialog = true;
  }
  getLocationList(lazyLoadData: Paginator) {
    this.locationService.getLocation(lazyLoadData).subscribe(
      (data: HttpResponse<any>) => {
        this.locations = data['body'];
        this.listingCount = data.body.length;
        this.totalCount = Number(data.headers.get('X-Total-Count'));
        if (this.totalCount <= this.pageCount) {
          this.paginator.paginator = false;
        }
        else {
          this.paginator.paginator = true;
        }
        this.paginator.loading = false;
        this.paginator.totalCount = this.totalCount;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);
        }
        else {
          this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
        }
      }
    );
  }
  getCountryList() {
    this.countryListService.getCountry().subscribe(
      data => {
        this.countries = data['body'];
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);
        }
        else
          this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
      }
    );
  }
  onSubmit() {
    this.submitted = true;
    this.locationform = {
      code: this.registerForm.value.locationCode,
      name: this.registerForm.value.locationName,
      address: this.registerForm.value.address,
      country: this.registerForm.value.country.name
    };
    this.locationService
      .addLocation(this.locationform)
      .subscribe((data: any) => { }, (err: any) => { });
    this.toastrMsgService.success('', this.message.locationAddSuccessMsg, this.message.toastrTime);
    this.getLocationList(this.paginator);
    this.registerForm.reset();

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
  }
  openModalWithComponent(locId: any) {
    this.locationService.setLocation(null);
    const initialState = {
      locationId: locId
    };
    this.modalService.onHide.subscribe((reason: string) => {
      if (this.locationService.getSavedLocation()) {
        this.getLocationList(this.paginator);
      }
    });
    this.bsModalRef = this.modalService.show(EditLocationComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  openModalAddLocation() {
    this.locationService.setLocation(null);
    const initialState = {
    };
    this.modalService.onHide.subscribe((reason: string) => {
      if (this.locationService.getSavedLocation()) {
        this.getLocationList(this.paginator);
      }
    });
    this.bsModalRef = this.modalService.show(AddLocationsComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  setCurrentPage(n: number) {
    const paging = {
      first: ((n - 1) * this.dataTable.rows),
      rows: this.dataTable.rows
    };
    this.loadUsersLazy(paging);
  }
  resetPagination($event?: any) {
    this.primePaginator.first = 0;
    this.paginator.pageNo = 0;
  }

  cancel() {
    this.displayDialog = false;
  }
  //   deleteLocation(rowData: any, cd: any) {
  //     this.confirmationService.confirm({
  //       message: 'Are you sure you want to delete this Location?',
  //       icon: 'pi pi-info-circle',
  //       acceptLabel: 'yes',
  //       key: 'addLocation',
  //       rejectLabel: 'no',
  //       accept: () => {
  //     this.locationService.deleteLocation(rowData).subscribe(
  //       (data: HttpResponse<any>) => {
  //         this.toastrMsgService.success('', this.message.locationDltMsg, this.message.toastrTime);
  //         if(this.listingCount==1)
  //         {
  //         this.setCurrentPage(this.paginator.pageNo);
  //       }
  //        this.getLocationList(this.paginator);
  //       },
  //       (err: any) => {
  //         if (navigator.onLine === false) {
  //           this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);

  //         }else{
  //         this.getLocationList(this.paginator);
  //         this.toastrMsgService.error('', this.message.activeWarehouseExistMsg, this.message.toastrTime);
  //       }
  //       }
  //     );
  //   },
  //   reject: () => {
  //   }
  // });
  // }

  deleteLocation(id) {
    this.locationService.deleteLocation(id).subscribe(
      (data: HttpResponse<any>) => {
        this.toastrMsgService.success('', this.message.locationDltMsg, this.message.toastrTime);
        if (this.listingCount == 1) {
          this.setCurrentPage(this.paginator.pageNo);
        }
        this.getLocationList(this.paginator);
        this.display = false;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);

        } else {
          this.getLocationList(this.paginator);
          this.toastrMsgService.error('', this.message.activeWarehouseExistMsg, this.message.toastrTime);
        }
      }
    );
  }
   getlocationItemList(lazyLoadData: Paginator) {
      this.locationService.getLocation(lazyLoadData).subscribe(
      data=>{
        this.locationReportList=data.body;
                 this.csvList=[];
                 this.locationReportList.forEach(item=> {
                 this.csvList.push({
                                      SerialNo:this.count++,
                                      code: isNaN(item.code) ? item.code : "'"+item.code,
                                      name: item.name,
                                      address: item.address,
                                      country: item.country.name
                                   });
                        });
        new AngularCsv(this.csvList, 'Location', this.csvOptions);
          this.locations = data['body'];
          this.listingCount = data.body.length;
          this.totalCount = Number(data.headers.get('X-Total-Count'));
          if (this.totalCount <= this.pageCount) {
            this.paginator.paginator = false;
          } else {
            this.paginator.paginator = true;
          }
          this.paginator.loading = false;
          this.paginator.totalCount = this.totalCount;
        },
        (err: any) => {
          if (navigator.onLine === false) {
            this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);
          }
          else {
            this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
          }
        }
      );
    }
       downloadCSV(){
          this.getlocationItemList(this.paginator)
    }
}
