import { DataService } from '@app/core/services/data.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '@app/core/services/auth.service';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public navbarCollapse: boolean;
  public navbarStateOpen: boolean;
  currentUser: User;
  //  isLoggedIn$: Observable<boolean>;    
  constructor(private dataService: DataService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private authService: AuthService
  ) {
    this.dataService.navbarState.subscribe(resp => {
      this.navbarCollapse = resp;
      if (this.navbarCollapse) {
        document.querySelector(':root')['style'].cssText = '--side-menu-width: 240px';
      } else {
        document.querySelector(':root')['style'].cssText = '--side-menu-width: 60px';
      }
    });
  }

  ngOnInit() {
    // this.isLoggedIn$ = this.authService.isLoggedIn;
    // this.isLoggedIn$=this.authService.setFlag()
    this.navbarStateOpen = true;
    this.authService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
      }
    );
  }
  addId() {
    if (this.deviceService.isMobile()) {
      this.navbarStateOpen = false;
      this.dataService.changeNavbarState(this.navbarStateOpen);
    }
  }

}
