import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Col } from "@app/shared/models/table-header.model";
import { Breadcrumb } from "@app/shared/models/breadcrumb.model";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { WarehouseService } from "@app/core/services/warehouse.service";
import { AddLocation } from "@app/shared/models/location.model";
import { BinService } from "@app/core/services/bin.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { Country } from "@app/core/constants/country";
import { CountryListService } from "@app/core/services/country-list.service";
import { Router } from "@angular/router";
import { LocationService } from "@app/core/services/location.service";
import { ToastrMsgService } from "@app/shared/services/toastr-msg.service";
import * as Constants from '@app/core/constants/common.constants';

@Component({
  selector: "app-edit-bin",
  templateUrl: "./edit-bin.component.html",
  styleUrls: ["./edit-bin.component.scss"]
})
export class EditBinComponent implements OnInit, AfterViewInit {
  name = new FormControl("");
  cols: Col[];
  locationId: any;
  lastNam: string;
  dropdown: any;
  displayDialog: boolean;
  addLocation: AddLocation;
  breadcrumb: Breadcrumb[];
  locations: any;
  registerForm: FormGroup;
  submitted = false;
  countries: any;
  filteredCountry: any;
  editLocation: any;
  messageEvent: any;
  locationList: any;
  warehouseList: any;
  locationform: { id: any; code: any; name: any; warehouse: { id: any }, defaultBin: any; };
  warehouseId: any;
  message: any;
  constructor(
    private locationService: LocationService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private countryListService: CountryListService,
    private binService: BinService,
    private toastrMsgService: ToastrMsgService,
    private modalService: BsModalService,
    private warehouseService: WarehouseService
  ) { }
  ngOnInit() {
    this.message = Constants.MESSAGES;
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    this.dropdown = Country;

    this.registerForm = this.formBuilder.group({
      location: ["", Validators.required],
      warehouseName: ["", Validators.required],
      binId: ["", [Validators.required]],
      binName: ["", [Validators.required]],
      defaultBin: [""],
      check: [""],
    });
    this.editLocations(this.locationId);
  }
  ngAfterViewInit() {
    this.displayDialog = true;
  }
  get f() {
    return this.registerForm.controls;
  }

  editLocations(locId: any) {
    locId = this.locationId;
    this.warehouseService.editBin(locId).subscribe(
      data => {
        this.editLocation = data["body"];
        this.registerForm.value.locationCode = this.editLocation.code;
        this.warehouseId = this.editLocation.warehouse.id;

        this.registerForm.setValue({
          location: this.editLocation.warehouse.location.name,
          warehouseName: this.editLocation.warehouse.name,
          binId: this.editLocation.code,
          binName: this.editLocation.name,
          defaultBin: this.editLocation.defaultBin ? this.editLocation.defaultBin : false,
          check: this.editLocation.defaultBin
        });
      },

      (err: any) => { }
    );
  }
  getLocation() {
    this.warehouseService.getLocationList().subscribe(
      data => {
        this.locationList = data["body"];
      },

      (err: any) => { }
    );
  }
  getWarehouse(id: any) {
    this.warehouseService.getWarehouseList(id).subscribe(
      data => {
        this.warehouseList = data["body"];
      },
      (err: any) => {

      }
    );
  }

  onSubmit() {
    this.submitted = true;

    this.locationform = {
      id: this.locationId,
      code: this.registerForm.value.binId,
      name: this.registerForm.value.binName,
      warehouse: { id: this.warehouseId },
      defaultBin: this.registerForm.value.defaultBin
    };
    this.warehouseService
      .editBinPost(this.locationform)
      .subscribe((data: any) => {
        this.toastrMsgService.success("", this.message.binEditSuccessMsg, this.message.toastrTime);
        this.registerForm.reset();
        this.binService.setBin(data);
        this.bsModalRef.hide();
        this.displayDialog = false;
      }, (err: any) => { });
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
  }
  cancel() {
    this.bsModalRef.hide();
    this.displayDialog = false;
  }
}
