import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/services/auth.service';

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): boolean {
    const token = this.authService.getToken();
    const role = this.authService.getRole();
    if (token && !this.authService.checkTokenExpiry()) {
      if (role) {
        this.redirectTo(role);
      } else {
        return true;
      }
    }
    return true;
  }
  redirectTo(role) {
    if (role === 'ROLE_BUYER' || role === 'ROLE_SUPPLIER') {
      this.router.navigate(['/dashboard']);
    } else {
      localStorage.clear();
      this.router.navigateByUrl('/login');
    }
  }
}
