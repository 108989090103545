import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Col } from '@app/shared/models/table-header.model';
import { ItemService } from '@app/core/services/item.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { take, takeLast } from 'rxjs/operators';
import { Breadcrumb, MenuItem, Dropdown } from 'primeng/primeng';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { AuthService } from '@app/core/services/auth.service';
import { SubReceipeService } from '@app/core/services/sub-receipe.service';

@Component({
  selector: 'app-view-subrecipe',
  templateUrl: './view-subrecipe.component.html',
  styleUrls: ['./view-subrecipe.component.scss']
})
export class ViewSubrecipeComponent implements OnInit {

  breadcrumb: MenuItem[];
  editParentSubrecipeForm: FormGroup;
  editSubRecipesForm : FormGroup;
  message: any;
  subRecipeitems : [{
    childItemName: any
    childItemCode: any
    childItemQty: any
    childParentItemUom: any
    cost: any
    parentItemCode: any
    parentItemName: any
    parentItemQty: any
    parentItemUom: any
  }];
  cols: Col[];
  // uomList: any[] = [];

  parentItemCode: any;
  parentItemName: any;
  parentItemUom: any;
  parentItemQuantity: any;
  parentItemCost: any;
  selectedItemId: any;
  childSubRecipeData: any[] = [];
  selectedCompanyId: any;
  selectedparentItemCode: any;

  constructor(
      private toastrMsgService: ToastrMsgService,
      private stockService: ManageStockService,
      private authService: AuthService,
      private router : Router,
      private route: ActivatedRoute,
      private itemService : ItemService,
      private subReceipeService : SubReceipeService,
      private fb: FormBuilder
  ) {
    // this.getUom();
    this.route.params.subscribe(params => {
      this.selectedparentItemCode = params.parentItemCode;
      this.viewSubRecipe();
    });
  }

  ngOnInit() {
    if (sessionStorage.getItem('checkSubrecipePassword')) {
      if (sessionStorage.getItem('checkSubrecipePassword') != 'true') {
        this.router.navigateByUrl('/manage-subrecipe');
      }
    } else {
      this.router.navigateByUrl('/manage-subrecipe');
    }
    this.breadcrumb = [
      { label: 'Manage Sub Recipe', routerLink: '/manage-subrecipe' },
      { label: 'View Sub Recipe', url: '' }
    ];
    this.message = Constants.MESSAGES;
    this.formSetup();
    this.cols = [
      { field: 'slNo', header: 'Sr. No', width: '10%', textAlign: 'left' },
      { field: 'childItemName', header: 'Child Item Name', width: '25%', textAlign: 'left' },
      { field: 'childItemCode', header: 'Child Item Code', width: '25%', textAlign: 'left' },
      { field: 'childItemUom', header: 'Child Item Uom', width: '20%', textAlign: 'left' },
      { field: 'childItemQuantity', header: 'Child Item Quantity', width: '10%', textAlign: 'right !important' }
    ];
    this.subRecipeitems = [{
      childItemName: '',
      childItemCode: '',
      childItemQty: '',
      childParentItemUom: '',
      cost: '',
      parentItemCode: '',
      parentItemName: '',
      parentItemQty: '',
      parentItemUom: '',
    }];
  }

  loadTableData(event: any) {
    this.viewSubRecipe();
  }

  viewSubRecipe(){
    this.subReceipeService.viewSubRecipe(this.selectedparentItemCode).subscribe(
      (data: any) => {
        this.subRecipeitems = data.body;
        // Dummy Values
        this.subRecipeitems.map(items =>{
          this.childSubRecipeData.push({
            childItemName: items.childItemName,
            childItemCode: items.childItemCode,
            childItemUom: items.childParentItemUom,
            childItemQuantity: items.childItemQty
          })
        });
        this.parentItemCode = this.subRecipeitems[0].parentItemCode;
        this.parentItemQuantity = this.subRecipeitems[0].parentItemQty;
        // Dummy Values
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
      }
    );
  }

  formSetup() {
      this.editSubRecipesForm = this.fb.group({
        childItemCode:  [{value: '', disabled: true}],
        childItemName: [{ value: '' }, Validators.required],
        childItemUom: [{ value: ''}],
        childItemQuantity: [{value: '' } , Validators.required]
      });

      this.editParentSubrecipeForm = this.fb.group({
        parentItemName: [{ value: '',disabled: true}, Validators.required],
        parentItemCode:  [{value: '', disabled: true}],
        parentItemUom:  [{value: '', disabled: true}],
        parentItemQty: [{value: '', disabled: true }, Validators.required],
        parentItemCost: [{value: '',disabled: true}]
      });
  }

  onItemChangeUpdateItemDetails() {
    this.parentItemUom = this.editParentSubrecipeForm.get('parentItemName').value.uom.name;
    this.parentItemCost = this.editParentSubrecipeForm.get('parentItemName').value.price;
  }

  // getUom() {
  //   this.itemService.getUom().subscribe(data => {
  //     this.uomList = data;
  //   });
  // }

  onCancel() {
    this.router.navigateByUrl('/manage-subrecipe');
  }

}
