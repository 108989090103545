import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {
  private api: string;
  constructor(public http: HttpClient) { this.api = environment.API_ENDPOINT; }
  getSubCategoryList(lazyLoadData: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(`subcategory?page=${lazyLoadData.pageNo}&size=${lazyLoadData.limit}&sort=${lazyLoadData.sortOrder}`, {
      observe: 'response'
    });
  }
  getSubCategory(id): Observable<HttpResponse<any>> {

    return this.http.get<any>(`subcategories?categoryId=${id}`, {
      observe: 'response'
    });
  }
   getSubCategoryItemList(lazyLoadData: any): Observable<HttpResponse<any>> {

      return this.http.get<any>(`subcategories`, {
        observe: 'response'
      });
    }
  getEdit(id): Observable<HttpResponse<any>> {
    return this.http.get<any>(`item/${id}`, {
      observe: 'response'
    });
  }
  getEditTable(id): Observable<HttpResponse<any>> {
    return this.http.get<any>(`inventory?itemId=${id}`, {
      observe: 'response'
    });
  }

  getCategoryList() {
    return this.http.get(`categories`);
  }

  checkDuplicateSubCategory(catName: string, subCatName: string) {
    return this.http.get(`subcategoryname-valid?subcategoryName=${subCatName}&categoryName=${catName}`);

  }

  addTheSubCategory(subCategory: any) {
    if (subCategory.description == null) {
      return this.http.post(`subcategory?name=${subCategory.name}&categoryId=${subCategory.category.id}`, null);
    }
    else {
      return this.http.post(`subcategory?name=${subCategory.name}&categoryId=${subCategory.category.id}&description=${subCategory.description}`, null);

    }
  }
  updateSubCategory(rowData: any) {
    if (rowData.description == null) {
      return this.http.put(`subcategory/${rowData.id}?name=${rowData.name}`, rowData);
    }
    else {
      return this.http.put(`subcategory/${rowData.id}?name=${rowData.name}&description=${rowData.description}`, rowData);
    }
  }
  deleteSubCategories(id: number) {
    return this.http.delete(`subcategory/${id}`);

  }
  getSubCategoryListForCSV(lazyLoadData: any): Observable<HttpResponse<any>> {
      return this.http.get<any>(`subcategory`, {
        observe: 'response'
      });
    }
}
