import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';;
import { BreadcrumbModule } from 'primeng/components/breadcrumb/breadcrumb';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { TooltipModule } from 'primeng/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ManageWarehouseRoutingModule } from '@app/feature/manage-warehouse/manage-warehouse-routing.module';
import { ListWarehousesComponent } from '@app/feature/manage-warehouse/list-warehouses/list-warehouses.component';

import { DropdownModule } from 'primeng/dropdown';
import { EditWarehouseComponent } from './edit-warehouse/edit-warehouse.component';
import { AddWarehouseComponent } from './add-warehouse/add-warehouse.component';
import { InputTextModule } from 'primeng/primeng';
import { ManageLocationsModule } from '@app/feature/manage-locations/manage-locations.module';
@NgModule({
  declarations: [
    ListWarehousesComponent, EditWarehouseComponent, AddWarehouseComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    FormsModule,
    ModalModule.forRoot(),
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    CheckboxModule,
    ChipsModule,
    TooltipModule,
    BreadcrumbModule,
    InputTextModule,
    ManageLocationsModule,
    ManageWarehouseRoutingModule
  ],
  entryComponents: [ EditWarehouseComponent,AddWarehouseComponent
  ]
})
export class ManageWarehouseModule { }
