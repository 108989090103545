import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageStockInRoutingModule } from './manage-stock-in-routing.module';
import { ManageStockInComponent } from './manage-stock-in/manage-stock-in.component';
import { AddStockInComponent } from './add-stock-in/add-stock-in.component';
import { TableModule } from 'primeng/components/table/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule, BreadcrumbModule, TooltipModule } from 'primeng/primeng';
import {InputTextModule} from 'primeng/inputtext';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { CoreModule } from '@app/core/core.module';
import { ViewStockInComponent } from './view-stock-in/view-stock-in.component';
import { DialogModule } from 'primeng/dialog';
@NgModule({
  declarations: [ManageStockInComponent, AddStockInComponent, ViewStockInComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableModule,
    CoreModule,
    InputTextModule,
    BreadcrumbModule,
    DropdownModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    ManageStockInRoutingModule,
    TooltipModule,
    DialogModule
  ]
})
export class ManageStockInModule { }
