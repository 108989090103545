import { CategoryService } from '@app/core/services/category.service';
import { EditSubcategoryComponent } from '@app/feature/manage-sub-category/edit-subcategory/edit-subcategory.component';
import { AddSubcategoryComponent } from '@app/feature/manage-sub-category/add-subcategory/add-subcategory.component';
import { EditSubCategory } from '@app/shared/models/edit-subcategory.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AddSubCategory } from '@app/shared/models/sub-category.model';
import { Col } from '@app/shared/models/table-header.model';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { Paginator } from '@app/shared/models/paginator.model';
import * as Constants from '@app/core/constants/common.constants';
import { SubCategoryService } from '@app/core/services/sub-category.service';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { HttpResponse } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Table } from 'primeng/table';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
@Component({
  selector: 'app-list-sub-category',
  templateUrl: './list-sub-category.component.html',
  styleUrls: ['./list-sub-category.component.scss']
})
export class ListSubCategoryComponent implements OnInit {
  displayDialog: boolean;
  displayDialogNew: boolean;
  cols: Col[];
  itemGroupName: string;
  paginator: Paginator;
  category: string;
  pageCount: number;
  listingCount: number;
  categoryName: string;
  totalCount: number;
  subCategoryList: any = [];
  public addSubCategory = new AddSubCategory();
  public editSubCategory = new EditSubCategory();
  breadcrumb: Breadcrumb[];
  categories: { id: number; category: string; name: string }[];
  bsModalRef: BsModalRef;
  message: any;
  display: boolean;
  deletedId = null;
  csvList: any;
  csvOptions: any;
  subCategoryReportList: any;
  count=1;
  subCategoryReportListCSV: any =[];

  constructor(
    private subCategoryService: SubCategoryService,
    private modalService: BsModalService,
    private toastrMsgService: ToastrMsgService,
    private confirmationService: ConfirmationService,
    private categoryService: CategoryService
  ) {
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
     this.csvOptions = {
                       fieldSeparator: ',',
                       quoteStrings: '"',
                       decimalseparator: '.',
                       showLabels: true,
                       showTitle: false,
                       useBom: true,
                       noDownload: false,
                 headers: [
                        'Sr.No',
                        'Category',
                        'Sub Category',
                        'Description'
                      ]
                      };
  }
  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;
  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.breadcrumb = [{ label: 'Manage Sub Categories', routerLink: '' }];
    this.addSubCategory = {
      name: null,
      description: null,
      category: { id: null }
    };
    this.editSubCategory = {
      id: null,
      name: null,
      description: null
    };
    this.cols = [
      { field: 'categoryName', header: 'Category', width: '20%'},
      { field: 'name', header: 'Sub Category',width: '60%' },
      { field: 'description', header: 'Description',width: '20%' }
    ];
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  //method for listing sub category
  getSubCategories(lazyLoadData: Paginator) {
    this.subCategoryService.getSubCategoryList(lazyLoadData).subscribe(
      (data: HttpResponse<any>) => {
        this.subCategoryList = data.body;
        this.listingCount = data.body.length;
        this.totalCount = Number(data.headers.get('X-Total-Count'));
        for (const i in this.subCategoryList) {
          this.subCategoryList[i].categoryName = this.subCategoryList[
            i
          ].category.name;
        }
        if (this.totalCount <= this.pageCount) {
          this.paginator.paginator = false;
        } else {
          this.paginator.paginator = true;
        }
        this.paginator.loading = false;
        this.paginator.totalCount = this.totalCount;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
          this.paginator.loading = false;
        }
      }
    );
  }
  //add sub category open modal
  public openModalWithComponent() {
    this.categoryService.setCategory(null);
    /* this is how we open a Modal Component from another component */
    this.bsModalRef = this.modalService.show(AddSubcategoryComponent);
    this.modalService.onHide.subscribe(() => {
      if (this.categoryService.getSavedCategory()) {
        this.getSubCategories(this.paginator);
      }
    });
  }
  //method for getting previous page
  setCurrentPage(n: number) {
    const paging = {
      first: (n - 1) * this.dataTable.rows,
      rows: this.dataTable.rows
    };
    this.loadUsersLazy(paging);
  }
  resetPagination($event?: any) {
    this.primePaginator.first = 0;
    this.paginator.pageNo = 0;
  }
  //edit sub category open modal
  openModalEditSubCategories(rowData: EditSubCategory) {
    this.categoryService.setCategory(null);
    const initialState = {
      editData: rowData
    };
    this.bsModalRef = this.modalService.show(EditSubcategoryComponent, {
      initialState
    });
    this.modalService.onHide.subscribe(() => {
      if (this.categoryService.getSavedCategory) {
        this.getSubCategories(this.paginator);
      }
    });
  }

  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",DESC";
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",ASC";
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getSubCategories(this.paginator);
  }
  //delete sub category method
  // deleteSubCategory(rowData: any, cd: any) {
  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to delete this Sub Category?',
  //     icon: 'pi pi-info-circle',
  //     acceptLabel: 'yes',
  //     key: 'addSubCategory',
  //     rejectLabel: 'no',
  //     accept: () => {
  //       this.subCategoryService.deleteSubCategories(rowData.id).subscribe(
  //         (data: HttpResponse<any>) => {
  //           this.toastrMsgService.success('', this.message.subcategoryDltMsg, this.message.toastrTime);
  //           if (this.listingCount == 1) {
  //             this.setCurrentPage(this.paginator.pageNo);
  //           }
  //           this.getSubCategories(this.paginator);
  //         },
  //         (err: any) => {
  //           if (navigator.onLine === false) {
  //             this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);

  //           }
  //           else
  //           this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
  //         }
  //       );
  //     },
  //     reject: () => {
  //     }
  //   });
  // }

  deleteSubCategory(id) {
    this.subCategoryService.deleteSubCategories(id).subscribe(
      (data: HttpResponse<any>) => {
        this.toastrMsgService.success(
          '',
          this.message.subcategoryDltMsg,
          this.message.toastrTime
        );
        if (this.listingCount == 1) {
          this.setCurrentPage(this.paginator.pageNo);
        }
        this.getSubCategories(this.paginator);
        this.display = false;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        }
        if(err.status === 406){
          this.toastrMsgService.error('', err.error, this.message.toastrTime);
        }
        else
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
      }
    );
  }
  getSubCategoriesItemList(lazyLoadData: Paginator) {
      this.subCategoryService.getSubCategoryListForCSV(lazyLoadData).subscribe(
        data=>{
          this.subCategoryReportListCSV=data.body;
          this.csvList=[];
          this.subCategoryReportListCSV.forEach(item=> {
            this.csvList.push({
              SerialNo:this.count++,
              category: item.category.name,
              name: item.name,
              description: item.description != null ? item.description : ''
            });
          });
          new AngularCsv(this.csvList, 'Sub-Category', this.csvOptions);
          this.listingCount = data.body.length;
          this.totalCount = Number(data.headers.get('X-Total-Count'));
          if (this.totalCount <= this.pageCount) {
            this.paginator.paginator = false;
          } else {
          this.paginator.paginator = true;
            }
            this.paginator.loading = false;
            this.paginator.totalCount = this.totalCount;
            this.count = 1;
        },
        (err: any) => {
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              '',
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
            '',
              this.message.errorMsg,
              this.message.toastrTime
            );
              this.paginator.loading = false;
            }
          }
        );
     }
       downloadCSV(){
         this.getSubCategoriesItemList(this.paginator);
        }
     }
