import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageReportsRoutingModule } from './manage-reports-routing.module';
import { ListReportsComponent } from '@app/feature/manage-reports/list-reports/list-reports.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DropdownModule, TooltipModule, InputTextModule, TreeModule, TreeTableModule } from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@app/core/core.module';
import { TableModule } from 'primeng/table';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { TreeInventoryReportComponent } from './tree-inventory-report/tree-inventory-report.component';
import { InventryReportsComponent } from './inventry-reports/inventry-reports.component';
import { ExpiryReportComponent } from './expiry-report/expiry-report.component';
import { AuditLogReportComponent } from './log-report/audit-log-report.component';
import { VarianceReportComponent } from './variance-report/variance-report.component';
import { TreeVarianceReportComponent } from './tree-variance-report/tree-variance-report.component';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [ListReportsComponent,TreeInventoryReportComponent, InventryReportsComponent, ExpiryReportComponent, AuditLogReportComponent, VarianceReportComponent, TreeVarianceReportComponent],
  imports: [
    CommonModule,
    ManageReportsRoutingModule,
    InputTextModule,
    BreadcrumbModule,
    BsDatepickerModule.forRoot(),
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    TreeTableModule,
    CoreModule,
    TableModule,
    TooltipModule,
    MultiSelectModule
  ],
  exports: [ListReportsComponent,InventryReportsComponent],

})
export class ManageReportsModule { }
