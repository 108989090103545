import { Component, OnInit, ViewChild } from '@angular/core';
import { Col } from '@app/shared/models/table-header.model';
import { Paginator } from '@app/shared/models/paginator.model';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WarehouseService } from '@app/core/services/warehouse.service';
import { AddLocation } from '@app/shared/models/location.model';
import { BinService } from '@app/core/services/bin.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Country } from '@app/core/constants/country';
import { AddBinComponent } from '@app/feature/manage-bin/add-bin/add-bin.component';
import { CountryListService } from '@app/core/services/country-list.service';
import { EditBinComponent } from '@app/feature/manage-bin/edit-bin/edit-bin.component';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { ConfirmationService } from 'primeng/api';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { Router } from '@angular/router';
import { ItemService } from '@app/core/services/item.service';
import { HttpResponse } from '@angular/common/http';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { FileUploadService } from '@app/core/services/fil-upload.service';
@Component({
  selector: 'app-list-items',
  templateUrl: './list-items.component.html',
  styleUrls: ['./list-items.component.scss']
})
export class ListItemsComponent implements OnInit {
  name = new FormControl('');
  cols: Col[];
  bsModalRef: BsModalRef;
  lastNam: string;
  dropdown: any;
  displayDialog: boolean;
  addLocation: AddLocation;
  breadcrumb: Breadcrumb[];
  locations: any;
  modalRef: BsModalRef;
  registerForm: FormGroup;
  paginator: Paginator;
  submitted = false;
  countries: any;
  filteredCountry: any;
  editLocation: any;
  locationList: any;
  warehouseList: any;
  warehouseId: any;
  locationform: { name: any; code: any; warehouse: { id: any; }; };
  warehouse: { locationId: any; };
  pageCount: number;
  totalCount: number;
  message: any;
  binName: any;
  active: any;
  isSubRecipe: any;
  warehouseName:any;
  listingCount: number;
  display: boolean;
  deletedId = null;
  itemList:any;
  uom: any;
  uomList:any=[];
  uomIdList:any=[];
  uomName:any;
  supplier:any;
  selectedValueUom:any;
  defaultSupplierList: any = [];
  defaultSupplierCompanyName:any;
  itemReportList: any;
  csvList: any;
  csvListDownload: any;
  csvOptions: any;
  csvOptionsDownload: any;
  uploadTrue: boolean = false;
  buttonEnable: boolean = false;
  sampleItemCSVList: any;
  csvFile: File;
  fileName: string;
  valuationName: any;
  locationName: any;
  count=1;

  searchKey: string = "";

  constructor(
    private router: Router,
    private toastrMsgService: ToastrMsgService,
    private binService: BinService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private countryListService: CountryListService,
    private modalService: BsModalService,
    private warehouseService: WarehouseService,
    private itemService: ItemService,
    public fileUploadService: FileUploadService,
  )
  {
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.csvOptions = {
         fieldSeparator: ',',
         quoteStrings: '"',
         decimalseparator: '.',
         showLabels: true,
         showTitle: false,
         useBom: true,
         noDownload: false,
         headers: [
                  'Sr.No',
                  'Ezyprocure Item ID',
                  'Default supplier name',
                  'Sub category Name*',
                  'Item code*',
                  'Item name*',
                  'Uom*',
                  'Min level',
                  'Max level',
                  'Re order level',
                  'Reorder Quantity',
                  'Default Location Code',
                  'Default Warehouse Code',
                  'Default Bin Code',
                  'Batch management(Yes/No)*',
                  'Col 1',
                  'Col 2',
                  'Col 3',
                  'Col 4',
                  'Col 5',
                  'Col 6',
                  'Sub recipe item(Yes/No)'
                  ]
         };
         this.csvOptionsDownload = {
                  fieldSeparator: ',',
                  quoteStrings: '"',
                  decimalseparator: '.',
                  showLabels: true,
                  showTitle: false,
                  useBom: true,
                  noDownload: false,
                  headers: [
                           'Sr.No',
                           'ItemCode',
                           'ItemName',
                           'Category',
                           'SubCategory',
                           'Valuation Method*',
                           'Barcode',
                           'UOM',
                           'Location',
                           'Warehouse',
                           'Default Bin',
                           'Batch management',
                           'Supplier',
                           'Active',
                           'Col 1',
                           'Col 2',
                           'Col 3',
                           'Col 4',
                           'Col 5',
                           'Col 6',
                           'Sub recipe item(Yes/No)'
                           ]
                  };
    }
  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;
  ngOnInit() {
    this.message = Constants.MESSAGES;

    // FUNCTION CALL
    this.dropdown = Country;
    this.breadcrumb = [{ label: 'Manage Item', routerLink: '' }];
    this.cols = [
      { field: 'code', header: 'Item Code', width: '10%' },
      { field: 'name', header: 'Item Name', width: '12%' },
      { field: 'Category', header: 'Category', width: '9%' },
      { field: 'subcategory.name', header: 'Sub Category', width: '10%' },
      { field: 'valuationMethod.name', header: 'Valuation Method', width:'5%' },
    // { field: 'barcode', header: 'Barcode' },
      { field: 'uom.name', header: 'UOM', width:'6%' },
      { field: 'managementMethod.name', header: 'Batch Management', width:'7%' },
      // { field: 'defaultSupplierCompanyName', header: 'Default Supplier', width:'7%' },
      { field: 'isActive', header: 'Active', width:'6%' }
    ];
      this.registerForm = this.formBuilder.group({
      locationCode: ['', Validators.required],
      warehouseName: ['', Validators.required],
      binId: ['', [Validators.required]],
      binName: ['', [Validators.required]]
    });
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
    }

    getItemsByQuery(event) {
      // this.paginatorInitial();
      // this.stockInDetails();
    }

  paginatorInitial() {
      this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",DESC";
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ",ASC";
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getItem(this.paginator);
  }

  handleSearchInput(event: KeyboardEvent) {
    if(event.keyCode == 13) {
      this.getItem();
    }
  }

  getItem(lazyLoadData: Paginator = this.paginator) {
    this.binService.getItem(lazyLoadData, this.searchKey).subscribe(
      data =>{
        this.locations = data['body'];
        this.listingCount = data.body.length;
        this.totalCount = Number(data.headers.get('X-Total-Count'));
        if (this.totalCount <= this.pageCount) {
          this.paginator.paginator = false;
        } else {
          this.paginator.paginator = true;
        }
        this.paginator.loading = false;
        this.paginator.totalCount = this.totalCount;
      },
      (err: any) => {
     }
    );
  }
  onSubmit() {
    this.submitted = true;
    this.locationform = {
      name: this.registerForm.value.binName,
      code: this.registerForm.value.binId,
      warehouse: { id: this.warehouseId },
    };
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
  }
  setCurrentPage(n: number) {
    const paging = {
      first: ((n - 1) * this.dataTable.rows),
      rows: this.dataTable.rows
    };
    this.loadUsersLazy(paging);
  }
  openAddItem() {
    this.router.navigateByUrl('/manage-items/add-items');
  }
  openEditItem(id) {
    this.router.navigate(['/manage-items/edit-item', id]);
  }
  openViewItem(id) {
    this.router.navigate(['/manage-items/view-item', id]);
  }
  resetPagination($event?: any) {
    this.primePaginator.first = 0;
    this.paginator.pageNo = 0;
  }

   deleteItem(id: any) {
    this.display = false;
    this.itemService.deleteItem(id).subscribe(
      (data: HttpResponse<any>) => {
        this.toastrMsgService.success('', this.message.itemDeleteSuccess, this.message.toastrTime);
        if (this.listingCount === 1) {
          this.setCurrentPage(this.paginator.pageNo);
        }
        this.getItem(this.paginator);
        this.display = false;
      },
      (err: HttpResponse<any>) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);

        } else {
          this.getItem(this.paginator);
          this.toastrMsgService.error('', this.message.itemDeleteFail, this.message.toastrTime);
        }
      }
    );
  }
  getUom() {
      this.itemService.getUom().subscribe(data => {
      this.uomList = data;
      });
    }
  getDefaultSupplier() {
      this.itemService.getDefaultSupplier().subscribe(data => {
      this.defaultSupplierList = data;
      });
     }
  getItemList() {
       this.getUom();
       this.getDefaultSupplier();
       this.binService.getAllItem().subscribe(
       (data: HttpResponse<any>) => {
       this.itemReportList = data.body;
       this.csvListDownload = [];
       this.itemReportList.forEach(item => {
       this.uomList.forEach(uom=> {
       if(item.uom.id==uom.id){
       item.uomName = uom.name;
        }
      });
      this.defaultSupplierList.forEach(defaultSupplierId=>{
      if(item.defaultSupplierId==defaultSupplierId.id){
      item.defaultSupplierCompanyName=defaultSupplierId.companyName;
      }
     });
     if(item.defaultSupplierId===null)
     {
     item.defaultSupplierCompanyName='null';
     }
     if(item.defaultBin == null){
          this.binName = '';
       } else{
          this.binName = item.defaultBin.name;
       }
     if(item.defaultLocation === null){
           this.locationName = '';
       } else{
          this.locationName = item.defaultLocation.name;
       }
     if(item.defaultWarehouse === null){
         this.warehouseName = '';
       } else{
         this.warehouseName = item.defaultWarehouse.name;
     }
     if(item.valuationMethod == null){
          this.valuationName = '';
        } else {
          this.valuationName = item.valuationMethod.name;
     }
     if(item.isActive== true ){
          this.active='YES';
       }
     else if(item.isActive==false){
          this.active='NO'
       }
     else{
         this.active=item.isActive
       }
         if(item.isSubRecipe == true){
          this.isSubRecipe = 'Yes';
         } else if(item.isSubRecipe === false) {
          this.isSubRecipe = 'No';
         }
         this.csvListDownload.push({
                           SerialNo: this.count++,
                           code: item.code,
                           itemName: item.name,
                           category: item.category.name,
                           subcategory: item.subcategory.name,
                           valuation: this.valuationName,
                           barcode: item.barcode != null ? item.barcode : '',
                           uom: item.uomName,
                           location: this.locationName != null ? this.locationName : '',
                           warehouse:this.warehouseName != null ? this.warehouseName : '',
                           bin: this.binName,
                           management: item.managementMethod.name,
                           defaultSupplier: item.defaultSupplierCompanyName,
                           active: this.active,
                           col1: item.col1,
                           col2: item.col2,
                           col3: item.col3,
                           col4: item.col4,
                           col5: item.col5,
                           col6: item.col6,
                           isSubRecipe : this.isSubRecipe,
         });
      });
      new AngularCsv(this.csvListDownload, 'ItemMaster', this.csvOptionsDownload);
          this.listingCount = data.body.length;
          this.totalCount = Number(data.headers.get('X-Total-Count'));
          this.count=1;
        },
        (err: any) => {
      }
      );
      }
      downloadCSV(){
        this.getItemList();
      }
      itemSampleCSV(lazyLoadData: Paginator) {
             this.binService.getItem(lazyLoadData).subscribe(
                 (data: HttpResponse<any>) => {
                           this.sampleItemCSVList = data.body;
                           this.csvList = [];
                           this.sampleItemCSVList.forEach(item => {
                           item.currentStock = null;
                           this.csvList.push({
                           });
                           });
                         new AngularCsv(this.csvList, 'Sample_CSV', this.csvOptions);
                      },
                     (err: any) => { }
                   );
           }

          download_Sample_CSV() {
            this.itemSampleCSV(this.paginator);
          }

       submit()
       {
         this.uploadTrue = false;
         const formData = new FormData();
         formData.append('multipartFile', this.csvFile);
         this.itemService.uploadCSV(formData).subscribe(
           (data: HttpResponse<any>) => {
            if (navigator.onLine === true){
             this.toastrMsgService.success(
               '',
               this.message.uploadCsvSuccess,
               this.message.toastrTime
             );
            }
             this.getItem(this.paginator);
            },
           (err: HttpResponse<any>) => {
             if (navigator.onLine === false) {
               this.toastrMsgService.warning(
                 '',
                 this.message.noInternet,
                 this.message.toastrTime
               );
             } else {
               this.toastrMsgService.error(
                 '',
                 this.message.errorMsg,
                 this.message.toastrTime
               );
             }
           }
         );
       }

       cancel() {
           this.uploadTrue = false;
           this.displayDialog = false;
         }
         // on select the csv file to upload
         onSelectFile(event: any) {
           this.uploadTrue = false;
           if (event.target.files) {
             if (
               this.fileUploadService.checkFileType(
                 event.target.files[0].type,
                 event.target.files[0].name
               )
             ) {
               this.csvFile = event.target.files[0];
               this.fileName = event.target.files[0].name;
               this.uploadTrue = true;
             } else {
               this.toastrMsgService.error(
                 '',
                 this.message.csvFailure,
                 this.message.toastrTime
               );
             }
             event.target.value = '';
           }
         }
   }
