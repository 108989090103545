import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private api: any;
  location: any;
  constructor(public http: HttpClient) {
    this.api = environment.API_ENDPOINT;

  }

  getLocation(lazyLoadData: any): Observable<HttpResponse<any>> {

    return this.http.get<any>(`location?page=${lazyLoadData.pageNo}&size=${lazyLoadData.limit}&sort=${lazyLoadData.sortOrder}`,{
      observe: 'response'
    });

  }

  getAllLocations(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`all-locations`,{
      observe: 'response'
    });
  }

  getlocationItemList(): Observable<HttpResponse<any>> {

      return this.http.get<any>(`locations`, {
        observe: 'response'
      });
    }

  editLocation(locationId: any): Observable<HttpResponse<any>> {

    return this.http.get<any>(`location/${locationId}`, {
      observe: 'response'
    });
  }
  addLocation(formData: any): Observable<any> {
    const body = formData;
    return this.http.post(`location`, body);
  }
  editLocationPost(formData: any): Observable<any> {

    const body = formData;
    return this.http.put(`location`, body);
  }
  deleteLocation(id: number) {

    return this.http.delete(`location/${id}`);

  }

  // Setter and gettter for locations
  setLocation(data: any) {
    this.location = data;
  }

  getSavedLocation() {
    return this.location;
  }
}
