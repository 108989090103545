import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { debug } from 'util';
const httpOptions = {
  headers: new HttpHeaders({
    'api-end-type': 'ezyprocure'
  })
};
@Injectable({
  providedIn: 'root'
})
export class SubReceipeService {

  private api: any;
  constructor(public http: HttpClient) {
    this.api = environment.API_ENDPOINT;
  }

  addSubReceipeData(subrecipeData: any): Observable<any> {
    const body = subrecipeData;
    return this.http.post(`sub-recipe`, body);
   }

  getSubRecipeById(id: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(`sub-recipe/${id}`, {
      observe: 'response'
    });
   }

   editSubRecipeById(companyID: any, parentItemCode:any ): Observable<HttpResponse<any>> {
    return this.http.get<any>(`sub-recipe/lst?companyId=${companyID}&parentItemCode=${parentItemCode}`, {
      observe: 'response'
    });
  }

  updateSubReceipeData(subrecipeData: any): Observable<any> {
    const body = subrecipeData;
    return this.http.put(`sub-recipes`, body);
   }

   viewSubRecipe(parentItemCode: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(`sub-recipe/view?parentItemCode=${parentItemCode}`, {
      observe: 'response'
    });
   }

   deleteSubreceipe(id: number) {
    return this.http.delete(`sub-recipe/${id}`);
  }

  deleteSubRecipeByParentItemCode(parentItemCode: any) {
   return this.http.delete(`sub-recipes/${parentItemCode}`);
 }

}
