import { ROLE_BUYER, DEFAULT_MGMT_METHOD } from './../../../core/constants/common.constants';
import { Component, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';


import { CategoryService } from '@app/core/services/category.service';
import { SubCategoryService } from '@app/core/services/sub-category.service';
import { Breadcrumb } from '@app/shared/models/breadcrumb.model';
import { FormGroup } from '@angular/forms';
import { Col } from '@app/shared/models/table-header.model';
import { WarehouseService } from '@app/core/services/warehouse.service';
import { ItemService } from '@app/core/services/item.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddInventoryComponent } from '../add-inventory/add-inventory.component';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';
import { BinService } from '@app/core/services/bin.service';
import { LocationService } from '@app/core/services/location.service';
import { location } from 'ngx-bootstrap/utils/facade/browser';
import { take, takeLast } from 'rxjs/operators';
import { Dropdown } from 'primeng/primeng';

type AlternateUoM = { uomName: any, conversionFactor: number, baseUom:any, conversionFactorBaseUom: number };
type AlternateUomWithItem = {id:any,alternateUomId:any, alternateUomName:any,alternateConversionFactor: number, baseUomId:any, baseUomName: any, baseUomFactor: number};
type TabModel = {code: string, header: string, active: boolean};
type LocationDetail = { locationId: number, locationName: string, minLevel: number, maxLevel: number, reorderLevel: number, reorderQty: number};

@Component({
  selector: 'app-add-items',
  templateUrl: './add-items.component.html',
  styleUrls: ['./add-items.component.scss']
})
export class AddItemsComponent implements OnInit {
  @ViewChild('ddStatus') ddStatus: Dropdown;
  breadcrumb: Breadcrumb[];
  category: string[];
  display: boolean;
  bsModalRef: BsModalRef;
  price: any;
  altUomData: any;
  catName: any;
  cols: Col[];
  itemCode: any;
  subCategory: any;
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  itemName: any;
  uom: any;
  alternate: any;
  tableContents: any = [];
  message: any;
  quantity: any;
  alternateUom: any;
  location: any;
  displayTable: boolean;
  warehouse: any;
  bin: any;
  locationList: any;
  warehouseList: any;
  binList: any;
  uomListName: any;
  lactionName: any;
  loctionName: any;
  warehouseName: any;
  binName: any;
  toggle: boolean;
  catList: any;
  SubcatList: any;
  itemId:any;

  uomList: any[] = [];
  batchList: any;
  supplierList: any;
  defaultSupplierList: any;
  valuation: any;
  batchData: any;
  displayDialog: boolean;
  batchCodeBoolean: boolean;
  uomListData: any;
  uomAlterListData: any;
  locationId: any;
  warehouseId: any;
  binId: any;
  qtyValue: any;
  // paginator: any;
  roleBuyer: boolean;
  showDates: boolean;
  addInventory = {
    locationInventory: null,
    alternativeUomId: null,
    category: null,
    valuation: null,
    subCategory: null,
    defaultSupplier: null,
    itemCode: null,
    itemName: null,
    cost: null,
    alternateUomQty: undefined,
    barCode: null,
    batchcode: { name: 'Yes' },
    uom: null,
    alternateUom: undefined,
    location: null,
    // alternateUomQty:null,
    warehouse: null,
    batchManagement: null,
    bin: null,
    isActive: true,
    isSubRecipe: false,
    inActiveReason: '',
    activationDate: null,
    baseUomQty: undefined,
    baseUoM:undefined,
    col1: null,
    col2: null,
    col3: null,
    col4: null,
    col5: null,
    col6: null,
  };
  inventoryDetail = {
    location: null,
    warehouse: null,
    bin: null
  };
  inventoryWarehouseList = [];
  inventoryBinList = [];
  isItemDuplicate: boolean;
  minDate = new Date();
  alternateUoMsList: any[] = [];
  alternateUoMDropdown: any[] = [];
  alternateUoMsListItem:any[]=[];
  /* Tabs declaration */
  inventoryTab: TabModel = { code:"INV", header: "Inventory Details", active: false };
  locationTab: TabModel = { code:"LOC", header: "Inventory Levels", active: true };
  currentTab: TabModel;
  tabsList: TabModel[] = [];
  /* Location details */
  locationDetails: LocationDetail[] = [];
  col1: null;
  col2: null;
  col3: null;
  col4: null;
  col5: null;
  col6: null;

  constructor(
    private router: Router,
    private itemService: ItemService,
    private el: ElementRef,
    public renderer: Renderer,
    private subCategoryService: SubCategoryService,
    private categoryService: CategoryService,
    private modalService: BsModalService,
    private binService: BinService,
    private locationService: LocationService,
    private toastrMsgService: ToastrMsgService,
    private warehouseService: WarehouseService
  ) { }

  ngOnInit() {
    //populate tabs
    this.tabsList = [ this.locationTab ];
    this.currentTab = this.locationTab;

    this.roleBuyer = (localStorage.getItem('role') === ROLE_BUYER) ? true : false;
    this.displayDialog = false;
    this.getbatch();
    this.getValuation();
    if (this.roleBuyer) {
      this.getDefaultSupplier();
    }
    this.getCategoryList();
    this.getUom();
    this.getDefaultItemDetails();
    //this.fetchInventoryLevels();
    // this.getBatchList();
    this.displayTable = false;
    this.cols = [
      { field: 'location', header: 'Location' },
      { field: 'warehouseName', header: 'Warehouse Name' },
      { field: 'binId', header: 'Bin (Shelf/Rack) ID' },
      { field: 'binName', header: 'Bin (Shelf/Rack) Name' }
    ];
    this.getLocation();
    this.fetchAllLocations();

    this.message = Constants.MESSAGES;

    this.breadcrumb = [
      { label: 'Manage Items', routerLink: '/manage-items' },
      { label: 'Add Item', routerLink: '' }
    ];
    this.cols = [
      {
        field: 'location',
        header: 'Location'
      },
      { field: 'warehouse', header: 'Warehouse' },
      { field: 'bin', header: 'Bin (Shelf/Rack)' },
      { field: 'batch', header: 'Batch#' },
      { field: 'productiondate', header: 'ProductionDate' },
      { field: 'expirydate', header: 'Expirydate' },
      { field: 'instock', header: 'InStock' },
      //{ field: 'allocatedstock', header: 'AllocatedStock' },
      //{ field: 'availablestock', header: 'Available Stock' },
      //{ field: 'onOrder', header: 'On Order' }
    ];
    // this.ddStatus.filled = true;
    // this.paginator.paginator = true;
    this.showDates = true;
    this.alternateUoMsList = [];
    this.alternateUoMsListItem=[];
  }

  fetchAllLocations() {
    this.locationService.getAllLocations().subscribe((response)=> {
      let repsonseData = response.body;
      if(repsonseData && repsonseData.length) {
        this.locationDetails = repsonseData.map((location) => {
          let locationDetail: LocationDetail = {
            "locationId": location.id,
            "locationName": location.name,
            "maxLevel": null,
            "minLevel": null,
            "reorderLevel": null,
            "reorderQty": null
          }
          return locationDetail;
        });
      }
      else {
        this.locationDetails = [];
      }
    },
    (error) => {
      this.toastrMsgService.error("Error", "Failed to retrieve all locations", this.message.toastrTime);
    });
  }

  changeEveLoc(locdata) {
    this.locationId = locdata.id;

    this.loctionName = locdata.name;
    if (locdata) {
      this.getWarehouse(locdata.id);
    }
  }

  changeWarLoc(warData) {

    this.warehouseId = warData.id;
    this.warehouseName = warData.name;

    if (warData) {
      this.getBinList(warData.id);
    }
  }
  changeWarLo(warData) {

    this.warehouseId = warData.id;
    this.warehouseName = warData.name;

    if (warData) {
      this.getBinList(warData.id);
    }
  }
  changeBinLoc(binData) {
    this.binId = binData.id;
    this.binName = binData.name;
  }
  changeEveCat(catData) {
    this.catName = catData.name;
    if (catData) {
      this.getSubCategoryList(catData.id);
    }
  }

  changeEveBatch(batchData) {
    if (batchData.name === 'No') {
      this.cols = [
        { field: 'location', header: 'Location' },
        { field: 'warehouse', header: 'Warehouse' },
        { field: 'bin', header: 'Bin (Shelf/Rack)' },
        { field: 'batch', header: 'Batch#' },
        { field: 'instock', header: 'InStock' },
        { field: 'allocatedstock', header: 'AllocatedStock' },
        { field: 'availablestock', header: 'Available Stock' },
        { field: 'onOrder', header: 'On Order' }
      ];
      this.showDates = false;
    } else {
      this.cols = [
        { field: 'location', header: 'Location' },
        { field: 'warehouse', header: 'Warehouse' },
        { field: 'bin', header: 'Bin (Shelf/Rack)' },
        { field: 'batch', header: 'Batch#' },
        { field: 'productiondate', header: 'ProductionDate' },
        { field: 'expirydate', header: 'Expirydate' },
        { field: 'instock', header: 'InStock' },
        { field: 'allocatedstock', header: 'AllocatedStock' },
        { field: 'availablestock', header: 'Available Stock' },
        { field: 'onOrder', header: 'On Order' }
      ];
      this.showDates = true;
    }

  }


  changeUomCat(uomData) {
    this.uomListData = uomData;
    this.uomListName = uomData.name;
    this.filterAlternateUoms(uomData.name);
  }

  /**
   * @description make UoM list without base UoM
   * @param {string} baseUoM - base UoM name
   */
  filterAlternateUoms(baseUoM: string) {
    this.alternateUoMDropdown = this.uomList.filter(uom => {
      return uom.name != baseUoM;
    })
  }

  // changeUomAlt(altData) {
  //   this.altUomData = altData.name;
  // }

  changeAlterUomCat(uomAlterData) {
    this.uomAlterListData = uomAlterData;
  }
  getBinList(id: any) {

    this.binService.getBinList(id).subscribe(
      data => {
        this.binList = data.body;
        this.addInventory.bin = null;
      },
      (err: any) => { }
    );
  }
  getCategoryList() {
    this.categoryService.getCategory().subscribe(data => {
      this.catList = data.body;
    });

  }
  getBatchList() {
    this.itemService.getBatch().subscribe(data => {
      this.batchList = data.body;
    });

  }
  getUom() {
    this.itemService.getUom().subscribe(data => {
      this.uomList = data;
    });

  }
  getDefaultSupplier() {

    this.itemService.getDefaultSupplier().subscribe(data => {
      this.defaultSupplierList = data;
      });

  }
  getDefaultSupplierName(id: any) {
    this.itemService.getDefaultSupplierName(id).subscribe(data => {
      this.defaultSupplierList = data;


    });

  }
  getValuation() {
    this.itemService.getValuation().subscribe(data => {
      this.valuation = data;
    });
  }
  getbatch() {
    this.itemService.getBatch().subscribe(data => {

      this.batchData = data.body;
      this.addInventory.batchcode = {
        name: DEFAULT_MGMT_METHOD
      };
    });

  }
  getSubCategoryList(id: any) {
    this.subCategoryService.getSubCategory(id).subscribe(data => {
      this.SubcatList = data.body;
    });

  }
  getWarehouse(id: any) {
    this.warehouseService.getWarehouseList(id).subscribe(
      data => {
        this.warehouseList = data.body;
        this.addInventory.warehouse = null;
        this.addInventory.bin = null;
      },
      (err: any) => { }
    );

  }
  showDialog() {
    this.display = true;
  }

  cancel() {
    this.display = false;
  }
  addFieldValue() {
    this.fieldArray.push(this.newAttribute);
    this.newAttribute = {};
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }

  // fetchInventoryLevels() {
  //   this.itemService.getInventoryLevels(Number(this.selectedItemId)).subscribe(response => {
  //     let responseData: LocationDetail[] = response.body;
  //     if (responseData && responseData.length > 0) {
  //       this.locationDetails = responseData;
  //     }
  //     else {
  //       this.locationDetails = [];
  //     }
  //   });
  // }

  // Function to open the modal for inventory details entry
  openModalAddInventory() {
    if (this.addInventory.batchcode == null) {
      this.addInventory.batchcode.name = DEFAULT_MGMT_METHOD;
    }
    if (this.addInventory.batchcode.name === DEFAULT_MGMT_METHOD) {
      this.batchCodeBoolean = true;
    } else {
      this.batchCodeBoolean = false;
    }
    const initialState = {
      location: this.inventoryDetail.location,
      warehouse: this.inventoryDetail.warehouse,
      bin: this.inventoryDetail.bin,
      batchCodeBoolean: this.batchCodeBoolean,
      locationList: this.locationList,
      inventoryBinList: this.inventoryBinList,
      inventoryWarehouseList: this.inventoryWarehouseList,
      tableContents: this.tableContents
    };
    this.modalService.onHide.pipe(take(1)).subscribe((reason: any) => {
      if (reason ? reason.length > 0 : 0) {
        reason.forEach(element => {
          this.tableContents.push(element);

        });

      }

      this.displayTable = true;

    });
    this.bsModalRef = this.modalService.show(AddInventoryComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  checkMinMaxLevel(locationLevel: LocationDetail) {
    return Number(locationLevel.maxLevel) >= Number(locationLevel.minLevel);
  }

  checkReorderLevel(locationLevel: LocationDetail) {
    return Number(locationLevel.maxLevel) >= Number(locationLevel.reorderLevel) && Number(locationLevel.reorderLevel) >= Number(locationLevel.minLevel);
  }

  validateInventoryLevel() {
    let isMaxLevelValid = true;
    let isReorderLevelValid = true;

    if(this.locationDetails.length) {
      isMaxLevelValid = this.locationDetails.every(this.checkMinMaxLevel);
      if(!isMaxLevelValid) {
        this.toastrMsgService.error("Invalid Data", "Minimum level exceeds maximum level for some locations", this.message.toastrTime);
      }
      else {
        isReorderLevelValid = this.locationDetails.every(this.checkReorderLevel);
        if(!isReorderLevelValid) {
          this.toastrMsgService.error("Invalid Data", "Reorder level should be between minimum level and maximum level", this.message.toastrTime);
        }
      }
      return isMaxLevelValid && isReorderLevelValid;
    }
  }

  onSubmit(addCategoryForm: NgForm) {

    if (addCategoryForm.value.defaultLocation != null && addCategoryForm.value.defaultWarehouse == null) {
      this.toastrMsgService.warning('', 'Please Choose a Warehouse', this.message.toastrTime);
    }
    else {

      if(!this.validateInventoryLevel()) {
        return;
      }

      const tableDataArr = [];

      this.tableContents.forEach((element) => {
        const rowObj = {
          location: {
            id: element.locId
          },
          warehouse: {
            id: element.warId
          },
          bin: element.binId ? { id: element.binId } : null,
          batches: [
            {
              batchNumber: element.namebatch,
              productionDate: element.namest,
              expiryDate: element.nameed,
              availableStock: element.nameavas,
              allocatedStock: element.namealloc,
              onOrder: element.nameonor,
              inStock: element.nameinst
            }
          ]
        };
        tableDataArr.push(rowObj);
      });

      const ItemDto = {
        name: this.addInventory.itemName,
        code: this.addInventory.itemCode,
        uom: { id: addCategoryForm.value.uomId.id },
        alternativeUom: this.addInventory.alternateUom ? { id: this.addInventory.alternateUom.id } : null,
        defaultSupplierId: this.addInventory.defaultSupplier ? this.addInventory.defaultSupplier.id : null,
        //defaultSupplierId:this.addInventory.defaultSupplier.id,
        alternateUomQty: this.addInventory.alternateUomQty,
        defaultWarehouse: addCategoryForm.value.defaultWarehouse,
        defaultLocation: addCategoryForm.value.defaultLocation,
        defaultBin: addCategoryForm.value.defaultBin,
        management: { name: 'BATCH' },
        managementMethod: addCategoryForm.value.defaultBatch,
        category: { id: this.addInventory.category.id },
        subcategory: { id: this.addInventory.subCategory.id },
        valuationMethod: addCategoryForm.value.defaultValuation,
        inventoryStatus: true,
        price: this.addInventory.isSubRecipe ? this.addInventory.cost : 0,
        type: this.displayDialog,
        barcode: this.addInventory.barCode,
        stockInList: tableDataArr,
        isActive: this.addInventory.isActive,
        isSubRecipe: this.addInventory.isSubRecipe,
        inActiveReason: this.addInventory.inActiveReason,
        activationDate: this.addInventory.activationDate,
        baseUomQty: this.addInventory.baseUomQty,
        col1: this.addInventory.col1,
        col2: this.addInventory.col2,
        col3: this.addInventory.col3,
        col4: this.addInventory.col4,
        col5: this.addInventory.col5,
        col6: this.addInventory.col6
      };

        this.itemService.addItem(ItemDto)
          .subscribe(
            (data: any) => {
              this.toastrMsgService.success('', this.message.itemaddSuccess, this.message.toastrTime);
              this.itemId = data.id;

              this.itemService.postInventoryLevels(Number(this.itemId), this.locationDetails)
                .subscribe(
                  (response) => { },
                  (error) => {
                    this.toastrMsgService.error('Error', 'Inventory details could not be saved', this.message.toastrTime);
                  });

              this.itemService.editAlternateUom(this.itemId, this.alternateUoMsListItem)
                .subscribe(
                  (data: any) => {},
                  (err: any) => {
                    this.toastrMsgService.error('Alternate UoM', 'Error occured on saving', this.message.toastrTime);
                  });

              this.router.navigateByUrl('/manage-items');
            },
            (err: any) => {
              this.toastrMsgService.error('', this.message.errorMsg, this.message.toastrTime);
            });
    }
  }
  onChange(itemStatus) {
    this.addInventory.isActive = itemStatus;
    this.addInventory.inActiveReason = '';
  }

  onSubRecipeChange(subrecipe) {
    this.addInventory.isSubRecipe = subrecipe;
  }
  showCancelBox() {
    this.router.navigateByUrl('/manage-items');
  }
  onRadioChange() {

  }
  getLocation() {
    this.itemService.getLocationList().subscribe(
      data => {
        this.locationList = data.body;
      },

      (err: any) => {
        this.toastrMsgService.error(
          '',
          this.message.errorMsg,
          this.message.toastrTime
        );
      }
    );
  }


  // Function to detect the change in inventory location
  changeInventoryLocation() {
    this.getInventoryWarehouses();
  }

  // Function to get warehouses based on the selected location
  getInventoryWarehouses() {
    this.warehouseService.getWarehouseList(this.inventoryDetail.location.id).subscribe(
      data => {
        this.inventoryWarehouseList = data.body;
        this.inventoryDetail.warehouse = null;
        this.inventoryDetail.bin = null;
      },
      (err: any) => { }
    );
  }

  // Function to detect the change in inventory warehouse
  changeInventoryWarehouse() {
    this.getInventoryBins();
  }

  // Function to get the bins based on selected warehouse
  getInventoryBins() {
    this.binService.getBinList(this.inventoryDetail.warehouse.id).subscribe(
      data => {
        this.inventoryBinList = data.body;
        this.inventoryDetail.bin = null;
      },
      (err: any) => { }
    );
  }

  // Function to get the default item details baserd on the previous selections
  getDefaultItemDetails() {
    // this.itemService.getDefaultItemDetails().subscribe(data => {
    //   this.addInventory.location = data.body.location;
    //   this.addInventory.warehouse = data.body.warehouse;
    //   this.addInventory.bin = data.body.bin ? data.body.bin : null;
    //   if (this.addInventory.location) {
    //     this.changeEveLoc(this.addInventory.location);
    //     this.getWarehouse(this.addInventory.location.id);
    //     this.getBinList(this.addInventory.warehouse.id);
    //   }
    // }, (error => {

    // }));
  }

  // Function to cancel the alternate uom selection
  cancelAltUom() {
    this.addInventory.alternateUomQty = null;
    this.addInventory.baseUomQty=null;
    this.addInventory.alternateUom = {};
    this.display = !this.display;
  }

  // validate cost
  isValidCost() {
    return /^\s*(?=.*[1-9])\d*(?:\.\d{1,20})?\s*$/.test(this.addInventory.cost);
  }

  // Function to submit the alternate uom
  submitAltUom() {

    // this.qtyValue = this.addInventory.alternateUomQty;
    // this.altUomData = this.addInventory.alternateUom.name;
    this.display = !this.display;
    this.getUoMNames();
    this.alternateUoMsList.forEach(uom => {
                    let _auom: AlternateUomWithItem={id:null,alternateUomId:uom.uomName.id, alternateUomName:uom.uomName.name, alternateConversionFactor: uom.conversionFactor, baseUomId:this.addInventory.uom.id, baseUomName:this.addInventory.uom.name, baseUomFactor:uom.conversionFactorBaseUom };
                    this.alternateUoMsListItem.push(_auom);
                    });
        // } else {
    //   this.toastrMsgService.error('', 'Please enter a valid conversion factor', this.message.toastrTime);
    // }
  }

  checkDuplicate(type, item) {
    this.itemService.checkDuplication(type, item).subscribe(
      (data: any) => {
        if (data) {
          this.isItemDuplicate = data.body;
        }
      }, err => {

      }
    );
  }

  addUoMToList() {
    const regexp = /^\s*(?=.*[1-9])\d*(?:\.\d{1,20})?\s*$/;

    if (!this.addInventory.alternateUomQty || !regexp.test(this.addInventory.alternateUomQty)) {
      this.toastrMsgService.error('', 'Please enter a valid conversion factor', this.message.toastrTime);
      return;
    }

    if (!this.addInventory.baseUomQty || !regexp.test(this.addInventory.baseUomQty)) {
          this.toastrMsgService.error('', 'Please enter a valid conversion factor', this.message.toastrTime);
          return;
        }

    if (!this.addInventory.alternateUom) {
      this.toastrMsgService.error('', 'Please choose an alternate UoM', this.message.toastrTime);
      return;
    }

    if (this.checkDuplicateUoM()) {
      this.toastrMsgService.error('', 'Duplicate entry', this.message.toastrTime);
      return;
    }

    let _uom: AlternateUoM = { uomName: this.addInventory.alternateUom, conversionFactor: this.addInventory.alternateUomQty, baseUom:this.addInventory.uom.name,conversionFactorBaseUom:this.addInventory.baseUomQty };
    this.alternateUoMsList.push(_uom);
    this.addInventory.alternateUom = this.addInventory.alternateUomQty = undefined;
    this.addInventory.baseUoM = this.addInventory.baseUomQty = undefined;
  }

  checkDuplicateUoM(): boolean {
    let hasDuplicate = false;
    this.alternateUoMsList.forEach(uom => {
      if (uom.uomName.name == this.addInventory.alternateUom.name) {
        hasDuplicate = true;
      }
    });
    return hasDuplicate;
  }

  getUoMNames(): void {
    let maxIndex: number = this.alternateUoMsList.length - 1;
    let selectedUoMs: string = "";
    this.alternateUoMsList.forEach((uom: AlternateUoM, index: number) => {
      selectedUoMs = index != maxIndex ? (selectedUoMs + uom.uomName.name + ", ") : (selectedUoMs + uom.uomName.name);
    })

    this.addInventory.alternativeUomId = selectedUoMs;
  }
  // // Function to check the number value for e,+,-
  // pureNumberCheck(value) {
  //   if (value.indexOf('e') >= 0) {
  //     document.getElementById('cost').value = null;
  //   }
  // }
}
