import { CategoryService } from '@app/core/services/category.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageCategoryRoutingModule } from './manage-category-routing.module';
import { ListCategoryComponent } from '@app/feature/manage-category/list-category/list-category.component';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import {PaginatorModule} from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AddCategoryComponent } from './add-category/add-category.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EditCategoryComponent } from './edit-category/edit-category.component';

@NgModule({
  declarations: [ListCategoryComponent, AddCategoryComponent, EditCategoryComponent],
  imports: [
    CommonModule,
    PaginatorModule,
    ManageCategoryRoutingModule,
    FormsModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    CheckboxModule,
    ChipsModule,
    TooltipModule,
    BreadcrumbModule,
    ModalModule
  ],
  providers: [ConfirmationService, CategoryService],
  exports: [ListCategoryComponent],
  entryComponents: [
    AddCategoryComponent, EditCategoryComponent
],
})
export class ManageCategoryModule { }
