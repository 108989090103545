import { Component, OnInit, ViewChild } from '@angular/core';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { SelectItem } from 'primeng/api';
import { Paginator } from '@app/shared/models/paginator.model';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { MenuItem, Breadcrumb } from 'primeng/primeng';
import * as Constants from '@app/core/constants/common.constants';
import { Col } from '@app/shared/models/table-header.model';
import { HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { Dropdown } from 'primeng/primeng';
import { FileUploadService } from '@app/core/services/fil-upload.service';
import { ConfirmationService } from 'primeng/api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-stock-take',
  templateUrl: './stock-take.component.html',
  styleUrls: ['./stock-take.component.scss']
})
export class StockTakeAddComponent implements OnInit {
  breadcrumb: MenuItem[];
  location: any;
  uploadTrue: boolean = false;
  enableStockTake: boolean = false;
  stockUpdateList: any[] = [];
  stockUpdateListDTO: any = [];
  redList: any = [];
  stockChange: boolean = false;
  stock: any;
  fileName: string;
  tableDisplay: boolean = false;
  message: any;
  wareHouse: any;
  itemList: any;
  categories: any;
  stockDetailsList: any[] = [];
  stockDetailsReportList: any;
  selectedWare: any = { id: '' };
  selectedItem: any = { id: '' };
  subCategories: any = { id: '' };
  selectedLocation: any = { id: '' };
  selectedCategory: any = { id: '' };
  selectedSubCategory: any;
  cols = [];
  checked: boolean;
  scrollEnable: boolean = false;
  clear: boolean = true;
  paginator: Paginator;
  pageCount: number;
  totalCount: number;
  csvOptions: any;
  csvList: any;
  csvFile: File;
  filterText: string;
  documentDate = new Date();
  remarks: string = "";
  restrictSave: boolean = false;
  itemUoMList: any = [];
  showDialog = false;
  selectedIndex: number = undefined;
  selectedItemName: string = undefined;
  totalInvStock: string = undefined;
  totalCurrentStockValue: string = undefined;
  totalCurrentStock: string = undefined;
  totalInvValue: string = undefined;
  totalRate: string = undefined;
  rate: string = undefined;
  showPreview: boolean = false;
  transactionErrorList: any[] = [];

  constructor(
    public stockService: ManageStockService,
    public fileUploadService: FileUploadService,
    private datePipe: DatePipe,
    private toastrMsgService: ToastrMsgService,
    public route: Router
  ) {
    this.locationDetails();
    this.categoryDetails();
    this.itemDetails();
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,

      useBom: true,
      noDownload: false,
      headers: [
        'Item code',
        'Item name',
        'Category',
        'Subcategory',
        'Batch',
        'Warehouse code',
        'Expiry Date',
        'Inventory UOM',
        'In Stock',
        'Alt UOM',
			  'Alt In Stock'
      ]
    };
  }

  @ViewChild('ddStatusWare') ddStatusWare: Dropdown;
  @ViewChild('ddStatusLoc') ddStatusLoc: Dropdown;
  @ViewChild('ddStatusSub') ddStatusSub: Dropdown;
  @ViewChild('ddStatusCat') ddStatusCat: Dropdown;
  @ViewChild('ddStatusItem') ddStatusItem: Dropdown;

  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;
  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.cols = [
      { field: 'itemCode', header: 'Item Code', width: '60px', sortable: true, editable: false, number: false },
      { field: 'itemName', header: 'Item Name', width: '150px', sortable: true, editable: false, number: false },
      { field: 'uom', header: 'UOM', width: '40px', sortable: false, editable: false, number: false },
      { field: 'currentStock', header: 'Current Stock - Base UOM', width: '85px', sortable: false, editable: true, number: true },
      { field: 'altUom', header: 'Alt. UOM', width: '85px', sortable: false, editable: true, number: false },
      { field: 'altUomCurrentStock', header: 'Current Stock - Alternate UOM', width: '85px', sortable: false, editable: true, number: true },
      { field: 'warehouseCode', header: 'Warehouse', width: '85px', sortable: false, editable: false, number: false },
      { field: 'locationCode', header: 'Location', width: '85px', sortable: false, editable: false, number: false },
      { field: 'subcategory', header: 'Subcategory', width: '85px', sortable: true, editable: false, number: false },
      { field: 'batch', header: 'Batch#', width: '40px', sortable: false, editable: false, number: false },
      { field: 'newDate', header: 'Expiry Date', width: '40px', sortable: false, editable: false, number: false },
      { field: 'bin', header: 'Bin (Shelf/Rack)', width: '40px', sortable: false, editable: false, number: false }
    ];
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
    this.breadcrumb = [
      { label: 'Manage Stock Take', routerLink: '/stock-take-list' },
      { label: 'New Stock Take', url: '' }
    ];
    this.stockDetails(this.paginator);
  }

  get disableStockTake() {
    return !this.enableStockTake || this.restrictSave;
  }
  
  previewAltUomUpdate(event, op, index: number) {
    op.toggle(event);
    this.itemUoMList = this.stockUpdateList[index].itemUoMList;
    this.selectedIndex = index;
    this.selectedItemName = this.stockUpdateList[index].itemName;
  }

  openPreview() {
    this.showPreview = true;
  }

  closeErrorList() {
    this.transactionErrorList = [];
  }

  calculateStockValue(currentStock, baseConversionFactor, alternateUnits) {
    let invStock = (parseFloat(isNaN(currentStock) ? 0 : currentStock) / parseFloat(alternateUnits)) * parseFloat(baseConversionFactor);
    return invStock.toFixed(3);
  }

  calculateCurrentStockValue(currentStock, rate) {
    let cStockValue = (parseFloat(currentStock) * parseFloat(rate));
    return cStockValue.toFixed(3);
  }

  calculateTotalRate(currentStockValue, currentStock) {
    let cRateValue = parseFloat(currentStockValue) / parseFloat(currentStock);
    return cRateValue.toFixed(3);
  }

  openDialog(index: number) {
    index -= this.paginator.pageNo * this.paginator.limit;
    this.itemUoMList = this.stockDetailsList[index].itemUoMList;
    this.selectedIndex = index;
    this.selectedItemName = this.stockDetailsList[index].itemName;
    this.calculateTotalInvStock('MAIN');
    // open dialog
    this.showDialog = true;
  }

  openUomDialogFromReview(index: number) {
    this.itemUoMList = this.stockUpdateList[index].itemUoMList;
    this.selectedIndex = index;
    this.selectedItemName = this.stockUpdateList[index].itemName;
    // this.calculateTotalInvStock();
    // open dialog
    this.showDialog = true;
  }

  calculateInvStock(uomIndex: number, screen: string) {
    let dataList = screen == 'REVIEW' ? this.stockUpdateList : this.stockDetailsList;
    if (uomIndex) {
      // do calculations
      let selectedItem = dataList[this.selectedIndex];
      let chosenAltUoM = selectedItem.alternateUoms.find(altUoM => {
        return altUoM.id == selectedItem.itemUoMList[uomIndex].id;
      });
      let invStock = this.calculateStockValue(selectedItem.itemUoMList[uomIndex].currentStock, chosenAltUoM.baseUomFactor, chosenAltUoM.alternateConversionFactor);
      selectedItem.itemUoMList[uomIndex].invCurrentStock = invStock;
      let cStockValue = this.calculateCurrentStockValue(selectedItem.itemUoMList[uomIndex].invCurrentStock, selectedItem.itemUoMList[uomIndex].rate);
      selectedItem.itemUoMList[uomIndex].currentStockValue = cStockValue;
    }
    else {
      dataList[this.selectedIndex].itemUoMList[uomIndex].invCurrentStock = (+dataList[this.selectedIndex].itemUoMList[uomIndex].currentStock).toFixed(3);
      let cStockValue = this.calculateCurrentStockValue(dataList[this.selectedIndex].itemUoMList[uomIndex].currentStock, dataList[this.selectedIndex].itemUoMList[uomIndex].rate);
      dataList[this.selectedIndex].itemUoMList[uomIndex].currentStockValue = cStockValue;
    }
    this.calculateTotalInvStock(screen);
  }

  calculateTotalInvStock(screen: string) {
    let dataList = screen == 'REVIEW' ? this.stockUpdateList : this.stockDetailsList;
    let selectedItem = dataList[this.selectedIndex];
    let _totalStock = 0;
    let _totalCurrentStock = 0;
    let _calcRate = 0;
    selectedItem.itemUoMList.forEach(uom => {
      if (uom.invCurrentStock && !isNaN(parseFloat(uom.invCurrentStock))) {
        _totalStock += parseFloat(uom.invCurrentStock);
      }

      if (uom.currentStockValue && !isNaN(parseFloat(uom.currentStockValue))) {
        _totalCurrentStock += parseFloat(uom.currentStockValue);
      }

    });
    this.totalInvStock = _totalStock.toFixed(3);
    this.totalCurrentStockValue = _totalCurrentStock.toFixed(3);
    selectedItem.currentStockValue = this.totalCurrentStockValue;
    selectedItem.currentStock = this.totalInvStock;
    _calcRate = _totalCurrentStock / _totalStock;
    this.rate = selectedItem.rate;
    // this.rate = _calcRate.toFixed(3);
    // selectedItem.rate = this.rate;
    this.modelChange(undefined, selectedItem);
  }

  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  // to get warehouse details and showing in dropdown
  warehouseDetails() {
    this.stockService.getWarehouse(this.selectedLocation).subscribe(
      (data: any) => {
        this.wareHouse = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  //  to get item details and showing the result in dropdown
  itemDetails() {
    this.stockService.getItemNames().subscribe(
      (data: any) => {
        this.itemList = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );

  }
  // to get location details and showing the result in dropdown
  locationDetails() {
    this.stockService.getLocation().subscribe(
      (data: any) => {
        this.location = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // to get the category details and showing in the dropdown
  categoryDetails() {
    this.stockService.getCategories().subscribe(
      (data: any) => {
        this.categories = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // to get the subcategory details and showing in the dropdown
  subCatDetails() {
    this.stockService.getSubCategories(this.selectedCategory.id).subscribe(
      (data: any) => {
        this.subCategories = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }
  // to get the stock details
  stockDetails(lazyLoadData: Paginator) {
    if (this.selectedWare == null) {
      this.selectedWare = [];
      this.selectedWare.id = '';
    }
    if (this.selectedItem == null) {
      this.selectedItem = [];
      this.selectedItem.id = '';
    }
    if (this.selectedSubCategory == null) {
      this.selectedSubCategory = [];
      this.selectedSubCategory.id = '';
    }
    if (this.selectedCategory == null) {
      this.selectedCategory = [];
      this.selectedCategory.id = '';
    }
    if (this.selectedLocation == null) {
      this.selectedLocation = [];
      this.selectedLocation.id = '';
    }
    this.tableDisplay = true;
    this.stockService
      .getStockDetails(
        this.selectedWare.id,
        this.selectedSubCategory.id,
        this.selectedCategory.id,
        this.selectedLocation.id,
        this.selectedItem.id,
        lazyLoadData.pageNo,
        lazyLoadData.limit,
        lazyLoadData.sortOrder
      )
      .subscribe(
        (data: HttpResponse<any>) => {
          this.stockDetailsList = data.body;
          this.csvList = [];
          this.stockDetailsList.forEach(item => {
            if (item.expiryDate !== null) {
              item.newDate = this.datePipe.transform(
                new Date(item.expiryDate),
                'dd-MM-yyyy'
              );
            } else {
              item.newDate = '';
            }
            item.currentStock = null;
            item.variation = 0;
            item.absolute = 0;
            item.absolute = item.absolute.toString();
            item.variation = item.variation.toFixed(3);
            item.color = null;

            this.stockUpdateList.forEach(itemUpdate => {
              if (Number(item.id) === Number(itemUpdate.id)) {
                item.currentStock = itemUpdate.currentStock;
                item.altUomCurrentStock = itemUpdate.altUomCurrentStock;
                item.variation = itemUpdate.variation;
                item.absolute = itemUpdate.absolute;
                item.color = itemUpdate.color;
              }
            });
            item.id = item.id;
            item.inStock = item.inStock.toFixed(3);
            item.value = item.value.toFixed(3);
            item.rate = item.rate.toFixed(3);
            item.originalInStock = item.inStock;
            item.allocated = item.allocated;
            item.inStockValue = item.value.toString();
            let inventoryUoM = { "id": item.uomId, "name": item.uom, "rate": item.rate };
            item.selectedUoM = inventoryUoM.id;
            item.itemUoMList = [];
            if (item.alternateUoms && item.alternateUoms.length > 0) {
              item.itemUoMList = item.alternateUoms.map(altUoM => {
                let _altUoM = { "id": altUoM.id, "name": altUoM.alternateUomName, "rate": item.rate };
                return _altUoM;
              });
            }
            item.itemUoMList.unshift(inventoryUoM);
            item.rowAlternateUoMs = [];
            if (item.itemUoMList.length) {
              item.rowAlternateUoMs = item.itemUoMList.slice(1);
            }
            if (item.rowAlternateUoMs.length == 1) {
              item.rowSelectedUom = item.rowAlternateUoMs[0].id;
            }
          });

          this.totalCount = Number(data.headers.get('X-Total-Count'));

          if (this.totalCount <= this.pageCount) {
            this.paginator.paginator = false;
          } else {
            this.paginator.paginator = true;
          }
          this.paginator.loading = false;
          this.paginator.totalCount = this.totalCount;
        },
        (err: any) => {
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              '',
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
          this.paginator.loading = false;
        }
      );
  }
  // location change
  change() {
    this.clear = false;
    this.selectedWare = [];
    this.selectedWare.id = '';
    this.warehouseDetails();
    this.stockDetails(this.paginator);
  }
  // category change
  catChange() {
    this.clear = false;
    this.selectedSubCategory = [];
    this.selectedSubCategory.id = '';
    this.subCatDetails();
    this.stockDetails(this.paginator);
  }
  // warehouse change
  wareChange() {
    this.stockDetails(this.paginator);
    this.clear = false;
  }
  //  item change
  itemChange() {
    this.stockDetails(this.paginator);
    this.clear = false;
  }
  // subcategory change
  subCatChange() {
    this.stockDetails(this.paginator);
    this.clear = false;
  }
  // on pagination call
  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      this.filterText = event.sortField;
      if (this.filterText === 'newDate') {
        this.filterText = 'batch.expiryDate';
      } else if (this.filterText === 'location') {
        this.filterText = 'warehouse.location.name';
      } else if (this.filterText === 'subcategory') {
        this.filterText = 'subcategory.name';
      } else if (this.filterText === 'category') {
        this.filterText = 'batch.item.category.name';
      } else if (this.filterText === 'itemName') {
        this.filterText = 'item.name';
      } else if (this.filterText === 'itemCode') {
        this.filterText = 'item.code';
      }
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = this.filterText + ',DESC';
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = this.filterText + ',ASC';
      }
    } else {
      this.paginator.sortOrder = 'createdDate,DESC';
    }

    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.stockDetails(this.paginator);
  }

  removeItem(index) {
    this.stockUpdateList.splice(index, 1);
    this.enableStockTake = this.stockUpdateList.length != 0;
  }

  // each time input changes
  modelChange(event: any, rowData: any) {
    this.enableStockTake = false;
    let index = this.stockUpdateList.findIndex(item => item.id === rowData.id);
    if (!rowData.currentStock && !rowData.altUomCurrentStock) {
      rowData.currentStock = undefined;
      rowData.amount = undefined;
      rowData.color = undefined;
      rowData.variation = 0;
      rowData.absolute = 0;
      rowData.absolute = rowData.absolute.toString();
      rowData.variation = rowData.variation.toString();
    }
    else {
      let finalStockAndValue = this.calculateFinalStockandValue(rowData);
      rowData.amount = +finalStockAndValue.finalStockValue;
      rowData.finalCurrentStock = +finalStockAndValue.finalStock;
      rowData.variation = Math.abs(rowData.finalCurrentStock - rowData.inStock).toFixed(3);
      rowData.absolute = (rowData.finalCurrentStock - rowData.inStock).toFixed(3);
      rowData.variance = Math.sign(rowData.absolute);
      rowData.color = (rowData.finalCurrentStock - rowData.allocated) < 0 ? "red" : undefined;
      // variance percent calculation
      rowData.variancePercent = rowData.inStock > 0 ? (((rowData.finalCurrentStock - rowData.inStock) * 100) / rowData.inStock).toFixed(2) : rowData.finalCurrentStock;
      (index > -1) ? this.stockUpdateList.splice(index, 1, rowData) : this.stockUpdateList.push(rowData);
    }
    this.enableStockTake = this.stockUpdateList.every(item => item.finalCurrentStock !== null && item.rate !== null);
  }

  calculateFinalStockandValue(rowData) {
    let baseUOMStock: number = isNaN(parseFloat(rowData.currentStock)) ? 0 : parseFloat(rowData.currentStock);
    let altUOMStock: number = this.convertAltUomStockToBaseUomStock(rowData);
    let finalStock: number = baseUOMStock + altUOMStock;
    let finalStockValue: number = finalStock * rowData.rate;
    return { "finalStock": finalStock.toFixed(3), "finalStockValue": finalStockValue.toFixed(3) };
  }

  convertAltUomStockToBaseUomStock(rowData): number {
    let convertedStock: number = 0;
    let altUomStock = rowData.altUomCurrentStock;
    let uomId = rowData.rowSelectedUom;
    if (altUomStock && uomId) {
      let chosenAltUoM = rowData.alternateUoms.find(altUoM => {
        return altUoM.id == uomId;
      });
      convertedStock = (parseFloat(isNaN(altUomStock) ? 0 : altUomStock) / parseFloat(chosenAltUoM.alternateConversionFactor)) * parseFloat(chosenAltUoM.baseUomFactor);
    }
    return convertedStock;
  }

  // to update the selected values on submit
  approve() {
    if (this.stockUpdateList.length > 0) {
      this.restrictSave = true;
      let takeObject = false;
      // this.stockUpdateList.find(item => {
      //   if (Number(item.currentStock) - Number(item.allocated) < 0) {
      //     takeObject = true;
      //     this.toastrMsgService.warning(
      //       '',
      //       this.message.stockTakeWarn,
      //       this.message.toastrTime
      //     );
      //   }
      // });
      let filteredList = this.stockUpdateList.filter(item => {
        return item.currentStock || item.altUomCurrentStock;
      });

      if (takeObject === false) {
        this.stockUpdateListDTO = [];

        filteredList.forEach(item => {
          this.stockUpdateListDTO.push({
            stockId: item.id,
            currentStock: item.finalCurrentStock,
            remarks: this.remarks,
            createdDate: this.documentDate,
            itemCode: item.itemCode,
            warehouse: item.warehouse,
            bin: item.bin,
            cost: item.rate,
            amount: item.amount
          });
        });
        this.stockService.updateStockDetails(this.stockUpdateListDTO).subscribe(
          (data: any) => {
            this.stockDetails(this.paginator);
            this.stockUpdateListDTO = [];
            this.stockUpdateList = [];
            this.restrictSave = false;
            this.showPreview = false;
            this.route.navigateByUrl('/stock-take-list');
            this.toastrMsgService.success(
              '',
              this.message.takeSuccessMsg,
              this.message.toastrTime
            );
            // }
          },
          (err: any) => {
            this.restrictSave = false;
            if (navigator.onLine === false) {
              this.toastrMsgService.warning(
                '',
                this.message.noInternet,
                this.message.toastrTime
              );
            } else {
              this.toastrMsgService.error(
                '',
                this.message.errorMsg,
                this.message.toastrTime
              );
            }
            this.paginator.loading = false;
          }
        );
      }
    }
    // }
  }
  // to clear the dropdown
  clearAll() {
    this.clear = true;
    this.selectedWare = { id: '' };
    this.selectedSubCategory = { id: '' };
    this.selectedCategory = { id: '' };
    this.selectedLocation = { id: '' };
    this.selectedItem = { id: '' };
    this.stockDetails(this.paginator);
  }
  // to get the stock details report
  stockDetailsReport(lazyLoadData: string) {
    if (this.selectedWare == null) {
      this.selectedWare = [];
      this.selectedWare.id = '';
    }
    if (this.selectedItem == null) {
      this.selectedItem = [];
      this.selectedItem.id = '';
    }
    if (this.selectedSubCategory == null) {
      this.selectedSubCategory = [];
      this.selectedSubCategory.id = '';
    }
    if (this.selectedCategory == null) {
      this.selectedCategory = [];
      this.selectedCategory.id = '';
    }
    if (this.selectedLocation == null) {
      this.selectedLocation = [];
      this.selectedLocation.id = '';
    }
    this.tableDisplay = true;
    this.stockService
      .getStockDetails(
        this.selectedWare.id,
        this.selectedSubCategory.id,
        this.selectedCategory.id,
        this.selectedLocation.id,
        this.selectedItem.id,
        0,
        this.totalCount,
        lazyLoadData
      )
      .subscribe(
        (data: HttpResponse<any>) => {
          this.stockDetailsReportList = data.body;
          this.csvList = [];
          this.stockDetailsReportList.forEach(item => {
            if (item.expiryDate !== null) {
              item.newDate = this.datePipe.transform(
                new Date(item.expiryDate),
                'dd-MM-yyyy'
              );
            } else {
              item.newDate = '';
            }
            item.currentStock = null;
            item.inStock = item.inStock.toString();
            item.allocated = item.allocated;

            this.csvList.push({
              itemCode: item.itemCode,
              itemName: item.itemName,
              category: item.category,
              subcategory: item.subcategory,
              batch: item.batch || '',
              warehouseCode: item.warehouseCode,
              newDate: item.newDate,
              uom : item.uom,
              inStock: '',
              altUOM: item.alternateUoms && item.alternateUoms.length > 0?item.alternateUoms[0].alternateUomName?item.alternateUoms[0].alternateUomName:'':'',
              altInStock: ''
            });
          });

          new AngularCsv(this.csvList, 'StockTake', this.csvOptions);
        },
        (err: any) => {
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              '',
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
        }
      );
  }
  // on download csv button click
  downloadCSV() {
    this.stockDetailsReport(this.paginator.sortOrder);
  }
  binClick(event: any) {
    if (event === 'ddStatusWare') {
      this.ddStatusWare.filled = true;
    } else if (event === 'ddStatusLoc') {
      this.ddStatusLoc.filled = true;
    } else if (event === 'ddStatusSub') {
      this.ddStatusSub.filled = true;
    } else if (event === 'ddStatusCat') {
      this.ddStatusCat.filled = true;
    } else if (event === 'ddStatusItem') {
      this.ddStatusItem.filled = true;
    }
  }

  // on csv upload
  submit() {
    this.uploadTrue = false;
    this.restrictSave = true;
    const formData = new FormData();
    formData.append('multipartFile', this.csvFile);
    this.stockService.uploadCSV(formData).subscribe(
      (data: any) => {
        this.stockDetails(this.paginator);
        if (data === null) {
          this.toastrMsgService.success(
            '',
            this.message.uploadCsvSuccess,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      },
      (err: any) => {
        this.restrictSave = false;
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } 
        else if(Array.isArray(err.error) && err.error.length) {
          this.transactionErrorList = err.error;
        }
        else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }

      }
    );
  }
  // on upload csv cancel
  cancel() {
    this.uploadTrue = false;
  }
  // on select the csv file to upload
  onSelectFile(event: any) {
    this.uploadTrue = false;
    if (event.target.files) {
      if (
        this.fileUploadService.checkFileType(
          event.target.files[0].type,
          event.target.files[0].name
        )
      ) {
        this.csvFile = event.target.files[0];
        this.fileName = event.target.files[0].name;
        this.uploadTrue = true;
      } else {
        this.toastrMsgService.error(
          '',
          this.message.csvFailure,
          this.message.toastrTime
        );
      }
      event.target.value = '';
    }
  }
  // left arrow click
  moveleft() {
    document.getElementsByClassName(
      'ui-table-scrollable-body'
    )[0].scrollLeft -= 200;
  }
  // right arrow click
  moveright() {
    document.getElementsByClassName(
      'ui-table-scrollable-body'
    )[0].scrollLeft += 200;
  }
}
