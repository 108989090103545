import { AuthGuard } from './../../core/guards/auth-guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListItemsComponent } from '@app/feature/manage-items/list-items/list-items.component';
import { AddItemsComponent } from '@app/feature/manage-items/add-items/add-items.component';
import { EditItemsComponent } from './edit-items/edit-items.component';
import { ViewItemComponent } from './view-item/view-item.component';

const routes: Routes = [
  // {path:'manage-items', component: ListItemsComponent},
  { path: 'manage-items/add-items', component: AddItemsComponent, canActivate: [AuthGuard] },
  { path: 'manage-items', component: ListItemsComponent, canActivate: [AuthGuard] },
  { path: 'manage-items/edit-item/:id', component: EditItemsComponent, canActivate: [AuthGuard] },
  { path: 'manage-items/view-item/:id', component: ViewItemComponent, canActivate: [AuthGuard] }


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageItemsRoutingModule {

}
