import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuItem, Breadcrumb } from 'primeng/primeng';
import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';

import { Paginator } from '@app/shared/models/paginator.model';
import * as Constants from '@app/core/constants/common.constants';
import { Col } from '@app/shared/models/table-header.model';
import { ManageStockService } from '@app/core/services/manage-stock.service';

import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-stock-take-view',
  templateUrl: './stock-take-view.component.html',
  styleUrls: ['./stock-take-view.component.scss']
})
export class StockTakeViewComponent implements OnInit {

  transactionId: string = undefined;

  paginator: Paginator;
  message: any;
  pageCount: number;
  filterText: string;
  stockTakeDetails: any = {};
  datePipe = new DatePipe('en-US');

  breadcrumb: MenuItem[];
  stockTakeList: any = [];
  cols: Col[];

  @ViewChild('primePaginator') primePaginator: ezPaginator;
  @ViewChild('dt') dataTable: Table;

  constructor(
    private stockService: ManageStockService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.transactionId = this.route.snapshot.paramMap.get('id');

    this.message = Constants.MESSAGES;
    this.pageCount = Constants.PTABLE_PAGE_COUNT;

    this.breadcrumb = [
      { label: 'Manage Stock Take', routerLink: '/stock-take-list' },
      { label: 'Stock Take View', url: '' }
    ];

    this.cols = [
      { field: 'itemCode', header: 'Item Code' },
      { field: 'itemName', header: 'Item Name' },
      { field: 'subcategory', header: 'Subcategory' },
      { field: 'currentStock', header: 'Current Stock' },
      { field: 'uom', header: 'UOM' },
      { field: 'location', header: 'Location' },
      { field: 'warehouse', header: 'Warehouse' },
      { field: 'bin', header: 'Bin(Shelf/Rack)' },
      { field: 'batch', header: 'Batch' },
      { field: 'expiryDate', header: 'Expiry Date' }
    ];

    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };

    this.getStockTakeDetails();
  }

  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      this.filterText = event.sortField;
      // if (this.filterText === 'location') {
      //   this.filterText = 'warehouse.location.name';
      // } else if (this.filterText === 'subcategory') {
      //   this.filterText = 'batch.item.subcategory.name';
      // } else if (this.filterText === 'category') {
      //   this.filterText = 'batch.item.category.name';
      // } else if (this.filterText === 'itemCode') {
      //   this.filterText = 'batch.item.code';
      // } else if (this.filterText === 'itemName') {
      //   this.filterText = 'batch.item.name';
      // }
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = this.filterText + ',DESC';
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = this.filterText + ',ASC';
      }
    } else {
      this.paginator.sortOrder = 'createdDate,DESC';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getStockTakeDetails();
  }

  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  onCancel() {
    this.router.navigateByUrl('/stock-take-list');
  }

  getStockTakeDetails() {
    this.stockService.getTransDetails(this.transactionId).subscribe(
      (data) => {
        this.stockTakeDetails.createdDate = this.datePipe.transform(data.createdDate, 'dd-MM-yyyy');
        this.stockTakeDetails.remarks = data.remarks;
        this.stockTakeList = data.batchList.map(batch => {
            let processedBatch = {
            'itemCode': batch.item.code,
            'itemName': batch.item.name,
            'category': batch.item.category.name,
            'subcategory': batch.item.subcategory.name,
            'currentStock': batch.availableStock,
            'value': batch.price,
            'uom': batch.uom,
            'location': batch.warehouse.location.name,
            'warehouse': batch.warehouse.name,
            'bin': batch.bin.name,
            'batch': batch.batchNumber,
            'expiryDate': this.datePipe.transform(batch.expiryDate, 'dd-MM-yyyy'),
          }
          return processedBatch;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

}
