import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageSubCategoryRoutingModule } from '@app/feature/manage-sub-category/manage-sub-category-routing.module';
import { ListSubCategoryComponent } from '@app/feature/manage-sub-category/list-sub-category/list-sub-category.component';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AddSubcategoryComponent } from './add-subcategory/add-subcategory.component';
import { EditSubcategoryComponent } from './edit-subcategory/edit-subcategory.component';
import {InputTextModule} from 'primeng/inputtext';

@NgModule({
  declarations: [ListSubCategoryComponent, AddSubcategoryComponent, EditSubcategoryComponent],
  imports: [
    CommonModule,
    ManageSubCategoryRoutingModule,
    FormsModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    TooltipModule,
    DropdownModule,
    BreadcrumbModule,
    InputTextModule
  ],
  providers: [ConfirmationService],
  exports: [ListSubCategoryComponent],
  entryComponents: [
      AddSubcategoryComponent,EditSubcategoryComponent
]
})
export class ManageSubCategoryModule { }
