export const MESSAGES = {
    toastrTime: 4000,
    pageNo: 0,
    limit: 10,
    totalCount: 0,
    skip: 0,
    batchMsg: 'Batch# already exist',
    csvFailure: 'Invalid file format',
    uploadCsvSuccess: 'File uploaded successfully',
    stockInAlert: ' Reorder level is Zero,hence mail will not be triggered',
    takeSuccessMsg: 'Stock take updated successfully',
    stockOutSuccessMsg: 'Stock out processed successfully',
    addStockInSuccessMsg: 'Stock in added successfully',
    transferSuccessMsg: 'Stock transferred successfully',
    errorMsgQuantity: "Oops, something wasn't right. Please check and try again",
    categoryAddSuccessMsg: 'Category added successfully',
    categoryAddDuplicateMsg: 'Category already exists',
    categoryEditSuccessMsg: 'Category updated successfully',
    subcategoryAddSuccessMsg: 'Sub Category added successfully',
    subcategoryAddDuplicateMsg: 'Sub Category already exists',
    subcategoryEditSuccessMsg: 'Sub Category updated successfully',
    locationAddSuccessMsg: 'Location added successfully',
    locationEditSuccessMsg: 'Location updated successfully',
    warehouseAddSuccessMsg: 'Warehouse added successfully',
    warehouseEditSuccessMsg: 'Warehouse updated successfully',
    binAddSuccessMsg: 'Bin added successfully',
    binEditSuccessMsg: 'Bin updated successfully',
    errorMsg: 'Something went wrong.Please try again!',
    activeCategoryExistMsg: 'Active sub categories exists for this category!',
    activeWarehouseExistMsg: 'Active warehouses exists for this location!',
    activeBinExistMsg: 'Active bins exists for this warehouse!',
    categoryDltMsg: 'Category deleted successfully',
    subcategoryDltMsg: 'Sub Category deleted successfully',
    locationDltMsg: 'Location deleted successfully',
    warehouseDltMsg: 'Warehouse deleted successfully',
    binDltMsg: 'Bin deleted successfully',
    stockTakeWarn: 'Few items reached negative values since items are already allocated',
    noInternet: 'Please check your internet and try again.',
    invalidInput: 'Please provide valid inputs',
    completeInputs: 'Please complete the inputs',
    itemaddSuccess: 'Item added successfully',
    itemDeleteSuccess: 'Item deleted successfully',
    itemDeleteFail: 'Item cannot be deleted',
    itemeditSuccess: 'Item updated successfully',
    subrecipeDltMsg: 'Subreceipe deleted successfully',
    activeSubrecipeExistMsg: 'Active sub receipe exists for this subreceipe!',
    moreThanSixMonths:"For query with more than 6 Months, Please contact service provider.",
    noData:"No Data available.",
};
export const PTABLE_PAGE_COUNT = 10;
export const ROLE_BUYER = 'ROLE_BUYER';
export const DEFAULT_MGMT_METHOD = 'Yes';
export const STOCKIN = 'STOCKIN';
export const GRN = 'GRN';
export const ALL = 'ALL';
export const STOCKOUT = 'STOCKOUT';
export const PROD_ISSUE = 'PROD_ISSUE';
export const ALL_STOCK_OUT = 'ALL_STOCK_OUT';
export const PROCESS_ORDER = 'PROCESS_ORDER';
