import {
  Component,
  AfterViewInit,
  OnInit,
  ChangeDetectorRef,
  ViewChild
} from "@angular/core";
import { ManageStockService } from "@app/core/services/manage-stock.service";
import { DashboardService } from "@app/core/services/dashboard.service";
import { AuthService } from "@app/core/services/auth.service";
import { DatePipe } from "@angular/common";
import { ToastrMsgService } from "@app/shared/services/toastr-msg.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Paginator } from "@app/shared/models/paginator.model";
import { Paginator as ezPaginator } from "primeng/paginator";
import * as Constants from "@app/core/constants/common.constants";
import { HttpResponse } from "@angular/common/http";
import { TreeNode, Dropdown } from "primeng/primeng";
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';

@Component({
  selector: "app-tree-inventory-report",
  templateUrl: "./tree-inventory-report.component.html",
  styleUrls: ["./tree-inventory-report.component.scss"]
})
export class TreeInventoryReportComponent implements AfterViewInit {
  pageCount: number;
  totalCount: number;
  files: TreeNode[];
  data: any[];
  loading: boolean;
  message: any;
  paginator: Paginator;
  cols: any[];
  reportForm: FormGroup;
  treeTableInventoryList = [];
  treeTableInventoryListNew = [];
  treeTableList = [];
  itemNames: any = [];
  itemName: any;
  warehouseName: any;
  itemId: number;
  wareId: number;
  locId: number;
  categoryId: number;
  batchId: number;
  itemCode: any;
  categoryName: any;
  categories: any = [];
  warehouseList: any = [];
  batchNo: any;
  batchList: any = [];
  locationName: any;
  locationList: any = [];
   csvList: any;
   csvOptions: any;
   pTree=[];
   defaultBin: any;
   batch:any;
   uom:any;
   count=1;

  constructor(
    public stockService: ManageStockService,
    public dashboardService: DashboardService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
    private toastrMsgService: ToastrMsgService,
    public route: Router,
    private fb: FormBuilder,
    private router: ActivatedRoute
  ) {
    this.pageCount = Constants.PTABLE_PAGE_COUNT;
    this.csvOptions = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: false,
          useBom: true,
          noDownload: false,
          headers: [
          'Sr.No',
          'Code',
          'Name',
          'Location',
          'Warehouse',
          'Bin(Shelf/Rack)',
        //  'Allocated',
        //  'Available',
          'In Stock',
          'Value',
          'UOM',
          'Category',
          'Sub Category',
           ]
        };
    this.message = Constants.MESSAGES;
  }
  @ViewChild("primePaginator") primePaginator: ezPaginator;
  @ViewChild('ddStatusName') ddStatusName: Dropdown;
  @ViewChild('ddStatusCode') ddStatusCode: Dropdown;
  @ViewChild('ddStatusWare') ddStatusWare: Dropdown;
  @ViewChild('ddStatusBatch') ddStatusBatch: Dropdown;
  @ViewChild('ddStatusCat') ddStatusCat: Dropdown;
  @ViewChild('ddStatusLoc') ddStatusLoc: Dropdown;
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
  ngOnInit() {
    this.formSetup();
    this.itemNameDetails();
    this.categoryDetails();
    this.batchDetails();
    this.paginatorInitial();
    this.locationDetails();
    this.cols = [
      { field: "name", header: "Code", width: "30%" },
      { field: "item", header: "Name", width: "30%" },
     // { field: "totalAllocated", header: "Allocated", width: "10%" },
     // { field: "totalAvailable", header: "Available", width: "10%" },
      { field: "uom", header: "UOM", width: "10%" },
      { field: "totalInStock", header: "In Stock", width: "10%" },
     // { field: "totalOnOrder", header: "On Order", width: "10%" },
      { field: "totalValue", header: "Value", width: "10%" }
    ];
  }
  formSetup() {
    this.reportForm = this.fb.group({
      itemName: [{ value: '' }],
      itemCode: [{ value: '' }],
      warehouse: [{ value: ''}],
      batch: [{ value: ''}],
      category: [{value: ''}],
      location: [{value: ''}]
    });
  }

  itemNameDetails() {
    this.stockService.getItemNames().subscribe(
      (data: any) => {
        this.itemNames = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  batchDetails() {
    this.stockService.getBatchList().subscribe(
      data => {
        this.batchList = data;
      },
      err => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  warehouseDetails() {
    this.stockService.getWarehouse(this.locationName).subscribe(
      data => {
        this.warehouseList = data;
      },
      err => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

  // to get the category details and showing in the dropdown
  categoryDetails() {
    this.stockService.getCategories().subscribe(
      (data: any) => {
        this.categories = data;
      },
      (err: any) => {
        if (navigator.onLine === false) {
          this.toastrMsgService.warning(
            '',
            this.message.noInternet,
            this.message.toastrTime
          );
        } else {
          this.toastrMsgService.error(
            '',
            this.message.errorMsg,
            this.message.toastrTime
          );
        }
      }
    );
  }

 itemNameFilter() {
 this.itemId = this.itemName.id;
 }
 searchFilter()
  {
    this.getTreeInventoryTableDetails(this.paginator);
  }
  itemCodeFilter() {
  }
  wareFilter() {
    this.wareId = this.warehouseName.id;
  }
  catFilter() {
    this.categoryId = this.categoryName.id;
  }
  locFilter(){
    this.locId = this.locationName.id;
    this.warehouseDetails();}
  clearFilter() {
    this.reportForm.reset();
    this.itemId = null;
    this.wareId = null;
    this.categoryId = null;
    this.batchId = null;
    this.locId = null;
    this.getTreeInventoryTableDetails(this.paginator);
  }

  binClick(event: any) {
  if (event === 'ddStatusName') {
    this.ddStatusName.filled = true;
  } else if (event === 'ddStatusCode') {
    this.ddStatusCode.filled = true;
  }  else if (event === 'ddStatusWare') {
    this.ddStatusWare.filled = true;
  }  else if (event === 'ddStatusCat') {
    this.ddStatusCat.filled = true;
  } else if (event === 'ddStatusLoc') {
    this.ddStatusLoc.filled = true;
  }
}
  getTreeInventoryTableDetails(lazyData: Paginator) {
    this.loading = true;
    this.files = [];
    this.stockService
      .getTreetableInventory(
        this.itemId,
        this.wareId,
        this.categoryId,
        this.batchId,
        this.locId,
        lazyData.pageNo,
        lazyData.limit, "")
      .subscribe(
        (data: HttpResponse<any>) => {
          const pTree = [];
          this.treeTableInventoryList = data.body;
          for (let i = 0; i < data.body.length; i++) {
            const element = data.body[i];
            const node = {
              data: {
                name: element.item.code,
                item: element.item.name,
                id: element.item.id,
                totalAllocated: element.summaryDTO.totalAllocated,
                totalAvailable: element.summaryDTO.totalAvailable,
                totalInStock: element.summaryDTO.totalInStock,
                totalOnOrder: element.summaryDTO.totalOnOrder,
                totalValue: element.summaryDTO.totalValue,
                uom: element.summaryDTO.uom
              },
              leaf: !(element.locationInventoryDTOList.length > 0)
            };
            pTree.push(node);
          }
          this.files = pTree;
          this.files = [...this.files];
          this.loading = false;

          this.totalCount = Number(data.headers.get("X-Total-Count"));

          if (this.totalCount <= this.pageCount) {
            this.paginator.paginator = false;
          } else {
            this.paginator.paginator = true;
          }
          this.paginator.loading = false;
          this.paginator.totalCount = this.totalCount;
        },
        (err: any) => {
          this.loading = false;
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              "",
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
              "",
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
          this.paginator.loading = false;
        }
      );
  }
  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: "",
      sortColumn: "",
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }
  loadUsersLazy(event: any) {
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.getTreeInventoryTableDetails(this.paginator);
  }
  onNodeExpand(event) {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      const node = event.node;
      node.children = [];

      if (event.node.parent === undefined || event.node.parent === null) {
        this.treeTableInventoryList.forEach(item => {
          if (item.item.id === event.node.data.id) {
            const locationDto = item.locationInventoryDTOList;
            locationDto.forEach(location => {
              const WareDto = location.warehouseWiseInventoryDTOList;
              const nodeNew = {
                data: {
                  name: location.location.code,
                  item: location.location.name,
                  id: location.location.id,
                  totalAllocated: location.summaryDTO.totalAllocated,
                  totalAvailable: location.summaryDTO.totalAvailable,
                  totalInStock: location.summaryDTO.totalInStock,
                  totalOnOrder: location.summaryDTO.totalOnOrder,
                  totalValue: location.summaryDTO.totalValue,
                  uom: location.summaryDTO.uom
                },
                leaf: !(WareDto.length > 0)
              };
              node.children.push(nodeNew);
            });
          }
        });
      } else {
        if (
          event.node.parent.parent === undefined ||
          event.node.parent.parent === null
        ) {
          this.treeTableInventoryList.forEach(item => {
            if (item.item.id === event.node.parent.data.id) {
              const locationDto = item.locationInventoryDTOList;
              locationDto.forEach(location => {
                if (location.location.id === event.node.data.id) {
                  const warehouseDto = location.warehouseWiseInventoryDTOList;
                  warehouseDto.forEach(ware => {
                    const stockDto = ware.stockList;
                    const nodeNew = {
                      data: {
                        name: ware.warehouse.code,
                        item: ware.warehouse.name,
                        id: ware.warehouse.id,
                        totalAllocated: ware.summaryDTO.totalAllocated,
                        totalAvailable: ware.summaryDTO.totalAvailable,
                        totalInStock: ware.summaryDTO.totalInStock,
                        totalOnOrder: ware.summaryDTO.totalOnOrder,
                        totalValue: ware.summaryDTO.totalValue,
                        uom: ware.summaryDTO.uom
                      },
                      leaf: !(stockDto.length > 0)
                    };
                    node.children.push(nodeNew);
                  });
                }
              });
            }
          });
        } else {

          this.treeTableInventoryList.forEach(item => {
            if (item.item.id === event.node.parent.parent.data.id) {
              const locationDto = item.locationInventoryDTOList;
              locationDto.forEach(location => {
                if (location.location.id === event.node.parent.data.id) {
                  const warehouseDto = location.warehouseWiseInventoryDTOList;
                  warehouseDto.forEach(ware => {
                    if (ware.warehouse.id === event.node.data.id) {
                      const stockDto = ware.binWiseInventoryDTOList;
                      stockDto.forEach(stock => {
                        const nodeNew = {
                          data: {
                            name: stock.bin.code,
                            item: stock.bin.name,
                            id: stock.id,
                            totalAllocated: stock.summaryDTO.totalAllocated,
                            totalAvailable: stock.summaryDTO.totalAvailable,
                            totalInStock: stock.summaryDTO.totalInStock,
                            totalOnOrder: stock.summaryDTO.totalOnOrder,
                            totalValue: stock.summaryDTO.totalValue,
                            uom: stock.uom
                          },
                          leaf: true
                        };
                        node.children.push(nodeNew);
                      });

                    }
                  });

                }
              });
            }
          });
        }
      }

      this.files = [...this.files];
    }, 10);
  }
  getTreeInventoryTableReportDetails(lazyData) {
  this.loading = true;
  //this.files = [];
  this.stockService.
  exportTreeInventoryReportCSV(this.itemId,
                        this.wareId,
                        this.categoryId,
                        this.batchId,
                        this.locId,
                        null,
                        null)
   .subscribe(
    (data: HttpResponse<any>) => {
    this.treeTableList = data.body;
    if(this.treeTableList.length==0){
      this.toastrMsgService.warning('CSV not exported',this.message.noData,this.message.toastrTime);
     return
   }
    this.csvList = [];
       this.treeTableList.forEach(report => {
         const location1 = report.locationInventoryDTOList;
         if (report.item.defaultBin == null) {
           this.defaultBin = '';
         } else {
           this.defaultBin = report.item.defaultBin.name;
         }
         if (location1 == null) {
           this.csvList.push({
             SerialNo: this.count++,
             code: report.item.code,
             name: report.item.name,
             location: report.item.defaultLocation.name,
             warehouse: report.item.defaultWarehouse.name,
             bin: this.defaultBin,
             totalInStock: report.summaryDTO.totalInStock,
             totalValue: report.summaryDTO.totalValue,
             uom: report.summaryDTO.uom,
             category: report.item.category.name,
             subcategory: report.item.subcategory.name,
           });
         }
         else {
           for (let j = 0; j < report.locationInventoryDTOList.length; j++) {
             const location = report.locationInventoryDTOList[j];
             const ware1 = location.warehouseWiseInventoryDTOList;
             if (ware1 == null) {
               this.csvList.push({
                 SerialNo: this.count++,
                 code: report.item.code,
                 name: report.item.name,
                 location: location.location.name,
                 warehouse: location.warehouseWiseInventoryDTOList.warehouse.name, bin: this.defaultBin,
                 totalInStock: ware1.summaryDTO.totalInStock,
                 totalValue: ware1.summaryDTO.totalValue,
                 uom: location.summaryDTO.uom,
                 category: report.item.category.name,
                 subcategory: report.item.subcategory.name,
               });
             } else {
               for (let k = 0; k < location.warehouseWiseInventoryDTOList.length; k++) {
                 const ware = location.warehouseWiseInventoryDTOList[k];
                 const stocklist1 = ware.stockList;
                 if (!ware.binWiseInventoryDTOList.length) {
                   this.csvList.push({
                     SerialNo: this.count++,
                     code: report.item.code,
                     name: report.item.name,
                     location: location.location.name,
                     warehouse: ware.warehouse.name,
                     bin: "-",
                     totalInStock: ware.summaryDTO.totalInStock,
                     totalValue: ware.summaryDTO.totalValue,
                     uom: ware.summaryDTO.uom,
                     category: report.item.category.name,
                     subcategory: report.item.subcategory.name,
                   });
                 }
                 else {
                   for (let s = 0; s < ware.binWiseInventoryDTOList.length; s++) {
                     const stock = ware.binWiseInventoryDTOList[s];
                     if (stock.bin == null) { this.defaultBin = null; } else { this.defaultBin = stock.bin.code; }
                     if (stock.batch == null) { this.batchNo = null; } else { this.batchNo = stock.batch.batchNumber; }
                     if (report.summaryDTO.uom === null) { this.uom = null; } else { if (ware.summaryDTO.uom !== null) { this.uom = report.summaryDTO.uom; } else { this.uom = location.summaryDTO.uom; } }
                     this.csvList.push({
                       SerialNo: this.count++,
                       code: report.item.code,
                       name: report.item.name,
                       location: location.location.name,
                       warehouse: ware.warehouse.name,
                       bin: this.defaultBin == null ? "-" : this.defaultBin,
                       totalInStock: stock.summaryDTO.totalInStock,
                       totalValue: stock.summaryDTO.totalValue,
                       uom: this.uom,
                       category: report.item.category.name,
                       subcategory: report.item.subcategory.name,
                     });
                   }
                 }
               }
             }
           }
         }
       });
       new AngularCsv(this.csvList, 'InventoryReport', this.csvOptions);
       //this.files = [...this.files];
       this.loading = false;
      //  this.totalCount = Number(data.headers.get("X-Total-Count"));
      //  if (this.totalCount <= this.pageCount) {
      //    this.paginator.paginator = false;
      //  } else {
      //    this.paginator.paginator = true;
      //  }
       this.paginator.loading = false;
       //this.paginator.totalCount = this.totalCount;
     },
     (err: any) => {
       this.loading = false;
       if (navigator.onLine === false) {
         this.toastrMsgService.warning(
           "",
           this.message.noInternet,
           this.message.toastrTime
         );
       } else {
         this.toastrMsgService.error(
           "",
           this.message.errorMsg,
           this.message.toastrTime
         );
       }
       this.paginator.loading = false;
     }
   );
  }
     downloadCSV() {
        this.getTreeInventoryTableReportDetails(this.paginator);
    }

    locationDetails() {
        this.stockService.getLocation().subscribe(
          (data: any) => {
            this.locationList = data;
          },
          (err: any) => {
            if (navigator.onLine === false) {
              this.toastrMsgService.warning(
                '',
                this.message.noInternet,
                this.message.toastrTime
              );
            } else {
              this.toastrMsgService.error(
                '',
                this.message.errorMsg,
                this.message.toastrTime
              );
            }
          }
        );
      }
}
