import { AuthGuard } from './../../core/guards/auth-guard';
import { Routes, RouterModule } from '@angular/router';
import { StockTakeAddComponent } from '@app/feature/manage-stock/stock-take-add/stock-take.component';
import { StockTakeListComponent } from '@app/feature/manage-stock/stock-take-list/stock-take-list.component';
import { StockTakeViewComponent } from '@app/feature/manage-stock/stock-take-view/stock-take-view.component';
import { NgModule } from '@angular/core';
import { StockTransferListComponent } from '@app/feature/manage-stock/stock-transfer-list/stock-transfer-list.component';
import { StockTransferAddComponent } from '@app/feature/manage-stock/stock-transfer-add/stock-transfer-add.component';
import { StockTransferViewComponent } from '@app/feature/manage-stock/stock-transfer-view/stock-transfer-view.component';

const routes: Routes = [
  { path: 'stock-take-list', component: StockTakeListComponent, canActivate: [AuthGuard] },
  { path: 'stock-take-add', component: StockTakeAddComponent, canActivate: [AuthGuard] },
  { path: 'stock-take-view/:id', component: StockTakeViewComponent, canActivate: [AuthGuard] },
  { path: 'stock/transfer', component: StockTransferListComponent, canActivate: [AuthGuard] },
  { path: 'stock/transfer-add', component: StockTransferAddComponent, canActivate: [AuthGuard] },
  { path: 'stock/transfer-view/:id', component: StockTransferViewComponent, canActivate: [AuthGuard] }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageStockRoutingModule { }

