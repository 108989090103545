import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private navbarEvent = new BehaviorSubject(true);
  navbarState = this.navbarEvent.asObservable();
  constructor() { }
  changeNavbarState(data: boolean) {
    this.navbarEvent.next(data);
  }
}
