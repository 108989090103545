import { EditSubrecipeComponent } from './edit-subrecipe/edit-subrecipe.component';
import { AuthGuard } from './../../core/guards/auth-guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListSubrecipeComponent } from '@app/feature/manage-subrecipe/list-subrecipe/list-subrecipe.component';
import { AddSubrecipesComponent } from '@app/feature/manage-subrecipe/add-subrecipes/add-subrecipes.component';
import { SubRecipeListingComponent } from './sub-recipe-listing/sub-recipe-listing.component';
import { ViewSubrecipeComponent } from './view-subrecipe/view-subrecipe.component';

const routes: Routes = [
  {
    path: 'manage-subrecipe',
    component: SubRecipeListingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-subrecipe/add-subrecipes',
    component: AddSubrecipesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-subrecipe/edit-subrecipes/:companyID/:parentItemCode',
    component: EditSubrecipeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-subrecipe/view-subrecipe/:parentItemCode',
    component: ViewSubrecipeComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageSubrecipeRoutingModule {

}
