import { User } from '@app/shared/models/user.model';
import { SignInService } from '@app/core/services/sign-in.service';
import { AuthService } from '@app/core/services/auth.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userName: string = "John Doe";
  currentUser: User;
  message: any
  display: boolean = false;


  constructor(private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (userData: User) => {
        this.currentUser = userData;
      }
    );
    // this.getDetails();
  }

  //  getDetails(){
  //   this.authService.getAuthenticatedUserDetails().subscribe(
  //     (data)=>{
  //       this.message=data;
  //     }
  //   );
  //  }
  userLogout() {
    sessionStorage.removeItem('checkSubrecipePassword');
    this.authService.purgeAuth();
    this.router.navigateByUrl('/login');
  }
  showDialog() {
    this.display = true;
  }

}
