// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_ENDPOINT: 'https://ezyinvmanual.ezyprocure.com//api/',
  EP_API_ENDPOINT: 'https://manual.ezyprocure.com/api/',
};

/**
 * Zingrill
 * API_ENDPOINT: 'https://zingrill-uat-inventory-api.ezyprocure.com/api/',
 * EP_API_ENDPOINT: 'https://zingrill-uat.ezyprocure.com/api/',
 * Dev
 * API_ENDPOINT: 'https://ezyinventory-dev-api.ezyprocure.com/api/'
 * EP_API_ENDPOINT: 'https://dev.ezyprocure.com/api/'
 * Localhost
 * API_ENDPOINT: 'http://localhost:8080/api/'
 * EP_API_ENDPOINT: 'http://localhost:8080/api/'
 * Misc
 * API_ENDPOINT: 'http://192.168.32.127:8080/api/'
 * API_ENDPOINT: 'http:192.168.32.142/api/'
 */


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
