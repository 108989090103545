import { AuthService } from '@app/core/services/auth.service';
import { SignInService } from '@app/core/services/sign-in.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import * as Constants from '@app/core/constants/common.constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  error: boolean = false;
  username: string;
  password: string;
  message: any;
  errorMessage: string;

  constructor(private signInService: SignInService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrMsgService: ToastrMsgService
  ) { }

  ngOnInit() {
    this.message = Constants.MESSAGES;
    let authToken = this.route.snapshot.queryParams.authtoken;
    if(authToken) {
      this.handleRedirection(authToken);
    }
    else if (this.authService.getToken() && !this.authService.checkTokenExpiry()) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  // Function to detect the changes in input
  inputChanged() {
    this.error = false;
  }

  handleRedirection(authToken: string) {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(authToken);

    this.authService.setToken(authToken);
    this.authService.setRole(decodedToken.auth);
    this.authService.populate();

    if (decodedToken.auth === 'ROLE_BUYER') {
      this.router.navigateByUrl('/dashboard');
    } else if (decodedToken.auth === 'ROLE_SUPPLIER') {
      this.router.navigateByUrl('/dashboard');
    }
  }

  onSubmit(signInForm: NgForm) {
    this.signInService.login(signInForm.value).subscribe(
      (data: any) => {
        if (data.code === 401) {
          this.error = true;
          this.errorMessage = "Invalid credentials"
        } else {
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(data.id_token);

          this.authService.setToken(data.id_token);
          this.authService.setRole(decodedToken.auth);
          this.authService.populate();

          if (decodedToken.auth === 'ROLE_BUYER') {
            this.router.navigateByUrl('/dashboard');
          } else if (decodedToken.auth === 'ROLE_SUPPLIER') {
            this.router.navigateByUrl('/dashboard');
          }
        }
      },
      err => {
        if ( err.status === 400 || err.status === 401 ) {
          this.error = true;
          this.errorMessage = "Invalid credentials"
        } else if( err.status === 403 ) {
          this.error = true;
          this.errorMessage = "You are not authorized to access EzyInventory"
        }
         else if (navigator.onLine === false) {
          this.toastrMsgService.warning('', this.message.noInternet, this.message.toastrTime);
        }
      }
    );
  }
}
