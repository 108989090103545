import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Paginator as ezPaginator } from 'primeng/paginator';
import { Table } from 'primeng/table';

import { Col } from '@app/shared/models/table-header.model';
import { Paginator } from '@app/shared/models/paginator.model';
import * as Constants from '@app/core/constants/common.constants';
import { ToastrMsgService } from '@app/shared/services/toastr-msg.service';
import { ManageStockService } from '@app/core/services/manage-stock.service';
import { AuthService } from '@app/core/services/auth.service';

type FilterByDate = { fromDate: string, toDate: string };

@Component({
  selector: 'app-manage-stock',
  templateUrl: './stock-transfer-list.component.html',
  styleUrls: ['./stock-transfer-list.component.scss']
})
export class StockTransferListComponent implements OnInit {

  cols: Col[];
  stockTransferList: any;
  message: any;
  totalCount: number;
  paginator: Paginator;
  pageCount: number;

  /* filters */
  fromDate: any = null;
  toDate: any = null;
  utcFDate : any = null;
  utcTDate : any = null;

  constructor(
    private toastrMsgService: ToastrMsgService,
    private stockService: ManageStockService,
    private authService: AuthService,
    private router: Router
  ) { }

  @ViewChild('primePaginator') primePaginator: ezPaginator;

  ngOnInit() {
    this.message = Constants.MESSAGES;
    this.pageCount = Constants.PTABLE_PAGE_COUNT;

    this.cols = [
      { field: 'transactionNumber', header: 'Stock Transfer No' },
      { field: 'createdDate', header: 'Document Date' },
      { field: 'comment', header: 'Remarks' }
    ];
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
    this.fromDate=new Date();
    this.toDate=new Date();
    this.stockTransferDetails();
  }

  loadUsersLazy(event: any) {
    if (event.sortField !== undefined) {
      if (event.sortOrder === -1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ',DESC';
      } else if (event.sortOrder === 1) {
        this.paginatorInitial();
        this.primePaginator.first = 0;
        this.paginator.sortOrder = event.sortField + ',ASC';
      }
    } else {
      this.paginator.sortOrder = '';
    }
    this.paginator.loading = true;
    this.paginator.pageNo = event.first / event.rows;
    this.paginator.limit = event.rows;
    this.stockTransferDetails(this.paginator);
  }

  stockTransferDetails(lazyLoadData: Paginator = this.paginator) {
    let filterByDate: FilterByDate | null = { fromDate: "", toDate: "" };
    if (this.fromDate && this.toDate) {
      this.utcFDate = this.utcFromDate(this.fromDate);
      this.utcTDate = this.utcToDate(this.toDate);
      filterByDate.fromDate = this.fromDate;
      filterByDate.toDate = this.toDate;
    }
    else {
      filterByDate = null;
      this.toastrMsgService.error(
        '',
        "Please select From Date and To Date",
        this.message.toastrTime
      );
      return;
    }

    this.stockService
      .getStockTransferList(lazyLoadData.pageNo, lazyLoadData.limit, lazyLoadData.sortOrder, filterByDate)
      .subscribe(
        (data: HttpResponse<any>) => {
          this.stockTransferList = data.body;
          this.totalCount = Number(data.headers.get('X-Total-Count'));

          if (this.totalCount <= this.pageCount) {
            this.paginator.paginator = false;
          } else {
            this.paginator.paginator = true;
          }
          this.paginator.loading = false;
          this.paginator.totalCount = this.totalCount;
        },
        (err: any) => {
          this.paginator.loading = false;
          if (navigator.onLine === false) {
            this.toastrMsgService.warning(
              '',
              this.message.noInternet,
              this.message.toastrTime
            );
          } else {
            this.toastrMsgService.error(
              '',
              this.message.errorMsg,
              this.message.toastrTime
            );
          }
        }
      );
  }

  clearFilters() {
    this.fromDate = "";
    this.toDate = "";
  }

  paginatorInitial() {
    this.paginator = {
      pageNo: this.message.pageNo,
      limit: this.message.limit,
      paginator: true,
      rows: this.pageCount,
      loading: true,
      sortOrder: '',
      sortColumn: '',
      totalCount: this.message.totalCount,
      skip: this.message.skip
    };
  }

  goToView(transId: string) {
    //sessionStorage.setItem('stockTransId', transId);
  }

  addNewtransfer() {
    this.router.navigateByUrl('/stock/transfer-add');
  }

  utcFromDate(fromDate) {
      fromDate = new Date(fromDate.setHours(0,0,0,0));
      return fromDate;
  }

  utcToDate(toDate) {
      toDate = new Date(toDate.setHours(23,59,58,999));
      return toDate;
  }
}
