import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  checkFileType(type: any, fileName?: string) {
    const extension = fileName.substr(fileName.lastIndexOf(".") + 1);
    if (type === "text/csv") {
      return true;
    } // quick fix for csv upload file type checking in chrome
    else if (extension === "csv") {
      return true;
    } else {
      return false;
    }
  }
}
