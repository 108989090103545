export class AddStockIn {
  supplier: any;
  category: any;
  subcategory: any;
  location: any;
  warehouse: any;
  bin: any;
  itemName: any;
  qtyReceived: any;
  allocated: 0;
  onOrder: 0;
  batchNo: any;
  productionDate: any;
  expiryDate: any;
  dateInput: any;
  remarks: any;
  categories: [];
  subCategories: [];
  locations: [];
  warehouses: [];
  bins: [];
  itemNames: [];
  suppliers: [];
  itemCost: any;
  itemValue: any;
  uom: string;
}

export class AddStockInModel {
  batchList: [];
  createdDate: any;
  remarks: any;
  supplier: any;
}
